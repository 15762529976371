import { format } from 'date-fns'
import React, { useMemo } from 'react'
import { Column } from 'react-table'
import styled from 'styled-components'

import translateLabelsToPortuguese from '../../../views/internas/Integracoes/translateLabelstoPortuguese'
import { RefreshBankIntegrationConnectionButton } from '../../Buttons'
import { TrashCanIcon } from '../../Icons'
import { TableData } from './ConnectionsTable'

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  padding-left: 3.5vw;
  padding-right: 3.5vw;
  align-items: center;
`

export const useColumns = (onConnectionDelete: (itemId: string) => void) => {
  const columns = useMemo(
    (): Column<TableData>[] => [
      {
        Header: 'Instituição',
        id: 'Instituição',
        accessor: ({ connection: { conexaoNome } }) => conexaoNome
      },
      {
        Header: 'Contas conectadas',
        id: 'Contas conectadas',
        accessor: ({ connection: { qtdContasCards } }) => qtdContasCards
      },
      {
        Header: 'Status',
        id: 'Status',
        accessor: ({ connection: { status } }) => status,
        Cell: (data) => {
          if (!data?.row?.original?.connection) return ''
          const { status, id } = data.row.original.connection
          const translated = translateLabelsToPortuguese(status)
          if (status === 'TOKEN_NEEDED')
            return <RefreshBankIntegrationConnectionButton itemId={id} text={translated} />
          else return translated || ''
        }
      },
      {
        Header: 'Responsável',
        id: 'Responsável',
        accessor: ({ responsible }) => responsible || ''
      },
      {
        Header: 'Última atualização',
        id: 'Última atualização',
        accessor: ({ connection: { ultimoSucesso } }) => ultimoSucesso,
        Cell: ({ value }) => (value ? format(new Date(value), 'dd/MM/yyyy HH:mm') : '')
      },
      {
        Header: 'Opções',
        id: 'Opções',
        accessor: ({ connection: { id } }) => id,
        Cell: ({ value }) => (
          <OptionsContainer>
            <TrashCanIcon size="small" tooltip="Deletar conexão" onClick={() => onConnectionDelete(value)} />
            <RefreshBankIntegrationConnectionButton size="small" itemId={value} />
          </OptionsContainer>
        )
      }
    ],
    [onConnectionDelete]
  )
  return columns
}
