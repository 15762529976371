import CurrencyInput from 'react-currency-input'
import styled from 'styled-components'

import { StyledMuiDataTable } from '../components/Tables/StyledMuiDataTable'

export const Container = styled.div`
  padding: 2rem 5%;
`

export const Table = styled(StyledMuiDataTable)`
  && {
    margin-bottom: 2rem;
  }

  [class^='MuiToolbar-root-'] {
    background-color: #e4eaf4;
    z-index: 2;
  }
`

export const FooterButtons = styled.div`
  display: flex;
  margin-bottom: 2rem;
  gap: 1rem;
`

export const HomeContainer = styled.div`
  @media screen and (max-width: 1200px) {
    width: 100% !important;
  }
`

export const StyledCurrencyInput = styled(CurrencyInput)`
  && {
    border: none;
    border-bottom: 1px solid #d9d9d9;
    transition: border 0.2s ease-in-out;
    padding: 0.3rem 0.5rem;

    &:active,
    &:focus {
      border-color: ${({ theme }) => theme.colors.secondary};
    }
  }
`
