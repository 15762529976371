import styled from 'styled-components'

import Colors from '../../../domain/Colors'
import { ScrollProps } from './types'

export const ScrollContainer = styled.div<ScrollProps>`
  border-radius: 1vw;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%),
    0px 1px 10px 0px rgb(0 0 0 / 12%);
  width: 100%;
  /* overflow-x: auto; */

  .table-scroll-container {
    overflow-x: initial;
  }
  /* @media only screen and (max-width: ${({ widthBeforeScroll }) => widthBeforeScroll}px) {
  } */
`

export const Container = styled.div<ScrollProps>`
  align-items: center;
  position: relative;
`

export const TableContainer = styled.div<ScrollProps>`
  align-items: center;
  position: relative;
  overflow: auto;
`

export const Table = styled.table`
  font-size: small;
  position: relative;
  width: 100%;
`

export const Header = styled.thead`
  height: 3.2rem;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: auto;
  align-content: start;
`

export const HeaderRow = styled.tr`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  align-items: center;
  align-content: start;
  height: 2.6rem;
  background-color: ${Colors.white};
`

export const HeadCell = styled.th<{ width?: number }>`
  justify-content: center;
  display: flex;
  flex: ${({ width }) => (width ? width : 1)};
`

export const ExpanderHeadCell = styled.th`
  justify-content: center;
  display: flex;
  flex: 25;
  padding-left: 8px;
  margin-right: 2px;
`

export const Body = styled.tbody`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  align-items: auto;
  align-content: start;
`
