import React from 'react'

import { DateRangePickerWithHook } from '../../../components/Mui/DatePicker'
import { PageHeader } from '../../../components/PageHeader'
import Rightbar from '../../../components/Rightbar/RightBar'
import { LancamentosTableV2 } from '../../../components/Tables/LancamentosTable/LancamentosTableV2'
import { useCoreContext } from '../../../hooks/contexts/coreContext/coreProvider'
import { setEndOfDay } from '../../../hooks/useDate'
import { setStartOfDay } from '../../../hooks/useDate'
import * as S from './styles'

export const LancamentosV2 = () => {
  const { familiaId: familyId, startDate, endDate } = useCoreContext()
  if (!familyId) return null

  return (
    <S.ContentContainer>
      <PageHeader title="Extrato">
        <div style={{ display: 'flex', gap: '1rem' }}>
          <S.StyledUploadExtrato />
        </div>
      </PageHeader>

      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1rem' }}>
        <DateRangePickerWithHook label="Período" />
      </div>

      <LancamentosTableV2
        familyId={familyId}
        startDate={setStartOfDay(new Date(startDate))}
        endDate={setEndOfDay(new Date(endDate))}
      />

      <Rightbar
        // @ts-expect-error
        logo={{
          innerLink: '/admin/home',
          imgSrc: require('../../../assets/img/brand/logo-nova-branca.png'),
          imgAlt: '...'
        }}
        id="right-sidenav-main"
        position="fixed-right"
        menuItens={['sumarioPrevistoRealizadoGeral', 'gastosPorOrcamento', 'gastosPorCategoria']}
      />
    </S.ContentContainer>
  )
}
