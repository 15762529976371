import { useState } from 'react'
import { useForm } from 'react-hook-form'

import { useBankPdfTypes } from '../../../queries/extrato/queries'
import { FileUploadForExtrato, TFileFormat } from '../../FileUpload/FileUploadForExtrato'
import { ButtonDefault } from '../../Mui/Button'
import { BancsSelect } from '../../Selects/BancsSelect'
import { ExtractOriginSelect } from '../../Selects/ExtractOriginSelect'
import { FilesTypesExtractSelect } from '../../Selects/FilesTypesExtractSelect'
import { PaymentMethodSelect } from '../../Selects/PaymentMethodSelect'
import { YearSelect } from '../../Selects/YearSelect'
import * as ParentStyles from '../styles'

interface IFormExtractUpload {
  paymentMethodId: number
  banco: string
  formato: TFileFormat
  typePdf: string
  year: number
  file: File
}

export const FormExtractUpload = ({ extractMenuIsOpen }: { extractMenuIsOpen: boolean }) => {
  const [paymentMethodId, setPaymentMethodId] = useState<number>()
  const [banco, setBanco] = useState<string>()
  const [formato, setFormato] = useState<TFileFormat>()
  const [file, setFile] = useState<File>()
  const [typePdf, setTypesPdf] = useState<string>()
  const [year, setYear] = useState<number>()
  const typesPDF = useBankPdfTypes(banco)

  const displayPdfTypes = typesPDF && typesPDF.length > 0
  if (!displayPdfTypes && typePdf) setTypesPdf(undefined)

  const displayYearDropdown = banco?.toLowerCase()?.startsWith('btg')
  if (!displayYearDropdown && year) setYear(undefined)

  const {
    // control,
    handleSubmit,
    formState: {
      isValid,
      isSubmitting
      // errors
    }
  } = useForm<IFormExtractUpload>({
    mode: 'all',
    defaultValues: {
      paymentMethodId,
      banco,
      formato,
      file
    }
  })

  const onSubmit = (data: IFormExtractUpload) => {
    console.log(data)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ParentStyles.Wrapper isOpen={extractMenuIsOpen}>
        <PaymentMethodSelect
          onChange={(newPaymentMethodId) => setPaymentMethodId(newPaymentMethodId)}
          selectedId={paymentMethodId}
          placeholder="Meio de pagamento"
        />

        <BancsSelect
          onChange={({ value }) => {
            setBanco(value)
          }}
          disabled={!paymentMethodId}
        />

        <FilesTypesExtractSelect
          onChange={(option) => option?.value && setFormato(option?.value as TFileFormat)}
          currentBank={banco}
          disabled={!banco}
        />

        {displayPdfTypes && <ExtractOriginSelect currentBank={banco} onChange={setTypesPdf} />}

        {displayYearDropdown && <YearSelect selected={year} onChange={setYear} />}

        <FileUploadForExtrato
          banco={banco}
          formato={formato}
          typePdf={typePdf}
          meioPagamentoId={paymentMethodId}
          year={year}
          onUpload={(newFile) => setFile(newFile)}
          disabled={!paymentMethodId || !banco || !formato}
        />

        <ButtonDefault hidden type="submit" disabled={!isValid || isSubmitting} loading={isSubmitting}>
          Enviar
        </ButtonDefault>
      </ParentStyles.Wrapper>
    </form>
  )
}
