import { useHistory } from 'react-router-dom'

import { spinnerHide, spinnerShow } from '../globalValues'
import { useGetImageBYFamilyId } from '../queries/family/queries'
import { useFamilyStore } from '../store/family/useFamilyStore'
import { useCoreContext } from './contexts/coreContext/coreProvider'
import { CurrentFamilyContext } from './contexts/currentFamilyContext'
import { useLocalStorage } from './useLocalStorage'
import { useRequiredContext } from './useRequiredContext'

export const useCurrentFamily = () =>
  useRequiredContext({ Context: CurrentFamilyContext, name: 'currentFamily' })

export const usePlanner = () => {
  const { userType, setEmpresaId, setFamiliaId } = useCoreContext()
  const { setFamily } = useFamilyStore()

  const goBackToPlanner = () => {
    if (userType?.isSupport) setEmpresaId(null)
    setFamiliaId('')
    setFamily({})
  }

  return { goBackToPlanner }
}

export interface IUseSelectFamilyHandleFamilyChange {
  familyId: string | number | null
  name?: string
  callback?: () => void
  planejadorId?: number
  planejadorNome?: string
  empresaId?: number
  empresaNome?: string
}

export const useSelectFamily = () => {
  const history = useHistory()
  const { userType, familiaId, setEmpresaId } = useCoreContext()
  const { setValue: setFamiliaId } = useLocalStorage('familia-id')
  const { family, defaultImage, setFamilyImage, setFamily, families } = useFamilyStore()
  const { familyImage, isLoadingFamilyImage } = useGetImageBYFamilyId({
    familyId: familiaId ? Number(familiaId) : undefined
  })

  const isPlanned = userType?.isPlanned
  const finalImageUrl = family?.image || defaultImage

  const getName = () => {
    if (isPlanned) {
      return family?.nome || ''
    }
    return family?.nome || 'Selecione um cliente'
  }

  const getFamilyById = (familyId: string | number) => {
    return families.find((family) => family.id === Number(familyId))
  }

  const handleFamilyChange = ({
    familyId,
    name,
    callback,
    planejadorId,
    planejadorNome,
    empresaId,
    empresaNome
  }: IUseSelectFamilyHandleFamilyChange) => {
    spinnerShow($)

    const empresaIdToSet = empresaId || family?.empresaId || null
    let familyName = name

    if (!name && familyId) {
      const family = getFamilyById(familyId)
      familyName = family?.nome || ''
    }

    setFamiliaId(familyId ? String(familyId) : '')

    setFamily({
      id: familyId ? Number(familyId) : undefined,
      nome: familyId ? familyName : '',
      planejadorId: planejadorId || family?.planejadorId,
      planejadorNome: planejadorNome || family?.planejadorNome,
      empresaId: empresaId || family?.empresaId,
      empresaNome: empresaNome || family?.empresaNome
    })

    setEmpresaId(empresaIdToSet ? String(empresaIdToSet) : null)

    callback?.()

    setTimeout(() => {
      history.push('/admin/home')
      spinnerHide($)
    }, 800)
  }

  return {
    families,
    family,
    defaultImage,
    setFamilyImage,
    familyImage,
    isLoadingFamilyImage,
    isPlanned,
    finalImageUrl,
    getName,
    handleFamilyChange
  }
}
