import { QueryOptions, useQuery } from 'react-query'

import { getPersonalByUserId, getUsersByFamilyId } from '../../domain/user/'
import {
  Params as GetPersonalByUserIdParams,
  Response as GetPersonalByUserIdResponse
} from '../../domain/user/api/getPersonalByUserId'
import { Params as GetUserByFamilyIdParams } from '../../domain/user/api/getUsersByFamilyId'
export const cacheBaseKey = 'user'

type UsePersonalByUserIdParams = {
  options?: QueryOptions<GetPersonalByUserIdResponse, Error>
} & GetPersonalByUserIdParams

export const usePersonalByUserIdQuery = ({ userId, options }: UsePersonalByUserIdParams) => {
  const {
    data: personalByUserId,
    isError: isPersonalByUserIdError,
    isLoading: isLoadingPersonalByUserId,
    error: personalByUserIdError,
    refetch: refetchGetPersonalByUserId
  } = useQuery(
    [cacheBaseKey, userId, 'personalByUserId'],
    () =>
      getPersonalByUserId({
        userId
      }),
    options
  )

  return {
    personalByUserId,
    personalByUserIdError,
    isLoadingPersonalByUserId,
    isPersonalByUserIdError,
    refetchGetPersonalByUserId
  }
}

type UseUserByFamilyIdParams = GetUserByFamilyIdParams

export const useUserByFamilyIdQuery = ({ familyId }: UseUserByFamilyIdParams) => {
  const {
    data: usersByFamilyId = [],
    isError: isGetUsersByFamilyIdError,
    isLoading: isLoadingGetUsersByFamilyId,
    error: getUsersByFamilyIdError,
    refetch: refetchGetGetUsersByFamilyId
  } = useQuery([cacheBaseKey, familyId, 'usersByFamilyId'], () =>
    getUsersByFamilyId({
      familyId
    })
  )

  return {
    usersByFamilyId,
    isGetUsersByFamilyIdError,
    isLoadingGetUsersByFamilyId,
    getUsersByFamilyIdError,
    refetchGetGetUsersByFamilyId
  }
}
