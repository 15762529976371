import React, { useCallback } from 'react'
import styled from 'styled-components'

import { SimplifiedOrcamento } from '../../../domain/orcamento/Orcamento'
import { useObjectLocalStorage } from '../../../hooks'
import { useCoreContext } from '../../../hooks/contexts/coreContext/coreProvider'
import { useFamilyOrcamentos } from '../../../queries/orcamento/queries'
import { BudgetSelect } from '../../Selects/BudgetSelect'
import { LancamentoEdit } from '../LancamentosTable/columns'
interface Props {
  selectedId: SimplifiedOrcamento['id']
}

const Text = styled.span`
  text-align: center;
  align-items: center;
`

// const SelectContainer = styled.div`
//   display: flex;
//   padding-left: 5%;
//   padding-right: 5%;
//   padding-bottom: 1.5%;
//   padding-top: 1.5%;
//   min-width: 135px;
// `

const OrcamentoCell = ({ selectedId }: Props) => {
  const { familiaId: familyId } = useCoreContext()
  const { orcamentos, isLoadingOrcamentos } =
    useFamilyOrcamentos(familyId ? Number(familyId) : undefined) || {}

  const text = useCallback(() => {
    if (isLoadingOrcamentos) {
      return '...'
    }

    let name = 'Sem orçamento'

    if (orcamentos?.length && selectedId) {
      const getName = orcamentos?.find(({ id }) => {
        if (id === selectedId) {
          return true
        }
        return false
      })

      // @ts-expect-error expected-error
      if (getName) name = getName?.name ?? getName?.nome
    }

    return name
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingOrcamentos, selectedId])

  return <Text>{text()}</Text>
}

export const BudgetCellText = ({ lancamentoId, id, onSelectOrcamento, orcamentoPadrao, tipo }) => {
  const { value: edited, setValue } = useObjectLocalStorage<LancamentoEdit>('table_l_edits', {})

  const OrcamentoField = useCallback(() => {
    const selectedId = edited[lancamentoId]?.orcamentoId || id

    return (
      <BudgetSelect
        size="small"
        type={tipo}
        onSelect={({ id: orcamentoId }) => {
          setValue({ ...edited, [lancamentoId]: { orcamentoId } })
          onSelectOrcamento?.()
        }}
        selectedId={selectedId}
      />
    )
  }, [edited, id, lancamentoId, onSelectOrcamento, setValue, tipo])

  if (orcamentoPadrao) {
    return <OrcamentoField />
  }

  return <OrcamentoCell selectedId={id} />
}

export default OrcamentoCell
