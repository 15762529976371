import React from 'react'
import styled from 'styled-components'

import Modal from '../Modal'
import ChoiceButton, { Option } from './ChoiceButton'

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const ButtonContainer = styled.div`
  margin-top: 2vh;
  display: flex;
  flex-direction: row;
`

interface Props<T> {
  open: boolean
  title: string
  text: string
  options: Option<T>[]
  onChoice?: (selected: Option<T>) => void
  onClose?: () => void
}

export default <T,>({ open, options, title, text, onChoice, onClose }: Props<T>) => {
  return (
    <Modal open={open} onClose={() => onClose?.()} top={27.5}>
      <Container>
        <h2>{title}</h2>
        <br />
        {text}
        <br />
        <ButtonContainer>
          {options.map((currentOption, index) => (
            <ChoiceButton
              key={`choice_${title}_${index}`}
              option={currentOption}
              onChange={() => {
                onChoice?.(currentOption)
              }}
            />
          ))}
        </ButtonContainer>
      </Container>
    </Modal>
  )
}
