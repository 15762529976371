import React from 'react'
import styled from 'styled-components'

import Colors from '../../domain/Colors'

const Row = styled.tr<{ text: string; index: number }>`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  align-items: center;
  align-content: start;
  min-height: 2.5rem;
  background-color: ${({ index }) => (index % 2 === 0 ? Colors.tableRowGray : Colors.white)};
`

const Cell = styled.td<{ width?: number }>`
  justify-content: center;
  display: flex;
  flex: ${({ width }) => (width ? width : 1)};
  width: 100%;
`

interface Props {
  index: number
  text: string
}

export default ({ text, index }: Props) => {
  return (
    <React.Fragment>
      <Row index={index || 1} text={text}>
        <Cell>{text}</Cell>
      </Row>
    </React.Fragment>
  )
}
