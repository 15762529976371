import styled from 'styled-components'

import { PageFirstRow } from '../../../components/Headers'
import { UploadExtratoV2 } from '../../../components/UploadExtratoV2'

export const ContentContainer = styled.div`
  padding: 2rem;
  width: 100%;
`

export const FirstRow = styled(PageFirstRow)`
  margin-bottom: 1rem;
  h3,
  img {
    margin-bottom: 0;
  }
`

export const ButtonsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  gap: 20px;
  width: 100%;

  h3,
  img {
    margin-bottom: 0;
  }
`

export const StyledUploadExtrato = styled(UploadExtratoV2)`
  margin-top: 0.5rem;
  margin-bottom: 2rem;
  width: 100%;
`
