import { Typography } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import styled from 'styled-components'

export const DatePickerMui = styled(DatePicker)`
  .MuiInputBase-input {
    &.MuiInput-input {
      max-width: 86px;

      &.MuiInputBase-inputAdornedEnd {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
      }
    }
  }

  .MuiInputAdornment-root {
    &.MuiInputAdornment-positionEnd {
      &.MuiInputAdornment-standard {
        margin-left: 0;
      }
    }
  }

  &.MuiFormControl-root.MuiTextField-root {
    .MuiInputBase-root.MuiFilledInput-root {
      border-radius: 15px;
      padding: 0 0.8rem 0 0.2rem;

      &:before {
        display: none;
      }

      &:after {
        border-color: ${({ theme }) => theme.colors?.secondary || 'rgba(0, 0, 0, 0.23)'};
      }
    }

    .MuiInputBase-input.MuiFilledInput-input {
      font-size: 0.95rem;
      padding: 0.5rem 0px 0.5rem 1rem;
      max-width: 86px;
    }
  }

  .MuiButtonBase-root.MuiIconButton-root {
    svg {
      font-size: 1.2rem;
    }
  }

  .MuiInputAdornment-root.MuiInputAdornment-positionEnd {
    margin-left: 0;
  }

  .MuiButtonBase-root.MuiPickersDay-root {
    &.Mui-selected {
      background-color: ${({ theme }) => theme.colors?.primary || '#7e32d0'};
    }
  }

  .MuiPickersYear-yearButton {
    &.mui-selected {
      background-color: ${({ theme }) => theme.colors?.primary || '#7e32d0'};
    }
  }

  .MuiButton-text {
    &.MuiButton-textPrimary {
      color: ${({ theme }) => theme.colors?.primary || '#7e32d0'};
    }
  }
`

export const DatePickerRangeContainer = styled.div`
  display: flex;
  gap: 10px;

  .MuiFormControl-root {
    .MuiTextField-root {
      max-width: 150px;
    }
  }
`

export const DatePickerRangeTitle = styled(Typography)`
  align-items: center;
  display: flex;
`
