import { format } from 'date-fns'
import React, { useMemo } from 'react'
import { Column } from 'react-table'
import ReactTooltip from 'react-tooltip'

import { formatMonetaryValue } from '../../../../domain/formatter'
import { InvestmentProps } from '../../../../domain/investments'
import { InvestmentNameInput } from '../../../Inputs/editableInput/investmentNameInput'
import { InvestmentValueInput } from '../../../Inputs/editableInput/investmentValueInput'
import { ButtonDelete } from './ButtonDelete'
import { IUseColumns } from './types'

export const useColumnsAcoes = ({ handleDelete }: IUseColumns) => {
  const columns = useMemo((): Column<InvestmentProps>[] => {
    return [
      {
        Header: 'Descrição',
        id: 'name',
        accessor: 'name',
        disableSortBy: false,
        Cell: (row) => {
          const {
            value: investmentName,
            row: {
              values: { id }
            }
          } = row

          return <InvestmentNameInput currentName={investmentName} investmentId={id} key={investmentName} />
        }
      },
      {
        Header: 'Valor Aplicado',
        id: 'amountOriginal',
        accessor: 'amountOriginal',
        Cell: (row) => {
          const {
            value,
            row: {
              values: { id, name }
            }
          } = row

          return <InvestmentValueInput key={value} investmentId={id} currentValue={value || 0} name={name} />
        }
      },
      {
        Header: 'Valor Atual',
        id: 'balance',
        accessor: 'balance',
        Cell: ({ value }) => (value ? `R$ ${formatMonetaryValue(value)}` : '- - -')
      },
      {
        Header: 'Quantidade da cota',
        id: 'quantityQuota',
        accessor: 'quantityQuota',
        Cell: ({ value }) => {
          return value ? (
            value
          ) : (
            <>
              <span data-tip="Quantidade da cota">- - -</span>
              <ReactTooltip place="top" type="dark" effect="float" />
            </>
          )
        }
      },
      {
        Header: 'Preço da cota',
        id: 'unitPriceQuota',
        accessor: 'unitPriceQuota',
        Cell: ({ value }) =>
          value ? (
            `R$ ${formatMonetaryValue(value)}`
          ) : (
            <>
              <span data-tip="Preço da cota">- - -</span>
              <ReactTooltip place="top" type="dark" effect="float" />
            </>
          )
      },
      {
        Header: 'Data da cota',
        id: 'dateQuota',
        accessor: ({ dateQuota, date }) => dateQuota || date,
        Cell: ({ value }) => (value && format(new Date(value), 'dd/MM/yyyy')) || '- - -'
      },
      {
        Header: 'CNPJ',
        id: 'code',
        accessor: 'code',
        Cell: ({ value }) => value || '- - -'
      },
      {
        Header: 'Taxa anual',
        id: 'taxes',
        accessor: 'taxes',
        Cell: ({ value }) => (value ? `R$ ${formatMonetaryValue(value)}` : '- - -')
      },
      {
        Header: 'Controle',
        id: 'id',
        accessor: 'id',
        disableSortBy: true,
        Cell: (row) => {
          const {
            row: {
              values: { id, name },
              original: { isManual }
            }
          } = row

          return <ButtonDelete id={id} name={name} handleDelete={handleDelete} isManual={isManual} />
        }
      }
    ]
  }, [handleDelete])
  return columns
}
