import 'dayjs/locale/pt-br'

import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { endOfMonth, startOfMonth } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import React, { createContext, FC, useContext, useEffect, useState } from 'react'
import { registerLocale, setDefaultLocale } from 'react-datepicker'
import { ReactQueryDevtools } from 'react-query/devtools'

import { LoadingScreen } from '../../../components/Loading'
import { ThemeModal } from '../../../components/Modal'
import { useGetFamiliesQuery } from '../../../queries/lancamento/queries'
import { useFamilyStore } from '../../../store/family/useFamilyStore'
import { useClearCacheEasterEgg } from '../../useClearCacheEasterEgg'
import { formatDateEnd, formatDateStart } from '../../useDate'
import { useLocalStorage } from '../../useLocalStorage'
import { useAuth } from '../authContext'
import { DashboardProvider } from '../dashboardContext'
import { InitialDataContextProvider } from '../initialDataContext'
import { LoadingScreenProvider } from '../loadingScreenContext'
import { SelectComboContextProvider } from '../selectComboContext'
import { TransactionContextProvider } from '../transactionContext'
import { TutorialsProvider } from '../tutorialsContext'
import { ICoreContext } from './types'

const localStartDate = localStorage.getItem('startDate')
const localEndDate = localStorage.getItem('endDate')
const defaultStartDate = localStartDate
  ? formatDateStart(new Date(localStartDate))
  : formatDateStart(startOfMonth(new Date()))
const defaultEndDate = localEndDate
  ? formatDateEnd(new Date(localEndDate))
  : formatDateEnd(endOfMonth(new Date()))

const CoreContext = createContext({
  authenticated: false,
  setFamiliaId: () => {},
  setEmpresaId: () => {},
  setTipoUsuario: () => {},
  setUsuarioEmail: () => {},
  setUsuarioId: () => {},
  setStartDate: () => {},
  setEndDate: () => {},
  usuarioEmailSetado: null,
  tipoUsuarioSetado: null,
  userId: null,
  pessoaId: null,
  loginRedirect: false,
  startDate: defaultStartDate,
  endDate: defaultEndDate,
  navBarCollapsed: false,
  setNavBarCollapsed: () => {}
} as ICoreContext)

registerLocale('br', ptBR)
setDefaultLocale('br')

export const CoreContextProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const { value: valueJwt } = useLocalStorage('jwt')
  const auth = useAuth()
  const { authenticated, token, isPlanner, isPlanned, isManager, isSupport } = auth || {}

  const {
    email: usuarioEmail,
    empresaId,
    familiaId,
    id: usuarioId,
    pessoaId,
    planejadorId,
    theme,
    type: tipoUsuario
  } = token || {}

  const { setValue: setEmpresaId } = useLocalStorage('empresa-id')
  const { setValue: setFamiliaId, value: storeFamiliaId } = useLocalStorage('familia-id')
  const { setValue: setTipoUsuario, value: tipoUsuarioSetado } = useLocalStorage('tipo-usuario')
  const { setValue: setUsuarioEmail, value: usuarioEmailSetado } = useLocalStorage('usuario-email')
  const { setValue: setUsuarioId, value: userId } = useLocalStorage('usuario-id')
  const finalFamiliaId = storeFamiliaId ? Number(storeFamiliaId) : (familiaId ?? null)
  // const { family: familyImage } = useFamilyQuery(finalFamiliaId ? finalFamiliaId : undefined)
  const [loginRedirect, setLoginRedirect] = useState(false)
  const { setValue: setStartRangeDate, value: startDate } = useLocalStorage('startDate')
  const { setValue: setEndRangeDate, value: endDate } = useLocalStorage('endDate')
  const { setValue: setDataInicio } = useLocalStorage('dataInicio')
  const { setValue: setDataFim } = useLocalStorage('dataFim')
  const { families } = useGetFamiliesQuery()
  const { setFamilies, setFamily, family } = useFamilyStore()
  const finalEmpresaId = empresaId || family?.empresaId

  const [navBarCollapsed, setNavBarCollapsed] = useState(false)

  const MaybeReactQueryDevTools = () => {
    const isReactQueryDebuggingOn = process.env.REACT_APP_QUERY_DEBUG
    return isReactQueryDebuggingOn === 'true' ? <ReactQueryDevtools /> : <></>
  }

  const ClearCacheContainer = () => {
    useClearCacheEasterEgg()
    return <></>
  }

  const setStartDate = (start: Date) => {
    const formattedStart = formatDateStart(start)
    setStartRangeDate(formattedStart)
    setDataInicio(formattedStart)
  }
  const setEndDate = (end: Date) => {
    const formattedEnd = formatDateEnd(end)
    setEndRangeDate(formattedEnd)
    setDataFim(formattedEnd)
  }

  useEffect(() => {
    if (!startDate) {
      setStartRangeDate(defaultStartDate)
      setDataInicio(defaultStartDate)
    }
    if (!endDate) {
      setEndRangeDate(defaultEndDate)
      setDataFim(defaultEndDate)
    }
  }, [])

  useEffect(() => {
    if (authenticated) {
      if (families?.length === 1) {
        setFamily(families[0])
        return
      }
      setFamilies(families || [])
    }
  }, [families, authenticated, setFamily, setFamilies])

  useEffect(() => {
    if (authenticated && valueJwt) {
      // Os dados só são salvos no localStorage por retrocompatibilidade
      // com os componentes antigos. Os novos devem usar o authContext.
      if (finalEmpresaId) setEmpresaId(String(finalEmpresaId))
      if (familiaId || storeFamiliaId) setFamiliaId(String(familiaId || storeFamiliaId))
      if (tipoUsuario) setTipoUsuario(tipoUsuario)
      if (usuarioEmail) setUsuarioEmail(usuarioEmail)
      if (usuarioId) setUsuarioId(String(usuarioId))
      setLoginRedirect(true)
    }
  }, [
    authenticated,
    empresaId,
    familiaId,
    family,
    finalEmpresaId,
    pessoaId,
    planejadorId,
    setEmpresaId,
    setFamiliaId,
    setTipoUsuario,
    setUsuarioEmail,
    setUsuarioId,
    storeFamiliaId,
    theme,
    tipoUsuario,
    usuarioEmail,
    usuarioId,
    valueJwt
  ])

  return (
    <CoreContext.Provider
      value={{
        authenticated,
        empresaId: finalEmpresaId,
        familiaId: finalFamiliaId,
        planejadorId,
        tipoUsuario,
        usuarioId,
        userType: {
          isPlanner,
          isPlanned,
          isManager,
          isSupport
        },
        setEmpresaId,
        setFamiliaId,
        setTipoUsuario,
        setUsuarioEmail,
        setUsuarioId,
        setStartDate,
        setEndDate,
        startDate: startDate || defaultStartDate,
        endDate: endDate || defaultEndDate,
        usuarioEmailSetado,
        tipoUsuarioSetado,
        userId,
        loginRedirect,
        pessoaId: pessoaId?.toString() || null,
        navBarCollapsed,
        setNavBarCollapsed
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-BR">
        <LoadingScreenProvider>
          <InitialDataContextProvider>
            <SelectComboContextProvider>
              <TutorialsProvider>
                <TransactionContextProvider>
                  <DashboardProvider>
                    {children}
                    <LoadingScreen />
                    <ThemeModal />
                    <MaybeReactQueryDevTools />
                    <ClearCacheContainer />
                  </DashboardProvider>
                </TransactionContextProvider>
              </TutorialsProvider>
            </SelectComboContextProvider>
          </InitialDataContextProvider>
        </LoadingScreenProvider>
      </LocalizationProvider>
    </CoreContext.Provider>
  )
}

export const useCoreContext = () => {
  const context = useContext(CoreContext)
  if (!context) {
    throw new Error('CoreContext ===> Need Wrap User container')
  }
  return context
}
