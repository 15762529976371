import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import { IconButton, Tooltip } from '@mui/material'
import { format, parseJSON } from 'date-fns'
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import { useCallback, useMemo } from 'react'

import { SimplifiedLancamento } from '../../../domain/lancamento/lancamento'
import { BudgetType } from '../../../domain/orcamento/Orcamento'
import { useFamilyMeiosPagamentos } from '../../../queries/meioPagamento/queries'
import { useFamilyOrcamentosForTable } from '../../../queries/orcamento/queries'
import { useFamilyStore } from '../../../store/family/useFamilyStore'
import { formatCurrencyBRL } from '../../Utils'
import { IExportExtractTableToPDF, IRowsData } from './types'

export const ExportExtractTableToPDF = ({
  familyId,
  columns,
  table,
  startDate,
  endDate
}: IExportExtractTableToPDF) => {
  const { orcamentos, isLoadingOrcamentos } = useFamilyOrcamentosForTable(familyId)
  const { meiosPagamentos, isLoadingMeios } = useFamilyMeiosPagamentos(familyId ? familyId : undefined)
  const { family } = useFamilyStore()

  const getPaymentMethodName = useCallback(
    (paymentMethodId: number) => {
      const payment = meiosPagamentos?.find((meio) => Number(meio.id) === Number(paymentMethodId))
      return payment?.nome
    },
    [meiosPagamentos]
  )

  const getOrcamentoName = useCallback(
    (orcamentoId: number) =>
      orcamentos?.find((orcamento) => Number(orcamento.id) === Number(orcamentoId))?.nome,
    [orcamentos]
  )

  const getCategoryName = useCallback(
    (categoryId: number) => {
      let categoryName = ''
      orcamentos?.forEach((orcamento) => {
        orcamento?.categorias?.find((category) => {
        if (Number(category.id) === Number(categoryId)) {
            categoryName = category.nome
            return true
        }
        return false
        })
      })
      return categoryName
    },
    [orcamentos]
  )

  const FORMAT_MAP = useMemo(() => {
    return {
      data: (value: string) => format(parseJSON(value), 'dd/MM/yyyy'),
      descricao: (value: string) => value,
      valor: (original: SimplifiedLancamento) =>
        formatCurrencyBRL(original.parcelado ? original.valorParcela || 0 : original.valor || 0),
      meioPagamentoId: (value: number) => getPaymentMethodName(value),
      categoriaId: (categoryId: number) => getCategoryName(categoryId),
      orcamentoId: (budgetId: number) => getOrcamentoName(budgetId),
      parcelado: (value: boolean) => (value ? 'Sim' : 'Não'),
      tipo: (value: number) => BudgetType[value]
    }
  }, [getPaymentMethodName, getCategoryName, getOrcamentoName])

  const handleExportRows = useCallback(
    (rows: IRowsData) => {
      const doc = new jsPDF()
      const tableHeaders = columns?.map((column) => column.header)
      const tableHeadersIds: (keyof SimplifiedLancamento)[] = columns
        ?.map((column) => column.id)
        .filter((item) => item !== undefined) as (keyof SimplifiedLancamento)[]

      const rowsData = rows?.map((row) => {
        const { original } = row

        const processedData = tableHeadersIds?.map((headerId) => {
          const fn = FORMAT_MAP[headerId]
          if (typeof fn === 'function') {
            if (headerId === 'valor') {
              const value = fn(original)
              return value
            }
            const value = fn(original[headerId])
            return value
          }
          return original[headerId]
        })

        return processedData
      })

      autoTable(doc, {
        head: [tableHeaders],
        body: rowsData
      })

      const processedFileName = `${family?.nome?.replace(/\s+/g, '_')}-${startDate}-${endDate}.pdf`
      doc.save(processedFileName)
    },
    [columns, FORMAT_MAP, family?.nome, startDate, endDate]
  )

  const isLoading = isLoadingOrcamentos || isLoadingMeios
  const hasData = table.getRowModel().rows.length > 0
  const isDisabled = !hasData || isLoading

  if (!orcamentos?.length || !meiosPagamentos?.length || isLoading || !family) return null

  return (
    <Tooltip title="Exportar para PDF">
      <IconButton
        disabled={isDisabled}
        loading={isLoadingOrcamentos}
        onClick={() => handleExportRows(table.getRowModel().rows)}
        title="Exportar para PDF"
      >
        <PictureAsPdfIcon />
      </IconButton>
    </Tooltip>
  )
}
