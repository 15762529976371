import React, { useState } from 'react'
import CurrencyInput from 'react-currency-input-field'
import styled from 'styled-components'

import Colors from '../../domain/Colors'
import TextInputTitle from './TextInputTitle'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 15px 0 15px;
  width: 100%;
`

const Input = styled(CurrencyInput)`
  height: calc(2.75rem + 2px);
  padding: 5px 8px;
  color: ${Colors.disabledTextGray};
  background-color: ${Colors.disabledTextBackground};
  border-radius: 1.3rem;
  border: 0px solid white;
  font-size: 0.875rem;
  cursor: text;

  &[disabled] {
    opacity: 0.3;
  }
`

interface Props {
  title: string
  value?: number
  onChange?: (newValue?: number) => void
  disabled?: boolean
}

export default ({ value, title, onChange, disabled }: Props) => {
  const [display, setDisplay] = useState(value?.toString()?.replace('.', ','))
  return (
    <Container>
      <TextInputTitle>{title}</TextInputTitle>
      <Input
        value={display}
        allowNegativeValue={false}
        decimalSeparator=","
        groupSeparator="."
        placeholder="0,00"
        maxLength={13}
        disabled={disabled}
        onValueChange={(newValue) => {
          if (!onChange) return
          if (!newValue) {
            setDisplay(undefined)
            onChange(undefined)
            return
          }
          if (typeof newValue !== 'string') {
            console.warn(`Unexpected value type: ${typeof newValue}: ${newValue}`)
            return
          }
          const parsed = parseFloat(newValue.replace(',', '.'))
          if (isNaN(parsed)) {
            console.warn(`Unexpected value NaN: ${typeof newValue}: ${newValue}`)
            return
          }
          onChange(parsed)
          setDisplay(newValue)
        }}
      />
    </Container>
  )
}
