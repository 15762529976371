import React from 'react'
import { PropTypes } from 'prop-types'
import Chart from 'react-apexcharts'
import { MDBProgress } from 'mdbreact'
import axios from 'axios'
import { Button, Card, CardHeader, Table, Row } from 'reactstrap'
import WrapperForPlusButton from '../../theme/wrapperForPlusButton'
import { ThemeContext } from '../../hooks/contexts/themeContext'
import { withContexts } from '..'
import * as S from './styles'
import { noticeError } from '../../services/Monitoring'
import { showAlert } from '../../hooks'
import { SumarioPrevistoRealizadoGeral } from './SumarioPrevistoRealizadoGeral'
import { formatDateEnd, formatDateStart } from '../../hooks/useDate'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'

const defaultState = {
  collapseOpen: false,
  maisLancamentosPorCategoria: false,
  lancamentos: [],
  startDate: null,
  endDate: null,
  dadosOrcamento: {
    options: {
      labels: []
    },
    series: []
  },
  dadosCategorias: [],
  retry: {
    jwt: 3
  }
}

class Rightbar extends React.Component {
  static contextType = ThemeContext

  moment = require('moment')

  state = defaultState

  componentDidMount() {
    this.setState(
      {
        empresaId: this.props.empresaId,
        familiaId: this.props.familiaId,
        startDate: this.props.dataInicio,
        endDate: this.props.dataFim
      },
      function () {
        this.getData()
      }
    )
  }

  clearData = () => this.setState(defaultState)

  getData = () => {
    if (!this.props.authenticated) {
      console.info(`Not authenticated, not loading data`)
      return
    }

    if (this.state.startDate == null || this.state.endDate == null) {
      this.clearData()
    } else {
      global.spinnerShow($)
      this.atualizarDadosTipoOrcamento()
      this.atualizarDadosOrcamento()
      this.atualizarDadosCategorias()
      this.atualizarUltimosLancamentos()
    }
  }

  atualizarUltimosLancamentos = () => {
    if (!this.state.familiaId) return
    const quantidade = 3
    const url =
      global.server_api_new + '/dashboard/ultimos-lancamentos/' + this.state.familiaId + '/' + quantidade

    global.spinnerShow($)

    axios
      .get(url /*, filtro*/)
      .then((res) => {
        const lancamentos = res.data.results
        this.setState({ lancamentos }, () => global.spinnerHide($))
      })
      .catch((error) => {
        console.log(error)
        noticeError(error)
        global.spinnerHide($)
        showAlert(
          'Erro',
          'Houve um erro interno ao recuperar os dados dos últimos lançamentos. Por favor, tente novamente mais tarde.'
        )
      })
  }

  preparaDataParaGrid = (dataString) => {
    var data = this.moment(dataString, 'YYYY-MM-DDThh:mm:ss')
    return data.format('DD/MM/YYYY')
  }

  atualizarDadosTipoOrcamento = () => {
    if (!this.state.familiaId) return
    var url = global.server_api_new + '/dashboard/tipo-orcamento-previsto-realizado/' + this.state.familiaId

    var filtro = {
      DataInicial: formatDateStart(this.state.startDate),
      DataFinal: formatDateEnd(this.state.endDate),
      Origem: 'RightBar:atualizarDadosTipoOrcamento',
      Cliente: 'Web'
    }

    global.spinnerShow($)

    axios
      .post(url, filtro)
      .then((res) => {
        const dadosTipoOrcamento = res.data.results || []
        this.reordenacaoListaPorNome(dadosTipoOrcamento, ['Receitas', 'Despesas', 'Investimentos', 'Dívidas'])
        var estimadoTotal = 0
        var realizadoTotal = 0
        if (dadosTipoOrcamento) {
          dadosTipoOrcamento.forEach(function (item) {
            estimadoTotal += item.nome === 'Receitas' ? item.estimado : -item.estimado
            realizadoTotal += item.nome === 'Receitas' ? item.totalMedio : -item.totalMedio
          })
        }
        this.setState({ dadosTipoOrcamento, estimadoTotal, realizadoTotal }, () => global.spinnerHide($))
      })
      .catch((error) => {
        console.log(error)
        noticeError(error)
        global.spinnerHide($)
        showAlert(
          'Erro',
          'Houve um erro interno ao recuperar os dados dos tipos de orçamento. Por favor, tente novamente mais tarde.'
        )
      })
  }

  reordenacaoListaPorNome = (array, nomes) => {
    if (!array || !nomes) return []
    nomes.forEach(function (nome, i) {
      for (var j = 0; j < array.length; j++) {
        var item = array[j]
        if (item.nome === nome) {
          var aux = array[i]
          array[i] = item
          array[j] = aux
        }
      }
    })
  }

  atualizarDadosOrcamento = () => {
    if (!this.state.familiaId) return
    var url = global.server_api_new + '/dashboard/orcamento-previsto-realizado/' + this.state.familiaId

    var filtro = {
      DataInicial: formatDateStart(this.props.dataInicio),
      DataFinal: formatDateEnd(this.props.dataFim),
      Origin: 'Rightbr: Orcamento Previsto Realizado'
    }

    global.spinnerShow($)

    axios
      .post(url, filtro)
      .then((res) => {
        var dadosOrcamento = res.data.results

        var labels = []
        var series = []

        if (dadosOrcamento != null && dadosOrcamento.length > 0) {
          dadosOrcamento.forEach((item) => {
            if (item.totalMedio > 0 && item.tipoOrcamento !== 0) {
              labels.push(item.nome)
              series.push(item.totalMedio)
            }
          })
        }

        dadosOrcamento = {
          options: {
            labels: labels,
            legend: {
              show: false
            },
            chart: {
              background: '#ffffff'
            }
          },
          series: series
        }

        this.setState({ dadosOrcamento }, () => global.spinnerHide($))
      })
      .catch((error) => {
        console.log(error)
        noticeError(error)
        global.spinnerHide($)
        showAlert(
          'Erro',
          'Houve um erro interno ao recuperar os dados do orçamento. Por favor, tente novamente mais tarde.'
        )
      })
  }

  atualizarDadosCategorias = () => {
    if (!this.state.familiaId) return
    var url = global.server_api_new + '/dashboard/categoria-previsto-realizado/' + this.state.familiaId

    var filtro = {
      DataInicial: formatDateStart(this.props.dataInicio),
      DataFinal: formatDateEnd(this.props.dataFim),
      Ordenacao: 'Rightbr: Previsto Desc'
    }

    global.spinnerShow($)

    axios
      .post(url, filtro)
      .then((res) => {
        var results = res.data.results

        var dadosCategorias = []

        if (results != null && results.length > 0) {
          results.forEach((item) => {
            dadosCategorias.push({
              nome: item.nome,
              percentual: item.estimado > 0 ? (item.totalMedio / item.estimado) * 100 : 0,
              estimado: item.estimado,
              realizado: item.totalMedio,
              tipoOrcamento: item.tipoOrcamento
            })
          })
        }
        this.setState({ dadosCategorias }, () => global.spinnerHide($))
      })
      .catch((error) => {
        console.log(error)
        noticeError(error)
        global.spinnerHide($)
        showAlert(
          'Erro',
          'Houve um erro interno ao recuperar os dados das categorias. Por favor, tente novamente mais tarde.'
        )
      })
  }

  categoriesAreaChart = {
    options: {
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth'
      },
      xaxis: {
        categories: ['Fam.', 'Res.', 'Adu.', 'Fil.', 'Inv.', 'Div.', 'Tra.', 'Out.']
      },
      colors: ['#FA5C86']
    },
    series: [
      {
        name: 'Valor Gasto',
        data: [17, 14, 15, 3, 4, 4.5, 20]
      }
    ]
  }

  // toggles collapse between opened and closed (true/false)
  toggleCollapse = () => {
    const collapseOpen = !this.state.collapseOpen
    this.setState(
      {
        collapseOpen
      },
      () => {
        const $rightBar = $('#right-sidenav-main')
        const hasClosed = !$rightBar.hasClass('closed')
        const $homeIndex = $('.home-index')
        const $sidebar = $('#sidenav-main')

        const screenWidth = $(window).width()
        const sidebarWidth = $sidebar.width()

        $rightBar.toggleClass('closed', 3000)

        if (hasClosed) {
          $rightBar.animate(
            {
              // right: '-384px'
              right: `-${S.RightBarWidth - 16}px`
            },
            300
          )
          if ($homeIndex.length > 0) {
            $homeIndex.animate(
              {
                width: '100%'
              },
              300
            )
          }
        } else {
          $rightBar.animate(
            {
              right: '1px'
            },
            300
          )
          if ($homeIndex.length > 0) {
            $homeIndex.animate(
              {
                width: `${screenWidth - sidebarWidth - S.RightBarWidth}px`
              },
              300
            )
          }
        }
      }
    )
  }

  // closes the collapse
  closeCollapse = () => {
    this.setState({
      collapseOpen: false
    })
  }

  //Progress bar
  createProgressBar = () => {
    if (this.state.dadosCategorias == null) {
      return <></>
    }

    return this.state.dadosCategorias.map((item, key) => {
      if (!this.state.maisLancamentosPorCategoria && key > 9) {
        return null
      }
      return (
        <div className="progressLine" style={{ position: 'relative' }} key={key}>
          <div className="progress-bar-title">{item.nome}</div>
          {this.dadosProgress(item, key)}
        </div>
      )
    })
  }

  dadosProgress = (item, key) => {
    var color
    if (item.tipoOrcamento === 0) {
      color =
        item.estimado != null && item.estimado > 0
          ? item.percentual <= 100
            ? 'success'
            : 'info'
          : item.realizado != null && item.realizado > 0
            ? 'info'
            : 'success'
    } else {
      color =
        item.estimado != null && item.estimado > 0
          ? item.percentual <= 100
            ? 'success'
            : 'danger'
          : item.realizado != null && item.realizado > 0
            ? 'danger'
            : 'success'
    }
    return (
      <MDBProgress
        key={key}
        material
        value={
          item.estimado != null && item.estimado > 0
            ? item.percentual
            : item.realizado != null && item.realizado > 0
              ? 100
              : 0
        }
        color={color}
        height="25px"
      >
        <div style={{ position: 'absolute', left: '12px' }}>
          <b>
            {`${
              item.realizado != null
                ? item.realizado
                    .toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL'
                    })
                    .replace('R$', '')
                : '0.00'
            } / ${
              item.estimado != null
                ? item.estimado
                    .toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL'
                    })
                    .replace('R$', '')
                : '0.00'
            } 
          (${item.percentual != null ? item.percentual.toFixed(1) : '0.00'}%)`}
          </b>
        </div>
      </MDBProgress>
    )
  }

  dadosDosUltimosLancamentos = () => {
    if (this.state.lancamentos == null || this.state.lancamentos.length === 0) {
      return (
        <tr style={{ fontSize: '12px', marginLeft: '22px' }}>
          <td colSpan="3">Nenhum lançamento realizado hoje!</td>
        </tr>
      )
    } else {
      return this.state.lancamentos.map((item, key) => {
        var valor = item.valorParcela != null && item.valorParcela !== '' ? item.valorParcela : item.valor

        return (
          <tr key={key}>
            <td
              style={
                item.categoria.orcamento.tipoOrcamento === 0
                  ? { color: '#1e8d9d', whiteSpace: 'normal', fontSize: '11px' }
                  : {
                      color: 'rgb(250, 93, 134)',
                      whiteSpace: 'normal',
                      fontSize: '11px'
                    }
              }
            >
              {item.categoria.nome}
            </td>
            <td style={{ fontSize: '11px' }}>
              {valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }).replace('R$', '')}
            </td>
            <td style={{ fontSize: '11px' }}>{this.preparaDataParaGrid(item.data)}</td>
          </tr>
        )
      })
    }
  }

  //Last Entries
  lastEntriesCard = () => {
    return (
      <Card className="shadow" style={{ borderRadius: 15 }}>
        <CardHeader className="border-0" style={{ borderRadius: 15 }}>
          <Row className="align-items-center">
            <div className="col">
              <img
                src={require('../../assets/img/theme/icone-calendario.png')}
                alt="Últimos Lançamentos"
                className="before-title-img"
              />
              <h3 className="mb-0 chart-title">Lançamentos do dia</h3>
            </div>

            <S.PlusIcon to="/admin/extrato" className="right-position">
              +
            </S.PlusIcon>
          </Row>
        </CardHeader>
        <Table>
          <tbody>{this.dadosDosUltimosLancamentos()}</tbody>
        </Table>
      </Card>
    )
  }

  mostrarEsconderMaisLancamentos = (e) => {
    e.preventDefault()
    this.setState({
      maisLancamentosPorCategoria: !this.state.maisLancamentosPorCategoria
    })
  }

  mostrarCardGastosPorCategoria = () => {
    return (
      <>
        <Card className="shadow" style={{ borderRadius: 15 }}>
          <CardHeader className="border-0" style={{ borderRadius: 15 }}>
            <Row className="align-items-center">
              <div className="col">
                <img
                  src={require('../../assets/img/theme/icone-progress.png')}
                  alt="Lançamentos por Categorias"
                  className="before-title-img before-title-img-2"
                />
                <h3 className="mb-0 chart-title">
                  Lançamentos por <span>categorias</span>
                </h3>
              </div>
              <div className="col text-right">
                <WrapperForPlusButton>
                  <Button
                    className="buttonPlus"
                    onClick={(e) => this.mostrarEsconderMaisLancamentos(e)}
                    size="sm"
                  >
                    {!this.state.maisLancamentosPorCategoria ? '+' : '-'}
                  </Button>
                </WrapperForPlusButton>
              </div>
            </Row>
          </CardHeader>
          <div className="chart-expense-adjust">
            <div className="expense-progress-bar">{this.createProgressBar()}</div>
          </div>
        </Card>
      </>
    )
  }

  mostrarCardUltimosLancamentos = () => {
    return (
      <>
        <div className="entries-right">{this.lastEntriesCard()}</div>
      </>
    )
  }

  mostrarCardGastosPorOrcamento = () => {
    return (
      <>
        <Card className="shadow" style={{ borderRadius: 15, border: 'none' }}>
          <CardHeader className="border-0" style={{ borderRadius: '15px 15px 0 0' }}>
            <Row className="align-items-center">
              <div className="col">
                <img
                  src={require('../../assets/img/theme/icone-analytics.png')}
                  alt="Orçamentos Realizados"
                  className="before-title-img before-title-img-2"
                />
                <h3 className="mb-0 chart-title">
                  Orçamentos <span>realizados</span>
                </h3>
              </div>
            </Row>
          </CardHeader>
        </Card>
        <div className="chart-expense-adjust">
          <Chart
            options={this.state.dadosOrcamento.options}
            series={this.state.dadosOrcamento.series}
            className="chart-expense-2"
            type="donut"
            width="100%"
          />
        </div>
      </>
    )
  }

  // TODO
  mostrarDadosSumarioPrevistoRealizado = () => {
    if (this.state.dadosTipoOrcamento == null || this.state.dadosTipoOrcamento.length === 0) {
      return (
        <tr style={{ fontSize: '12px', marginLeft: '22px' }}>
          <td colSpan="3">Nenhuma tipo de categoria encontrado!</td>
        </tr>
      )
    } else {
      return this.state.dadosTipoOrcamento.map((item, key) => {
        var estimado = item.estimado != null ? item.estimado : 0
        var totalMedio = item.totalMedio != null ? item.totalMedio : 0

        return (
          <tr key={key}>
            <td
              style={{
                whiteSpace: 'normal',
                paddingLeft: '1rem',
                fontSize: 11
              }}
            >
              <div>
                <b>{item.nome}</b>
              </div>
            </td>
            <td style={{ color: this.context.theme.colors.secondary, fontSize: '12px' }}>
              {estimado.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }).replace('R$', '')}
            </td>
            <td style={{ color: this.context.theme.colors.primary, fontSize: '12px' }}>
              {totalMedio.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }).replace('R$', '')}
            </td>
          </tr>
        )
      })
    }
  }

  mountFoot = () => {
    if (this.state.estimadoTotal != null && this.state.realizadoTotal != null) {
      return (
        <tfoot>
          <tr style={{ borderTop: '1px solid #dddddd' }}>
            <td>Saldo</td>
            <td style={{ fontSize: '12px', color: this.context.theme.colors.secondary }}>
              {this.state.estimadoTotal
                .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                .replace('R$', '')}
            </td>
            <td style={{ fontSize: '12px', color: this.context.theme.colors.primary }}>
              {this.state.realizadoTotal
                .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                .replace('R$', '')}
            </td>
          </tr>
        </tfoot>
      )
    } else {
      return <></>
    }
  }

  montarBarraLateral = () => {
    const { menuItens } = this.props || [
      'sumarioPrevistoRealizadoGeral',
      'ultimosLancamentos',
      'gastosPorCategoria'
    ]

    if (menuItens == null) {
      return <></>
    }

    return menuItens.map((item, key) => {
      return (
        <div key={key} style={{ background: '#FFF', borderRadius: 15 }}>
          {item === 'ultimosLancamentos' && this.mostrarCardUltimosLancamentos()}
          {item === 'gastosPorOrcamento' && this.mostrarCardGastosPorOrcamento()}
          {item === 'gastosPorCategoria' && this.mostrarCardGastosPorCategoria()}
          {item === 'sumarioPrevistoRealizadoGeral' && <SumarioPrevistoRealizadoGeral />}
        </div>
      )
    })
  }

  updateStartDate = (callback) => {
    let startDate = this.props.dataInicio

    if (startDate != null) {
      try {
        startDate = new Date(startDate)

        this.setState(
          {
            startDate: startDate
          },
          function () {
            if (callback != null) {
              callback()
            }
          }
        )
      } catch (ex) {
        console.log(ex)
      }
    }
  }

  updateEndDate = (callback) => {
    let endDate = this.props.dataFim

    if (endDate != null) {
      try {
        endDate = new Date(endDate)

        this.setState(
          {
            endDate: endDate
          },
          function () {
            if (callback != null) {
              callback()
            }
          }
        )
      } catch (ex) {
        console.log(ex)
      }
    }
  }

  render() {
    const { id, position } = this.props

    return (
      <S.RightBarWrapper>
        <button
          id="rightbar-startdate-refresh"
          onClick={() => this.updateStartDate(this.getData)}
          style={{ display: 'none' }}
        />
        <button
          id="rightbar-enddate-refresh"
          onClick={() => this.updateEndDate(this.getData)}
          style={{ display: 'none' }}
        />
        <S.StyledNavbar
          className={'closed navbar-vertical ' + position + ' navbar-light'}
          expand="md"
          id={id}
        >
          <S.NavBarContent>{this.montarBarraLateral()}</S.NavBarContent>
        </S.StyledNavbar>

        <S.ToggleRightBarButton
          id="toggle-rightbar"
          className="toggle-rightbar"
          onClick={() => this.toggleCollapse()}
          $isOpen={this.state.collapseOpen}
        >
          <KeyboardArrowLeftIcon />
        </S.ToggleRightBarButton>
      </S.RightBarWrapper>
    )
  }
}

Rightbar.defaultProps = {}

Rightbar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired
  })
}

export default withContexts(Rightbar)
