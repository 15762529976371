import styled from 'styled-components'

const WrapperForPlusButton = styled.div`
  .buttonPlus {
    background: ${({ theme: { colors } }) => colors.secondary} !important;
    border-radius: 25px;
    width: 26px;
    height: 26px;
    font-weight: 700;
    color: #fff;
  }
`

export default WrapperForPlusButton
