import { format } from 'date-fns'
import React, { useMemo } from 'react'
import { Column } from 'react-table'

import { formatMonetaryValue } from '../../../domain/formatter'
import { IFixedAsset } from '../../../domain/investments/api/ManualInvestments/types'
import { translateFixedAssetType } from '../../../domain/investments/fixedAsset'
import { TipoOrcamento } from '../../../domain/orcamento/Orcamento'
import { ValueCell } from '../Cells'
import { CellDeleteEquity } from '../Cells/DeleteEquity'

export const useColumns = (onDelete: (id: string) => void) => {
  const columns = useMemo(
    (): Column<IFixedAsset>[] => [
      {
        Header: 'Descrição',
        id: 'Nome',
        accessor: 'name'
      },
      {
        Header: 'Tipo de Patrimônio',
        id: 'Tipo de Patrimônio',
        accessor: 'type',
        Cell: ({ value: type }) => translateFixedAssetType(type)
      },
      {
        Header: 'Data da Compra',
        id: 'Data da Compra',
        accessor: 'buyDate',
        Cell: ({ value: buyDate }) => format(new Date(buyDate), 'dd/MM/yyyy')
      },
      {
        Header: 'Valor Atual',
        id: 'Valor Atual',
        accessor: 'value',
        Cell: ({ value }) => (
          <ValueCell tipo={value >= 0 ? TipoOrcamento.Receita : TipoOrcamento.Despesa}>
            {formatMonetaryValue(value)}
          </ValueCell>
        )
      },
      {
        Header: 'Opções',
        id: 'opções',
        accessor: '_id',
        width: 80,
        Cell: (props) => <CellDeleteEquity {...props} onDelete={onDelete} />
      }
    ],
    [onDelete]
  )
  return columns
}
