import { useQuery } from 'react-query'

import { fetchMeiosPagamentoByFamilyIdFiltroApi } from '../../domain/meioPagamento/api/fetchByFamily'
import { MeioPagamento } from '../../domain/meioPagamento/meioPagamento'
import { QueryOptions } from '../queryOptions'

export interface UseFamilyMeiosPagamentosResponse {
  isLoadingMeios: boolean
  isErrorMeios: boolean
  meiosPagamentos: MeioPagamento[] | undefined
  meiosPagamentoError: Error | null
  refetchMeios: () => void
}

export const cacheBaseKey = 'meio-pagamento'

export const useFamilyMeiosPagamentos = (
  familyId?: number,
  options?: QueryOptions<MeioPagamento[]>
): UseFamilyMeiosPagamentosResponse => {
  const {
    isLoading: isLoadingMeios,
    isError: isErrorMeios,
    data: meiosPagamentos,
    error: meiosPagamentoError,
    refetch: refetchMeios
  } = useQuery<unknown, Error, MeioPagamento[]>(
    [cacheBaseKey, familyId],
    () => familyId && fetchMeiosPagamentoByFamilyIdFiltroApi(familyId, {}),
    { ...options, enabled: !!familyId }
  )

  return {
    isLoadingMeios,
    isErrorMeios,
    meiosPagamentos,
    meiosPagamentoError,
    refetchMeios
  }
}
