import React from 'react'
import { Col, Row } from 'reactstrap'
import { withContexts } from '../../../components'
import { DateRangePickerWithHook } from '../../../components/Mui/DatePicker'
import { PageHeader } from '../../../components/PageHeader'
import * as GlobalS from '../../viewStyles'
import Geral from './components/Geral'
import GraficoEquilibrio from './components/GraficoEquilibrio'
import InvestirMais from './components/InvestirMais'
import ReduzirGastos from './components/ReduzirGastos'
import * as S from './styles'
import { formatDateEnd, formatDateStart } from '../../../hooks/useDate'

class Equilibrio extends React.Component {
  moment = require('moment')

  constructor(props) {
    super(props)

    this.title = {
      padding: '30px 15px 30px 0px',
      display: 'flex'
    }

    this.titleHolder = {
      paddingLeft: 0
    }

    this.state = {
      currentSelect: 0,
      startDate: this.props.dataInicio,
      endDate: this.props.dataFim
    }
  }

  onSelect = (event) => {
    this.setState({ currentSelect: parseFloat(event.target.id) })
  }

  setStartDate = (date) => {
    const startDate = formatDateStart(date)

    this.props.setStartDate(startDate)

    this.setState({ startDate }, function () {
      $('#reduzirGastos').click()
      $('#investirMais').click()
      $('#geral').click()
      $('#grafico').click()
    })
  }

  setEndDate = (date) => {
    const endDate = formatDateEnd(date)

    this.props.setEndDate(endDate)

    this.setState({ endDate }, function () {
      $('#reduzirGastos').click()
      $('#investirMais').click()
      $('#geral').click()
      $('#grafico').click()
    })
  }

  renderTabs = (defaultSelected) => {
    const current = defaultSelected ? defaultSelected : this.state.currentSelect
    return (
      <S.TabContainer>
        <S.TabTitle variant="title" id="0" onClick={this.onSelect} isActive={current === 0}>
          Reduzir gastos
        </S.TabTitle>
        <S.TabTitle variant="title" id="2" onClick={this.onSelect} isActive={current === 2}>
          Investir mais
        </S.TabTitle>
        <S.TabTitle variant="title" id="1" onClick={this.onSelect} isActive={current === 1}>
          Geral
        </S.TabTitle>
      </S.TabContainer>
    )
  }

  renderSelected = (id) => {
    var array = [
      {
        display: 'none'
      },
      {
        display: 'none'
      },
      {
        display: 'none'
      }
    ]
    array[id].display = 'block'
    return (
      <div style={{ marginTop: '-15px' }}>
        <div style={array[0]}>
          <ReduzirGastos />
        </div>
        <div style={array[1]}>
          <Geral />
        </div>
        <div style={array[2]}>
          <InvestirMais />
        </div>
      </div>
    )
  }

  componentDidUpdate(prevProps) {
    if (prevProps.dataInicio !== this.props.dataInicio || prevProps.dataFim !== this.props.dataFim) {
      this.setStartDate(this.props.dataInicio)
      this.setEndDate(this.props.dataFim)
    }
  }

  render() {
    return (
      <GlobalS.Container>
        <PageHeader title="Equilíbrio Financeiro" icon="calendar">
          <DateRangePickerWithHook label="Período" />
        </PageHeader>

        <Row>
          <Col xl="6" lg="12" md="12" style={{ marginBottom: 40 }}>
            <div style={this.halfBody}>
              <div style={this.tabs}>
                {this.renderTabs(this.state.currentSelect)}
                {this.renderSelected(this.state.currentSelect)}
              </div>
            </div>
          </Col>
          <Col xl="6" lg="12" md="12">
            <div style={this.halfBody}>
              <GraficoEquilibrio />
            </div>
          </Col>
        </Row>
      </GlobalS.Container>
    )
  }
}

export default withContexts(Equilibrio)
