import React, { useCallback } from 'react'
import styled from 'styled-components'

import Colors from '../../../domain/Colors'
import { useTheme } from '../../../hooks'
import { ButtonImage, ButtonProps, ButtonsContainer, IconButton, LinkButton } from './Buttons'
// import { CardContentContainer } from './CardContentContainer'
import Footer from './Footer'
import goToButtonWhiteIcon from './images/arrow-riight-white.png'
import goToButtonIcon from './images/arrowRight.png'
import plusButtonIcon from './images/plus.png'
import taskIcon from './images/task.png'
import * as S from './styles'

// interface IconProps {
//   iconSize: string
//   iconOrientation?: 'horizontal' | 'vertical'
// }

// const Icon = styled.img<IconProps>`
//   ${({ iconOrientation, iconSize }) =>
//     iconOrientation === 'horizontal'
//       ? css`
//           height: auto;
//           width: ${iconSize};
//         `
//       : css`
//           height: ${iconSize};
//           width: auto;
//         `}
//   padding-top: 1vw;
//   padding-bottom: 1vw;
// `

const TitleContainer = styled.h2`
  font-weight: bold;
  margin-left: 0.3vw;
  margin-right: 0.3vw;
`

// const DescriptionContainer = styled.div`
//   margin-left: 0.3vw;
//   margin-right: 0.3vw;
//   margin-bottom: 0.3vw;
// `

interface Props {
  title: string
  description: string
  color?: keyof typeof Colors
  goToButton?: ButtonProps
  plusButton?: ButtonProps
  icon?: string
  iconSize?: string
  iconOrientation?: 'horizontal' | 'vertical'
  featured?: boolean
}

interface GetButtonProps {
  goToButton?: ButtonProps
  plusButton?: ButtonProps
}

export default ({
  title,
  featured = false,
  description,
  color = 'blue',
  goToButton,
  plusButton,
  icon = taskIcon,
  iconSize = '4vw',
  iconOrientation = 'horizontal'
}: Props) => {
  const { theme } = useTheme()
  const getButton = useCallback(
    ({ goToButton, plusButton }: GetButtonProps) => {
      const iconSrc = goToButton ? (featured ? goToButtonWhiteIcon : goToButtonIcon) : plusButtonIcon
      const link = goToButton?.link ?? plusButton?.link
      const newTab = goToButton?.newTab || plusButton?.newTab

      if (newTab) {
        return (
          <IconButton className="newTab" onClick={() => window.open(link, '_blank', 'noopener,noreferrer')}>
            <ButtonImage src={iconSrc} />
          </IconButton>
        )
      }
      return (
        <LinkButton className="LinkButton" to={String(link)} title={title}>
          <ButtonImage src={iconSrc} />
        </LinkButton>
      )
    },
    [title, featured]
  )

  return (
    <S.Container featured={featured ? theme : undefined}>
      <div>
        <S.Icon src={icon} iconSize={iconSize} iconOrientation={iconOrientation} />
      </div>
      <S.DescriptionContainer>
        <TitleContainer>{title.toUpperCase()}</TitleContainer>
        <p>{description}</p>
      </S.DescriptionContainer>
      <ButtonsContainer>
        {goToButton && getButton({ goToButton })}
        {plusButton && getButton({ plusButton })}
      </ButtonsContainer>
      <Footer color={color} />
    </S.Container>
  )
}
