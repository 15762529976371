import { create } from 'zustand'
import { persist } from 'zustand/middleware'
// import { logger } from '../logger/logger'

export interface IUserData {
  ativo: number
  autoCategorization: boolean
  autoCategorizationDate: string | null
  cep: string
  cidade: string
  complemento: string
  dataCriacao: string
  dataUltimaAtualizacao: string
  empresaId: number
  endereco: string
  estado: number
  fimContrato: string
  id: number
  inicioContrato: string
  meiosPagamentos?: unknown
  nome: string
  pais: string
  planejadorId: number
  quantidadeCarteiras: number
  recorrencia: number
  statusFamilia: number
  valorPago: number
}

export interface IFamilies {
  empresaId?: number
  empresaNome?: string
  id?: number
  nome?: string
  planejadorId?: number
  planejadorNome?: string
  statusFamilia?: number
}

export interface IFamiliesInitialState {
  defaultImage?: string
  families: IFamilies[]
  family?: IFamilies & { image?: string }
  user?: IUserData
  planejadorId?: number
  planejadorNome?: string
  empresaId?: number
  empresaNome?: string
}

export interface IFamilyStore extends IFamiliesInitialState {
  setFamilies: (families: IFamilies[]) => void
  setFamily: (family: IFamilies & { image?: string }) => void
  setFamilyImage: (image: string) => void
  setUser: (userData: IUserData) => void
}

const DOMAIN = process.env.PUBLIC_URL
const initialState: IFamiliesInitialState = {
  families: [],
  family: undefined,
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  defaultImage: `${DOMAIN}/static/media/familia.b63f190f.png`,
  planejadorId: undefined,
  planejadorNome: undefined,
  empresaId: undefined,
  empresaNome: undefined
}

export const useFamilyStore = create<IFamilyStore>()(
  persist(
    (set) => ({
      ...initialState,
      setFamilies: (families: IFamilies[]) => {
        set({ families })
      },
      setFamily: (family: IFamilies & { image?: string }) => {
        set({ family })
      },
      setFamilyImage: (image: string) => {
        const { family } = useFamilyStore.getState()
        if (!family) return
        set({ family: { ...family, image } })
      },
      setUser: (userData: IUserData) => {
        set({ user: userData })
      }
    }),
    {
      name: 'familyStore'
    }
  )
)
