import React from 'react'

import { Footer } from './Footer.styles'

interface Props {
  children: React.ReactNode
  rounded?: boolean
}

export const TableFooter = ({ children, rounded = false }: Props) => (
  <Footer rounded={rounded}>{children}</Footer>
)
