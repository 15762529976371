import { type ReactElement,useEffect } from 'react'
import { Redirect } from 'react-router'

import { useTheme } from '../../hooks'
import { useAuthStore } from '../../store/auth'
import RelationsThemeDomains from '../../theme/relationsThemeDomains.json'

interface Props {
  children: ReactElement
}

export const PrivateRoute: React.FC<Props> = ({ children }) => {
  const { isAuthenticated } = useAuthStore()
  const { changeTheme } = useTheme()

  useEffect(() => {
    const currentDomain = window.location.host
    for (const themeDomain in RelationsThemeDomains) {
      if (currentDomain === themeDomain) changeTheme(`theme-${RelationsThemeDomains[themeDomain]}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return isAuthenticated ? children : <Redirect to="/auth/login" />
}
