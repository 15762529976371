// import InfoIcon from '@mui/icons-material/Info'
import React, { useEffect } from 'react'

import { UserType, userTypes } from '../../../domain/user/userType'
import { useLocalStorage } from '../../../hooks'
import { useCoreContext } from '../../../hooks/contexts/coreContext/coreProvider'
import { useTutorialsContext } from '../../../hooks/contexts/tutorialsContext'
import { getRouteNameByPath } from '../../../routes'
import { ButtonDefault } from '../../Mui/Button'
import { ModalV2 } from '../ModalV2'
import { MAP } from './helpClientPlanner'
import { MAP_PLANNER } from './helpPlanner'
import * as S from './styles'

export const getRouteProps = () => {
  const pageId = window.location.pathname.replace(/\/v1\/admin/, '')
  const routeName = getRouteNameByPath(pageId) ?? pageId

  return {
    pageId,
    path: routeName,
    name: routeName?.replace(/\//gi, '')
  }
}

const getPage = ({ pageId, userType: type }) => {
  const userType: UserType = userTypes[type]
  let Page: () => JSX.Element | undefined

  if (['planejador', 'admin'].includes(userType)) {
    Page = MAP_PLANNER[pageId]

    if (!Page) {
      Page = MAP[pageId]
    }
  } else {
    Page = MAP[pageId]
  }

  return {
    Page
  }
}

export const HelpModal = () => {
  const { tipoUsuario: userType } = useCoreContext()
  const { isOpen, setIsOpen } = useTutorialsContext()
  const { pageId, name } = getRouteProps()
  const { Page } = getPage({ pageId, userType })
  const { value: storageValue, setValue: setStorageValue } = useLocalStorage(pageId?.replace('/v1/admin', ''))
  const automaticOpen = !storageValue

  const handleClose = () => {
    setIsOpen?.(false)
    globalThis.bodyScroll(false)
  }

  useEffect(() => {
    if (automaticOpen) {
      setIsOpen?.(true)
      setStorageValue('ok')
    }
  }, [])

  useEffect(() => {
    if (isOpen && pageId) {
      setStorageValue('ok')
      globalThis.bodyScroll(isOpen)
    }
  }, [pageId, setStorageValue, isOpen])

  if (!Page || !isOpen) {
    return <></>
  }

  const Header = () => (
    <S.Header>
      <h4>{name}</h4>
      <S.LinkButton href="/admin/tutoriais">Ver todos os tutoriais</S.LinkButton>
    </S.Header>
  )

  return (
    <ModalV2 open={isOpen} close={handleClose} title={<Header />}>
      {Page()}
    </ModalV2>
  )
}

export const HelpModalButton = () => {
  const { value: tipoUsuarioSetado } = useLocalStorage('tipo-usuario')
  const { setIsOpen } = useTutorialsContext()
  const {
    pageId
    // name
  } = getRouteProps()
  // const _name = name === 'Tutoriais' ? false : name
  const { Page } = getPage({ pageId, userType: tipoUsuarioSetado })

  return (
    <ButtonDefault
      onClick={() => {
        console.log('HelpModalButton')
        setIsOpen(true)
      }}
      variant="outlined"
      type="button"
      sx={{ my: 2, display: 'block' }}
      disabled={!Page}
    >
      {/* <InfoIcon sx={{ mr: 1 }} /> */}
      Tutoriais
      {/* {`${_name ? ` ${_name}` : ''}`} */}
    </ButtonDefault>
  )
}
