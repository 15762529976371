import styled from 'styled-components'

import colors from '../../../../domain/Colors'
import { InfoMessage as BaseInfoMessage } from '../../../Navbars/AdminNavbar/components/InfoMessage/infoMessage'
import { AlertModal as BaseAlertModal } from '../AlertModal'

export const AlertModal = styled(BaseAlertModal)`
  /* width: 520px; */
`

export const InfoMessage = styled(BaseInfoMessage)`
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 0;
`

export const AlertFamilyList = styled.ul`
  background-color: ${colors.tableRowGray};
  font-size: 0.88rem;
  max-height: 65px;
  margin-bottom: 1.5rem;
  overflow-y: scroll;
  padding-left: 28px;
`
