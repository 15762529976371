import React from 'react'

import { AlertModal } from '../AlertModal'

interface Props {
  originalName: string
  newName: string
  onConfirm: () => void
  onCancel: () => void
  confirmDisabled: boolean
}

export const InvestmentNameConfirmModal = ({
  originalName,
  newName,
  onConfirm,
  onCancel,
  confirmDisabled
}: Props) => {
  return (
    <AlertModal
      title="Confirmação de mudança de investimento"
      open
      onClose={onConfirm}
      primaryButton={{
        text: 'Confirmar',
        disabled: confirmDisabled,
        onClick: onConfirm
      }}
      secondaryButton={{
        text: 'Cancelar',
        disabled: confirmDisabled,
        onClick: onCancel
      }}
    >
      <p>
        Gostaria de mudar o nome do investimento <b>{originalName}</b>
        <br />
        para <b>{newName}?</b>
      </p>
    </AlertModal>
  )
}
