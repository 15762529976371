import { Col } from 'reactstrap'

import { useTheme } from '../hooks'
import Logo from '../theme/logo'

export const AuthLayout = ({ children }) => {
  const { theme } = useTheme()

  return (
    <>
      <Col
        lg="6"
        md="12"
        style={{
          backgroundColor: theme.colors.backgroundLogin || theme.colors.primary,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          minHeight: 240
        }}
      >
        <a
          href="https://meuvista.com/"
          target="_blank"
          rel="noreferrer noopener"
          style={{
            textAlign: 'center',
            maxWidth: '350px'
          }}
        >
          <Logo sizeW={'100%'} />
        </a>
        <div style={{ position: 'absolute', bottom: 0, width: '100%' }} className="pb-3 mt-3">
          <div className="copyright text-center text-muted">
            © 2020 Todos os direitos reservados
            <span className="font-weight-bold ml-1">Vista</span>
            <span className="ml-1"> - Versão {global.version}</span>
          </div>
        </div>
      </Col>
      <Col
        lg="6"
        md="12"
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: '#f7faff',
          padding: '30px 0px 30px 0px'
        }}
      >
        {children}
      </Col>
    </>
  )
}
