import { useMemo } from 'react'

import { IOrcamentoPrevistoRealizado } from '../../domain/dashboard/api'

export const useChartOrcamentoPrevistoRealizado = ({
  orcamentoRealizado
}: {
  orcamentoRealizado: IOrcamentoPrevistoRealizado[]
}) => {
  const chartData = useMemo(() => {
    const labels: string[] = []
    const series: number[] = []
    let totalMedio = 0

    if (orcamentoRealizado != null && orcamentoRealizado.length > 0) {
      orcamentoRealizado.forEach((item) => {
        if (item.totalMedio > 0 && Number(item.tipoOrcamento) !== 0) {
          labels.push(item.nome)
          totalMedio = Number(item.totalMedio.toFixed(2))
          series.push(totalMedio)
        }
      })
    }

    return {
      options: {
        labels: labels,
        dataLabels: {
          style: {
            fontFamily: 'Open Sans, sans-serif'
          }
        },
        legend: {
          position: 'right',
          fontFamily: 'Open Sans, sans-serif',
          fontSize: '11px',
          markers: {
            offsetY: '1.5px'
          }
        },
        chart: {
          background: '#ffffff'
        }
      },
      series
    }
  }, [orcamentoRealizado])

  return chartData
}
