import NavLinkBase from 'reactstrap/lib/NavLink'
import styled, { css } from 'styled-components'

import { ButtonGradient } from '../../../../Mui/Button'
import { INavItem } from './types'

export const NavItem = styled.li<INavItem>`
  ${({ visible }) =>
    !visible &&
    css`
      display: none;
    `}
`

export const NavLink = styled(NavLinkBase)`
  ${({ theme }) =>
    theme &&
    css`
      background: linear-gradient(45deg, ${theme.colors.primary} 0%, ${theme.colors.secondary} 100%);
      color: #ffffff !important;
    `}
  align-items: center;
  border-radius: 25px;
  display: flex;
  font-size: 0.88rem;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  gap: 5px;
  padding: 4px 10px !important;
  width: 100%;
`

export const Button = styled(ButtonGradient)`
  gap: 0.5rem;
`

export const Icon = styled.i`
  color: #fdd916;
  font-size: 0.8rem;

  svg {
    fill: #fff;
  }
`

export const Text = styled.span`
  font-size: 0.85rem;
`
