import { Typography } from '@mui/material'
import React from 'react'
import styled from 'styled-components'

import { useTheme } from '../../hooks'

const TitleText = styled(Typography)`
  && {
    color: ${({ theme: { colors } }) => colors.secondary};
    margin-bottom: 1rem;
    font-weight: normal;
  }
`

export const Titulo = () => {
  const {
    theme: {
      plannerHome: { title }
    }
  } = useTheme()
  return <TitleText variant="h6">{title}</TitleText>
}
