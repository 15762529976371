import { endOfMonth,isSameMonth } from 'date-fns'

const nonBreakingSpace = '\u00A0'
export const currencyPrefix = 'R$' + nonBreakingSpace

export const formatMonetaryValue = (value: number, removeCurrency = true) => {
  let parsed = value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
  if (removeCurrency) parsed = parsed.replace(currencyPrefix, '')
  return parsed
}

export const formatDateForInvoice = (date: Date): Date => {
  const currentDate = new Date()

  const setExactTime = (date: Date): Date => {
    const adjustedDate = new Date(date)
    adjustedDate.setUTCHours(23)
    adjustedDate.setUTCMinutes(59)
    adjustedDate.setUTCSeconds(59)
    adjustedDate.setUTCMilliseconds(0)
    return adjustedDate
  }

  if (isSameMonth(date, currentDate)) {
    return setExactTime(new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())))
  } else {
    const startDate = endOfMonth(date)
    return setExactTime(
      new Date(Date.UTC(startDate.getUTCFullYear(), startDate.getUTCMonth(), startDate.getUTCDate() - 1))
    )
  }
}
