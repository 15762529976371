import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { Box, Chip, Stack, styled } from '@mui/material'
import React, { ChangeEvent, useCallback, useState } from 'react'

import { useCoreContext } from '../../hooks/contexts/coreContext/coreProvider'
import { useExtratoUpload } from '../../queries/extrato/mutations'
import { useBankPdfTypes } from '../../queries/extrato/queries'
import { AlertModal } from '../Modal'
import { ButtonGradient } from '../Mui/Button'
import { SuccessfulUploadAlert } from './SuccessfulUploadAlert'
import { UploadErrorAlert } from './UploadErrorAlert'

export type TFileFormat = 'ofx' | 'csv' | 'xls' | 'pdf' | 'xlsx'
const FILE_FORMATS_MAP: Record<TFileFormat, string> = {
  ofx: '.ofx',
  csv: '.csv',
  xls: '.xls, .xlsx',
  pdf: '.pdf',
  xlsx: '.xlsx, .xls'
}

interface Props {
  meioPagamentoId?: number
  formato?: TFileFormat
  banco?: string
  typePdf?: string
  year?: number
  onUpload?: (file: File) => void
  disabled?: boolean
}

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
})

export const FileUploadForExtrato = ({
  banco,
  formato,
  meioPagamentoId,
  typePdf,
  year,
  onUpload,
  disabled
}: Props) => {
  const [errorMessage, setErrorMessage] = useState<string>()
  const { familiaId } = useCoreContext()
  const { empresaId } = useCoreContext()
  const [file, setFile] = useState<File | null>(null)

  const {
    duplicated,
    isExtratoUploadError,
    isUploading,
    resetUpload,
    sendToExtrato,
    sendToVistaExtract,
    uploadError,
    uploaded,
    withoutCategory
  } = useExtratoUpload()

  const uploadExtrato = useCallback(
    (uploadEvent: ChangeEvent<HTMLInputElement>) => {
      const bancoEditado = (rawBank: string) => rawBank.split('_')[0]
      const paymentMethodEditado = (rawPay: string) => rawPay.split('_')[1]

      if (!familiaId) {
        setErrorMessage('Informe a família.')
        return
      }

      if (!empresaId) {
        setErrorMessage('Informe a empresa.')
        return
      }

      if (!uploadEvent?.target?.files?.[0]) {
        console.warn('no files uploaded')
        setErrorMessage('Nenhum arquivo selecionado.')
        return
      } else {
        setFile(uploadEvent.target.files[0])
      }

      if (!meioPagamentoId) {
        setErrorMessage('Informe meio de pagamento.')
        return
      }

      if (!banco) {
        setErrorMessage('Informe o banco do extrato.')
        return
      }

      if (!formato) {
        setErrorMessage('Informe o formato do arquivo.')
        return
      }

      const file = uploadEvent.target.files[0]
      const originalFile = file
      const reader = new FileReader()

      reader.onload = (fileReadEvent) => {
        fileReadEvent?.preventDefault()
        if (!fileReadEvent?.target?.result) return
        const binaryString = fileReadEvent.target.result
        const file = btoa(binaryString.toString())

        onUpload?.(originalFile)

        if (banco === 'excelPadrao_excelPadrao' && empresaId)
          sendToVistaExtract({
            spreadsheetBase64: file,
            companyId: String(empresaId),
            familyId: String(familiaId),
            paymentMethodId: meioPagamentoId
          })
        else if (empresaId)
          sendToExtrato({
            file,
            type: formato,
            bank: bancoEditado(banco),
            paymentMethod: paymentMethodEditado(banco),
            empresaId: String(empresaId),
            familiaId: String(familiaId),
            meioPagamentoId: meioPagamentoId,
            typePdf,
            descriptionYear: year
          })
      }
      reader.readAsBinaryString(file)
    },
    [
      banco,
      empresaId,
      familiaId,
      formato,
      meioPagamentoId,
      onUpload,
      sendToExtrato,
      sendToVistaExtract,
      typePdf,
      year
    ]
  )

  // const getErrorMessage = () => {
  //   if (!meioPagamentoId) return 'Informe meio de pagamento.'
  //   if (!banco) return 'Informe o banco do extrato.'
  //   if (!formato) return 'Informe o formato do arquivo.'
  //   return ''
  // }
  const hasMainData = meioPagamentoId != null && formato != null && banco != null
  const requiresPdfType = useBankPdfTypes(banco).length > 1
  const canUpload = (hasMainData && (!requiresPdfType || typePdf !== undefined)) || !disabled
  // const filterdErrorMessage = getErrorMessage()

  const clearUpload = useCallback(() => {
    setFile(null)
    resetUpload()
  }, [resetUpload])

  return (
    <>
      <Box display="flex" gap={2} flexDirection="column">
        {file && (
          <Stack direction="row" spacing={2}>
            <Chip label={file.name} onDelete={clearUpload} />
          </Stack>
        )}
        <UploadErrorAlert isError={isExtratoUploadError} error={uploadError} onAccept={clearUpload} />
      </Box>

      <div>
        <ButtonGradient
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          type="button"
          startIcon={<CloudUploadIcon />}
          loading={isUploading}
          disabled={!canUpload}
          style={{ marginBottom: '1rem' }}
        >
          Selecionar arquivo
          <VisuallyHiddenInput
            type="file"
            accept={formato ? FILE_FORMATS_MAP[formato] : ''}
            name="file"
            onInput={uploadExtrato}
            disabled={!canUpload}
          />
        </ButtonGradient>
      </div>

      <SuccessfulUploadAlert
        isOpen={uploaded}
        duplicated={duplicated}
        withoutCategory={withoutCategory}
        onAccept={() => {
          resetUpload()
          window.location.reload()
        }}
      />
      <AlertModal
        open={!!errorMessage}
        onClose={() => setErrorMessage(undefined)}
        title="Erro no preenchimento dos campos"
      >
        {errorMessage}
      </AlertModal>
    </>
  )
}
