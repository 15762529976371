import React, { useCallback, useEffect, useRef, useState } from 'react'

import VerticalDivider from '../../dividers'
import { ArrowDown, Loading } from '../../Icons'
import { isClickOutside } from '../../Utils'
import Options, { Selectable } from './Options'
import * as S from './styles'

export interface Props<T> {
  filter?: string
  placeholder?: string
  options: Selectable<T>[]
  loading?: boolean
  grayscale?: boolean
  borderless?: boolean
  onSelection?: (value: T) => void
  onOpen?: () => void
  onClose?: () => void
  onFilter?: (filter: string) => void
  className?: string
  id?: string
  disabled?: boolean
}

export const Select = <T,>({
  filter = '',
  options,
  disabled,
  placeholder,
  loading = false,
  grayscale = false,
  borderless = false,
  onSelection,
  onOpen,
  onClose,
  onFilter,
  className,
  id
}: Props<T>) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const focusFilter = useCallback(() => {
    const { current } = inputRef
    if (!current) return
    current.focus()
  }, [inputRef])

  const ref = useRef<HTMLDivElement>(null)
  const [isOpen, setOpen] = useState(false)
  const open = useCallback(() => {
    focusFilter()
    setOpen(true)
    onOpen?.()
  }, [setOpen, onOpen, focusFilter])
  const close = useCallback(() => {
    setOpen(false)
    onClose?.()
  }, [setOpen, onClose])
  const onClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.preventDefault()
      event.stopPropagation()
      if (loading || disabled) return
      if (isOpen) close()
      else open()
    },
    [isOpen, open, close, loading, disabled]
  )

  // Close on click outside
  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (isClickOutside(ref, event)) close()
    }

    document.addEventListener('mousedown', handleClick)
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  })

  const optionsAvailable = isOpen && !loading

  return (
    <S.Container
      ref={ref}
      open={optionsAvailable}
      borderless={borderless}
      onClick={onClick}
      className={className}
      id={id}
    >
      <S.SearchSizer open={optionsAvailable}>
        <S.SearchInput
          ref={inputRef}
          type="text"
          disabled={disabled}
          placeholder={placeholder}
          value={filter}
          borderless={borderless}
          onChange={({ target: { value } }) => onFilter?.(value)}
        />
      </S.SearchSizer>
      <S.IconContainer>
        {loading && <Loading />}
        <VerticalDivider color={grayscale ? 'gray' : 'darkGray'} />
        <ArrowDown grayscale={grayscale} />
      </S.IconContainer>
      {optionsAvailable && (
        <Options
          options={options}
          onSelection={(v) => {
            close()
            onSelection?.(v)
          }}
        />
      )}
    </S.Container>
  )
}
