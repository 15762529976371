import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { noticeError, Tags } from '../../services/Monitoring'

const DivContainerAll = styled.div`
  position: absolute;
  top: 0;
  right: 8px;
  z-index: 1000;
  padding: 8px;
  @media (min-width: 1200px) {
    right: 88px;
  }
`

const DivContainerMsg = styled.div<{ open?: boolean }>`
  position: absolute;
  top: 8px;
  right: 32vw;
  width: 32vw;
  z-index: 401;
  padding: 16px;
  border-top: solid 4px #fa5d86;
  border-bottom: solid 1px #fa5d86;
  border-left: dashed 1px #fc9eb6;
  border-right: dashed 1px #fc9eb6;
  border-radius: 16px;
  background: #ffeff3;
  box-shadow: 4px 4px 12px #bbbbbbcc;
  transition: all 0.6s ease-in-out;
  transform: ${({ open }) => (open ? 'translateY(0)' : 'translateY(-160%)')};
  display: ${({ open }) => (open ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: center;
`

const PTitleMsg = styled.p`
  font-size: 20px;
  font-weight: 700;
  line-height: 1.2;
  color: #e90742;
`

const PDescMsg = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 1.4;
  color: #a6052f;
`

const ButtonCloseMsg = styled.button`
  width: fit-content;
  align-self: flex-end;
  border: solid 2px #fa5d86;
  background: #fff;
  border-radius: 50%;
  color: #e90742;
  cursor: pointer;
`

interface MessageComponentProps {
  title: string
  text: string
  error?: Error | null
  tags?: Tags | null
}

const MessageComponent = ({ title, text, error, tags }: MessageComponentProps) => {
  const [open, setOpen] = useState(true)
  const close = () => setOpen(false)
  useEffect(() => {
    if (error) noticeError(error, { title, text, ...tags })
  }, [error, title, text, tags])

  return (
    <DivContainerMsg open={open}>
      <PTitleMsg>{title}</PTitleMsg>
      <PDescMsg>{text}</PDescMsg>
      <ButtonCloseMsg onClick={close}>✖</ButtonCloseMsg>
    </DivContainerMsg>
  )
}

interface Props {
  messages: MessageComponentProps[]
  on: boolean
}

export default ({ messages, on }: Props) => {
  return (
    <DivContainerAll>
      {on
        ? messages.map(({ title, text, error, tags }, index) => (
            <MessageComponent key={index} title={title} text={text} error={error} tags={tags} />
          ))
        : null}
    </DivContainerAll>
  )
}

export type { Props as ErrorMessageProps, MessageComponentProps }
