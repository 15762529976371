import React, { useState } from 'react'

import { ButtonGradient } from '../../../components/Mui/Button'
import { DateRangePickerWithHook } from '../../../components/Mui/DatePicker'
import { PageHeader } from '../../../components/PageHeader'
import { useCoreContext } from '../../../hooks/contexts/coreContext/coreProvider'
import { setEndOfDay, setStartOfDay } from '../../../hooks/useDate'
import { InnerContent } from './InnerContent'
import * as S from './styles'

export const Equity = () => {
  const { familiaId: familyId, startDate, endDate } = useCoreContext()
  const [isOpenEquityModal, setIsOpenEquityModal] = useState(false)

  const handleOpenModal = () => setIsOpenEquityModal(true)

  return (
    <S.ContentContainer>
      <PageHeader title="Patrimônio - Integração" />

      <S.Section justify="space-between">
        <DateRangePickerWithHook label="Período" />
        <ButtonGradient onClick={handleOpenModal}>Novo Patrimônio</ButtonGradient>
      </S.Section>

      {!!familyId && (
        <InnerContent
          isOpenEquityModal={isOpenEquityModal}
          setIsOpenEquityModal={setIsOpenEquityModal}
          familyId={Number(familyId)}
          start={setStartOfDay(new Date(startDate))}
          end={setEndOfDay(new Date(endDate))}
        />
      )}
    </S.ContentContainer>
  )
}
