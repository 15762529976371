import React from 'react'
import styled from 'styled-components'

import refresh from '../../assets/img/theme/refresh.png'
import { Size, withSize } from '../withSize'
import Icon from './Icon'

interface Props {
  size?: Size
  onClick?: () => void
  tooltip?: string
}

const StyledIcon = styled(Icon)<{ size?: Size }>`
  max-width: ${({ size }) => withSize(size)};
  max-height: ${({ size }) => withSize(size)};
`

export default ({ onClick, tooltip, size }: Props) => (
  <StyledIcon alt="Atualizar" size={size} src={refresh} onClick={onClick} tooltip={tooltip} />
)
