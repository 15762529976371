import styled from 'styled-components'

import Colors from '../../../domain/Colors'

interface Props {
  color: keyof typeof Colors
}

const Footer = styled.div<Props>`
  display: flex;
  height: 8px;
  width: 100%;
  background-color: rgb(${(props) => Colors[props.color]});
`

export default Footer
