import { Box, Tab, Tabs } from '@material-ui/core'
import { startOfMonth, subMonths } from 'date-fns'
import React, { useState } from 'react'
import Datepicker from 'react-datepicker'
import MaskedInput from 'react-maskedinput'
import styled from 'styled-components'

import WrapperForDatePicker from '../../../../theme/wrapperForDatePicker'

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box py={3} style={{ display: 'flex' }}>
          {children}
        </Box>
      )}
    </div>
  )
}

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

const SelectContainer = styled.div`
  display: flex;
  flex: 1;

  .react-datepicker__tab-loop {
    left: 0;
  }
`

export const TabsPanel = ({ invoiceDate, setInvoiceDate }) => {
  const [tabIndex, setTabIndex] = useState(0)
  const currentDate = new Date()
  const previousMonth = subMonths(currentDate, 1)

  const handleChange = (_event, newValue: number) => {
    if (newValue === 0) {
      setInvoiceDate(currentDate)
    } else {
      setInvoiceDate(previousMonth)
    }
    setTabIndex(newValue)
  }

  return (
    <>
      <Tabs
        value={tabIndex}
        onChange={handleChange}
        aria-label="Seleciona a fatura aberta ou as fechadas"
        indicatorColor="primary"
      >
        <Tab label="Fatura Aberta" {...a11yProps(0)} />
        <Tab label="Faturas Fechadas" {...a11yProps(1)} />
      </Tabs>
      <TabPanel value={tabIndex} index={0}>
        <span>Selecione a data</span>
        <SelectContainer>
          <WrapperForDatePicker>
            <Datepicker
              locale="br"
              className="date-picker-styled"
              id="invoice-date"
              dateFormat="dd/MM/yyyy"
              minDate={startOfMonth(currentDate)}
              maxDate={currentDate}
              placeholderText="Selecione a data"
              selected={invoiceDate}
              showYearDropdown
              dateFormatCalendar="MMMM"
              scrollableYearDropdown
              yearDropdownItemNumber={5}
              onChange={(e) => setInvoiceDate(e)}
              customInput={<MaskedInput mask="11/11/1111" />}
            />
          </WrapperForDatePicker>
        </SelectContainer>
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <span>Selecione o mês e ano</span>
        <SelectContainer>
          <WrapperForDatePicker>
            <Datepicker
              locale="br"
              className="date-picker-styled"
              id="invoice-date"
              dateFormat="MM/yyyy"
              showMonthYearPicker
              maxDate={previousMonth}
              placeholderText="Selecione o mês e ano"
              selected={invoiceDate}
              showYearDropdown
              dateFormatCalendar="MMMM"
              scrollableYearDropdown
              yearDropdownItemNumber={5}
              onChange={(e) => setInvoiceDate(e)}
            />
          </WrapperForDatePicker>
        </SelectContainer>
      </TabPanel>
    </>
  )
}
