import { getWithToken } from '../../../webClient'
import { SingleResult } from '../../Results'
import { Pessoa } from '../Pessoa'

interface Params {
  personId: number
}

export const getPessoaById = async ({ personId }: Params) => {
  const url = `pessoa/${personId}`
  const { data: { singleResult: pessoa, success, errorMsg } = {}, status } = await getWithToken<
    SingleResult<Pessoa>
  >(url)
  if (!success || status !== 200) {
    const msg = `Error getting Pessoa ${personId}: ${errorMsg}`
    console.error(msg)
    throw new Error(msg)
  }
  return pessoa
}
