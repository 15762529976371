import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'

import { Family } from '../../domain/family/Family'
import { AuthContext, ThemeContext, useBooleanLocalStorage } from '../../hooks'
import { useCoreContext } from '../../hooks/contexts/coreContext/coreProvider'
import { useLoadingScreenContext } from '../../hooks/contexts/loadingScreenContext'
import { useSelectFamily } from '../../hooks/useCurrentFamily'
import { useLocalStorage } from '../../hooks/useLocalStorage'
import { useFamilyQuery, useGetImageBYFamilyId } from '../../queries/family/queries'
import { useDiagnosticStore } from '../../store/Diagnostic'
import { ComponentProps } from './withContexts.types'

export const withContexts =
  (Component: (props: ComponentProps) => JSX.Element) => (props: ComponentProps) => {
    const themeContext = useContext(ThemeContext)
    const authContext = useContext(AuthContext)
    const { token } = authContext || {}
    const { setValue: setFamiliaId } = useLocalStorage('familia-id')
    const history = useHistory()
    const diagnostic = useDiagnosticStore()
    const { handleFamilyChange } = useSelectFamily()
    const { setIsLoading: setIsLoadingScreen, isLoading: isLoadingScreen } = useLoadingScreenContext()

    const {
      authenticated,
      empresaId,
      endDate,
      familiaId: familyId,
      pessoaId,
      planejadorId,
      setEmpresaId,
      setEndDate,
      setStartDate,
      setTipoUsuario,
      setUsuarioEmail,
      setUsuarioId,
      startDate,
      tipoUsuarioSetado,
      userId,
      usuarioEmailSetado
    } = useCoreContext()

    const { family } = useFamilyQuery(familyId ? parseInt(familyId as unknown as string) : undefined)
    const { familyImage } = useGetImageBYFamilyId({ familyId: familyId })
    const tipoUsuario = tipoUsuarioSetado ? parseInt(tipoUsuarioSetado as string) : token?.type
    const { value: mostrarModalAposentadoria, setValue: setMostrarModalAposentadoria } =
      useBooleanLocalStorage('mostrarModalAposentadoria', true)

    return (
      <Component
        {...props}
        authContext={authContext}
        authenticated={authenticated}
        dataFim={new Date(endDate)}
        dataInicio={new Date(startDate)}
        diagnostic={diagnostic}
        empresaId={empresaId ? Number(empresaId) : null}
        familiaId={familyId}
        family={family || ({} as Family)}
        familyImage={familyImage}
        handleFamilyChange={handleFamilyChange}
        history={history}
        isLoadingScreen={isLoadingScreen}
        mostrarModalAposentadoria={mostrarModalAposentadoria}
        pessoaId={pessoaId}
        planejadorId={planejadorId ? Number(planejadorId) : null}
        setDataFim={setEndDate}
        setDataInicio={setStartDate}
        setEmpresaId={setEmpresaId}
        setEndDate={setEndDate}
        setFamiliaId={(familyId) => setFamiliaId(familyId || null)}
        setIsLoadingScreen={setIsLoadingScreen}
        setMostrarModalAposentadoria={setMostrarModalAposentadoria}
        setStartDate={setStartDate}
        setTipoUsuario={setTipoUsuario}
        setUsuarioEmail={setUsuarioEmail}
        setUsuarioId={setUsuarioId}
        themeContext={themeContext}
        tipoUsuario={typeof tipoUsuario === 'number' ? tipoUsuario : null}
        tipoUsuarioSetado={tipoUsuarioSetado}
        userId={userId}
        usuarioEmailSetado={usuarioEmailSetado}
      />
    )
  }
