import React from 'react'

import { ButtonGradient } from '../../../../Mui/Button'

export interface ButtonSaveTrancactionProps {
  onClick: () => void
  disabled?: boolean
  isEditing?: boolean
  isSubmitting?: boolean
}
export const ButtonSaveTransaction = ({
  onClick,
  isEditing,
  isSubmitting = false,
  disabled,
  ...rest
}: ButtonSaveTrancactionProps) => {
  return (
    <ButtonGradient {...rest} disabled={disabled || isSubmitting} onClick={onClick} size="large">
      {isSubmitting ? 'Salvando...' : `${isEditing ? 'Salvar' : 'Criar'} lançamento`}
    </ButtonGradient>
  )
}
