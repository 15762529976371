import React from 'react'

import { AlertModal } from '../../../components/Modal'

export const ErrorTransaction = ({ onClose, editingTransaction, message }) => {
  const title = editingTransaction ? 'Edição de Lançamento' : 'Cadastro de Lançamento'

  return (
    <AlertModal
      mode={editingTransaction ? 'toasty' : 'confirm'}
      toastyType="error"
      open={true}
      onClose={onClose}
      title={title}
    >
      <h4>Ocorreu um erro ao salvar{!!message && ':'}</h4>
      {!!message && <p>{message}</p>}
    </AlertModal>
  )
}
