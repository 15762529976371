import { postWithToken } from '../../../webClient'
import { ManyResults } from '../../Results'
import { MeioPagamento } from '../meioPagamento'

// Todo: type filters when necessary
interface Filter {}

export const fetchMeiosPagamentoByFamilyIdFiltroApi = async (familyId: number, filter: Filter) => {
  if (!familyId) {
    console.log('Missing family id when fetching MeiosPagamentoByFamilyIdFiltroApi')
    return {
      categorias: [],
      totalResults: 0
    }
  }
  const url = `meioPagamento/familia/${familyId}/filtro`
  const {
    data: { results, success, errorMsg },
    status
  } = await postWithToken<ManyResults<MeioPagamento>, Filter>(url, filter)

  if (!success || status !== 200) {
    console.error(`Error getting MeiosPagamento from family ${familyId}: ${errorMsg}`)
    console.error('Returning empty list list of MeiosPagamento')
    return []
  }

  return results
}

// export const fetchAllByFamily = (familyId: number) => fetchByFamily(familyId, {})
