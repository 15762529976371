import React from 'react'

import { useCoreContext } from '../../hooks/contexts/coreContext/coreProvider'

export interface BetaUserProps {
  navBarCollapsed: boolean
  setNavBarCollapsed: React.Dispatch<React.SetStateAction<boolean>>
}

export const withSidebarContexts =
  (Component: (props: BetaUserProps) => JSX.Element) => (props: BetaUserProps) => {
    const { navBarCollapsed, setNavBarCollapsed } = useCoreContext()
    return <Component {...props} navBarCollapsed={navBarCollapsed} setNavBarCollapsed={setNavBarCollapsed} />
  }
