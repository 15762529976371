import { useInfiniteQuery } from 'react-query'

import { getListOfNotes } from '../../domain/Notes/api/getListNotes'

export const cacheKey = 'notes'

export const useListOfNotes = (
  familyId?: number,
  desc?: boolean,
  pageSize?: number,
  search?: string,
  favorite?: boolean,
  enabled = true
) => {
  const {
    data: notes,
    isSuccess,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage: isLoadingNextNotes,
    isLoading: isLoadingNotes
  } = useInfiniteQuery(
    [cacheKey, familyId, desc, pageSize, search, favorite],
    ({ pageParam: pageIndex }) => {
      if (!familyId) {
        throw new Error('Missing family id when fetching notes')
      }
      return getListOfNotes({ familyId, pageIndex, desc, pageSize, search, favorite })
    },
    {
      getNextPageParam: (lastPage) => lastPage.nextPage,
      enabled: !!familyId && enabled
    }
  )
  return { notes, hasNextPage, isLoadingNextNotes, isLoadingNotes, isSuccess, fetchNextPage }
}
