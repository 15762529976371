import React, { useState } from 'react'
import styled from 'styled-components'

import { FixedAsset } from '../../../domain/investments/fixedAsset'
import { showAlert,useCurrentFamilyId } from '../../../hooks'
import { useCreateFixedAsset, useUpdateFixedAsset } from '../../../queries/investments/fixedAssetsMutations'
import { noticeError } from '../../../services/Monitoring'
import { DatePicker, MonetaryInput,TextInput, TextInputTitle } from '../../Inputs'
import { FixedAssetTypeSelect } from '../../Selects'
import SaveButtonModal from '../SaveButtonModal'

const Title = styled.h2`
  padding-bottom: 2vh;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`

const SelectContainer = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-width: 350px;
  height: 6rem;
  display: flex;
  flex: 1;
  padding: 0.5vw;
`

interface Props {
  fixedAsset?: FixedAsset
  open: boolean
  onClose: () => void
  onSave: (fixedAsset: FixedAsset) => void
}

/** This modal allows creating new fixed assets. If a fixedAsset is passed as parameter
 * it'll actually be an edit modal for that asset. */
export default ({ open, fixedAsset, onClose, onSave }: Props) => {
  const title = fixedAsset ? `Editar Patrimônio ${fixedAsset.name}` : 'Cadastro Patrimônio Imobilizado'
  const { familyId } = useCurrentFamilyId()
  const {
    createFixedAssetAsync,
    isCreatingFixedAsset,
    isErrorCreatingFixedAsset,
    fixedAssetCreationError,
    resetFixedAssetCreation
  } = useCreateFixedAsset()

  const {
    updateFixedAssetAsync,
    isUpdatingFixedAsset,
    isErrorUpdatingFixedAsset,
    fixedAssetUpdateError,
    resetFixedAssetUpdate
  } = useUpdateFixedAsset()

  const [type, setType] = useState(fixedAsset?.type)
  const [name, setName] = useState(fixedAsset?.name)
  const newDate = fixedAsset?.buyDate ? new Date(fixedAsset.buyDate) : new Date()
  const [date, setDate] = useState<Date | undefined>(newDate)
  const [value, setValue] = useState(fixedAsset?.value)
  const isLoading = isCreatingFixedAsset || isUpdatingFixedAsset
  const isSaveButtonEnabled = type && name && date && value && !isLoading
  const getLoadingMessage = () => {
    if (isCreatingFixedAsset) return 'Criando patrimônio...'
    if (isUpdatingFixedAsset) return 'Atualizando patrimônio...'
  }
  const getSaveButtonTooltip = () => {
    if (!type) return 'Preencha o tipo do patrimônio'
    if (!name) return 'Preencha a descrição do patrimônio'
    if (!date) return 'Preencha a data de compra do patrimônio'
    if (!value) return 'Preencha o valor do patrimônio'
  }

  const valueField = type === 'vehicle' ? 'Valor de Mercado' : 'Valor'

  if (isErrorCreatingFixedAsset) {
    noticeError(fixedAssetCreationError, { familyId, type, name, date, value })
    showAlert(
      'Erro ao criar patrimônio',
      'Houve um erro interno ao criar o patrimônio. Por favor tente novamente mais tarde.',
      () => {
        resetFixedAssetCreation()
        onClose()
      }
    )
  }

  if (isErrorUpdatingFixedAsset) {
    noticeError(fixedAssetUpdateError, { familyId, type, name, date, value })
    showAlert(
      'Erro ao atualizar patrimônio',
      'Houve um erro interno ao atualizar o patrimônio. Por favor tente novamente mais tarde.',
      () => {
        resetFixedAssetUpdate()
        onClose()
      }
    )
  }

  return (
    <SaveButtonModal
      isLoading={isLoading}
      loadingMessage={getLoadingMessage()}
      open={open}
      onClose={onClose}
      onSave={async () => {
        if (!date || !type || !value || !name) {
          console.warn('should not have reached here with invalid data')
          return
        }

        const currentValues = {
          name,
          familyId,
          type,
          value,
          buyDate: date.toISOString()
        }
        if (fixedAsset) {
          const assetWithId = { ...currentValues, _id: fixedAsset._id }
          await updateFixedAssetAsync(assetWithId)
          onSave(assetWithId)
        } else {
          const asset = await createFixedAssetAsync(currentValues)
          onSave(asset)
        }
      }}
      disabledSaveButton={!isSaveButtonEnabled}
      tooltip={getSaveButtonTooltip()}
    >
      <Row>
        <Title>{title}</Title>
      </Row>
      <Row>
        <SelectContainer>
          <TextInputTitle>Tipo de Patrimônio</TextInputTitle>
          <FixedAssetTypeSelect type={type} onChange={setType} borderless={true} />
        </SelectContainer>
        <SelectContainer>
          <TextInput title="Descrição de Patrimônio" onChange={setName} text={name} />
        </SelectContainer>
      </Row>
      <Row>
        <SelectContainer>
          <TextInputTitle>Data da Compra</TextInputTitle>
          <DatePicker formStyle={true} currentDate={date} onChange={setDate} />
        </SelectContainer>
        <SelectContainer>
          <MonetaryInput title={valueField} onChange={setValue} value={value} />
        </SelectContainer>
      </Row>
    </SaveButtonModal>
  )
}
