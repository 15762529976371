import FilterListIcon from '@mui/icons-material/FilterList'
import SearchIcon from '@mui/icons-material/Search'
import StarIcon from '@mui/icons-material/Star'
import StarOutlineIcon from '@mui/icons-material/StarOutline'
import { IconButton, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import CreateNoteImg from '../../../assets/img/createNotesImg.svg'
import { Note } from '../../../domain/Notes/Notes'
import { useCurrentFamilyQuery } from '../../../queries/family/queries'
import { ButtonGradient } from '../../Mui/Button'
import RadioButton from '../../RadioButton'
import { ModalV2 } from '../ModalV2'
import { ListOfNotes } from './ListOfNotes'
import { ViewNote } from './ViewNote'

const ContainerAll = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow: hidden;
  border-radius: 16px;
  color: black;
  height: 540px;

  @media (min-width: 1360px) {
    /* width: 80vw; */
    min-height: 540px;
  }
  @media (min-width: 1712px) {
    /* width: 72vw; */
  }
`

const ContainerLeft = styled.div`
  border-right: solid 2px #64646480;
  display: flex;
  flex-direction: column;
  height: 100%;

  width: 50%;
`

const ContainerRight = styled.div`
  border-bottom-right-radius: 16px;
  border-top-right-radius: 16px;
  border: solid 4px ${({ theme }) => theme.colors.primary}64;
  overflow-y: auto;

  width: 100%;
`

const Header = styled.div`
  border-bottom: solid 1px #64646480;
  background: #fff;
  padding: 16px 16px 16px 24px;
  display: flex;
  flex-direction: column;
`

const HeaderFilters = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4px;
  margin-top: 0.7rem;
`

const TitleAndFilter = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
`

const Filters = styled.div`
  max-width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 4px;
`

// const FilterButton = styled.button<{ open: boolean }>`
//   border: none;
//   outline: none;
//   background: none;
//   width: 32px;
//   height: 32px;
//   padding: 8px;
//   border: none;
//   outline: none;
//   background: none;
//   cursor: pointer;
//   border-radius: 8px;
//   ${({ open }) => (open ? 'background: #64646416;' : '')}

//   &:hover {
//     background: #64646424;
//   }
// `

const SearchInput = styled.input`
  flex: 1;
  background: #b0b0b016;
  padding: 6px;
  color: #484848;
  border-radius: 8px;
  border: 1px solid #aaa;
  font-size: 14px;
  cursor: text;
`

const ContainerCreateNote = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding: 2rem 1rem;
`

const ImgCreateNote = styled.img`
  width: 36%;
`

const ContainerNewNote = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const FamilyName = styled.span`
  font-size: 0.72rem;
  padding: 0.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #eee8;
  align-self: center;
  margin-top: 0.3rem;
  flex: 1;
  line-height: 1.2;
  font-weight: 600;
`

const StyledModalV2 = styled(ModalV2)`
  && {
    .skylight-dialog {
      padding: 0px !important;
    }
  }
`

const FilterButton = styled(IconButton)<{ active: boolean }>`
  && {
    ${({ active }) => active && 'background: #64646416;'}
  }
`

type WhatIsOpen = 'home' | 'view' | 'create'

interface Props {
  open: boolean
  onClose: () => void
}

export const NotesModal = ({ open, onClose }: Props) => {
  const [showFilter, setShowFilter] = useState(false)
  const [showSearch, setShowSearch] = useState(false)
  const [favoritesOnly, setFavoritesOnly] = useState(false)
  const [lastOrOlder, setLastOrOlder] = useState('last')
  const [filter, setFilter] = useState('')
  const [selectedItem, setSelectedItem] = useState<Note | undefined>()
  const [isNewNote, setIsNewNote] = useState(false)
  const [whatIsOpen, setWhatIsOpen] = useState<WhatIsOpen>('home')
  const { family } = useCurrentFamilyQuery()
  const searchInputRef = useRef<HTMLInputElement>(null)

  const handleShowFilter = () => {
    setShowSearch(false)
    setShowFilter(!showFilter)
  }
  const handleShowSearch = () => {
    setShowFilter(false)
    setShowSearch((value) => !value)
  }

  useEffect(() => {
    if (showSearch) {
      setTimeout(() => {
        searchInputRef.current?.focus()
      }, 150)
    }
  }, [showSearch])

  useEffect(() => {
    if (selectedItem !== undefined && !isNewNote) setWhatIsOpen('view')
    if (selectedItem === undefined && !isNewNote) setWhatIsOpen('home')
    if (isNewNote) {
      setSelectedItem(undefined)
      setWhatIsOpen('create')
    }
  }, [selectedItem, setSelectedItem, isNewNote])

  return (
    <StyledModalV2 open={open} close={onClose} title="" size="xl">
      <ContainerAll>
        <ContainerLeft>
          <Header>
            <TitleAndFilter>
              <Typography variant="h5" sx={{ display: 'flex', gap: '8px', justifyContent: 'center' }}>
                Notas
                <FamilyName> {family ? family.nome : 'Planejador'} </FamilyName>
              </Typography>

              <Filters>
                <FilterButton active={showFilter} onClick={handleShowFilter}>
                  <FilterListIcon />
                </FilterButton>
                <FilterButton active={showSearch} onClick={handleShowSearch}>
                  <SearchIcon />
                </FilterButton>
                <IconButton
                  className={favoritesOnly ? 'active' : ''}
                  onClick={() => setFavoritesOnly(!favoritesOnly)}
                >
                  {favoritesOnly ? <StarIcon /> : <StarOutlineIcon />}
                </IconButton>
              </Filters>
            </TitleAndFilter>

            <HeaderFilters>
              {showFilter && (
                <RadioButton
                  name="notesOrder"
                  onChangeState={setLastOrOlder}
                  style={{ padding: 0 }}
                  items={[
                    { id: 'last', text: 'Mais recentes' },
                    { id: 'older', text: 'Mais antigas' }
                  ]}
                />
              )}

              {showSearch && (
                <SearchInput
                  ref={searchInputRef}
                  value={filter}
                  onChange={(e) => setFilter(e.target.value)}
                />
              )}
            </HeaderFilters>
          </Header>
          <ListOfNotes
            isOpen={open}
            onNoteSelection={setSelectedItem}
            selectedNote={selectedItem}
            desc={lastOrOlder === 'last'}
            search={filter}
            favorite={favoritesOnly}
          />
        </ContainerLeft>

        <ContainerRight>
          {whatIsOpen === 'view' && (
            <ViewNote selectedItem={selectedItem} setSelectedItem={setSelectedItem} />
          )}
          {whatIsOpen === 'home' && (
            <ContainerCreateNote>
              <Typography variant="h6">Crie anotações para ajudar a reorganizar suas finanças</Typography>
              <ImgCreateNote src={CreateNoteImg} alt="" />
              <ButtonGradient onClick={() => setIsNewNote(true)}>Adicionar anotação</ButtonGradient>
            </ContainerCreateNote>
          )}
          {whatIsOpen === 'create' && (
            <ContainerNewNote>
              <ViewNote isNewNote={true} setIsNewNote={setIsNewNote} />
            </ContainerNewNote>
          )}
        </ContainerRight>
      </ContainerAll>
    </StyledModalV2>
  )
}
