import { useMemo } from 'react'

import { IFamiliesResponse } from '../../../../domain/lancamento/api/fetchByFamily'
import { useSelectFamily } from '../../../../hooks/useCurrentFamily'
import { FieldSelect } from '../../../Selects/FieldSelect'

interface IFamiliesOptionsValue {
  id: string | number | null
  nome: string
  planejadorId?: number
  planejadorNome?: string
  empresaId?: number
  empresaNome?: string
}
interface IFamiliesOptions {
  value?: IFamiliesOptionsValue | null
  label: string
}

const orderByName = (array?: IFamiliesResponse[]) => {
  return array?.sort((a, b) => {
    const nameA = a?.nome?.toUpperCase() || ''
    const nameB = b?.nome?.toUpperCase() || ''
    if (nameA < nameB) return -1
    if (nameA > nameB) return 1
    return 0
  })
}
export const FamilySelect = ({
  onClose,
  onChange
}: {
  onClose?: () => void
  onChange?: (familyId: string) => void
}) => {
  const { handleFamilyChange, families } = useSelectFamily()

  const orderedFamiliesOptions = useMemo(() => {
    const orderedFamilies = orderByName(families)
    const familiesOptions: IFamiliesOptions[] = [
      {
        value: null,
        label: 'Voltar para o Vista Planejador'
      }
    ]

    orderedFamilies?.forEach(
      (family) =>
        family &&
        familiesOptions?.push({
          value: {
            empresaId: family.empresaId,
            empresaNome: family.empresaNome,
            id: family.id || '',
            nome: family.nome || '',
            planejadorId: family.planejadorId,
            planejadorNome: family.planejadorNome
          },
          label: family.nome || ''
        })
    )

    return familiesOptions
  }, [families])

  return (
    <FieldSelect<IFamiliesOptions>
      id="familia-select"
      placeholder="Selecione..."
      options={orderedFamiliesOptions}
      onChange={({ value, label }) =>
        handleFamilyChange({
          familyId: value?.id ? String(value.id) : '',
          name: label,
          empresaId: value?.empresaId,
          empresaNome: value?.empresaNome,
          planejadorId: value?.planejadorId,
          planejadorNome: value?.planejadorNome,
          callback: () => {
            onChange?.(value?.id ? String(value.id) : '')
            onClose?.()
          }
        })
      }
      size={{ lg: 12, xl: 12 }}
    />
  )
}
