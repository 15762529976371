import styled from 'styled-components'

export const LabelUpload = styled.label<{ disabled: boolean }>`
  align-items: center;
  background: ${({ disabled }) => (disabled ? '#E0E0E0' : '#fff')};
  border-radius: 24px;
  border: solid 1px;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  justify-content: center;
  margin: 0;
  padding: 4px 8px 4px 8px;
  transition: all 0.2s ease-in;
  width: 150px;

  &:hover {
    background: ${({ disabled }) => (disabled ? '#E0E0E0' : '##f0f0f0')};
  }
`

export const FileInput = styled.input`
  display: none;
`

export const AlertContent = styled.div`
  margin-bottom: 1.2rem;

  h4 {
    font-size: 0.9rem;
    margin-bottom: 0.2rem;
  }

  p {
    font-size: 0.9rem;
    line-height: 1.2;
    margin-bottom: 0.2rem;
  }
`

export const NotUploadedList = styled.ul`
  font-size: 0.9rem;
  height: 100%;
  list-style: decimal;
  max-height: 80px;
  overflow-y: scroll;
  padding-left: 20px;
`
