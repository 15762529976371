import React from 'react'
import { Oval } from 'react-loader-spinner'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'

import Colors from '../../domain/Colors'

interface ButtonContainerProps {
  text?: string
  disabled?: boolean
  tooltip?: string
  invertedColors?: boolean
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined
  className?: string
  color?: string
  loading?: boolean
}

const ButtonContainer = styled.button<ButtonContainerProps>`
  display: flex;
  align-items: center;
  gap: 5px;
  color: ${({ invertedColors, theme: { colors } }) => (invertedColors ? colors.primary : `${Colors.white}`)};
  float: right;
  word-wrap: break-word;
  background: ${({ invertedColors, theme: { colors } }) =>
    invertedColors
      ? 'white'
      : `linear-gradient(
    45deg,
    ${colors?.primary} 0%,
    ${colors?.secondary} 100%
  )`};
  background-position: 50%;
  border-radius: 25px;
  border: ${({ invertedColors }) => (invertedColors ? '1px' : '0px')} solid
    ${({
      theme: {
        colors: { secondary }
      }
    }) => secondary};
  padding: 7px 15px;
  cursor: pointer;
  margin-left: 4px;
  margin-right: 4px;
  transition: all 0.2s ease-in-out 0s;

  &:hover {
    color: ${({
      invertedColors,
      theme: {
        colors: { secondary }
      }
    }) => (invertedColors ? secondary : `${Colors.highLightGray}CC`)};
  }

  &:disabled {
    background: linear-gradient(
      45deg,
      ${({ theme: { colors } }) => colors.primary}64 0%,
      ${({ theme: { colors } }) => colors.secondary}64 100%
    );
    color: #ffffff64;
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5;
  }
`

const Text = styled.span``

const ImageLoading = styled.div``

export default ({
  text,
  onClick,
  tooltip,
  invertedColors,
  disabled,
  loading,
  ...rest
}: ButtonContainerProps) => {
  return (
    <ButtonContainer
      {...rest}
      disabled={disabled || loading}
      onClick={disabled ? undefined : onClick}
      invertedColors={invertedColors}
    >
      {tooltip && <ReactTooltip id={`button_${tooltip}`} effect="solid" type="info" />}
      <Text data-tip={tooltip} data-for={`button_${tooltip}`}>
        {text}
      </Text>

      {loading && (
        <ImageLoading>
          <Oval color="white" height={18} width={18} />
        </ImageLoading>
      )}
    </ButtonContainer>
  )
}
