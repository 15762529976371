import React, { useMemo, useState } from 'react'

import { formatMonetaryValue } from '../../../domain/formatter'
import { getLoadingInvestmentsByUserId } from '../../../domain/investments'
import { useNumericLocalStorage } from '../../../hooks'
import { useRequiredAuth } from '../../../hooks/useAuth'
import { useInvestmentsByUserIdQuery } from '../../../queries/investments/queries'
import { usePersonalByUserIdQuery } from '../../../queries/user/queries'
import { noticeError } from '../../../services/Monitoring'
import Button from '../../Buttons'
import { TableFooter } from '../Footer'
import { Text } from '../Footer/Footer.styles'
import Table from '../Table'
import { ContainerTable } from '../Table.styles'
import { TransactionsByInvestmentIdTable } from '../TransactionsByInvestmentIdTable'
import { useColumns } from './columns'
import { ErrorContainer, ExpandedContainer, Footer } from './InvestmentTypesByUserIdTable.styles'
import { useColumnsAcoes } from './useColumnsAcoes'
import { useColumnsFundos } from './useColumnsFundos'
import { useColumnsRendaFixa } from './useColumnsRendaFixa'

interface Sort {
  prop: string
  desc: boolean
  columnLevelOne?: string
  columnLevelTwo?: string
}

interface Props {
  familyId: number
  userId: number
  transactionsStartDate: Date
  transactionsEndDate: Date
  widthBeforeHorizontalScroll?: number
}

const defaultSort: Sort = { desc: true, prop: 'type' }
const loadingData = getLoadingInvestmentsByUserId()

export const InvestmentTypesByUserIdTable = ({
  familyId,
  userId,
  transactionsStartDate,
  transactionsEndDate,
  widthBeforeHorizontalScroll
}: Props) => {
  const token = useRequiredAuth()
  const [page, setPage] = useState(0)

  const { value: pageSize, setValue: setPageSize } = useNumericLocalStorage(
    'investments-by-userid-page-size',
    10
  )

  // const [sort, setSort] = useState<Sort>(defaultSort)
  // const onSort = useCallback(
  //   ({ columnId, order, columnLevelOne, columnLevelTwo }: OnSortProps) => {
  //     if (order === 'none') setSort(defaultSort)
  //     else setSort({ desc: order === 'desc', prop: columnId, columnLevelOne, columnLevelTwo })
  //   },
  //   [setSort]
  // )

  const {
    investmentsByUserId,
    isInvestmentsByUserIdError,
    isLoadingInvestmentsByUserId,
    investmentsByUserIdError,
    refetchGetInvestmentsByUserId
  } = useInvestmentsByUserIdQuery({
    userId,
    desc: defaultSort?.desc,
    orderBy: defaultSort?.prop,
    pageIndex: page,
    pageSize,
    columnLevelOne: defaultSort?.columnLevelOne,
    columnLevelTwo: defaultSort?.columnLevelTwo
  })

  const { personalByUserId } = usePersonalByUserIdQuery({ userId })
  const userFullName = `${personalByUserId?.firstName} ${personalByUserId?.lastName}`
  const totalPages = investmentsByUserId
    ? Math.ceil(investmentsByUserId.groupedInvestments.length / pageSize)
    : 1

  const columns = useColumns()
  const columnsRendaFixa = useColumnsRendaFixa()
  const columnsAcoes = useColumnsAcoes()
  const columnsFundos = useColumnsFundos()

  const investmentsColumnMap = useMemo(
    () => ({
      'Renda Fixa': columnsRendaFixa,
      'Tesouro Direto': columnsRendaFixa,
      Ações: columnsAcoes,
      ETFs: columnsAcoes,
      Fundos: columnsFundos
    }),
    [columnsAcoes, columnsFundos, columnsRendaFixa]
  )

  if (isInvestmentsByUserIdError) {
    noticeError(investmentsByUserIdError, { token })
    return (
      <ErrorContainer>
        <br /> Erro ao carregar tabela :c <br /> Tente novamente: <br />
        <Button text="Tentar novamente." onClick={() => refetchGetInvestmentsByUserId()} />
      </ErrorContainer>
    )
  }

  return (
    <ContainerTable>
      <Table
        columns={columns}
        data={investmentsByUserId?.groupedInvestments || []}
        downloadFileName={`Investimentos-${new Date().toISOString().split('T')[0]}`}
        filterEnabled={false}
        isLoading={isLoadingInvestmentsByUserId}
        loadingData={loadingData}
        onNextPage={() => setPage(page + 1)}
        onPageSizeChange={setPageSize}
        onPreviousPage={() => setPage(page - 1)}
        pageable={true}
        pageIndex={page}
        pageSize={pageSize}
        title={`Investimentos: ${personalByUserId ? userFullName : 'Carregando...'}`}
        totalPages={totalPages}
        renderExpanded={({ type, investments }) => (
          <ExpandedContainer>
            <Table
              title=" "
              data={investments || []}
              columns={investmentsColumnMap[type] || columnsFundos}
              filterEnabled={false}
              toggledOffByDefaultColumns={[
                'quantidadeCota',
                'quantityQuota',
                'precoCota',
                'unitPriceQuota',
                'dateQuota',
                'taxes'
              ]}
              // sortable={true}
              // onSort={(columnId, order) => onSort({ columnId, order, columnLevelTwo: columnId })}
              downloadFileName={`Investimentos-${type}-${new Date().toISOString().split('T')[0]}`}
              renderExpanded={({ id }) => (
                <ExpandedContainer>
                  <TransactionsByInvestmentIdTable
                    familyId={familyId}
                    pluggyInvestmentId={id}
                    start={transactionsStartDate}
                    end={transactionsEndDate}
                    toggledOffByDefaultColumns={[
                      'quantidadeCota',
                      'quantityQuota',
                      'precoCota',
                      'unitPriceQuota',
                      'dateQuota',
                      'taxes'
                    ]}
                  />
                </ExpandedContainer>
              )}
              widthBeforeHorizontalScroll={widthBeforeHorizontalScroll || 1382}
            />
          </ExpandedContainer>
        )}
        renderFooter={() => (
          <TableFooter>
            <Footer>
              <Text />
              <Text />
              <Text>
                Valor total aplicado: <br />
                R$ {formatMonetaryValue(investmentsByUserId?.totalAmountOriginal || 0)}
              </Text>
              <Text>
                Valor total atual: <br />
                R$ {formatMonetaryValue(investmentsByUserId?.totalAmount || 0)}
              </Text>
              <Text />
            </Footer>
          </TableFooter>
        )}
        widthBeforeHorizontalScroll={widthBeforeHorizontalScroll || 1382}
      />
    </ContainerTable>
  )
}
