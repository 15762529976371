import React from 'react'
import styled from 'styled-components'

import downloadIcon from '../../assets/img/theme/download.png'
import Icon from './Icon'

interface Props {
  onClick?: () => void
  tooltip?: string
  classNames?: string
}

const StyledIcon = styled(Icon)`
  max-width: 24px;
  max-height: 24px;
  cursor: pointer;
`

export default ({ onClick, tooltip, classNames }: Props) => (
  <StyledIcon alt="." src={downloadIcon} onClick={onClick} tooltip={tooltip} className={classNames} />
)
