import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import DashboardIcon from '@mui/icons-material/Dashboard'
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined'
import styled, { css } from 'styled-components'

export const PageHeader = styled.header`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;
  margin-bottom: 2rem;
`

export const TitleContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 10px;
  margin-right: 2rem;
`

const IconProps = css`
  margin-bottom: 0;

  svg {
    fill: #a9bad6;
  }
`

export const IconCalendar = styled(CalendarMonthIcon)`
  ${IconProps}
`

export const IconTipsIdea = styled(TipsAndUpdatesOutlinedIcon)`
  ${IconProps}
`

export const IconDashboard = styled(DashboardIcon)`
  ${IconProps}
`
