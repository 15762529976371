import { AxiosError } from 'axios'
import { useCallback, useState } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import { Link, Redirect, useParams } from 'react-router-dom'
import { Card, CardBody, Form, FormGroup, Input, InputGroup, Row } from 'reactstrap'

import Button from '../../../components/Buttons'
import { useTheme } from '../../../hooks'
import { useRecaptcha } from '../../../hooks/useRecaptcha'
import { AuthLayout } from '../../../layouts/AuthV2'
import { apiWebClient } from '../../../webClient'

const input = {
  borderRadius: 25,
  paddingLeft: 20,
  border: 'none',
  backgroundColor: '#f7faff'
}

export const Reset = () => {
  const { handleReCaptchaVerify, token: tokenRecaptcha } = useRecaptcha()
  const { token } = useParams<{ token?: string }>()
  const [redirect, setRedirect] = useState(false)
  const [isSubmitting, setIsSubmiting] = useState(false)
  const { theme } = useTheme()

  const [email, setEmail] = useState<string>()
  const [senha, setSenha] = useState<string>()
  const [novaSenha, setNovaSenha] = useState<string>()

  const resetarSenha = useCallback(() => {
    setIsSubmiting(true)

    const dados = {
      email,
      senha,
      novaSenha,
      token,
      recaptcha: tokenRecaptcha,
      origem: 'W'
    }

    let url = '/auth/resetsenha'

    if (token != null) {
      url = '/auth/executarresetsenha'
    }

    apiWebClient
      .post(url, dados, {
        timeout: 30000
      })
      .then((res) => {
        if (res.data.success === true) {
          let msg = 'Instruções para o procedimento de reset enviadas para o seu e-mail'
          if (token != null) {
            msg = 'Senha alterada com sucesso'
          }
          const options = {
            title: 'Resetar Senha',
            message: msg,
            buttons: [
              {
                label: 'Ok',
                onClick: () => {
                  setRedirect(true)
                }
              }
            ]
          }

          setIsSubmiting(false)
          confirmAlert(options)
        } else {
          console.log(res.data.exception)

          const options = {
            title: 'Falha ao Resetar Senha',
            message: res.data.exception.Message,
            buttons: [
              {
                label: 'Ok'
              }
            ]
          }

          setIsSubmiting(false)
          // @ts-expect-error expected-error
          confirmAlert(options)
        }
      })
      .catch((error: AxiosError) => {
        const { response } = error || {}
        console.log('/auth/resetsenha', { error })
        const invalidCaptcha = response?.data?.type === 'captcha_invalid_reset_senha'
        let message = 'Ocorreu um erro inesperado'

        if (response?.data?.message) {
          message = response?.data?.message
        }
        if (invalidCaptcha) {
          message = 'Ocorreu um erro, por favor tente novamente. (captcha_invalid)'
        }

        confirmAlert({
          title: 'Falha ao Resetar Senha',
          message,
          buttons: [
            {
              label: 'Ok',
              onClick: () => {
                setIsSubmiting(false)
                if (invalidCaptcha) {
                  window.location.reload()
                }
              }
            }
          ]
        })
      })
  }, [email, novaSenha, senha, token, tokenRecaptcha])

  const isValid = () => {
    if (token != null) {
      return email !== '' && senha !== '' && novaSenha !== ''
    }

    return email && email !== '' && email?.length >= 6
  }

  if (redirect) {
    return <Redirect to="/auth/login" />
  }

  return (
    <AuthLayout>
      <Row className="mt-3">
        <Link to="/auth/login" className="alert-link mb-3" style={{ color: theme.colors.primary }}>
          Voltar para a página de login
        </Link>
      </Row>

      <Card
        className="bg-secondary shadow border-0"
        style={{ width: '90%', borderRadius: 15, maxWidth: 500 }}
      >
        <CardBody className="px-lg-5 py-lg-5" style={{ backgroundColor: '#FFF', borderRadius: 15 }}>
          <div className="text-center text-muted mb-4">
            <h2>Por favor, informe seu e-mail</h2>
          </div>

          <Form role="form">
            <FormGroup className="mb-3">
              <InputGroup>
                <Input
                  placeholder="E-mail"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  style={input}
                />
              </InputGroup>
            </FormGroup>

            {!!token && (
              <>
                <FormGroup>
                  <InputGroup>
                    <Input
                      placeholder="Senha"
                      type="password"
                      value={senha}
                      onChange={(e) => setSenha(e.target.value)}
                      style={input}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup>
                    <Input
                      placeholder="Confirmação de Senha"
                      type="password"
                      value={novaSenha}
                      onChange={(e) => setNovaSenha(e.target.value)}
                      style={input}
                    />
                  </InputGroup>
                </FormGroup>
              </>
            )}

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%'
              }}
            >
              <Button
                className="my-4 featured-button"
                color="primary"
                disabled={isSubmitting || !isValid()}
                onClick={(event) => {
                  event?.preventDefault()
                  handleReCaptchaVerify()
                  resetarSenha()
                }}
                text={isSubmitting ? 'Enviando...' : 'Resetar Senha'}
              />
            </div>
          </Form>
        </CardBody>
      </Card>
    </AuthLayout>
  )
}
