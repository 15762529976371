import React, { useMemo, useState } from 'react'

import { useSelectComboContext } from '../../../hooks/contexts/selectComboContext'
import { FieldSelect } from '../FieldSelect'
import { Props } from './types'

export const FieldSelectPaymentMethods = ({
  selectedId,
  label = 'Forma de Pagamento *',
  id = 'lancamentoMeioPagamento',
  defaultPlaceholder,
  defaultValue,
  onSelect
}: Props) => {
  const [filter, setFilter] = useState('')
  const { MEIOS_PAGAMENTOS_OPTIONS: options } = useSelectComboContext()

  const selected = useMemo(
    () => (defaultValue ? options?.find(({ value }) => value.id === defaultValue) : undefined),
    [defaultValue, options]
  )

  const placeholder =
    (selectedId && options?.find(({ value }) => value.id === selectedId)?.value.nome) ||
    defaultPlaceholder ||
    'Selecione...'

  return (
    <FieldSelect
      filter={filter}
      onFilter={setFilter}
      label={label}
      id={id}
      options={options?.sort((a, b) => a.label.localeCompare(b.label)) ?? []}
      placeholder={placeholder}
      onChange={({ value }) => {
        // @ts-expect-error expected-error
        onSelect?.({ value })
      }}
      // @ts-expect-error expected-error
      selectedValue={selected?.value}
    />
  )
}
