import styled from 'styled-components'

import colors from '../../../../../domain/Colors'
import { Loading } from '../../../../Icons'

export const Figure = styled.figure`
  align-items: center;
  background: ${colors.grayBlue};
  border-radius: 0.8rem;
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100%;
  max-width: 184px;
`

export const ImageContainer = styled.div`
  align-items: center;
  border: 1px solid ${colors.grayBlue};
  display: flex;
  height: 100px;
  height: 80px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 100%;
`

export const Loader = styled.span<{ isVisible: boolean }>`
  align-items: center;
  background: ${colors.white};
  border-radius: 0.8rem 0.8rem 0 0;
  display: flex;
  height: 100%;
  justify-content: center;
  overflow: hidden;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  position: absolute;
  width: 100%;
  z-index: 1;
`

export const LoaderIcon = styled(Loading)`
  height: 60px;
  width: 60px;
  fill: ${colors.gray};
`

export const FigureCaption = styled.figcaption`
  font-family: 'Open Sans';
  font-size: 10px;
  font-weight: 600;
  line-height: 1.5;
  padding: 0 0.8rem 0.1rem;
  width: 100%;
`

export const AdditionalInfo = styled.div`
  padding: 0 0.8rem 0.5rem;
  width: 100%;
`

export const AdditionalInfoDetail = styled.p`
  margin: 0.2rem 0;
  font-size: 10px;
`

export const Image = styled.img`
  max-width: 100%;
  width: 100%;
`

export const DreamCardList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  box-shadow: 0px 0px 32px 0px #8898aa26;
  border-radius: 0.8rem;
  padding: 0.8rem;
`
