import React from 'react'

import { AlertModal } from '../../Modal'
import { ConfirmCategorizationProps } from './types'

export const ConfirmCategorization = ({
  handleCloseFirstCheck,
  handleCloseDisable,
  confirmFirstCheck,
  setConfirmFirstCheck,
  confirmDisable,
  setConfirmDisable,
  toggleFamilyAutoCategorization,
  familyId
}: ConfirmCategorizationProps) => {
  if (confirmFirstCheck) {
    return (
      <AlertModal
        open
        title="Categorização automática"
        onClose={handleCloseFirstCheck}
        primaryButton={{
          text: 'Sim',
          onClick: () => {
            toggleFamilyAutoCategorization({ enabled: true, familyId })
            setConfirmFirstCheck(false)
          }
        }}
        secondaryButton={{
          text: 'Não',
          onClick: handleCloseFirstCheck
        }}
      >
        <p>
          Deseja ativar a categorização automática dos lançamentos? Você poderá ativar ou desativar novamente
          no botão ON/OFF.
          <br />
          Deseja continuar?
        </p>
      </AlertModal>
    )
  }
  if (confirmDisable) {
    return (
      <AlertModal
        open
        title="Categorização automática"
        onClose={handleCloseDisable}
        primaryButton={{
          text: 'Sim',
          onClick: () => {
            toggleFamilyAutoCategorization({ enabled: false, familyId })
            setConfirmDisable(false)
          }
        }}
        secondaryButton={{
          text: 'Não',
          onClick: handleCloseDisable
        }}
      >
        <p>
          A partir de agora os seus lançamentos não serão mais categorizados automaticamente.
          <br />
          Deseja continuar?
        </p>
      </AlertModal>
    )
  }
  return <></>
}
