import React, { useCallback, useEffect } from 'react'
import { Redirect } from 'react-router-dom'

import { Chip } from '../../../components/Chip'
import { CompaniesTable, LonelyPlannersTable } from '../../../components/Tables'
import { formatMonetaryValue } from '../../../domain/formatter'
import { useRequiredAuth } from '../../../hooks/useAuth'
import { useFamilyBillingSummary } from '../../../queries/company/queries'
import { loginPath } from '../../../routes'

export const SupportView = ({ filterDate }) => {
  const token = useRequiredAuth()

  const { familyBillingSummary, refetchGetFamilyBillingSummary } = useFamilyBillingSummary({ filterDate })

  const refetchFamilyBillingSummary = useCallback(() => {
    refetchGetFamilyBillingSummary?.()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterDate, refetchGetFamilyBillingSummary])

  useEffect(() => {
    refetchFamilyBillingSummary()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterDate])

  if (!token?.isSupport) return <Redirect to={loginPath} />

  return (
    <>
      <div style={{ display: 'flex', columnGap: 20, marginBottom: 20 }}>
        <Chip title={familyBillingSummary?.quantityOfFamilies || 0} subTitle="FAMÍLIAS" />
        <Chip
          title={`R$ ${formatMonetaryValue(familyBillingSummary?.totalInvoice || 0)}`}
          subTitle="FATURA"
        />
      </div>
      <CompaniesTable marginBottom={20} filterDate={filterDate} />
      <LonelyPlannersTable filterDate={filterDate} />
    </>
  )
}
