import React, { useState } from 'react'

import { useBankAndTypes } from '../../../queries/extrato/queries'
import { SelectAutoComplete } from '../../Mui/SelectAutoComplete'

export type OnChangeBankParam = {
  value?: string
  label: string
}

interface Props {
  onChange?: (banco: OnChangeBankParam) => void
  className?: string
  disabled?: boolean
}

export const BancsSelect = ({ onChange, disabled }: Props) => {
  const { banksAndTypes } = useBankAndTypes()
  const [value, setValue] = useState<OnChangeBankParam>()
  const banks =
    banksAndTypes?.map(({ bank, label, types }) => ({
      value: bank,
      label: label.replace('- undefined', ' '),
      types
    })) || []

  const defaultValueVistaPattern = banks.find((bank) => bank.label === 'Excel Padrão Vista')?.value

  return (
    <SelectAutoComplete<OnChangeBankParam>
      label="Instiuição Financeira"
      placeholder={'Conta Corrente / Cartão de Crédito'}
      options={banks}
      defaultValue={defaultValueVistaPattern as OnChangeBankParam['value']}
      value={value?.value}
      disabled={disabled}
      onChange={(selectedValue) => {
        if (selectedValue) {
          onChange?.(selectedValue)
          setValue(selectedValue)
        }
      }}
    />
  )
}
