import { useCallback } from 'react'
import { useMutation, useQueryClient } from 'react-query'

import { Result, sendExtratoApi, SendToExtratoBody } from '../../domain/extrato/api/sendExtrato'
import {
  postSendVistaExtract,
  SendToVistaExtractBody,
  SendVistaExtractResponse
} from '../../domain/extrato/api/sendVistaExtract'
import { asyncDelay } from '../../domain/timing'
import { cacheBaseKey as investmentsCacheKey } from '../investments/queries'
import { cacheBaseKey as lancamentoCacheKey } from '../lancamento/queries'

export const useExtratoUpload = () => {
  const client = useQueryClient()

  const {
    duplicated: extratosDuplicated,
    isExtratoError,
    isSending,
    resetExtratos,
    sendToExtrato,
    sendToExtratoError,
    sentExtratos
  } = useSendToExtratos()

  const {
    duplicated: vistaExtractDuplicated,
    isVistaExtractError,
    isSendingVistaExtract,
    resetVistaExtracts,
    sendToVistaExtract,
    sendToVistaExtractError,
    sentVistaExtract,
    withoutCategory
  } = useSendToVistaExtract()

  const duplicated = extratosDuplicated.concat(vistaExtractDuplicated)
  const isExtratoUploadError = isExtratoError || isVistaExtractError
  const isUploading = isSending || isSendingVistaExtract
  const uploaded = sentExtratos || sentVistaExtract
  const uploadError = sendToExtratoError ?? sendToVistaExtractError

  const resetUpload = useCallback(() => {
    resetExtratos()
    resetVistaExtracts()
    client.invalidateQueries(investmentsCacheKey)
  }, [client, resetExtratos, resetVistaExtracts])

  return {
    sendToExtrato,
    sendToVistaExtract,
    isExtratoUploadError,
    uploadError,
    isUploading,
    uploaded,
    resetUpload,
    withoutCategory,
    duplicated
  }
}

export const useSendToExtratos = () => {
  const client = useQueryClient()
  const {
    mutate: sendToExtrato,
    data: sendExtratoResponse,
    isLoading: isSending,
    isError: isExtratoError,
    error: sendToExtratoError,
    isSuccess: sentExtratos,
    reset: resetExtratos
  } = useMutation<Result, Error, SendToExtratoBody>(sendExtratoApi, {
    retry: false,
    onSuccess: () => {
      asyncDelay(400).then(() => {
        client.invalidateQueries([...investmentsCacheKey, lancamentoCacheKey])
      })
    }
  })

  const duplicated = sendExtratoResponse?.exception ?? []

  return {
    sendToExtrato,
    isSending,
    isExtratoError,
    sendToExtratoError,
    sentExtratos,
    resetExtratos,
    duplicated
  }
}

export const useSendToVistaExtract = () => {
  const client = useQueryClient()
  const {
    data: sendExtractResponse,
    error: sendToVistaExtractError,
    isError: isVistaExtractError,
    isLoading: isSendingVistaExtract,
    isSuccess: sentVistaExtract,
    mutate: sendToVistaExtract,
    reset: resetVistaExtracts
  } = useMutation<SendVistaExtractResponse, Error, SendToVistaExtractBody>(postSendVistaExtract, {
    retry: false,
    onSuccess: () => {
      asyncDelay(400).then(() => {
        client.invalidateQueries([...investmentsCacheKey, lancamentoCacheKey])
      })
    }
  })

  const duplicated = sendExtractResponse?.duplicated ?? []
  const withoutCategory = sendExtractResponse?.withoutCategory ?? []

  return {
    sendToVistaExtract,
    isSendingVistaExtract,
    isVistaExtractError,
    sendToVistaExtractError,
    sentVistaExtract,
    resetVistaExtracts,
    duplicated,
    withoutCategory
  }
}
