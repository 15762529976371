import { noticeError } from '../../../services/Monitoring'
import { investmentsClient as client } from '../../../webClient'
import { FixedAsset, FixedAssetCreationRequest } from '../fixedAsset'

export const createFixedAsset = async (fixedAsset: FixedAssetCreationRequest) => {
  const { data: asset, status } = await client.post<FixedAsset>('/fixedAsset', fixedAsset)
  if (status !== 201) {
    const error = new Error(`Error creating asset ${JSON.stringify(fixedAsset)}. Status: ${status}`)
    noticeError(error)
    throw error
  }
  return asset
}
