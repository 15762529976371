import React, { useEffect } from 'react'

import { AlertModal } from '../../../components/Modal'

export const SuccessTransaction = ({ onClose, editingTransaction, open }) => {
  const title = editingTransaction ? 'Edição de Lançamento' : 'Cadastro de Lançamento'

  useEffect(() => {
    let timer: NodeJS.Timeout
    if (open) timer = setTimeout(onClose, 2000)
    return () => clearTimeout(timer)
  }, [onClose, open])

  if (!open) return null

  return (
    <AlertModal mode="toasty" toastyType="success" open={open} onClose={onClose} title={title}>
      <h4>Lançamento salvo com sucesso!</h4>
    </AlertModal>
  )
}
