import { useMutation, useQuery } from 'react-query'

import {
  getDiagnosticsByFamilyIdApi,
  getDiagnosticsByIdApi,
  getDiagnosticSummaryApi,
  IDiagnosticSummaryResponse,
  postCreateDiagnosticApi
} from '../../domain/diagnostic/api'
import { IPostCreateDiagnostic, IPostCreateDiagnosticResponse } from '../../domain/diagnostic/types'

/**
 * Use this hook to get the diagnostic summary.
 * @returns {Object} An object with the following properties:
 * - `xRaySummary`: The diagnostic summary data.
 * - `isDiagnosticSummaryError`: A boolean indicating if there was an error fetching the data.
 * - `isLoadingDiagnosticSummary`: A boolean indicating if the data is being fetched.
 * - `xRaySummaryError`: An error object if there was an error fetching the data.
 * - `refetchDiagnosticSummary`: A function to refetch the data.
 * - `isFetchedDiagnosticSummary`: A boolean indicating if the data has been fetched.
 */
export const useGetDiagnosticSummary = () => {
  const {
    isError: isDiagnosticSummaryError,
    isLoading: isLoadingDiagnosticSummary,
    data: xRaySummary,
    error: xRaySummaryError,
    refetch: refetchDiagnosticSummary,
    isFetched: isFetchedDiagnosticSummary
  } = useQuery<unknown, Error, IDiagnosticSummaryResponse[]>(
    'getDiagnosticSummary',
    () => getDiagnosticSummaryApi(),
    {
      retry: 2,
      retryDelay: 3000
    }
  )

  return {
    xRaySummary,
    isDiagnosticSummaryError,
    isLoadingDiagnosticSummary,
    xRaySummaryError,
    refetchDiagnosticSummary,
    isFetchedDiagnosticSummary
  }
}

export const usePostCreateDiagnostic = () => {
  const {
    mutate: postCreateDiagnostic,
    mutateAsync: postCreateDiagnosticAsync,
    isLoading: isLoadingPostCreateDiagnostic,
    isError: isErrorPostCreateDiagnostic,
    error: errorPostCreateDiagnostic,
    reset: resetPostCreateDiagnostic,
    isSuccess: postCreateDiagnosticSuccess,
    data: postCreateDiagnosticData
  } = useMutation<IPostCreateDiagnosticResponse, Error, IPostCreateDiagnostic>(postCreateDiagnosticApi)

  return {
    postCreateDiagnostic,
    postCreateDiagnosticAsync,
    postCreateDiagnosticSuccess,
    isLoadingPostCreateDiagnostic,
    isErrorPostCreateDiagnostic,
    errorPostCreateDiagnostic,
    resetPostCreateDiagnostic,
    postCreateDiagnosticData: postCreateDiagnosticData?.data?.id
  }
}

export const useGetDiagnosticsByFamilyId = () => {
  const {
    mutate: getDiagnosticsByFamilyId,
    mutateAsync: getDiagnosticsByFamilyIdAsync,
    isLoading: isLoadingGetDiagnosticsByFamilyId,
    isError: isErrorGetDiagnosticsByFamilyId,
    error: errorGetDiagnosticsByFamilyId,
    reset: resetGetDiagnosticsByFamilyId,
    isSuccess: getDiagnosticsByFamilyIdSuccess,
    data: getDiagnosticsByFamilyIdData
  } = useMutation(getDiagnosticsByFamilyIdApi)

  return {
    getDiagnosticsByFamilyId,
    getDiagnosticsByFamilyIdAsync,
    getDiagnosticsByFamilyIdSuccess,
    isLoadingGetDiagnosticsByFamilyId,
    isErrorGetDiagnosticsByFamilyId,
    errorGetDiagnosticsByFamilyId,
    resetGetDiagnosticsByFamilyId,
    getDiagnosticsByFamilyIdData
  }
}

export const useGetDiagnosticById = () => {
  const {
    mutate: getDiagnosticById,
    mutateAsync: getDiagnosticByIdAsync,
    isLoading: isLoadingGetDiagnosticById,
    isError: isErrorGetDiagnosticById,
    error: errorGetDiagnosticById,
    reset: resetGetDiagnosticById,
    isSuccess: getDiagnosticByIdSuccess,
    data: getDiagnosticByIdData
  } = useMutation(getDiagnosticsByIdApi)

  return {
    getDiagnosticById,
    getDiagnosticByIdAsync,
    getDiagnosticByIdSuccess,
    isLoadingGetDiagnosticById,
    isErrorGetDiagnosticById,
    errorGetDiagnosticById,
    resetGetDiagnosticById,
    getDiagnosticByIdData
  }
}
