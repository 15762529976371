import '../../assets/css/always-visible.css'

import MenuIcon from '@mui/icons-material/Menu'
import MenuOpenIcon from '@mui/icons-material/MenuOpen'
import React, { useState } from 'react'

import { useTheme } from '../../hooks'
import { useCoreContext } from '../../hooks/contexts/coreContext/coreProvider'
import Logo from '../../theme/logo'
import { ModalClientSelect } from './components/ModalClientSelect'
import * as S from './styles'

export const SidebarV2 = ({ id, position, logo, routes }) => {
  const { tipoUsuario: userType, navBarCollapsed, setNavBarCollapsed } = useCoreContext()
  const { theme } = useTheme()
  const [collapseOpen, setCollapseOpen] = useState(false)
  const [modalSelecaoCliente, setModalSelecaoCliente] = useState(false)
  const tipoUsuario = Number(userType)

  const toggleCollapse = () => setCollapseOpen((value) => !value)

  return (
    <>
      <ModalClientSelect isOpen={modalSelecaoCliente} onClose={() => setModalSelecaoCliente(false)} />
      <S.Nav
        className={`navbar-vertical ${position} navbar-light`}
        expand={navBarCollapsed ? 'sm' : 'md'}
        id={id}
        style={{
          overflowX: 'hidden',
          background:
            tipoUsuario <= 1
              ? theme.colors?.backgroundSidebarManager || theme.colors.secondary
              : theme.colors.primary
        }}
      >
        <S.Container>
          {/* Mobile toggle menu */}
          <button className="navbar-toggler" type="button" onClick={toggleCollapse}>
            <span className="navbar-toggler-icon" />
          </button>

          {!!logo && (
            <S.Logo
              $width={
                theme?.sizes?.web?.x !== undefined && theme?.sizes?.web?.x !== ''
                  ? theme?.sizes?.web?.x
                  : '100%'
              }
            >
              <Logo />
            </S.Logo>
          )}

          {!navBarCollapsed && tipoUsuario !== 2 && <S.UserTypeBanner />}

          <S.ClientSelect size={navBarCollapsed ? 'sm' : 'md'} onClick={() => setModalSelecaoCliente(true)} />

          {/* Navigation links */}
          <S.NavigationsLinks collapseOpen={collapseOpen} toggleCollapse={toggleCollapse} routes={routes} />
        </S.Container>

        <S.Version expanded={!navBarCollapsed}>
          <span>Versão {global.version}</span>

          <S.IconCollapse onClick={() => setNavBarCollapsed((value) => !value)} size="small">
            {navBarCollapsed ? <MenuIcon /> : <MenuOpenIcon />}
          </S.IconCollapse>
        </S.Version>
      </S.Nav>
    </>
  )
}
