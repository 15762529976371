/* eslint-disable @typescript-eslint/no-explicit-any */
import { frequenciaCategoria, grupoDeCategoria, tipoOrcamento } from '../../../globalValues'

export const getOptions = () => {
  const grupos: { value: number | null; label: string }[] = [{ value: null, label: 'Selecione...' }]
  const frequencias: { value: number | null; label: string }[] = [{ value: null, label: 'Selecione...' }]
  const tiposOrcamento: { value: number | null; label: string }[] = [{ value: null, label: 'Selecione...' }]

  grupoDeCategoria?.forEach(function (value, index) {
    grupos.push({
      value: index,
      label: value
    })
  })

  frequenciaCategoria?.forEach(function (value, index) {
    frequencias.push({
      value: index,
      label: value
    })
  })

  tipoOrcamento?.forEach(function (value, index) {
    tiposOrcamento.push({
      value: index,
      label: value
    })
  })

  const tiposOrcamentoMenorQueDois = tiposOrcamento?.filter(function (item) {
    // @ts-ignore
    return item.value < 2
  })

  return {
    grupos,
    frequencias,
    tiposOrcamento,
    tiposOrcamentoMenorQueDois
  }
}

export const mapearGrupoPeloId = (id) => {
  if (id == null) return ''

  const { grupos } = getOptions()
  const grupo = grupos.filter(function (item) {
    return (item.value != null ? item.value?.toString() : null) === (id != null ? id.toString() : null)
  })

  return grupo != null && grupo.length > 0 ? grupo[0].label : ''
}

/**
 * Returns the index of an item in the given array that has the same `OrcamentoId` as the given `orcamentoId`.
 * If no item is found, returns `null`.
 * @param {Array<{OrcamentoId:number}>} array The array to search in.
 * @param {number} orcamentoId The `OrcamentoId` to search for.
 * @returns {number|null}
 */
export const buscaOrcamentoNaLista = (array, orcamentoId) => {
  let indexFiltrado = null

  array?.filter((arrItem, index) => {
    if (arrItem.OrcamentoId === orcamentoId) {
      indexFiltrado = index
      return true
    }
    return false
  })

  return indexFiltrado
}

export const buscaGrupoNaLista = (array, grupo) => {
  let indexFiltrado = null

  array.filter(function (arrItem, index) {
    if (arrItem.GrupoId === grupo) {
      indexFiltrado = index
      return true
    }
    return false
  })

  return indexFiltrado
}

export const mapearFrequenciaPeloId = (id) => {
  if (id == null) return ''

  const { frequencias } = getOptions()
  const frequencia = frequencias.filter(function (item) {
    return (item.value != null ? item.value.toString() : null) === (id != null ? id.toString() : null)
  })

  return frequencia != null && frequencia.length > 0 ? frequencia[0].label : ''
}

export const parseDataOrcamentoPeriodo = (data) => {
  const listaDeOrcamentos: any = []
  const listaDeCategorias: any = {}
  const listaDeGrupos: any = []
  const listaDeCategoriasNoGrupo: any = {}

  const listaDeOrcamentosComSuspensos: any = []
  const listaDeCategoriasComSuspensos: any = {}
  const listaDeGruposComSuspensos: any = []
  const listaDeCategoriasNoGrupoComSuspensos: any = {}

  const listaDeCategoriasSemSuspensos: any = []
  const listaDeCategoriasOriginal: any = []

  let totalDespesaEstimado: any = 0
  let totalDespesaRealizado: any = 0
  let totalDespesaEstimadoComSuspensos: any = 0
  let totalDespesaRealizadoComSuspensos: any = 0

  let totalReceitaEstimado: any = 0
  let totalReceitaRealizado: any = 0
  let totalReceitaEstimadoComSuspensos: any = 0
  let totalReceitaRealizadoComSuspensos: any = 0

  let totalInvestimentosEstimado: any = 0
  let totalInvestimentosRealizado: any = 0
  let totalInvestimentosEstimadoComSuspensos: any = 0
  let totalInvestimentosRealizadoComSuspensos: any = 0

  let totalDividasEstimado: any = 0
  let totalDividasRealizado: any = 0
  let totalDividasEstimadoComSuspensos: any = 0
  let totalDividasRealizadoComSuspensos: any = 0

  let mediaAnterior: any = 0

  if (data != null) {
    data.forEach(function (item) {
      const orcamentoId = item.orcamentoId
      const categoriaId = item.categoriaId
      const orcamentoSuspenso = item.orcamentoSuspenso === 1
      const categoriaSuspenso = item.categoriaSuspenso === 1

      item.estimadoAnual = item.frequencia === 1 ? item.estimado * 12 : item.estimado

      if (item.tipoOrcamento === 0) {
        totalReceitaEstimadoComSuspensos += item.estimado
        totalReceitaRealizadoComSuspensos += item.totalMedio
      }

      if (item.tipoOrcamento === 1) {
        totalDespesaEstimadoComSuspensos += item.estimado
        totalDespesaRealizadoComSuspensos += item.totalMedio
      }

      if (item.tipoOrcamento === 2) {
        totalInvestimentosEstimadoComSuspensos += item.estimado
        totalInvestimentosRealizadoComSuspensos += item.totalMedio
      }

      if (item.tipoOrcamento === 3) {
        totalDividasEstimadoComSuspensos += item.estimado
        totalDividasRealizadoComSuspensos += item.totalMedio
      }

      if (!orcamentoSuspenso) {
        if (!categoriaSuspenso) {
          if (item.tipoOrcamento === 0) {
            totalReceitaEstimado += item.estimado
            totalReceitaRealizado += item.totalMedio
          }

          if (item.tipoOrcamento === 1) {
            totalDespesaEstimado += item.estimado
            totalDespesaRealizado += item.totalMedio
          }

          if (item.tipoOrcamento === 2) {
            totalInvestimentosEstimado += item.estimado
            totalInvestimentosRealizado += item.totalMedio
          }

          if (item.tipoOrcamento === 3) {
            totalDividasEstimado += item.estimado
            totalDividasRealizado += item.totalMedio
          }
        }
      }

      let indexOrcamento = null

      //INICIO - Orçamento sem suspenso
      if (!orcamentoSuspenso) {
        indexOrcamento = buscaOrcamentoNaLista(listaDeOrcamentos, orcamentoId)

        //Se orçamento nao suspenso
        if (indexOrcamento === null) {
          listaDeOrcamentos.push({
            OrcamentoId: item.orcamentoId,
            CategoriaId: item.categoriaId,
            NomeOrcamento: item.nomeOrcamento,
            Estimado: !categoriaSuspenso && item.estimado != null && item.estimado !== '' ? item.estimado : 0,
            TotalMedio:
              !categoriaSuspenso && item.totalMedio != null && item.totalMedio !== '' ? item.totalMedio : 0,
            Suspenso: item.orcamentoSuspenso != null ? item.orcamentoSuspenso : 0,
            TipoOrcamento: item.tipoOrcamento,
            Total: !categoriaSuspenso && item.total != null ? item.total : 0,
            Media: !categoriaSuspenso && item.media != null ? item.media : 0,
            Minimo: !categoriaSuspenso && item.minimo != null ? item.minimo : 0,
            Maximo: !categoriaSuspenso && item.maximo != null ? item.maximo : 0,
            Quantidade: !categoriaSuspenso && item.quantidade != null ? item.quantidade : 0,
            PercentualDespesas: 0
          })

          listaDeCategorias[orcamentoId] = []
        }
        //Se não, se categoria nao suspenso, acrescental no estimado e realizado
        else if (!categoriaSuspenso) {
          const shouldInvertValue = item.nomeCategoria === 'Resgates'

          listaDeOrcamentos[indexOrcamento].Estimado +=
            (item.estimado != null && item.estimado !== '' ? item.estimado : 0) * (shouldInvertValue ? -1 : 1)

          if (item.totalMedio !== null && item.totalMedio !== '' && item.totalMedio !== 0) {
            listaDeOrcamentos[indexOrcamento].TotalMedio += item.totalMedio * (shouldInvertValue ? -1 : 1)
          }

          listaDeOrcamentos[indexOrcamento].Total +=
            (item.total != null ? item.total : 0) * (shouldInvertValue ? -1 : 1)
          listaDeOrcamentos[indexOrcamento].Quantidade +=
            (item.quantidade != null ? item.quantidade : 0) * (shouldInvertValue ? -1 : 1)

          if (item.minimo != null && item.minimo < listaDeOrcamentos[indexOrcamento].Minimo) {
            listaDeOrcamentos[indexOrcamento].Minimo = item.minimo
          }

          if (item.maximo != null && item.maximo > listaDeOrcamentos[indexOrcamento].Maximo) {
            listaDeOrcamentos[indexOrcamento].Maximo = item.maximo
          }

          if (item.media != null) {
            mediaAnterior = listaDeOrcamentos[indexOrcamento].Media
            listaDeOrcamentos[indexOrcamento].Media = (mediaAnterior + item.media) / 2
          }
        }
      }
      //FIM - Orçamento sem suspenso

      //INICIO - Orçamento com suspenso
      indexOrcamento = buscaOrcamentoNaLista(listaDeOrcamentosComSuspensos, orcamentoId)

      if (indexOrcamento == null) {
        listaDeOrcamentosComSuspensos.push({
          OrcamentoId: item.orcamentoId,
          CategoriaId: item.categoriaId,
          NomeOrcamento: item.nomeOrcamento,
          Estimado: item.estimado != null && item.estimado !== '' ? item.estimado : 0,
          TotalMedio: item.totalMedio != null && item.totalMedio !== '' ? item.totalMedio : 0,
          Suspenso: item.orcamentoSuspenso != null ? item.orcamentoSuspenso : 0,
          TipoOrcamento: item.tipoOrcamento,
          Total: item.total != null ? item.total : 0,
          Media: item.media != null ? item.media : 0,
          Minimo: item.minimo != null ? item.minimo : 0,
          Maximo: item.maximo != null ? item.maximo : 0,
          Quantidade: item.quantidade != null ? item.quantidade : 0,
          PercentualDespesas: 0
        })

        listaDeCategoriasComSuspensos[orcamentoId] = []
      } else {
        listaDeOrcamentosComSuspensos[indexOrcamento].Estimado +=
          item.estimado != null && item.estimado !== '' ? item.estimado : 0
        listaDeOrcamentosComSuspensos[indexOrcamento].TotalMedio +=
          item.totalMedio != null && item.totalMedio !== '' ? item.totalMedio : 0

        listaDeOrcamentosComSuspensos[indexOrcamento].Total += item.total != null ? item.total : 0
        listaDeOrcamentosComSuspensos[indexOrcamento].Quantidade +=
          item.quantidade != null ? item.quantidade : 0

        if (item.minimo != null && item.minimo < listaDeOrcamentosComSuspensos[indexOrcamento].Minimo) {
          listaDeOrcamentosComSuspensos[indexOrcamento].Minimo = item.minimo
        }

        if (item.maximo != null && item.maximo > listaDeOrcamentosComSuspensos[indexOrcamento].Maximo) {
          listaDeOrcamentosComSuspensos[indexOrcamento].Maximo = item.maximo
        }

        if (item.media != null) {
          mediaAnterior = listaDeOrcamentosComSuspensos[indexOrcamento].Media
          listaDeOrcamentosComSuspensos[indexOrcamento].Media = (mediaAnterior + item.media) / 2
        }
      }
      //FIM - Orçamento com suspenso

      //INICIO - Grupo sem suspenso
      if (!orcamentoSuspenso) {
        indexOrcamento = buscaGrupoNaLista(listaDeGrupos, item.grupo)

        //Se orçamento nao suspenso
        if (indexOrcamento == null) {
          listaDeGrupos.push({
            OrcamentoId: item.orcamentoId,
            CategoriaId: item.categoriaId,
            GrupoId: item.grupo,
            Grupo: mapearGrupoPeloId(item.grupo),
            Estimado: item.estimado != null && item.estimado !== '' ? item.estimado : 0,
            TotalMedio: item.totalMedio != null && item.totalMedio !== '' ? item.totalMedio : 0,
            Suspenso: 0,
            TipoOrcamento: item.tipoOrcamento,
            Total: item.total != null ? item.total : 0,
            Media: item.media != null ? item.media : 0,
            Minimo: item.minimo != null ? item.minimo : 0,
            Maximo: item.maximo != null ? item.maximo : 0,
            Quantidade: item.quantidade != null ? item.quantidade : 0,
            PercentualDespesas: 0
          })

          listaDeCategoriasNoGrupo[item.grupo] = []
        }
        //Se não, se categoria nao suspenso, acrescental no estimado e realizado
        else if (!categoriaSuspenso) {
          const shouldInvertValue = item.nomeCategoria === 'Resgates'

          listaDeGrupos[indexOrcamento].Estimado +=
            (item.estimado != null && item.estimado !== '' ? item.estimado : 0) * (shouldInvertValue ? -1 : 1)
          listaDeGrupos[indexOrcamento].TotalMedio +=
            (item.totalMedio != null && item.totalMedio !== '' ? item.totalMedio : 0) *
            (shouldInvertValue ? -1 : 1)

          listaDeGrupos[indexOrcamento].Total +=
            (item.total != null ? item.total : 0) * (shouldInvertValue ? -1 : 1)
          listaDeGrupos[indexOrcamento].Quantidade +=
            (item.quantidade != null ? item.quantidade : 0) * (shouldInvertValue ? -1 : 1)

          if (item.minimo != null && item.minimo < listaDeGrupos[indexOrcamento].Minimo) {
            listaDeGrupos[indexOrcamento].Minimo = item.minimo
          }

          if (item.maximo != null && item.maximo > listaDeGrupos[indexOrcamento].Maximo) {
            listaDeGrupos[indexOrcamento].Maximo = item.maximo
          }

          if (item.media != null) {
            mediaAnterior = listaDeGrupos[indexOrcamento].Media
            listaDeGrupos[indexOrcamento].Media = (mediaAnterior + item.media) / 2
          }
        }
      }
      //FIM - Grupo sem suspenso

      //INICIO - Grupo com suspenso
      indexOrcamento = buscaGrupoNaLista(listaDeGruposComSuspensos, item.grupo)

      if (indexOrcamento == null) {
        listaDeGruposComSuspensos.push({
          OrcamentoId: item.orcamentoId,
          CategoriaId: item.categoriaId,
          GrupoId: item.grupo,
          Grupo: mapearGrupoPeloId(item.grupo),
          Estimado: item.estimado != null && item.estimado !== '' ? item.estimado : 0,
          TotalMedio: item.totalMedio != null && item.totalMedio !== '' ? item.totalMedio : 0,
          Suspenso: 0,
          TipoOrcamento: item.tipoOrcamento,
          Total: item.total != null ? item.total : 0,
          Media: item.media != null ? item.media : 0,
          Minimo: item.minimo != null ? item.minimo : 0,
          Maximo: item.maximo != null ? item.maximo : 0,
          Quantidade: item.quantidade != null ? item.quantidade : 0,
          PercentualDespesas: 0
        })

        listaDeCategoriasNoGrupoComSuspensos[item.grupo] = []
      } else {
        listaDeGruposComSuspensos[indexOrcamento].Estimado +=
          item.estimado != null && item.estimado !== '' ? item.estimado : 0
        listaDeGruposComSuspensos[indexOrcamento].TotalMedio +=
          item.totalMedio != null && item.totalMedio !== '' ? item.totalMedio : 0

        listaDeGruposComSuspensos[indexOrcamento].Total += item.total != null ? item.total : 0
        listaDeGruposComSuspensos[indexOrcamento].Quantidade += item.quantidade != null ? item.quantidade : 0

        if (item.minimo != null && item.minimo < listaDeGruposComSuspensos[indexOrcamento].Minimo) {
          listaDeGruposComSuspensos[indexOrcamento].Minimo = item.minimo
        }

        if (item.maximo != null && item.maximo > listaDeGruposComSuspensos[indexOrcamento].Maximo) {
          listaDeGruposComSuspensos[indexOrcamento].Maximo = item.maximo
        }

        if (item.media != null) {
          mediaAnterior = listaDeGruposComSuspensos[indexOrcamento].Media
          listaDeGruposComSuspensos[indexOrcamento].Media = (mediaAnterior + item.media) / 2
        }
      }
      //FIM - Grupo com suspenso

      if (categoriaId != null) {
        const categoria = {
          OrcamentoId: item.orcamentoId,
          CategoriaId: item.categoriaId,
          MeioPagamentoPadraoId: item.meioPagamentoPadraoId,
          GrupoId: item.grupo,
          NomeOrcamento: item.nomeOrcamento,
          NomeCategoria: item.nomeCategoria,
          NomeMeioPagamento: item.nomeMeioPagamento,
          Grupo: mapearGrupoPeloId(item.grupo),
          Frequencia: mapearFrequenciaPeloId(item.frequencia),
          Estimado: item.estimadoAnual != null && item.estimadoAnual > 0 ? item.estimadoAnual : 0,
          Total: item.total != null ? item.total : 0,
          TotalMedio: item.totalMedio != null ? item.totalMedio : 0,
          Media: item.media != null ? item.media : 0,
          Minimo: item.minimo != null ? item.minimo : 0,
          Maximo: item.maximo != null ? item.maximo : 0,
          Quantidade: item.quantidade != null ? item.quantidade : 0,
          Suspenso: item.categoriaSuspenso != null ? item.categoriaSuspenso : 0,
          TipoOrcamento: item.tipoOrcamento,
          OrcamentoSuspenso: item.orcamentoSuspenso,
          PercentualDespesas: 0,
          percentualRealizado: 0
        }
        categoria.percentualRealizado =
          categoria.Estimado !== 0 ? (categoria.TotalMedio / categoria.Estimado) * 100 : 0

        if (orcamentoSuspenso === false && categoriaSuspenso === false) {
          //Adiciona categoria na lista de orçamentos
          listaDeCategorias[item.orcamentoId].push(categoria)
          listaDeCategoriasNoGrupo[item.grupo].push(categoria)
          listaDeCategoriasSemSuspensos.push(categoria)
        }

        //Adiciona categoria na lista de orçamentos (incluindo suspensos)
        listaDeCategoriasComSuspensos[item.orcamentoId].push(categoria)
        listaDeCategoriasNoGrupoComSuspensos[item.grupo].push(categoria)
        listaDeCategoriasOriginal.push(categoria)
      }
    })
  }

  totalDespesaEstimado =
    'Despesas estimadas: ' +
    totalDespesaEstimado.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }).replace('R$', '')
  totalDespesaEstimadoComSuspensos =
    'Despesas estimadas: ' +
    totalDespesaEstimadoComSuspensos
      .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
      .replace('R$', '')
  totalDespesaRealizado =
    'Despesas realizadas: ' +
    totalDespesaRealizado.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }).replace('R$', '')
  totalDespesaRealizadoComSuspensos =
    'Despesas realizadas: ' +
    totalDespesaRealizadoComSuspensos
      .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
      .replace('R$', '')

  totalReceitaEstimado =
    'Receitas estimadas: ' +
    totalReceitaEstimado.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }).replace('R$', '')
  totalReceitaEstimadoComSuspensos =
    'Receitas estimadas: ' +
    totalReceitaEstimadoComSuspensos
      .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
      .replace('R$', '')
  totalReceitaRealizado =
    'Receitas realizadas: ' +
    totalReceitaRealizado.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }).replace('R$', '')
  totalReceitaRealizadoComSuspensos =
    'Receitas realizadas: ' +
    totalReceitaRealizadoComSuspensos
      .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
      .replace('R$', '')

  totalInvestimentosEstimado =
    'Investimentos estimados: ' +
    totalInvestimentosEstimado
      .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
      .replace('R$', '')
  totalInvestimentosEstimadoComSuspensos =
    'Investimentos estimados: ' +
    totalInvestimentosEstimadoComSuspensos
      .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
      .replace('R$', '')
  totalInvestimentosRealizado =
    'Investimentos realizados: ' +
    totalInvestimentosRealizado
      .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
      .replace('R$', '')
  totalInvestimentosRealizadoComSuspensos =
    'Investimentos realizados: ' +
    totalInvestimentosRealizadoComSuspensos
      .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
      .replace('R$', '')

  totalDividasEstimado =
    'Dividas estimadas: ' +
    totalDividasEstimado.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }).replace('R$', '')
  totalDividasEstimadoComSuspensos =
    'Dividas estimadas: ' +
    totalDividasEstimadoComSuspensos
      .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
      .replace('R$', '')
  totalDividasRealizado =
    'Dividas realizadas: ' +
    totalDividasRealizado.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }).replace('R$', '')
  totalDividasRealizadoComSuspensos =
    'Dividas realizadas: ' +
    totalDividasRealizadoComSuspensos
      .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
      .replace('R$', '')

  const sumario = [
    totalDespesaEstimado,
    totalDespesaRealizado,
    totalDespesaEstimadoComSuspensos,
    totalDespesaRealizadoComSuspensos,
    totalReceitaEstimado,
    totalReceitaRealizado,
    totalReceitaEstimadoComSuspensos,
    totalReceitaRealizadoComSuspensos,
    totalInvestimentosEstimado,
    totalInvestimentosRealizado,
    totalInvestimentosEstimadoComSuspensos,
    totalInvestimentosRealizadoComSuspensos,
    totalDividasEstimado,
    totalDividasRealizado,
    totalDividasEstimadoComSuspensos,
    totalDividasRealizadoComSuspensos
  ]

  const total = data?.totalResults || data?.length
  const addPercentualRealizado = (listaOrcametnos) =>
    listaOrcametnos?.map((orcamento) => {
      orcamento.percentualRealizado =
        orcamento.Estimado !== 0 ? (orcamento.TotalMedio / orcamento.Estimado) * 100 : 0
      return orcamento
    })
  addPercentualRealizado(listaDeOrcamentos)
  addPercentualRealizado(listaDeOrcamentosComSuspensos)

  const newData = {
    originalData: listaDeCategoriasOriginal,
    listaDeOrcamentos,
    listaDeOrcamentosComSuspensos,
    listaDeCategorias,
    listaDeCategoriasComSuspensos,
    listaDeGrupos,
    listaDeGruposComSuspensos,
    listaDeCategoriasNoGrupo,
    listaDeCategoriasNoGrupoComSuspensos,
    listaDeCategoriasSemSuspensos,
    isLoading: false,
    count: total,
    sumario
  }

  return newData
}

export const getPercentageExpenditureBudgets = ({ listaDeOrcamentos, listaDeOrcamentosComSuspensos }) => {
  let totalDespesaDividas = 0
  if (listaDeOrcamentos.length > 0) {
    listaDeOrcamentos.reduce((total, num) => {
      total =
        total.TipoOrcamento === 1 || total.TipoOrcamento === 3
          ? total.TotalMedio === undefined || total.TotalMedio === null || total.TotalMedio === ''
            ? 0
            : total.TotalMedio
          : 0

      num =
        num.TipoOrcamento === 1 || num.TipoOrcamento === 3
          ? num.TotalMedio === undefined || num.TotalMedio === null || num.TotalMedio === ''
            ? 0
            : num.TotalMedio
          : 0

      totalDespesaDividas += total + num

      return total + num
    })
  }

  let totalDespesaDividasComSuspensos = 0
  if (listaDeOrcamentosComSuspensos.length > 0) {
    listaDeOrcamentosComSuspensos.reduce((total, num) => {
      total =
        total.TipoOrcamento === 1 || total.TipoOrcamento === 3
          ? total.TotalMedio === undefined || total.TotalMedio === null || total.TotalMedio === ''
            ? 0
            : total.TotalMedio
          : 0

      num =
        num.TipoOrcamento === 1 || num.TipoOrcamento === 3
          ? num.TotalMedio === undefined || num.TotalMedio === null || num.TotalMedio === ''
            ? 0
            : num.TotalMedio
          : 0

      totalDespesaDividasComSuspensos += total + num

      return total + num
    })
  }

  listaDeOrcamentos.forEach((item) => {
    item.PercentualDespesas =
      item.TipoOrcamento === 1 || item.TipoOrcamento === 3
        ? totalDespesaDividas !== 0
          ? item.TotalMedio / totalDespesaDividas
          : 0
        : '--'
  })

  listaDeOrcamentosComSuspensos.forEach((item) => {
    item.PercentualDespesas =
      item.TipoOrcamento === 1 || item.TipoOrcamento === 3
        ? totalDespesaDividasComSuspensos !== 0
          ? item.TotalMedio / totalDespesaDividasComSuspensos
          : 0
        : '--'
  })

  return { listaDeOrcamentos, listaDeOrcamentosComSuspensos }
}

export const getPercentageExpenseGroups = ({ listaDeGrupos, listaDeGruposComSuspensos }) => {
  let totalDespesaDividas = 0
  if (listaDeGrupos.length > 0) {
    listaDeGrupos.reduce((total, num) => {
      total =
        total.TipoOrcamento === 1 || total.TipoOrcamento === 3
          ? total.TotalMedio === undefined || total.TotalMedio === null || total.TotalMedio === ''
            ? 0
            : total.TotalMedio
          : 0

      num =
        num.TipoOrcamento === 1 || num.TipoOrcamento === 3
          ? num.TotalMedio === undefined || num.TotalMedio === null || num.TotalMedio === ''
            ? 0
            : num.TotalMedio
          : 0

      totalDespesaDividas += total + num

      return total + num
    })
  }

  let totalDespesaDividasComSuspensos = 0
  if (listaDeGruposComSuspensos.length > 0) {
    listaDeGruposComSuspensos.reduce((total, num) => {
      total =
        total.TipoOrcamento === 1 || total.TipoOrcamento === 3
          ? total.TotalMedio === undefined || total.TotalMedio === null || total.TotalMedio === ''
            ? 0
            : total.TotalMedio
          : 0

      num =
        num.TipoOrcamento === 1 || num.TipoOrcamento === 3
          ? num.TotalMedio === undefined || num.TotalMedio === null || num.TotalMedio === ''
            ? 0
            : num.TotalMedio
          : 0

      totalDespesaDividasComSuspensos += total + num

      return total + num
    })
  }

  listaDeGrupos.forEach((item) => {
    item.PercentualDespesas =
      item.TipoOrcamento === 1 || item.TipoOrcamento === 3
        ? totalDespesaDividas !== 0
          ? item.TotalMedio / totalDespesaDividas
          : 0
        : '--'
  })

  listaDeGruposComSuspensos.forEach((item) => {
    item.PercentualDespesas =
      item.TipoOrcamento === 1 || item.TipoOrcamento === 3
        ? totalDespesaDividasComSuspensos !== 0
          ? item.TotalMedio / totalDespesaDividasComSuspensos
          : 0
        : '--'
  })

  return { listaDeGrupos, listaDeGruposComSuspensos }
}

export const getPercentageExpenseCategories = ({ listaDeCategoriasSemSuspensos, originalData }) => {
  let totalDespesaDividas = 0
  if (listaDeCategoriasSemSuspensos.length > 0) {
    listaDeCategoriasSemSuspensos.reduce((total, num) => {
      total =
        total.TipoOrcamento === 1 || total.TipoOrcamento === 3
          ? total.TotalMedio === undefined || total.TotalMedio === null || total.TotalMedio === ''
            ? 0
            : total.TotalMedio
          : 0

      num =
        num.TipoOrcamento === 1 || num.TipoOrcamento === 3
          ? num.TotalMedio === undefined || num.TotalMedio === null || num.TotalMedio === ''
            ? 0
            : num.TotalMedio
          : 0

      totalDespesaDividas += total + num

      return total + num
    })
  }

  let totalDespesaDividasComSuspensos = 0
  if (!!originalData && originalData.length > 0) {
    originalData.reduce((total, num) => {
      total =
        total.TipoOrcamento === 1 || total.TipoOrcamento === 3
          ? total.TotalMedio === undefined || total.TotalMedio === null || total.TotalMedio === ''
            ? 0
            : total.TotalMedio
          : 0

      num =
        num.TipoOrcamento === 1 || num.TipoOrcamento === 3
          ? num.TotalMedio === undefined || num.TotalMedio === null || num.TotalMedio === ''
            ? 0
            : num.TotalMedio
          : 0

      totalDespesaDividasComSuspensos += total + num

      return total + num
    })
  }

  listaDeCategoriasSemSuspensos.forEach((item) => {
    item.PercentualDespesas =
      item.TipoOrcamento === 1 || item.TipoOrcamento === 3
        ? totalDespesaDividas !== 0
          ? item.TotalMedio / totalDespesaDividas
          : 0
        : '--'
  })

  if (!!originalData && originalData.length > 0) {
    originalData.forEach((item) => {
      item.PercentualDespesas =
        item.TipoOrcamento === 1 || item.TipoOrcamento === 3
          ? totalDespesaDividasComSuspensos !== 0
            ? item.TotalMedio / totalDespesaDividasComSuspensos
            : 0
          : '--'
    })
  }

  return {
    listaDeCategoriasSemSuspensos,
    originalData
  }
}

export const obterPorcentagemDespesaGruposCategorias = (childsGrupoCategoria) => {
  let totalDespesaDividas = 0
  if (childsGrupoCategoria.length > 0) {
    childsGrupoCategoria.reduce((total, num) => {
      total =
        total.TipoOrcamento === 1 || total.TipoOrcamento === 3
          ? total.TotalMedio === undefined || total.TotalMedio === null || total.TotalMedio === ''
            ? 0
            : total.TotalMedio
          : 0

      num =
        num.TipoOrcamento === 1 || num.TipoOrcamento === 3
          ? num.TotalMedio === undefined || num.TotalMedio === null || num.TotalMedio === ''
            ? 0
            : num.TotalMedio
          : 0

      totalDespesaDividas += total + num

      return total + num
    })
  }

  childsGrupoCategoria.forEach((item) => {
    item.PercentualDespesas =
      item.TipoOrcamento === 1 || item.TipoOrcamento === 3
        ? totalDespesaDividas !== 0
          ? item.TotalMedio / totalDespesaDividas
          : 0
        : '--'
  })

  return childsGrupoCategoria
}

export const obterPorcentagemDespesaOrcamentosCategorias = (childsOrcamentoCategoria) => {
  let totalDespesaDividas = 0
  if (childsOrcamentoCategoria.length > 0) {
    childsOrcamentoCategoria.reduce((total, num) => {
      total =
        total.TipoOrcamento === 1 || total.TipoOrcamento === 3
          ? total.TotalMedio === undefined || total.TotalMedio === null || total.TotalMedio === ''
            ? 0
            : total.TotalMedio
          : 0

      num =
        num.TipoOrcamento === 1 || num.TipoOrcamento === 3
          ? num.TotalMedio === undefined || num.TotalMedio === null || num.TotalMedio === ''
            ? 0
            : num.TotalMedio
          : 0

      totalDespesaDividas += total + num

      return total + num
    })
  }

  childsOrcamentoCategoria.forEach((item) => {
    item.PercentualDespesas =
      item.TipoOrcamento === 1 || item.TipoOrcamento === 3
        ? totalDespesaDividas !== 0
          ? item.TotalMedio / totalDespesaDividas
          : 0
        : '--'
  })

  return childsOrcamentoCategoria
}
