import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import React from 'react'
import { useHistory } from 'react-router-dom'

import { useAuth } from '../../../hooks/contexts/authContext'
import { useCoreContext } from '../../../hooks/contexts/coreContext/coreProvider'
import { usePlanner } from '../../../hooks/useCurrentFamily'
import { useFamilyStore } from '../../../store/family/useFamilyStore'
import ButtonNotesModal from '../../Buttons/ButtonNotesModal'
import { HelpModalButton } from '../../Modal/HelpModal'
import { ButtonDefault } from '../../Mui/Button'
import { ButtonNewEntry } from '../AdminNavbar/components/ButtonNewEntry'
import * as S from './styles'

const COMMON_ADMIN_MENU = [
  {
    title: 'Clientes',
    action: 'goBackToPlanner',
    path: '/admin/clientes'
  },
  {
    title: (loggedPlanner: boolean) => `Vista ${loggedPlanner ? 'Planejador' : 'Gestor'}`,
    action: 'goBackToPlanner',
    path: '/admin/home'
  }
]

const PLANNED_ADMIN_MENU = [
  {
    path: '/admin/patrimonio-integracao',
    title: 'Patrimônio'
  },
  {
    path: '/admin/gestao-planos-sonhos',
    title: 'Gestão de Planos'
  },
  {
    path: '/admin/gestao-orcamento',
    title: 'Gestão de Orçamento'
  }
]

const getUserTypeName = ({ tokenType, manager }: { tokenType: number; manager: boolean }) => {
  if (tokenType === 0) return 'Admin'
  if (tokenType === 1) {
    if (manager) return 'Gestor'
    return 'Planejador'
  }

  return null
}

const SETTINGS_ADMIN_MENU = [
  {
    title: 'Sair',
    action: 'logout',
    path: '/'
  }
]

export const AppBar = () => {
  const auth = useAuth()
  const { reset, token } = auth || {}
  const { tipoUsuario, familiaId } = useCoreContext()
  const { family } = useFamilyStore()
  const { goBackToPlanner } = usePlanner()
  const history = useHistory()
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null)

  const tokenType = Number(token?.type)
  const loggedPlanner = tokenType === 1
  const loggedAdmin = tokenType === 0

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  return (
    <>
      <S.MuiAppBar position="sticky">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' }, gap: '0.8rem' }}>
              {(loggedAdmin || loggedPlanner) &&
                COMMON_ADMIN_MENU.map((item) => {
                  const title = typeof item.title === 'function' ? item.title(loggedPlanner) : item.title
                  return (
                    <ButtonDefault
                      key={title}
                      onClick={(event) => {
                        event.preventDefault()
                        item.action === 'goBackToPlanner' && goBackToPlanner()
                        history.push(item.path)
                      }}
                      sx={{ my: 2 }}
                      variant="outlined"
                    >
                      {title}
                    </ButtonDefault>
                  )
                })}

              {(loggedAdmin || loggedPlanner) && (
                <ButtonDefault
                  variant="outlined"
                  component="a"
                  href="https://meuvista.crisp.help/pt-br/"
                  sx={{ my: 2 }}
                >
                  Suporte
                </ButtonDefault>
              )}

              {tipoUsuario === 2 &&
                PLANNED_ADMIN_MENU.map((item) => (
                  <ButtonDefault
                    key={item.title}
                    onClick={(event) => {
                      event.preventDefault()
                      history.push(item.path)
                    }}
                    variant="outlined"
                    sx={{ my: 2 }}
                  >
                    {item.title}
                  </ButtonDefault>
                ))}

              <HelpModalButton />
            </Box>

            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                justifyContent: 'flex-end',
                padding: '0 0.7rem',
                gap: '0.8rem'
              }}
            >
              <ButtonNotesModal />

              <div style={{ display: 'flex', alignItems: 'center', maxHeight: '50px' }}>
                <ButtonNewEntry />
              </div>
            </Box>

            <Box sx={{ flexGrow: 0, padding: '0 0 0 0.7rem', borderLeft: '1px solid #e0e0e0' }}>
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, gap: '0.5rem' }}>
                <Avatar alt={family?.nome} src="/static/images/avatar/2.jpg" />
                {tokenType < 2 && familiaId && (
                  <Typography>
                    {getUserTypeName({
                      tokenType,
                      manager: Boolean(token?.manager)
                    })}
                  </Typography>
                )}
              </IconButton>

              <Menu
                sx={{ mt: '45px', minWidth: '180px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {SETTINGS_ADMIN_MENU.map(({ title, path, action }) => (
                  <MenuItem
                    key={title}
                    sx={{ textAlign: 'center', minWidth: '150px' }}
                    onClick={() => {
                      handleCloseUserMenu()
                      if (action === 'logout') {
                        reset?.()
                      }
                      history.push(path)
                    }}
                  >
                    <Typography sx={{ textAlign: 'center' }}>{title}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </S.MuiAppBar>
    </>
  )
}
