import React, { useCallback, useEffect, useState } from 'react'

import { FixedEquityModal } from '../../../../components/Modal/FixedEquityModal'
import { IFixedEquity } from '../../../../components/Modal/FixedEquityModal/types'
import { EquityInvestmentsByUserIdTable } from '../../../../components/Tables'
import { EquityAssetsTable } from '../../../../components/Tables/EquityAssetsTable'
import { useGetAllEquitys } from '../../../../queries/investments/queries'
import * as S from '../styles'
import { IInnerContent } from './types'

export const InnerContent = ({
  familyId,
  start,
  end,
  isOpenEquityModal,
  setIsOpenEquityModal
}: IInnerContent) => {
  const [selectedFixedAsset, setSelectedFixedAsset] = useState<IFixedEquity>()
  const [modalIdentifier, setModalIdentifier] = useState(Math.random().toString())

  const equityQuery = {
    familyId,
    params: {
      startDate: start.toISOString(),
      endDate: end.toISOString()
    }
  }
  const { allEquitys, isErrorGetAllEquitys, isLoadingGetAllEquitys, refetchGetAllEquitys } =
    useGetAllEquitys(equityQuery)
  const { fixedAsset, investments } = allEquitys || {}

  const handleOpenModal = () => setIsOpenEquityModal(true)
  const closeModal = useCallback(() => {
    setIsOpenEquityModal(false)
    setSelectedFixedAsset(undefined)
    refetchGetAllEquitys()
  }, [refetchGetAllEquitys, setIsOpenEquityModal])

  const fixedEquityModalMethods = {
    openModal: handleOpenModal,
    closeModal,
    selectedFixedAsset,
    setSelectedFixedAsset
  }

  useEffect(() => {
    setModalIdentifier(Math.random().toString())
  }, [isOpenEquityModal, selectedFixedAsset])

  return (
    <S.ContentContainer>
      {investments?.map((item) => (
        <EquityInvestmentsByUserIdTable
          {...item}
          familyId={familyId}
          key={item?.userId}
          transactionsEndDate={end}
          transactionsStartDate={start}
          isError={isErrorGetAllEquitys}
          isLoading={isLoadingGetAllEquitys}
          refetchEquityInvestments={() => refetchGetAllEquitys()}
          fixedEquityModalMethods={fixedEquityModalMethods}
        />
      ))}

      <EquityAssetsTable
        fixedAssets={fixedAsset}
        isFixedAssetsLoading={isLoadingGetAllEquitys}
        onDeleteAsset={() => {
          refetchGetAllEquitys()
        }}
        onFixedAssetClick={(asset) => {
          if (asset) {
            setSelectedFixedAsset(asset as unknown as IFixedEquity)
            setIsOpenEquityModal(true)
          }
        }}
      />

      <FixedEquityModal
        open={isOpenEquityModal}
        onSave={closeModal}
        onClose={closeModal}
        key={modalIdentifier}
        fixedAsset={selectedFixedAsset}
      />
    </S.ContentContainer>
  )
}
