import { postWithToken } from '../../../webClient'
import { ManyResults } from '../../Results'
import { Pessoa } from '../Pessoa'

interface Params {
  familyId: number
}

export const getAllPessoasByFamily = async ({ familyId }: Params) => {
  const url = `pessoa/familia/${familyId}/filtro`
  const { data: { results: pessoas, success, errorMsg } = {}, status } = await postWithToken<
    ManyResults<Pessoa>,
    object
  >(url, {})
  if (!success || status !== 200) {
    const msg = `Error getting pessoas from family ${familyId}: ${errorMsg}`
    console.error(msg)
    throw new Error(msg)
  }
  return pessoas || []
}

interface GetAllUsersByPlannerIdFilterParams {
  plannerId?: number
  onlyName?: boolean
}

export const getAllUsersByPlannerIdFilterApi = async ({
  plannerId,
  onlyName = true
}: GetAllUsersByPlannerIdFilterParams) => {
  if (!plannerId) {
    console.error('[getAllUsersByPlannerIdFilterApi], Planner ID is required')
    return []
  }

  const url = `pessoa/planejador/${plannerId}/filtro`
  const { data: { results: pessoas, success, errorMsg } = {}, status } = await postWithToken<
    ManyResults<Pessoa>,
    object
  >(url, { ApenasNome: onlyName })

  if (!success || status !== 200) {
    const msg = `Error getting pessoas from family ${plannerId}: ${errorMsg}`
    console.error(msg)
    throw new Error(msg)
  }

  return pessoas || []
}

export const getAllFamiliesByPlannerIdApi = async ({
  plannerId
}: Pick<GetAllUsersByPlannerIdFilterParams, 'plannerId'>) => {
  if (!plannerId) {
    console.error('[getAllFamiliesByPlannerIdApi], Planner ID is required')
    return []
  }

  const url = `/familia/planejador/filtro/${plannerId}`
  const { data: { results: familias, success, errorMsg } = {}, status } = await postWithToken<
    ManyResults<Pessoa>,
    object
  >(url, {})

  if (!success || status !== 200) {
    const msg = `Error getting familias from planner ${plannerId}: ${errorMsg}`
    console.error(msg)
    throw new Error(msg)
  }

  return familias || []
}
