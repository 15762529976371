import React from 'react'
import styled from 'styled-components'

import { MeioPagamento } from '../../../domain/meioPagamento/meioPagamento'
import { useCoreContext } from '../../../hooks/contexts/coreContext/coreProvider'
import { useInitialInitialDataContext } from '../../../hooks/contexts/initialDataContext'
import { useFamilyMeiosPagamentos } from '../../../queries/meioPagamento/queries'
import { Transaction } from '../../Modal/DeleteTransaction/types'

interface Props {
  selectedId?: MeioPagamento['id']
  isManual?: boolean
  row?: Transaction
}

const Text = styled.span`
  text-align: center;
`

export const MeioPagamentoCell = ({ selectedId, row }: Props) => {
  const { familiaId: familyId } = useCoreContext()
  const { meiosPagamentos: meios, isLoadingMeios } = useFamilyMeiosPagamentos(
    familyId ? Number(familyId) : undefined
  )
  const { financialInstitutionCode, isManual } = row || {}
  const { financialInstitutions } = useInitialInitialDataContext()

  let selectedName = ''

  if (isManual && financialInstitutionCode) {
    const name = financialInstitutions?.find(
      ({ key }) => Number(key) === Number(financialInstitutionCode)
    )?.name

    selectedName = name ?? 'Sem meio'
  } else {
    selectedName = meios?.find(({ id }) => id === selectedId)?.nome ?? 'Sem meio'
  }

  return <Text>{isLoadingMeios ? '...' : selectedName}</Text>
}
