import React, { useMemo } from 'react'

import { useGetInvestmentsTypes } from '../../../queries/investments/queries'
import Select from '../Select'
import { IEquityTypeSelect, IOptions } from './types'

export const InvestmentsTypeSelect = ({
  type,
  disabled,
  onChange,
  borderless = false,
  loading
}: IEquityTypeSelect) => {
  const { investmentsTypes, isLoadingInvestmentstypes } = useGetInvestmentsTypes()
  const selected = type || 'Selecione...'

  const options = useMemo(
    () =>
      investmentsTypes?.map(({ type }: IOptions) => ({
        value: type,
        text: type
      })) || [],
    [investmentsTypes]
  )

  return (
    <Select
      borderless={borderless}
      disabled={disabled}
      grayscale={true}
      loading={loading || isLoadingInvestmentstypes}
      onSelection={onChange}
      options={options}
      placeholder={selected}
    />
  )
}
