import RawIconButton from '@material-ui/core/IconButton'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

export interface ButtonProps {
  link: string
  newTab?: boolean
}

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 1vw;

  .MuiIconButton-label {
    display: flex !important;
  }
`

export const LinkButton = styled(Link)`
  margin-left: 0.5vw;
  margin-right: 0.5vw;

  transform: scale(1);
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.06);
  }
`

export const IconButton = styled(RawIconButton)`
  margin: 0 !important;
  padding: 0 !important;
  transform: scale(1);
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.06);
  }
`

export const ButtonImage = styled.img`
  width: 2.2vw;
  height: auto;
  object-fit: contain;
`
