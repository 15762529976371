import styled from 'styled-components'

import Colors from '../../../domain/Colors'

export const Title = styled.span`
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 400;
  color: ${Colors.darkGray};
  padding-bottom: 0.5vh;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 1vh;
  background-color: ${Colors.white};
  border-radius: 0.375rem;
  padding: 0.5vw;
  width: 20vw;
  left: -15vw;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%),
    0px 1px 10px 0px rgb(0 0 0 / 12%);
  z-index: 100;
`

export const Input = styled.input`
  background-color: ${Colors.checkboxBlue};
  height: 14px;
  padding-right: 1vw;
  width: 14px;
`

export const ColumnName = styled.span`
  font-size: 13px;
  padding-left: 0.5vw;
`

export const ColumnPickContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`

export const ColumnPick = styled.label`
  align-items: center;
  cursor: pointer;
  display: flex;
  padding: 0;
  margin: 0;

  &:hover span {
    font-weight: bold;
  }
`
