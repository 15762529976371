import React from 'react'
import { Column } from 'react-table'

import { isCheckboxColumn } from '../columns'
import { useOnOutsideClick } from '../hooks'
import * as S from './styles'

interface Props<T extends object> {
  open: boolean
  columns: Column<T>[]
  disabledColumnNames?: string[]
  onHiddenColumnsChange: (hiddenColumnNames: string[]) => void
  onOutsideClick: () => void
}

const getColumnDisplayName = <T extends object>(column: Column<T>): string => {
  if (typeof column.Header === 'string') return column.Header as string
  else if (typeof column.id === 'string') return column.id as string
  else {
    console.warn(`Missing string header or id in column: ${JSON.stringify(column)}}`)
    return ''
  }
}

const getColumnIdentifier = <T extends object>(column: Column<T>): string => {
  if (typeof column.id === 'string') return column.id as string
  else if (typeof column.Header === 'string') return column.Header as string
  else {
    console.warn(`Missing string header or id in column: ${JSON.stringify(column)}}`)
    return ''
  }
}

export default <T extends object>({
  open,
  columns,
  disabledColumnNames = [],
  onHiddenColumnsChange,
  onOutsideClick
}: Props<T>) => {
  const ref = useOnOutsideClick(onOutsideClick)

  const isColumnChecked = (columnName: string) =>
    !disabledColumnNames.some((unselected) => unselected === columnName)
  const toggleColumn = (columnId: string) => {
    const newColumnsNames = isColumnChecked(columnId)
      ? disabledColumnNames.concat(columnId)
      : disabledColumnNames.filter((column) => column !== columnId)
    onHiddenColumnsChange(newColumnsNames)
  }

  if (!open) return <></>

  return (
    <S.Container ref={ref}>
      <S.Title>Mostrar Colunas</S.Title>

      <S.ColumnPickContainer>
        {columns
          .filter((c) => !isCheckboxColumn(c))
          .map((column, index) => {
            const name = getColumnDisplayName(column)
            const id = getColumnIdentifier(column)
            return (
              <S.ColumnPick key={index}>
                <S.Input type="checkbox" checked={isColumnChecked(id)} onChange={() => toggleColumn(id)} />
                <S.ColumnName>{name}</S.ColumnName>
              </S.ColumnPick>
            )
          })}
      </S.ColumnPickContainer>
    </S.Container>
  )
}
