import equals from 'fast-deep-equal'
import { useCallback, useEffect, useReducer, useRef } from 'react'

import { isIdentifiable } from '../../domain/identifiable'

export const tableSelectionReducer = <T extends object>(allRows: T[]) => {
  return (state: T[], action: { type: 'add' | 'add-all' | 'remove' | 'remove-all'; row?: T }) => {
    const { type, row } = action
    switch (type) {
      case 'add':
        if (!row) throw new Error('Missing value in select')
        return [...state, row]
      case 'add-all':
        return allRows
      case 'remove':
        if (!row) throw new Error('Missing value in unselect')
        return state.filter((v) => {
          if (isIdentifiable(v) && isIdentifiable(row)) return v.id !== row.id
          else if (typeof v === 'object' && typeof row === 'object') return !equals(v, row)
          console.warn('Warning, table rows are not identifiable nor objects. Comparison may fail.')
          return v !== row
        })
      case 'remove-all':
        return []
      default:
        console.warn(`Unexpected action type: ${type}`)
        return state
    }
  }
}

export const useTableSelection = <T extends object>(allRows: T[]) => {
  const [selectedRows, dispatch] = useReducer(tableSelectionReducer(allRows), allRows)
  const select = useCallback((row: T) => dispatch({ type: 'add', row }), [])
  const selectAll = useCallback(() => dispatch({ type: 'add-all' }), [])
  const unselect = useCallback((row: T) => dispatch({ type: 'remove', row }), [])
  const unselectAll = useCallback(() => dispatch({ type: 'remove-all' }), [])
  return { selectedRows, select, selectAll, unselect, unselectAll }
}

export const useOnOutsideClick = (callback: () => void) => {
  const ref = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) callback()
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  })
  return ref
}
