import React from 'react'

import * as Styled from './styles'
import { VideoIframeProps } from './types'

export const IframeVimeo = ({ videoId, title }: VideoIframeProps) => {
  const regexYoutube = /(youtu.be)|(youtube)/gi
  let src = `https://player.vimeo.com/video/${videoId}`

  if (videoId.match(regexYoutube)) {
    src = `https://www.youtube.com/embed/${videoId}`
  }

  return (
    <Styled.Iframe
      // eslint-disable-next-line max-len
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowFullScreen
      src={src}
      title={title}
      width="100%"
    />
  )
}
