import { format } from 'date-fns'
import React, { useCallback, useState } from 'react'

import { formatMonetaryValue } from '../../../domain/formatter'
import { getLoadingPlannerByCompanyId } from '../../../domain/planner'
import { PlannerByCompanyComplete } from '../../../domain/planner/planner'
import { useNumericLocalStorage } from '../../../hooks'
import { useRequiredAuth } from '../../../hooks/useAuth'
import { usePlannersByCompanyId } from '../../../queries/planner/queries'
import { noticeError } from '../../../services/Monitoring'
import Button from '../../Buttons'
import PlannerBillingTable from '../BillingsByPlannerIdTable'
import { TableFooter } from '../Footer'
import { Text } from '../Footer/Footer.styles'
import { FooterRow } from '../Footer/Footer.styles'
import Table from '../Table'
import { ContainerTable, ErrorContainer, ExpandedContainer } from '../Table.styles'
import { useColumns } from './columns'

const defaultSort: Sort = { desc: true, prop: 'name' }

const loadingData = getLoadingPlannerByCompanyId()

interface Sort {
  prop: string
  desc: boolean
}

interface Props {
  companyId: number
  widthBeforeHorizontalScroll?: number
  filterDate: Date
}

export const PlannersByCompanyIdTable = ({ companyId, filterDate, widthBeforeHorizontalScroll }: Props) => {
  const token = useRequiredAuth()
  const [search, setSearch] = useState<string>()
  const [page, setPage] = useState(0)
  const { value: pageSize, setValue: setPageSize } = useNumericLocalStorage(
    'planners-by-company-page-size',
    10
  )
  const [sort, setSort] = useState<Sort>(defaultSort)
  const onSort = useCallback((columnId: string, order: 'desc' | 'asc' | 'none') => {
    if (order === 'none') setSort(defaultSort)
    else setSort({ desc: order === 'desc', prop: columnId })
  }, [])

  const { isLoadingPlanners, isPlannersError, planners, plannersError, refetchGetPlannersByCompanyId } =
    usePlannersByCompanyId({
      companyId,
      desc: sort?.desc,
      orderBy: sort?.prop,
      pageIndex: page,
      pageSize,
      search,
      filterDate
    })

  const totalPages = planners ? Math.ceil(planners.planners.length / pageSize) : 1

  const columns = useColumns()

  if (isPlannersError) {
    noticeError(plannersError, { token })
    return (
      <ErrorContainer>
        <br /> Erro ao carregar tabela :c <br /> Tente novamente: <br />
        <Button text="Tentar novamente." onClick={() => refetchGetPlannersByCompanyId()} />
      </ErrorContainer>
    )
  }

  return (
    <ContainerTable>
      <Table
        title="Planejadores"
        data={planners?.planners || []}
        columns={columns}
        isLoading={isLoadingPlanners}
        loadingData={loadingData}
        filterEnabled={true}
        onFilterChange={setSearch}
        pageable={true}
        pageIndex={page}
        pageSize={pageSize}
        totalPages={totalPages}
        onNextPage={() => setPage(page + 1)}
        onPreviousPage={() => setPage(page - 1)}
        onPageSizeChange={setPageSize}
        sortable={true}
        onSort={onSort}
        mapDownloadFile={(rows: PlannerByCompanyComplete[]) => {
          return rows.map((row) => {
            return {
              'Id Planejador': row?.id,
              'Nome completo': row?.name,
              'Documento do Planejador': row?.document,
              'Data Criação': row?.createdAt ? format(new Date(row?.createdAt), 'dd/MM/yyyy') : '',
              Email: row?.email,
              Celular: row?.phone,
              'Nome Empresa': row?.companyName,
              'Id Empresa': row?.companyId,
              'Familias ativas': row?.activeFamilies?.length,
              Fatura: row?.totalInvoice
            }
          })
        }}
        downloadFileName={`Planejadores-${companyId}-${new Date().toISOString().split('T')[0]}`}
        renderExpanded={({ id }) => (
          <ExpandedContainer>
            <PlannerBillingTable plannerId={id} widthBeforeHorizontalScroll={499} filterDate={filterDate} />
          </ExpandedContainer>
        )}
        renderFooter={() => (
          <TableFooter>
            <FooterRow>
              <Text>Total de famílias: {planners?.quantityOfFamilies}</Text>
              <Text>Total da fatura: R$ {formatMonetaryValue(planners?.totalInvoice || 0)}</Text>
            </FooterRow>
          </TableFooter>
        )}
        widthBeforeHorizontalScroll={widthBeforeHorizontalScroll || 1230}
      />
    </ContainerTable>
  )
}
