import { Context as ReactContext, useContext } from 'react'

import { isPresent } from '../helpers'

interface Props<Value> {
  Context: ReactContext<Value>
  name: string
}

const useRequiredContext = <Value>({ Context, name }: Props<Value>): NonNullable<Value> => {
  const context = useContext(Context)

  if (isPresent(context)) return context

  const message = `the hook "use${name}" cannot be used outside a ${name}Provider`
  const error = new Error(message)
  console.error(error)
  throw error
}

export { useRequiredContext }
