import styled, { css } from 'styled-components'

import { collapsedNavBarWidth } from '../components/Sidebar/styles'

export const Main = styled.main<{ fullWidth?: boolean }>`
  transition: margin 0.2s ease-in-out;

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      margin-left: ${collapsedNavBarWidth} !important;
    `}
`
