import { SimplifiedLancamento } from '../../../domain/lancamento/lancamento'
import { useInitialInitialDataContext } from '../../../hooks/contexts/initialDataContext'

export const PaymentMethodCell = ({
  meioPagamentoId,
  row
}: {
  meioPagamentoId: SimplifiedLancamento['meioPagamentoId']
  row: SimplifiedLancamento
}) => {
  const { financialInstitutionCode, isManual } = row || {}
  const { financialInstitutions, meiosPagamentos } = useInitialInitialDataContext()

  let selectedName = ''

  if (isManual && financialInstitutionCode) {
    const name = financialInstitutions?.find(
      ({ key }) => Number(key) === Number(financialInstitutionCode)
    )?.name

    selectedName = name ?? 'Sem meio'
  } else {
    selectedName = meiosPagamentos?.find(({ id }) => id === meioPagamentoId)?.nome ?? 'Sem meio'
  }

  return <span>{selectedName}</span>
}
