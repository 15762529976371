import React from 'react'

import { formatMonetaryValue } from '../../../../domain/formatter'
import { AlertModal } from '../AlertModal'

interface Props {
  name: string
  originalValue: number
  newValue: number
  onConfirm: () => void
  onCancel: () => void
  confirmDisabled: boolean
}

export const InvestmentValueConfirmModal = ({
  name,
  originalValue,
  newValue,
  onConfirm,
  onCancel,
  confirmDisabled
}: Props) => {
  return (
    <AlertModal
      title="Confirmação de mudança de investimento"
      open
      onClose={onCancel}
      primaryButton={{
        text: 'Confirmar',
        disabled: confirmDisabled,
        onClick: onConfirm
      }}
      secondaryButton={{
        text: 'Cancelar',
        disabled: confirmDisabled,
        onClick: onCancel
      }}
    >
      <p>
        Gostaria de mudar o valor do investimento <b>{name}</b>
        <br />
        de <b>{formatMonetaryValue(originalValue, false)}</b> para{' '}
        <b>{formatMonetaryValue(newValue, false)}?</b>
      </p>
    </AlertModal>
  )
}
