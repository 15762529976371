import { Alert, styled } from '@mui/material'
import React from 'react'

type TError = Error | { response: { data?: string; status: number } } | null

interface Props {
  isError: boolean
  error: TError
  onAccept: () => void
}

const StyledAlert = styled(Alert)({
  margin: 0
})

const getErrorMessage = (error?: TError) => {
  // @ts-expect-error
  const { data = '', status = 0 } = error?.response || {}
  // @ts-expect-error
  const errorMessage = error?.message || data || ''

  if (status === 404) {
    return 'Ocorreu um erro interno. Por favor, tente novamente mais tarde.'
  }

  if (status === 500) {
    reportError(error)
    return (
      `Ocorreu um erro, por favor revise o arquivo. Erro: ${data}` ||
      'Erro ao enviar extrato. Arquivo não compatível E/OU disponível no Vista.'
    )
  }

  if (status === 422) {
    if (errorMessage?.toLocaleLowerCase()?.includes('request failed with status code 422')) {
      return (
        'Arquivo não compatível E/OU disponível no Vista.' +
        ' Caso queira adicionar esse arquivo, ' +
        'favor abrir ticket solicitando a inclusão. ' +
        'Não esqueça de enviar o arquivo anexado.'
      )
    }
    return 'Erro ao enviar extrato. Arquivo não compatível.'
  }

  return 'Erro ao enviar extrato. Arquivo não compatível.'
}

export const UploadErrorAlert = ({ isError, error, onAccept }: Props) => {
  if (!isError || !error) return <></>
  const errorMessage = getErrorMessage(error as TError)

  return (
    <StyledAlert severity="error" onClose={onAccept}>
      {errorMessage}
    </StyledAlert>
  )
}
