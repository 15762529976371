import styled from 'styled-components'

export const BeneficiosButton = styled.a`
  background: #faf6ff;
  border-left: solid 2px #9847f4;
  border-radius: 80px;
  border-right: solid 2px #9847f4;
  box-shadow: 4px 4px 8px #00000016;
  color: #9847f4 !important;
  font-size: 0.9rem;
  font-weight: 600;
  margin: 8px 16px;
  padding: 8px 12px;
  transition: background 0.18s ease-in-out, box-shadow 0.18s ease-in-out, color 0.18s ease-in-out;
  width: fit-content;

  &:hover {
    background: #300660;
    box-shadow: 4px 4px 8px #00000088;
    color: #fff !important;
  }
`
