import { confirmAlert } from 'react-confirm-alert'

import { SimplifiedLancamento } from '../domain/lancamento/lancamento'

const showAlert = (title: string, message: string, onClick?: () => void) => {
  const options = {
    title,
    message,
    buttons: [
      {
        label: 'Ok',
        onClick: onClick ? onClick : () => {}
      }
    ]
  }
  confirmAlert(options)
}

export const showYesNoAlert = (
  title: string,
  message: string,
  onYesClick: () => void,
  onNoClick: () => void = () => {}
) => {
  const options = {
    title,
    message,
    buttons: [
      { label: 'Sim', onClick: onYesClick },
      { label: 'Não', onClick: onNoClick }
    ]
  }
  confirmAlert(options)
}

export const showOptions = (
  title: string,
  message: string,
  options: {
    text: string
    onClick: () => void
  }[]
) => {
  const alertOptions = {
    title,
    message,
    buttons: options.map(({ text, onClick }) => ({ label: text, onClick }))
  }
  confirmAlert(alertOptions)
}

/** @deprecated
 * Use ModalDeleteTransaction instead
 */
export const confirmLancamentosDeletion = (lancamentos: SimplifiedLancamento[]) =>
  new Promise<{ deletionConfirmed: boolean; deleteFuture: boolean }>((resolve) => {
    const count = lancamentos.length
    const title = 'Exclusão de lançamentos'
    const hasAnyParcelado = lancamentos?.some(({ parcelado }) => parcelado) ?? false

    const confirmDeletion = () => resolve({ deletionConfirmed: true, deleteFuture: false })
    const confirmDeletionFuture = () => resolve({ deletionConfirmed: true, deleteFuture: true })
    const cancelDeletion = () => resolve({ deletionConfirmed: false, deleteFuture: false })

    let message = 'A exclusão é um procedimento irreversível! '
    if (hasAnyParcelado) {
      message +=
        'Existem lancamentos parcelados entre as exclusões. ' +
        'Gostaria de deletar somente este lançamento ou todas as parcelas? '
      if (count > 3) message += `${count} lançamentos foram selecionados. `
      const options = [
        { text: 'Deletar todos', onClick: confirmDeletionFuture },
        { text: 'Deletar apenas os selecionados', onClick: confirmDeletion },
        { text: 'Cancelar', onClick: cancelDeletion }
      ]
      showOptions(title, message, options)
    } else {
      message += 'Tem certeza que deseja excluir estes lançamentos? '
      if (count > 3) message += `${count} lançamentos foram selecionados. `
      showYesNoAlert(title, message, confirmDeletion, cancelDeletion)
    }
  })

export default showAlert
