import React from 'react'
import styled from 'styled-components'

import allColors, { Colors } from '../../domain/Colors'

interface Props {
  color?: Colors
}

const Divider = styled.div<Props>`
  display: flex;
  align-self: center;
  height: 80%;
  background-color: ${({ color }) => (color ? allColors[color] : allColors.darkGray)}33;
  width: 0.15rem;
  max-width: 2px;
  min-width: 1.9px;
`

export default ({ color }: Props) => {
  return <Divider color={color} />
}
