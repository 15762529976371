import { styled } from '@mui/material'
import AppBar from '@mui/material/AppBar'

export const MUI_APP_BAR_HEIGHT = 70

export const MuiAppBar = styled(AppBar)(({ theme }) => ({
  background: 'white',
  borderBottom: '1px solid #e0e0e0',
  margin: 0,
  boxShadow: theme.shadows[0],
  zIndex: 998,
  height: MUI_APP_BAR_HEIGHT,

  '& .MuiContainer-root': {
    padding: 0
  },

  '& .MuiToolbar-root.MuiToolbar-regular': {
    background: 'white',
    padding: '0 1rem'
  },

  '@media (min-width: 1536px)': {
    '& .MuiContainer-root': {
      maxWidth: '100%'
    }
  }
}))
