export const textAreaContentHeight = ({ text, maxHeight = 500, maxWidth = 600 }) => {
  // Criar um span temporário para medir a altura do conteúdo antes de exibir
  const tempSpan = document.createElement('span')
  tempSpan.style.whiteSpace = 'pre-wrap'
  tempSpan.style.wordBreak = 'break-word'
  tempSpan.style.width = `${maxWidth}px`
  tempSpan.style.position = 'absolute'
  tempSpan.style.visibility = 'hidden'
  tempSpan.style.lineHeight = '1.5'
  tempSpan.style.fontSize = '13px'
  tempSpan.textContent = text

  document.body.appendChild(tempSpan)

  // Verifica se o conteúdo cabe na altura máxima
  if (tempSpan.offsetHeight >= maxHeight) {
    console.log('O texto inserido ultrapassa a altura máxima permitida.')
    return 'O campo ultrapassa a altura máxima permitida.'
  }

  document.body.removeChild(tempSpan)
  return true
}
