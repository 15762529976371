import React from 'react'

import { DetailsDespesaTransaction } from '../detailsDespesa'
import { DetailsDividaTransaction } from '../detailsDivida'
import { DetailsInvestmentTransaction } from '../detailsInvestiment'
import { DetailsReceitaTransaction } from '../detailsReceita'
import { OrcamentoType, TIPO_ORCAMENTO_CODE_NAME } from '../types'

export const Summary = ({
  tipoOrcamento,
  open,
  onClose,
  onError,
  onSuccess,
  editingTransaction = true
}: {
  tipoOrcamento?: OrcamentoType
  open: boolean
  onClose: () => void
  onError?: (message?: string) => void
  onSuccess?: () => void
  editingTransaction?: boolean
}) => {
  switch (TIPO_ORCAMENTO_CODE_NAME[tipoOrcamento ?? 1]) {
    case 'Receita':
      return (
        <DetailsReceitaTransaction
          open={open}
          onSuccess={onSuccess}
          onError={onError}
          onClose={onClose}
          editingTransaction={editingTransaction}
        />
      )
    case 'Despesa':
      return (
        <DetailsDespesaTransaction
          open={open}
          onSuccess={onSuccess}
          onError={onError}
          onClose={onClose}
          editingTransaction={editingTransaction}
        />
      )
    case 'Investimento':
      return (
        <DetailsInvestmentTransaction
          open={open}
          onSuccess={onSuccess}
          onError={onError}
          onClose={onClose}
          editingTransaction={editingTransaction}
        />
      )
    case 'Dívida':
      return (
        <DetailsDividaTransaction
          open={open}
          onSuccess={onSuccess}
          onError={onError}
          onClose={onClose}
          editingTransaction={editingTransaction}
        />
      )
    default:
      return <></>
  }
}
