import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { Note } from '../../../domain/Notes/Notes'
import { useCurrentFamilyId } from '../../../hooks'
import { useCreateNote, useDeleteNote, useUpdateNote } from '../../../queries/Notes/mutation'
import Button from '../../Buttons'
import { PreviousIcon, TrashCanIcon } from '../../Icons'
import CloseIcon from '../../Icons/CloseIcon'
import EditIcon from '../../Icons/EditIcon'
import StarIcon from '../../Icons/StarIcon'
import { ButtonDefault, ButtonGradient } from '../../Mui/Button'
import DateAndTime from './DateAndTime'

const ContainerNote = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  padding: 1rem 0;
`

const ContainerHeaderOfNote = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  padding: 8px 32px;
  background: ${({ theme }) => theme.colors.primary}10;
  width: 100%;
  height: 64px;
`

const BackButton = styled.button`
  transition: all 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border: 1px solid #a0a0a0a0;
  background: #fff;
  color: #646464a0;
  font-size: 24px;
  font-weight: 700;
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 32px;

  &:hover {
    color: #646464;
    border: 1px solid #646464;
  }
`

const NoteTitle = styled.input`
  width: calc(100% - 16px);
  line-height: 1.4;
  margin: 8px 4px 4px 4px;
  padding: 8px;
  font-size: 20px;
  font-weight: 700;
  color: #323232;
  border: none;
  align-items: start;
  cursor: text;
  outline: none;
  transition: all 0.3s ease-in-out;
  border: 1px solid #64646432;
  border-radius: 8px;

  &:placeholder {
    color: #88888840;
  }

  &:focus {
    outline: none;
    background: #f8f8f888;
    border: 1px solid #64646496;
  }

  &:disabled {
    border: 1px solid #64646400;
    background: #fff;
    color: #323232;
  }
`

const NoteText = styled.textarea`
  width: calc(100% - 16px);
  height: 68%;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
  margin: 4px;
  border: none;
  padding: 8px;
  resize: none;
  /* overflow-y: auto; */
  outline: none;
  transition: all 0.3s ease-in-out;
  border: 1px solid #64646432;
  border-radius: 8px;
  color: #000;
  background: #fff;

  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
  &::-webkit-scrollbar-track-piece {
    background: #64646416;
  }
  &::-webkit-scrollbar-thumb {
    background: #64646432;
    border-radius: 16px;
  }

  &::placeholder {
    color: #888888aa;
  }

  &:focus {
    outline: none;
    background: #f8f8f888;
    border: 1px solid #64646496;
  }

  &:disabled {
    border: 1px solid #64646400;
  }
`

const SaveArea = styled.div`
  width: 100%;
  padding: 16px 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 16px;
`

const EditButton = styled.button`
  border: none;
  background: #a0a0a016;
  width: 120px;
  height: 40px;
  padding: 10px;
  margin: 16px 0 0 0;
  font-size: 14px;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 8px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  border-radius: 40px;

  &:hover {
    background: #a0a0a040;
  }
`

const CloseButton = styled.button`
  border: none;
  background: #a0a0a016;
  width: 128px;
  height: 40px;
  padding: 14px;
  font-size: 14px;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 8px;
  border-radius: 24px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    background: #a0a0a040;
  }
`
interface IIconSizer {
  pad?: string
}
const IconSizer = styled.div<IIconSizer>`
  width: 40px;
  height: 40px;
  padding: ${({ pad }) => pad || '0px'};
`

const AlertContainerAll = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(4px);
  background: #ffffff08;
`

const AlertContainer = styled.div`
  position: absolute;
  border: solid 1px #fa000032;
  background: #fafafa;
  width: 94%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
  gap: 8px;
  transition: all 0.3s ease-in-out;
  border-radius: 16px;
`

const AlertText = styled.h2`
  font-size: 16px;
  font-weight: 700;
  padding: 8px;
`

const AlertContainerButtons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  gap: 16px;
`

const DeleteConfirmButton = styled.button`
  outline: none;
  border-radius: 16px;
  min-width: 120px;
  border: none;
  padding: 8px 16px;
  font-size: 14px;
  background: #fa000016;
  font-weight: 700;
  color: #fa0000cc;
  transition: all 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    background: #fa000024;
  }
`

const EditButtons = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 16px;
`

const ButtonEdit = styled.button`
  border: none;
  outline: none;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  cursor: pointer;
  background: #ffffff32;
  border-radius: 24px;
  transition: all 0.3s ease-in-out;

  &:hover {
    background: #ffffffcc;
  }
`

const TitleNewNote = styled.h2`
  line-height: 1;
  font-size: 16px;
  font-weight: 700;
  color: #404040;
  width: 80%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ButtonClose = styled(ButtonDefault)`
  && {
    svg {
      width: 24px;
      height: 24px;
    }
  }
`

interface IViewNote {
  selectedItem?: Note
  setSelectedItem?: (note: Note | undefined) => void
  isNewNote?: boolean
  setIsNewNote?: (bool: boolean) => void
}
export const ViewNote = ({ selectedItem, setSelectedItem, isNewNote = false, setIsNewNote }: IViewNote) => {
  const [textValue, setTextValue] = useState<string>('')
  const [titleValue, setTitleValue] = useState<string>('')
  const [isFavorite, setIsFavorite] = useState<boolean>(false)
  const [isEditable, setIsEditable] = useState<boolean>(isNewNote)
  const [showAlertDelete, setShowAlertDelete] = useState<boolean>(false)
  const { createNote } = useCreateNote()
  const { deleteNote } = useDeleteNote()
  const { updateNote: noteUpdate } = useUpdateNote()
  const { familyId } = useCurrentFamilyId()

  const handleExcludeNote = () => {
    selectedItem !== undefined && deleteNote(selectedItem?._id)
    if (isNewNote) {
      setSelectedItem?.(undefined)
      setIsNewNote?.(false)
    } else {
      setSelectedItem?.(undefined)
    }
  }

  const handleUpdateNote = () => {
    selectedItem !== undefined &&
      noteUpdate({
        id: selectedItem?._id,
        text: textValue,
        title: titleValue,
        favorite: isFavorite
      })
    if (isNewNote) {
      setSelectedItem?.(undefined)
      setIsNewNote?.(false)
    } else {
      setSelectedItem?.(undefined)
    }
  }

  const changeFavorite = () => {
    setIsFavorite(!isFavorite)
    setIsEditable(true)
  }
  const turnOnEditable = () => setIsEditable(true)
  const cancelEdit = () => {
    if (selectedItem?.title && selectedItem?.text) {
      setTitleValue(selectedItem?.title)
      setTextValue(selectedItem?.text)
      setIsFavorite(selectedItem?.favorite)
    }
    setIsEditable(false)
  }
  const createNoteCallback = () => {
    createNote({
      title: titleValue,
      text: textValue,
      favorite: isFavorite,
      familyId
    })
    if (isNewNote) {
      setSelectedItem?.(undefined)
      setIsNewNote?.(false)
    } else {
      setSelectedItem?.(undefined)
    }
  }

  useEffect(() => {
    if (selectedItem?.title && selectedItem?.text) {
      setTitleValue(selectedItem?.title)
      setTextValue(selectedItem?.text)
      setIsFavorite(selectedItem?.favorite)
    }
  }, [selectedItem])

  return (
    <ContainerNote>
      <ContainerHeaderOfNote>
        <BackButton
          onClick={() => {
            if (isNewNote) {
              setSelectedItem?.(undefined)
              setIsNewNote?.(false)
            } else {
              setSelectedItem?.(undefined)
            }
          }}
        >
          <PreviousIcon />
        </BackButton>
        {!isNewNote && selectedItem && <DateAndTime item={selectedItem} />}
        {isNewNote && <TitleNewNote>{'Criar uma nova Nota'}</TitleNewNote>}
        <EditButtons>
          <ButtonEdit onClick={changeFavorite}>
            <StarIcon fill={isFavorite} />
          </ButtonEdit>
          {!isNewNote && (
            <ButtonEdit>
              <TrashCanIcon onClick={() => setShowAlertDelete(true)} size={'normal'} noMargin={true} />
            </ButtonEdit>
          )}
        </EditButtons>
      </ContainerHeaderOfNote>
      {showAlertDelete && (
        <AlertContainerAll>
          <AlertContainer>
            <AlertText> {'Você tem certeza que deseja excluir essa nota ?'} </AlertText>
            <AlertContainerButtons>
              <CloseButton onClick={() => setShowAlertDelete(false)}>
                <IconSizer pad="10px">
                  <CloseIcon />
                </IconSizer>
                Cancelar
              </CloseButton>
              <DeleteConfirmButton onClick={handleExcludeNote}> Excluir </DeleteConfirmButton>
            </AlertContainerButtons>
          </AlertContainer>
        </AlertContainerAll>
      )}
      <NoteTitle
        disabled={isNewNote ? false : isEditable ? false : true}
        type="text"
        onChange={(e) => setTitleValue(`${e.target.value}`)}
        placeholder={isNewNote ? 'Digite um título' : ''}
        value={titleValue}
      />
      <NoteText
        disabled={isNewNote ? false : isEditable ? false : true}
        onChange={(e) => setTextValue(`${e.target.value}`)}
        placeholder={isNewNote ? 'Digite o texto da nota' : ''}
        value={textValue}
      />
      {!isEditable && !isNewNote && (
        <EditButton onClick={turnOnEditable}>
          <IconSizer pad="10px">
            {' '}
            <EditIcon />
          </IconSizer>
          Editar
        </EditButton>
      )}
      {isEditable && isNewNote && (
        <SaveArea>
          <Button
            disabled={titleValue === '' || textValue === ''}
            text="Salvar"
            onClick={createNoteCallback}
          />
        </SaveArea>
      )}
      {isEditable && !isNewNote && (
        <SaveArea>
          <ButtonClose onClick={cancelEdit}>Cancelar</ButtonClose>
          <ButtonGradient disabled={titleValue === '' || textValue === ''} onClick={handleUpdateNote}>
            Salvar
          </ButtonGradient>
        </SaveArea>
      )}
    </ContainerNote>
  )
}
