import { Button, ButtonProps, CircularProgress, styled } from '@mui/material'

const StyledButton = styled(Button)(({ theme, color }) => ({
  borderRadius: '1rem',
  textTransform: 'none',

  '&.MuiButton-outlined': {
    borderColor: color && theme.palette[color],
    color: color && theme.palette[color],
    borderRadius: '1rem',
    padding: '5px 16px'
  },

  '&.MuiButton-contained': {
    background: `linear-gradient(45deg, ${theme.palette.secondary.main} 30%, ${theme.palette.primary.main} 90%)`,
    color: theme.palette.common.white,
    borderRadius: '1rem',
    padding: '5px 16px'
  },

  '&.text': {
    color: color && theme.palette[color]
  },

  '@media screen and (min-width: 1024px)': {
    minWidth: '100px'
  },

  '&:disabled': {
    opacity: 0.5
  },

  '&.MuiButton-sizeLarge': {
    paddingLeft: '1.6rem',
    paddingRight: '1.6rem',
    textTransform: 'uppercase'
  },

  '&.Mui-disabled': {
    opacity: 0.5,
    cursor: 'not-allowed'
  }
}))

export const ButtonGradient = ({ children, color = 'secondary', ...props }: ButtonProps) => {
  return (
    <StyledButton component="button" variant="contained" loading={props.loading} color={color} {...props}>
      {children}
      {props.loading && <CircularProgress size={16} />}
    </StyledButton>
  )
}
