import React from 'react'
import styled from 'styled-components'

import next from '../../assets/img/theme/next.png'
import { Size, withSize } from '../withSize'
import Icon from './Icon'

interface Props {
  size?: Size
  disabled?: boolean
  onClick?: () => void
}

const StyledIcon = styled(Icon)<Props>`
  max-width: ${({ size }) => withSize(size)};
  max-height: ${({ size }) => withSize(size)};
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
  margin: 0;
`

export default ({ size = 'small', disabled, onClick }: Props) => (
  <StyledIcon alt="." src={next} size={size} onClick={disabled ? undefined : onClick} disabled={disabled} />
)
