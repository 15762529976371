import React, { useState } from 'react'
import ReactTooltip from 'react-tooltip'

import * as Styled from './styles'

export const Pagination = ({ children }) => {
  const size = children?.length ?? 0
  const [current, setCurrent] = useState(1)

  return (
    <Styled.Container>
      <Styled.Content>
        {Array.isArray(children)
          ? children?.map(
              (
                child:
                  | boolean
                  | React.ReactChild
                  | React.ReactFragment
                  | React.ReactPortal
                  | null
                  | undefined,
                index: number
              ) => (
                <Styled.Item visible={current === index + 1} data-index={index + 1} key={index + 1}>
                  {child}
                </Styled.Item>
              )
            )
          : children}
      </Styled.Content>

      {!!size && (
        <Styled.Footer>
          <button
            disabled={current === 1}
            className="aux-button"
            onClick={() => setCurrent((value) => value - 1)}
          >
            Anterior
          </button>

          {size > 1 &&
            children?.map((item, index) => {
              const page = index + 1
              return (
                <Styled.PageButton
                  className="aux-button"
                  disabled={current === page}
                  onClick={() => setCurrent(page)}
                  key={page}
                >
                  {page}

                  {item?.props?.title && (
                    <>
                      <ReactTooltip id={item?.props?.title} effect="solid" type="info" />
                      <span data-tip={item?.props?.title} data-for={item?.props?.title}>
                        {item.props.title}
                      </span>
                    </>
                  )}
                </Styled.PageButton>
              )
            })}

          <button
            disabled={current === size}
            className="aux-button"
            onClick={() => setCurrent((value) => value + 1)}
          >
            Proximo
          </button>
        </Styled.Footer>
      )}
    </Styled.Container>
  )
}

export const BeforePagination = ({ children, defaultChildren }) => {
  const allChildren: unknown[] = []

  if (Array.isArray(children)) {
    children.forEach((child) => {
      allChildren.push(child)
    })
  }

  allChildren.push(defaultChildren)

  return <Pagination>{children}</Pagination>
}

export const ConcatChildrenPagination = ({ children }) => {
  return (
    <Pagination>
      {children?.map((item) => (
        <div key={item} title={item?.props?.title}>
          {item}
        </div>
      ))}
    </Pagination>
  )
}
