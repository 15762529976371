import React from 'react'
import styled from 'styled-components'

import Colors from '../../../domain/Colors'
import { ButtonImage, ButtonProps, ButtonsContainer, IconButton } from './Buttons'
import Footer from './Footer'
import goToButtonIcon from './images/arrowRight.png'
import * as S from './styles'

const TitleContainer = styled.h2`
  color: ${Colors['darkBlue']};
  font-weight: bold;
  margin-top: 1vw;
  margin-left: 0.3vw;
  margin-right: 0.3vw;
`

const Icon = styled.img`
  /* height: 10vw; */
  max-width: 140px;
  width: auto;
  max-height: 100px;
  cursor: pointer;
`

interface Props {
  title: string
  iconSource: string
  color?: keyof typeof Colors
  goToButton: ButtonProps
}

export default ({ title, iconSource, goToButton, color = 'blue' }: Props) => {
  const redirect = () => window.open(goToButton.link, '_blank', 'noopener,noreferrer')

  return (
    <S.Container>
      <div>
        <TitleContainer>{title}</TitleContainer>
      </div>

      <S.DescriptionContainer>
        <Icon src={iconSource} onClick={redirect} />
      </S.DescriptionContainer>

      <ButtonsContainer>
        <IconButton onClick={redirect}>
          <ButtonImage src={goToButtonIcon} />
        </IconButton>
      </ButtonsContainer>
      <Footer color={color} />
    </S.Container>
  )
}
