import React, { useMemo } from 'react'
import { Column } from 'react-table'
import ReactTooltip from 'react-tooltip'

// import { InvestmentsByUserId } from '../../../domain/investments'
import { formatMonetaryValue } from '../../../../domain/formatter'
import { IGroupedInvestment } from '../../../../domain/investments/api/ManualInvestments/types'
// import { IUseColumns } from './types'

export const useColumns = () => {
  const columns = useMemo(
    (): Column<IGroupedInvestment>[] => [
      {
        Header: 'Tipo de Investimento',
        id: 'type',
        accessor: 'type'
      },
      {
        Header: 'Valor Aplicado',
        id: 'originalValue',
        accessor: 'originalValue',
        Cell: ({ value }) =>
          value && value > 0 ? (
            `R$ ${formatMonetaryValue(value)}`
          ) : (
            <>
              <span data-tip="Valor não informado">- - -</span>
              <ReactTooltip place="top" type="dark" effect="float" />
            </>
          )
      },
      {
        Header: 'Valor Atual',
        id: 'currentValue',
        accessor: 'currentValue',
        Cell: ({ value }) => (value && value > 0 ? `R$ ${formatMonetaryValue(value)}` : '- - -')
      },
      {
        Header: 'Distribuição Atual',
        id: 'percentageInvested',
        accessor: 'percentageInvested',
        Cell: ({ value }) => value && `${value.toFixed(2).replace('.', ',')}%`
      }
    ],
    []
  )
  return columns
}
