import { format, getMonth, getYear } from 'date-fns'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'

import { showAlert, useTheme } from '../../../../hooks'
import { useCoreContext } from '../../../../hooks/contexts/coreContext/coreProvider'
import { usePostTipoOrcamentoPrevistoRealizado } from '../../../../queries/dashboard'
import {
  useGetDiagnosticById,
  useGetDiagnosticSummary,
  usePostCreateDiagnostic
} from '../../../../queries/diagnostics/queries'
import { useGetAllEquitys } from '../../../../queries/investments/queries'
import { usePostOrcamentoPeriodo } from '../../../../queries/orcamento/mutations'
import {
  useGetPlansDreamsByFamilyId,
  useMutationDreamImageListByDreamId
} from '../../../../queries/plansDreams'
import { noticeError } from '../../../../services/Monitoring'
import { useDiagnosticStore } from '../../../../store/Diagnostic'
import { getLighter } from '../../../Utils/Colors'
import { PDFOpen } from '../../../Utils/PDFDownload'
import { defaultValues } from './constants'
import { IFormCreateDiagnostic, IGoals, IWhiteLabel } from './types'

const defaultDreamImage = 'https://app.meuvista.com/v1/static/media/sonhos-padrao.dbde1829c8291a730660.jpg'

const formatCheckboxesValues = (values: string[]) => {
  return values.map((item) => item.split(/\[/)[1].replace(/\]/, ''))
}

const formatTextareaValues = (values: string) => {
  const text = values
    ?.replace(/([\p{Emoji}])/gu, (match) => {
      return `&#${match.codePointAt(0)};`
    })
    ?.replace(/\n/g, '<br>')

  return text
}

export const useCreateDiagnosticMethods = () => {
  const { familiaId: familyId, startDate, endDate } = useCoreContext()
  const { showCreateDiagnostic, modalCreateDiagnostic, modalDiagnosticsRefecth } = useDiagnosticStore()
  const { plansDreams, getPlansDreamsByFamilyId, isLoadingPlansDreams } = useGetPlansDreamsByFamilyId()
  const { postTipoOrcamentoPrevistoRealizadoAsync, orcamentoPrevistoRealizado } =
    usePostTipoOrcamentoPrevistoRealizado()
  const { postOrcamentoPeriodoAsync, postingOrcamentoPeriodoData } = usePostOrcamentoPeriodo()
  const { xRaySummary } = useGetDiagnosticSummary()
  const [tryingGetDiagnosticById, setTryingGetDiagnosticById] = useState(false)
  const { theme } = useTheme()
  const initialTime = useRef(2000)
  const retrys = useRef(0)
  const submitButtonRef = useRef<HTMLButtonElement>(null)
  const [Goals, setGoals] = useState<IGoals[]>()
  const dreamListIds = plansDreams?.map((item) => item.sonhoId)
  const [PontosFortesIds, setPontosFortesIds] = useState<string[]>([])
  const [DesafiosIds, setDesafiosIds] = useState<string[]>([])
  const [PrioridadesIds, setPrioridadesIds] = useState<string[]>([])

  const {
    getDreamImageByDreamIdAsync,
    dreamImageList,
    isLoadingGetDreamImageByDreamId,
    isSuccessGetDreamImageByDreamId,
    isErrorGetDreamImageByDreamId
  } = useMutationDreamImageListByDreamId()

  const pontosFortes = xRaySummary?.find((item) => item.groupName === 'Pontos Fortes')
  const desafios = xRaySummary?.find((item) => item.groupName === 'Desafios')
  const prioridades = xRaySummary?.find((item) => item.groupName.trim() === 'Prioridades')

  const {
    getDiagnosticByIdData,
    getDiagnosticByIdAsync,
    getDiagnosticByIdSuccess,
    resetGetDiagnosticById,
    isLoadingGetDiagnosticById
  } = useGetDiagnosticById()

  const {
    postCreateDiagnosticAsync,
    isLoadingPostCreateDiagnostic,
    isErrorPostCreateDiagnostic,
    errorPostCreateDiagnostic,
    postCreateDiagnosticSuccess,
    resetPostCreateDiagnostic,
    postCreateDiagnosticData
  } = usePostCreateDiagnostic()

  const isLoading =
    isLoadingPostCreateDiagnostic ||
    isLoadingGetDiagnosticById ||
    tryingGetDiagnosticById ||
    isLoadingPlansDreams ||
    isLoadingGetDreamImageByDreamId

  const { allEquitys } = useGetAllEquitys({
    familyId,
    params: {
      startDate,
      endDate
    }
  })
  const { fixedAsset, investments } = allEquitys || {}

  const orcamentoDespesasReceitas = useMemo(
    () => orcamentoPrevistoRealizado?.filter((item) => item.nome === 'Despesas' || item.nome === 'Receitas'),
    [orcamentoPrevistoRealizado]
  )
  const orcamentoDespesas = useMemo(
    () => orcamentoPrevistoRealizado?.find((item) => item.nome === 'Despesas'),
    [orcamentoPrevistoRealizado]
  )
  const orcamentoReceitas = useMemo(
    () => orcamentoPrevistoRealizado?.find((item) => item.nome === 'Receitas'),
    [orcamentoPrevistoRealizado]
  )
  const orcamentoDividas = useMemo(
    () => orcamentoPrevistoRealizado?.find((item) => item.nome === 'Dívidas'),
    [orcamentoPrevistoRealizado]
  )
  const orcamentoInvestimentos = useMemo(
    () => orcamentoPrevistoRealizado?.find((item) => item.nome === 'Investimentos'),
    [orcamentoPrevistoRealizado]
  )
  const investmentsReal = investments?.reduce((total, item) => total + item.totalAmount, 0) || 0
  const fixedAssetTotal = fixedAsset?.reduce((total, item) => total + item.value, 0) || 0
  const orcamentoDebtsRealTotal = orcamentoDividas?.dividasTotalDebitos || 0
  const filteredOrcamentoPeriodo = postingOrcamentoPeriodoData
    ?.filter((item) => [1, 3].includes(item.tipoOrcamento))
    .filter((item) => item.total && item.totalMedio)
    .sort((a, b) => a.totalMedio - b.totalMedio)
    .reverse()
    .slice(0, 6)

  const topExpensesTotals = useMemo(
    () =>
      filteredOrcamentoPeriodo?.reduce(
        (acc, item) => {
          return {
            idealValue: acc.idealValue + item.estimado,
            actualValue: acc.actualValue + item.totalMedio
          }
        },
        { idealValue: 0, actualValue: 0 }
      ),
    [filteredOrcamentoPeriodo]
  )

  const topExpenses = useMemo(
    () => ({
      ActualValue: topExpensesTotals?.actualValue,
      Categories: filteredOrcamentoPeriodo?.map((item) => ({
        Name: item.nomeCategoria,
        Percentage: Math.floor(
          Number(((item.totalMedio / topExpensesTotals?.actualValue) * 100)?.toFixed(2))
        ),
        IdealValue: item.estimado,
        ActualValue: item.totalMedio
      }))
    }),
    [filteredOrcamentoPeriodo, topExpensesTotals]
  )

  const CHECKBOX_MAP_SET = {
    PontosFortes: setPontosFortesIds,
    Desafios: setDesafiosIds,
    Prioridades: setPrioridadesIds
  }
  const CHECKBOX_MAP_VALUE = {
    PontosFortes: PontosFortesIds,
    Desafios: DesafiosIds,
    Prioridades: PrioridadesIds
  }

  const handleCheckboxOptionsControl = (name: string, { id, checked }: { id: string; checked: boolean }) => {
    if (checked) {
      CHECKBOX_MAP_SET[name]([...CHECKBOX_MAP_VALUE[name], id])
    } else {
      const list = CHECKBOX_MAP_VALUE[name]?.filter((itemId) => id !== itemId)
      CHECKBOX_MAP_SET[name](list)
    }
  }

  const formatDate = (date: string | null | undefined): string => {
    if (!date) return 'Data inválida!'
    return format(new Date(date), 'dd/MM/yyyy')
  }

  const plansDreamsListWithImage = useMemo(
    () =>
      plansDreams?.map((item, index) => ({
        ...item,
        dreamImage: dreamImageList?.[index] || defaultDreamImage
      })),
    [dreamImageList, plansDreams]
  )

  const getGoals = useCallback(() => {
    if (!plansDreams || !dreamImageList) return

    const goals = plansDreams?.map((item, index) => ({
      Dream: item.descricao || '',
      Image: dreamImageList?.[index] || defaultDreamImage,
      Value: item.valorEstimadoPresente || 0,
      AccumulatedValue: item.realizado || 0,
      FuturePercentage: item.percentualRealizadoFuturo || 0,
      FutureValue: item.valorEstimadoFuturo || 0,
      Installment: item.prazo || 0,
      InstallmentValue: item.parcela || 0,
      Month: getMonth(new Date(item.dataFinal)),
      Year: getYear(new Date(item.dataFinal)),
      IsPlan: item.tipo === 'Plano',
      PriorityOrder: item.ordemPrioridade,
      StatusId: item.statusSonho
    }))
    setGoals(goals)
  }, [dreamImageList, plansDreams])

  const {
    control,
    handleSubmit,
    formState: { isValid, isSubmitting, errors }
  } = useForm<IFormCreateDiagnostic>({
    mode: 'all',
    defaultValues: {
      ...defaultValues,
      FamilyId: familyId || 0,
      StartDate: startDate || undefined,
      EndDate: endDate || undefined
    }
  })

  const isDisabled = useMemo(() => isLoading || isSubmitting || !isValid, [isLoading, isSubmitting, isValid])

  const onSubmit: SubmitHandler<IFormCreateDiagnostic> = useCallback(
    async (data) => {
      if (!familyId) {
        noticeError(new Error('Erro ao criar diagnóstico, família não encontrada'))
        return
      }

      const orcamentoReceitaEstimado = orcamentoReceitas?.estimado || 0
      const orcamentoReceitaReal = orcamentoReceitas?.totalMedio || 0
      const orcamentoDespesaEstimado = orcamentoDespesas?.estimado || 0
      const orcamentoDespesaReal = orcamentoDespesas?.totalMedio || 0
      const orcamentoDividaEstimado = orcamentoDividas?.estimado || 0
      const orcamentoDividaReal = orcamentoDividas?.totalMedio || 0
      const orcamentoInvestimentoEstimado = orcamentoInvestimentos?.estimado || 0
      const orcamentoInvestimentoReal = orcamentoInvestimentos?.totalMedio || 0

      const Budgets = [
        {
          Name: 'Ideal',
          Expense: orcamentoDespesaEstimado,
          Income: orcamentoReceitaEstimado,
          Debt: orcamentoDividaEstimado,
          Investment: orcamentoInvestimentoEstimado,
          total:
            orcamentoReceitaEstimado -
            orcamentoInvestimentoEstimado -
            orcamentoDespesaEstimado -
            orcamentoDividaEstimado
        },
        {
          Name: 'Real',
          Expense: orcamentoDespesaReal,
          Income: orcamentoReceitaReal,
          Debt: orcamentoDividaReal,
          Investment: orcamentoInvestimentoReal,
          total: orcamentoReceitaReal - orcamentoInvestimentoReal - orcamentoDespesaReal - orcamentoDividaReal
        }
      ]

      const FamilyAssets = {
        TotalDebts: orcamentoDebtsRealTotal,
        TotalFinancialAssets: investmentsReal,
        TotalFixedAssets: fixedAssetTotal,
        TotalNetAssets: investmentsReal + fixedAssetTotal - orcamentoDebtsRealTotal
      }

      let Whitelabel: IWhiteLabel | undefined
      if (theme?.isWhiteLabel) {
        Whitelabel = {
          Logo: theme?.externalUrlLogo,
          PrimaryColor: theme?.colors?.primary,
          SecondaryColor: theme?.colors?.secondary,
          PrimaryDarkerColor: theme?.colors?.primaryDarker,
          SecondaryDarkerColor: theme?.colors?.secondaryDarker,
          primaryLighterColor: getLighter(theme?.colors?.primaryLighter, theme?.colors?.primary),
          SecondaryLighterColor: getLighter(theme?.colors?.secondaryLighter, theme?.colors?.secondary)
        }
      }

      const {
        Asset,
        BudgetObservation,
        ChallengeComment,
        EndDate,
        FamilyId,
        PatrimonyObservation,
        PriorityComment,
        StartDate,
        StrongPointComment
      } = data

      const newData = {
        Asset,
        Budgets,
        EndDate,
        FinancialAsset: {
          value: investmentsReal
        },
        FixedAsset: {
          value: fixedAssetTotal
        },
        Debts: {
          value: orcamentoDebtsRealTotal || 0
        },
        FamilyAssets,
        Expenses: topExpenses,
        FinancialDiagnosticOptionIds: formatCheckboxesValues([
          ...PontosFortesIds,
          ...DesafiosIds,
          ...PrioridadesIds
        ]),
        Goals,
        Whitelabel,
        FamilyId,
        StartDate,
        BudgetObservation: formatTextareaValues(BudgetObservation),
        PatrimonyObservation: formatTextareaValues(PatrimonyObservation),
        PriorityComment: formatTextareaValues(PriorityComment),
        StrongPointComment: formatTextareaValues(StrongPointComment),
        ChallengeComment: formatTextareaValues(ChallengeComment)
      }

      postCreateDiagnosticAsync(newData)
    },
    [
      familyId,
      orcamentoReceitas?.estimado,
      orcamentoReceitas?.totalMedio,
      orcamentoDespesas?.estimado,
      orcamentoDespesas?.totalMedio,
      orcamentoDividas?.estimado,
      orcamentoDividas?.totalMedio,
      orcamentoInvestimentos?.estimado,
      orcamentoInvestimentos?.totalMedio,
      orcamentoDebtsRealTotal,
      investmentsReal,
      fixedAssetTotal,
      theme?.isWhiteLabel,
      theme?.externalUrlLogo,
      theme?.colors?.primary,
      theme?.colors?.secondary,
      theme?.colors?.primaryDarker,
      theme?.colors?.secondaryDarker,
      theme?.colors?.primaryLighter,
      theme?.colors?.secondaryLighter,
      topExpenses,
      PontosFortesIds,
      DesafiosIds,
      PrioridadesIds,
      Goals,
      postCreateDiagnosticAsync
    ]
  )

  useEffect(() => {
    if (showCreateDiagnostic && familyId && startDate && endDate) {
      postTipoOrcamentoPrevistoRealizadoAsync({
        familyId: familyId || undefined,
        filter: {
          DataInicial: startDate,
          DataFinal: endDate
        }
      })
      postOrcamentoPeriodoAsync({
        dataInicial: startDate,
        dataFinal: endDate,
        familyId: familyId || undefined
      })
    }
  }, [
    endDate,
    familyId,
    postOrcamentoPeriodoAsync,
    postTipoOrcamentoPrevistoRealizadoAsync,
    showCreateDiagnostic,
    startDate
  ])

  useEffect(() => {
    if (showCreateDiagnostic) {
      if (familyId) getPlansDreamsByFamilyId({ familyId: familyId })
    }
  }, [familyId, getPlansDreamsByFamilyId, showCreateDiagnostic])

  useEffect(() => {
    if (isErrorPostCreateDiagnostic) {
      const errorMessage = String(errorPostCreateDiagnostic?.message || 'Erro ao criar diagnostico')
      noticeError(new Error(errorMessage))
      showAlert('Erro', errorMessage, () => resetPostCreateDiagnostic())
    }
  }, [errorPostCreateDiagnostic, isErrorPostCreateDiagnostic, resetPostCreateDiagnostic])

  // Verifica se o diagnostico foi criado com sucesso e requisita os dados
  useEffect(() => {
    let timeout: NodeJS.Timeout
    if (postCreateDiagnosticSuccess && postCreateDiagnosticData) {
      setTryingGetDiagnosticById(true)
      timeout = setTimeout(() => {
        getDiagnosticByIdAsync({ diagnosticId: postCreateDiagnosticData })
      }, 4000)
    }

    return () => {
      clearTimeout(timeout)
    }
  }, [
    familyId,
    getDiagnosticByIdAsync,
    modalCreateDiagnostic,
    modalDiagnosticsRefecth,
    postCreateDiagnosticData,
    postCreateDiagnosticSuccess,
    resetPostCreateDiagnostic
  ])

  useEffect(() => {
    let timeout: NodeJS.Timeout
    if (getDiagnosticByIdSuccess && tryingGetDiagnosticById) {
      const { status, pdfUrl } = getDiagnosticByIdData || {}

      if (retrys.current >= 50) {
        setTryingGetDiagnosticById(false)
        showAlert(
          'Erro',
          'Ocorreu um erro ao gerar o diagnóstico, por favor tente novamente mais tarde.',
          () => {
            resetPostCreateDiagnostic()
            resetGetDiagnosticById()
            initialTime.current = 2000
            retrys.current = 0
          }
        )
        return
      }
      if ((!status || !pdfUrl) && !isLoadingGetDiagnosticById) {
        timeout = setTimeout(() => {
          getDiagnosticByIdAsync({ diagnosticId: postCreateDiagnosticData })
          if (initialTime.current <= 5000) {
            initialTime.current = initialTime.current + 1000
          }
          retrys.current = retrys.current + 1
        }, initialTime.current)
      }
      if (status && pdfUrl) {
        setTryingGetDiagnosticById(false)
      }
    }

    return () => {
      clearTimeout(timeout)
    }
  }, [
    isLoadingGetDiagnosticById,
    getDiagnosticByIdAsync,
    getDiagnosticByIdData,
    getDiagnosticByIdSuccess,
    postCreateDiagnosticData,
    tryingGetDiagnosticById,
    resetPostCreateDiagnostic,
    resetGetDiagnosticById
  ])

  // Caso retorne os dados do diagnostico, abre o PDF em uma nova aba
  useEffect(() => {
    if (getDiagnosticByIdSuccess && !tryingGetDiagnosticById) {
      const { status, pdfUrl } = getDiagnosticByIdData || {}

      if ((!status || !pdfUrl) && !isLoadingGetDiagnosticById) {
        console.log('Erro ao gerar relatorio')
        return
      }

      if (pdfUrl && status) {
        PDFOpen({
          pdfUrl,
          onSucces: () => {
            showAlert('Diagnóstico criado com sucesso', '', () => {
              modalCreateDiagnostic(false)
              modalDiagnosticsRefecth(true)
              resetPostCreateDiagnostic()
              resetGetDiagnosticById()
            })
          },
          onError: () => console.log('Erro ao baixar o PDF:', pdfUrl)
        })
      } else {
        noticeError(new Error('Não foi possível gerar o relatório'))
        showAlert('Ocorreu um erro, por favor tente novamente', '', () => {
          resetPostCreateDiagnostic()
        })
      }
    }
  }, [
    getDiagnosticByIdData,
    getDiagnosticByIdSuccess,
    isLoadingGetDiagnosticById,
    modalCreateDiagnostic,
    modalDiagnosticsRefecth,
    resetGetDiagnosticById,
    resetPostCreateDiagnostic,
    tryingGetDiagnosticById
  ])

  useEffect(() => {
    getGoals()
  }, [getGoals])

  useEffect(() => {
    if (
      dreamListIds &&
      !isSuccessGetDreamImageByDreamId &&
      !isLoadingGetDreamImageByDreamId &&
      !isErrorGetDreamImageByDreamId
    )
      getDreamImageByDreamIdAsync(dreamListIds)
  }, [
    dreamListIds,
    getDreamImageByDreamIdAsync,
    isErrorGetDreamImageByDreamId,
    isLoadingGetDreamImageByDreamId,
    isSuccessGetDreamImageByDreamId
  ])

  return {
    allEquitys,
    control,
    desafios,
    Errors: errors,
    filteredOrcamentoPeriodo,
    fixedAssetTotal,
    formatDate,
    getDiagnosticByIdData,
    getDiagnosticByIdSuccess,
    Goals,
    handleSubmit,
    investmentsReal,
    isDisabled,
    isLoading,
    isValid,
    modalCreateDiagnostic,
    onSubmit,
    orcamentoDebtsRealTotal,
    orcamentoDespesas,
    orcamentoDespesasReceitas,
    orcamentoDividas,
    orcamentoInvestimentos,
    orcamentoPrevistoRealizado,
    orcamentoReceitas,
    pontosFortes,
    postCreateDiagnosticData,
    postCreateDiagnosticSuccess,
    prioridades,
    submitButtonRef,
    topExpenses,
    topExpensesTotals,
    tryingGetDiagnosticById,
    startDate,
    endDate,
    showCreateDiagnostic,
    plansDreamsListWithImage,
    handleCheckboxOptionsControl
  }
}
