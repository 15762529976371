import { useQuery } from 'react-query'

import { getAllPessoasByFamily } from '../../domain/pessoa/api/getAllPessoasByFamily'
import { getPessoaById } from '../../domain/pessoa/api/getPessoaById'
import { Pessoa } from '../../domain/pessoa/Pessoa'
import { QueryOptions } from '../queryOptions'

export const cacheBaseKey = 'pessoa'

export const useCurrentPessoaQuery = (personId: number, options?: QueryOptions<Pessoa | undefined>) =>
  useQuery<unknown, Error, Pessoa | undefined>(
    [cacheBaseKey, personId],
    () => getPessoaById({ personId }),
    options
  )

export const useCurrentFamilyPessoasQuery = (familyId?: number, options?: QueryOptions<Pessoa[]>) => {
  const { data: pessoas } = useQuery<unknown, Error, Pessoa[]>(
    [cacheBaseKey, 'family', familyId],
    () => {
      if (!familyId) throw new Error('familyId is required')
      return getAllPessoasByFamily({ familyId })
    },
    options
  )
  return { pessoas: pessoas || [] }
}
