import { useQuery } from 'react-query'
import { QueryOptions } from 'react-query'

import {
  getBillingsByPlannerId,
  getLonelyPlanners,
  getPlannersByCompanyId,
  listPlanners,
  SimplifiedPlannerList
} from '../../domain/planner'
import {
  Params as GetBillingsByPlannerIdParams,
  Response as GetBillingsByPlannerIdResponse
} from '../../domain/planner/api/getBillingsByPlannerId'
import {
  Params as GetLonelyPlannersParams,
  Response as GetLonelyPlannersResponse
} from '../../domain/planner/api/getLonelyPlanners'
import {
  Params as GetPlannersByCompanyIdParams,
  Response as GetPlannersByCompanyIdResponse
} from '../../domain/planner/api/getPlannersByCompanyId'

const cacheBaseKey = 'planner'

type UsePlannerBillingParams = {
  options?: QueryOptions<GetBillingsByPlannerIdResponse, Error>
} & GetBillingsByPlannerIdParams

export const usePlannerBilling = ({
  plannerId,
  pageIndex,
  pageSize,
  desc = true,
  orderBy,
  includeDeleted = true,
  search,
  filterDate,
  options
}: UsePlannerBillingParams) => {
  const {
    data: billings,
    isError: isBillingsError,
    isLoading: isLoadingBillings,
    error: billingsError,
    refetch: refetchBillingsByPlannerId
  } = useQuery(
    [
      cacheBaseKey,
      plannerId,
      'billings',
      pageIndex,
      pageSize,
      desc,
      orderBy,
      includeDeleted,
      search,
      filterDate
    ],
    () =>
      getBillingsByPlannerId({
        plannerId,
        pageIndex,
        pageSize,
        desc,
        orderBy,
        includeDeleted,
        search,
        filterDate
      }),
    options
  )

  return {
    billings,
    billingsError,
    isBillingsError,
    isLoadingBillings,
    refetchGetBillingsByPlannerId: refetchBillingsByPlannerId
  }
}

export const usePlannerList = (
  search?: string,
  includeDeleted?: boolean,
  orderBy?: string,
  desc?: boolean,
  pageIndex?: number,
  pageSize?: number,
  options?: QueryOptions<SimplifiedPlannerList, Error>
) => {
  const {
    data: plannersList,
    isError: isPlannersListError,
    isLoading: isLoadingPlannersList,
    error: plannersListError,
    refetch: refetchPlannersList
  } = useQuery(
    [cacheBaseKey, 'list', search, includeDeleted, orderBy, desc, pageIndex, pageSize],
    () => listPlanners(search, includeDeleted, orderBy, desc, pageIndex, pageSize),
    options
  )

  return {
    plannersList,
    isPlannersListError,
    isLoadingPlannersList,
    plannersListError,
    refetchPlannersList
  }
}

type UsePlannerByCompanyIdParams = {
  options?: QueryOptions<GetPlannersByCompanyIdResponse, Error>
} & GetPlannersByCompanyIdParams

export const usePlannersByCompanyId = ({
  companyId,
  desc = true,
  options,
  orderBy,
  pageIndex,
  pageSize,
  search,
  filterDate
}: UsePlannerByCompanyIdParams) => {
  const {
    data: planners,
    isError: isPlannersError,
    isLoading: isLoadingPlanners,
    error: plannersError,
    refetch: refetchGetPlannersByCompanyId
  } = useQuery(
    [cacheBaseKey, companyId, 'list', desc, orderBy, pageIndex, pageSize, search, filterDate],
    () => getPlannersByCompanyId({ companyId, desc, orderBy, pageIndex, pageSize, search, filterDate }),
    options
  )

  return {
    planners,
    isPlannersError,
    isLoadingPlanners,
    plannersError,
    refetchGetPlannersByCompanyId
  }
}

type UseLonelyPlannersParams = {
  options?: QueryOptions<GetLonelyPlannersResponse, Error>
} & GetLonelyPlannersParams

export const useLonelyPlanners = ({
  desc = true,
  options,
  orderBy,
  pageIndex,
  pageSize,
  search,
  filterDate
}: UseLonelyPlannersParams) => {
  const {
    data: lonelyPlanners,
    isError: isLonelyPlannersError,
    isLoading: isLoadingLonelyPlanners,
    error: LonelyPlannersError,
    refetch: refetchGetLonelyPlanners
  } = useQuery(
    [cacheBaseKey, 'lonelyPlanners', desc, orderBy, pageIndex, pageSize, search, filterDate],
    () => getLonelyPlanners({ desc, orderBy, pageIndex, pageSize, search, filterDate }),
    options
  )

  return {
    lonelyPlanners,
    isLonelyPlannersError,
    isLoadingLonelyPlanners,
    LonelyPlannersError,
    refetchGetLonelyPlanners
  }
}
