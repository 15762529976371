import 'react-confirm-alert/src/react-confirm-alert.css'

import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { Col, Row } from 'reactstrap'

import {
  getOperation,
  OPERATION_OPTIONS
} from '../../../../../../domain/investments/api/ManualInvestments/constants'
import { useTransactionContext } from '../../../../../../hooks/contexts/transactionContext'
import { CurrencyParceledInput } from '../../../../../Inputs/InputAmountV2'
import { FieldSelect } from '../../../../../Selects/FieldSelect'
import { FieldSelectDate } from '../../../../../Selects/FieldSelectDate'
import { FieldSelectFinancialInstitutions } from '../../../../../Selects/FieldSelectFinancialInstitutions'
import { FieldSelectPatrimonioFinanceiroManual } from '../../../../../Selects/FieldSelectPatrimonioFinanceiroManual'
import { TIPO_ORCAMENTO_NAME_CODE, Transaction } from '../../SaveDataTransaction/types'
import { useSaveTransactionsActions } from '../../SaveDataTransaction/useSaveTransactionAction'
import { ConfirmOperation } from './ConfirmOperation'

export const FormManualInvestment = () => {
  const { SaveButton } = useSaveTransactionsActions()
  const { transaction, setTransaction } = useTransactionContext()
  const [item, setStateItem] = useState<Transaction | undefined>({
    ...transaction,
    tipoOrcamento: TIPO_ORCAMENTO_NAME_CODE.Investimento,
    enableBudgetManager: !transaction?.id || transaction?.enableBudgetManager
  } as Transaction)
  const [validForm, setValidForm] = useState(false)
  const { isInvesting } = getOperation(item?.operacao)
  const isEditing = !item?.id || item?.pluggyInvestmentId

  const setItem = (data, callback?: () => void) => {
    setStateItem({
      ...item,
      ...data
    })
    if (callback) {
      setTimeout(() => {
        callback?.()
      }, 100)
    }
  }

  const validateForm = useCallback(() => {
    const { operacao, patrimonioAtivoId, pluggyInvestmentId, valor } = item || {}
    if (!item) {
      console.log('item not found')
      return
    }

    const basicFields = typeof operacao === 'number' && !!valor && !!(patrimonioAtivoId || pluggyInvestmentId)

    if (!basicFields) {
      setValidForm(false)
      return
    }

    setValidForm(true)
  }, [item])

  useEffect(() => {
    validateForm()
  }, [item, validateForm])

  useEffect(() => {
    const _transaction = transaction || {}
    setItem({
      ..._transaction,
      data: transaction?.data ?? moment(new Date(), 'DD-MM-YYYY').toDate(),
      tipoOrcamento: TIPO_ORCAMENTO_NAME_CODE.Investimento
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (validForm) {
      setTransaction(item)
    }
  }, [item, setTransaction, validForm])

  return (
    <Row id="formManualInvestment">
      <FieldSelect
        label="Operação *"
        id="lancamentoOperacao"
        options={OPERATION_OPTIONS}
        onChange={({ value }) => {
          setItem({
            operacao: value
          })
        }}
        selectedValue={item?.operacao ?? undefined}
        afterContent={
          <ConfirmOperation
            defaultChecked={isEditing ? item?.enableBudgetManager : true}
            updateState={(enableBudgetManager) =>
              setItem({
                enableBudgetManager
              })
            }
            value={item?.enableBudgetManager}
          />
        }
      />

      <FieldSelectPatrimonioFinanceiroManual
        // @ts-expect-error //null
        selectedId={item?.pluggyInvestmentId ?? item?.patrimonioId}
        onSelect={(patrimonio) => {
          if (patrimonio) {
            setItem({
              patrimonio: patrimonio?.value,
              patrimonioId: patrimonio?.value?.id,
              patrimonioAtivoId: patrimonio?.value?.id
            })
          }
        }}
        // @ts-expect-error //null
        defaultValue={item?.patrimonio?.id ?? item?.pluggyInvestmentId ?? item?.patrimonioId}
      />

      <FieldSelectFinancialInstitutions
        onSelect={({ value }) => {
          setItem({
            idInstituicaoFinanceira: value?._id,
            financialInstitutionCode: value?.key
          })
        }}
        defaultValue={item?.idInstituicaoFinanceira ?? item?.financialInstitutionCode}
      />

      <CurrencyParceledInput
        label={`Valor ${item?.operacao === 0 ? 'aplicado' : 'resgatado'}`}
        partialData={{
          id: item?.id,
          valor: item?.valor,
          parcelas: item?.parcelas,
          valorParcela: item?.valorParcela,
          tipoDeParcela: item?.tipoDeParcela
        }}
        onChange={(data) => {
          setItem(data)
          return
        }}
      />

      <FieldSelectDate
        label={`Data da ${isInvesting ? 'Compra' : 'Venda'} *`}
        id="lancamentoData"
        onChange={(date: Date) => {
          setItem({
            data: date
          })
        }}
        selectedValue={item?.data}
      />

      <Col lg="6" xl="6">
        <div>
          <label>Descrição</label>
          <textarea
            id="lancamentoOQue"
            onChange={(e) => {
              setItem({
                descricao: e.target.value
              })
            }}
            value={item?.descricao}
            style={{ padding: 20 }}
          ></textarea>
        </div>
      </Col>

      <Col lg="12" xl="12" style={{ display: 'flex', justifyContent: 'center' }}>
        <SaveButton
          transaction={item}
          disabled={!validForm || !item}
          // beforeSave={() => setTransaction(item)}
        />
      </Col>
    </Row>
  )
}
