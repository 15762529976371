import React from 'react'
import styled from 'styled-components'

import bars from '../../assets/img/theme/bars.png'
import Icon from './Icon'

interface Props {
  onClick?: () => void
  tooltip?: string
}

const StyledIcon = styled(Icon)`
  max-width: 24px;
  max-height: 24px;
`

export default ({ onClick, tooltip }: Props) => (
  <StyledIcon alt="Colunas" src={bars} onClick={onClick} tooltip={tooltip} />
)
