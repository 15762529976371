import React, { useState } from 'react'

import { SimplifiedOrcamentoWithCategory, TipoOrcamento } from '../../../domain/orcamento/Orcamento'
import { useCurrentFamilyId } from '../../../hooks'
import { useFamilyOrcamentosForTable } from '../../../queries/orcamento/queries'
import { SelectAutoComplete } from '../../Mui/SelectAutoComplete'

interface Props {
  onSelect: (newOrcamento: SimplifiedOrcamentoWithCategory) => void
  selectedId?: number
  defaultPlaceholder?: string
  type?: TipoOrcamento
  size?: 'small' | 'medium'
}

export const BudgetSelect = ({ selectedId, type, defaultPlaceholder, onSelect, size = 'medium' }: Props) => {
  const [filter, setFilter] = useState('')
  const { familyId } = useCurrentFamilyId()
  const { orcamentos, isLoadingOrcamentos } = useFamilyOrcamentosForTable(familyId)

  const options =
    orcamentos
      ?.map((o) => ({
        value: o,
        label: o.nome
      }))
      .filter(({ label }) => label?.toLowerCase().includes(filter?.toLowerCase()))
      .filter(({ value: { tipo } }) => type === undefined || tipo === type) ?? []
  const placeholder =
    orcamentos?.find(({ id }) => id === selectedId)?.nome || defaultPlaceholder || 'Selecione...'

  return (
    <SelectAutoComplete<{ label: string; value: SimplifiedOrcamentoWithCategory }>
      options={options}
      isLoading={isLoadingOrcamentos}
      placeholder={placeholder}
      onChange={(option) => {
        setFilter('')
        onSelect(option?.value as SimplifiedOrcamentoWithCategory)
      }}
      size={size}
    />
  )
}
