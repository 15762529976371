import React, { useState } from 'react'

import { getLoadingInvestmentsByItemId } from '../../../domain/investments'
import { useNumericLocalStorage } from '../../../hooks'
import { useRequiredAuth } from '../../../hooks/useAuth'
import { useInvestmentsByItemIdQuery } from '../../../queries/investments/queries'
import { noticeError } from '../../../services/Monitoring'
import Button from '../../Buttons'
import Table from '../Table'
import { useColumns } from './columns'
import { ContainerTable, ErrorContainer } from './InvestmentsByItemIdTable.styles'

interface Props {
  itemId: string
  widthBeforeHorizontalScroll?: number
}

const loadingData = getLoadingInvestmentsByItemId()

export const InvestmentsByItemIdTable = ({ itemId, widthBeforeHorizontalScroll }: Props) => {
  const token = useRequiredAuth()
  const [page, setPage] = useState(0)
  const { value: pageSize, setValue: setPageSize } = useNumericLocalStorage(
    'investments-by-item-id-page-size',
    10
  )

  const {
    investmentsByItemId,
    isInvestmentsByItemIdError,
    isLoadingInvestmentsByItemId,
    investmentsByItemIdError,
    refetchGetInvestmentsByItemId
  } = useInvestmentsByItemIdQuery({ itemId, pageIndex: page, pageSize })

  const columns = useColumns()

  if (isInvestmentsByItemIdError) {
    noticeError(investmentsByItemIdError, { token })
    return (
      <ErrorContainer>
        <br /> Erro ao carregar tabela :c <br /> Tente novamente: <br />
        <Button text="Tentar novamente." onClick={() => refetchGetInvestmentsByItemId()} />
      </ErrorContainer>
    )
  }

  return (
    <ContainerTable className="InvestmentsByItemIdTable">
      <Table
        title=" "
        data={investmentsByItemId || []}
        columns={columns}
        isLoading={isLoadingInvestmentsByItemId}
        loadingData={loadingData}
        filterEnabled={false}
        pageable={true}
        pageIndex={page}
        pageSize={pageSize}
        totalPages={investmentsByItemId?.length}
        onNextPage={() => setPage(page + 1)}
        onPreviousPage={() => setPage(page - 1)}
        onPageSizeChange={setPageSize}
        sortable={false}
        downloadFileName={`Investments-Conexão-${new Date().toISOString().split('T')[0]}`}
        widthBeforeHorizontalScroll={widthBeforeHorizontalScroll || 1382}
      />
    </ContainerTable>
  )
}
