import React from 'react'
import styled from 'styled-components'

import arrow from '../../assets/img/theme/expandArrow.png'
import Icon from './Icon'

interface Props {
  isExpanded: boolean
  onClick?: () => void
  tooltip?: string
}

const StyledContainer = styled.a`
  align-items: center;
  background-color: rgba(255, 255, 255, 0);
  justify-content: center;
  display: flex;
  border-radius: 50%;
  border: none;
  text-decoration: none;
  height: 35px;
  width: 35px;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: rgb(240 239 239 / 67%);
  }

  img {
    margin: 0;
  }
`

const StyledIcon = styled(Icon)<{ isExpanded: boolean }>`
  height: 1rem;
  width: 1rem;
  transform: ${({ isExpanded }) => (isExpanded ? 'rotate(90deg)' : 'none')};
  transition: ${({ isExpanded }) => (isExpanded ? '0.5s' : '0s')};
`

export default ({ isExpanded, onClick, tooltip }: Props) => (
  <StyledContainer
    onClick={(event) => {
      event.preventDefault()
      onClick?.()
    }}
  >
    <StyledIcon isExpanded={isExpanded} onClick={onClick} tooltip={tooltip} alt="Expandir" src={arrow} />
  </StyledContainer>
)
// <StyledIcon
//   isExpanded={isExpanded}
//   onClick={onClick}
//   tooltip={tooltip}
//   alt="Expandir"
//   src={arrow}
//   noMargin
// />
// )
