import React from 'react'
import Datepicker from 'react-datepicker'
import MaskedInput from 'react-maskedinput'

export const createDatePicker = (id, callback, defaultDate, _state, updateState, _moment) => {
  let currentDate = null
  const { item: _item } = _state

  //date type
  if (_item != null && _item[id] != null) {
    //string vinda da edicao
    if (typeof _item[id] === 'string') {
      const temp = _moment(_item[id], 'YYYY-MM-DDThh:mm:ss')
      const item = { ..._item }
      item[id] = temp
      updateState({ item })
      currentDate = temp.toDate()
    }
    //data vinda da selecao atraves do calendario
    else {
      currentDate = _item[id]
    }
  }
  //momentjs date
  else if (defaultDate != null) {
    const item = { ..._item }
    item[id] = defaultDate.toDate()
    updateState({ item })
  }
  //now (date)
  else {
    const item = { ..._item }
    item[id] = new Date()
    updateState({ item })
  }

  return (
    <Datepicker
      locale="br"
      id={id}
      dateFormat="dd/MM/yyyy"
      selected={currentDate}
      showYearDropdown
      dateFormatCalendar="MMMM"
      scrollableYearDropdown
      yearDropdownItemNumber={5}
      onChange={(date) => callback(id, date)}
      customInput={<MaskedInput mask="11/11/1111" />}
    />
  )
}
