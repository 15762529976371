import React, { useState } from 'react'

import { useUpdateInvestmentById } from '../../../../queries/investments/mutations'
import { InvestmentValueConfirmModal } from '../../../Modal'
import { EditableMonetaryInput } from '..'

interface Props {
  name: string
  currentValue: number
  investmentId: string
}

export const InvestmentValueInput = ({ currentValue, investmentId, name }: Props) => {
  const { isLoadingUpdateInvestment, updateInvestment } = useUpdateInvestmentById()
  const [newValue, setNewValue] = useState(currentValue)
  const isConfirmModalOpen = currentValue !== newValue
  const [inputIdentifier, setInputIdentifier] = useState(0)

  const onCancel = () => {
    setNewValue(currentValue)
    setInputIdentifier(inputIdentifier + 1)
  }

  return (
    <>
      {isConfirmModalOpen && (
        <InvestmentValueConfirmModal
          name={name}
          confirmDisabled={isLoadingUpdateInvestment}
          newValue={newValue}
          originalValue={currentValue}
          onCancel={onCancel}
          onConfirm={() => {
            updateInvestment({ pluggyInvestmentId: investmentId, amountOriginal: newValue })
            setNewValue(currentValue)
          }}
        />
      )}
      <EditableMonetaryInput
        key={inputIdentifier}
        loading={isLoadingUpdateInvestment}
        initialInputValue={currentValue}
        placeholder="Valor do investimento"
        onEdit={setNewValue}
      />
    </>
  )
}
