import StarIcon from '@mui/icons-material/Star'
import StarOutlineIcon from '@mui/icons-material/StarOutline'
import { IconButton } from '@mui/material'
import React, { useRef } from 'react'
import styled from 'styled-components'

import { Note } from '../../../domain/Notes/Notes'
import { useOnScrollEnd } from '../../../hooks'
import { useCurrentFamilyId } from '../../../hooks/useCurrentFamilyId'
import { useListOfNotes } from '../../../queries/Notes/queries'
import LoadingCircleBackground from '../../Loading'
import DateAndTime from './DateAndTime'

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
  &::-webkit-scrollbar-track-piece {
    background-color: #64646416;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #64646432;
    border-radius: 16px;
  }
`

const NoteContainer = styled.div<{ isSelected?: boolean }>`
  transition: all 0.3s ease-in-out;
  width: 100%;
  border-bottom: solid 2px #64646440;
  ${({ isSelected, theme }) =>
    isSelected
      ? `
      border-left: solid 6px #64646464;
      `
      : `
      border-left: solid 6px ${theme.colors.primary};
      `}
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0px;
  padding: 8px 16px 8px 16px;
  cursor: pointer;
  /* overflow-y: auto; */

  &:hover {
    background: ${({ theme }) => theme.colors.primary}12;
    border-left: solid 6px #64646464;
  }
`

const NoteTitle = styled.h3`
  font-size: 20px;
  font-weight: 600;
  color: black;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
`

const NoteName = styled.p`
  transition: all 0.3s ease-in-out;
  font-weight: 500;
  font-size: 14px;
`

// const StarIconContainer = styled.div`
//   width: 24px;
// `

const NoResultsMessage = styled.h3`
  width: 100%;
  text-align: center;
  padding: 16px;
  font-weight: 700;
  color: #808080;
  font-size: 20px;
`

const StarIconButton = styled(IconButton)<{ isFavorite: boolean }>`
  svg {
    fill: ${({ isFavorite, theme }) => (isFavorite ? theme.colors.primary : 'none')};
  }
`

interface IListOfNotes {
  isOpen: boolean
  desc?: boolean
  search?: string
  favorite?: boolean
  onNoteSelection: (selected: Note) => void
  selectedNote?: Note
}

export const ListOfNotes = ({
  isOpen,
  desc,
  search,
  onNoteSelection,
  favorite = false,
  selectedNote
}: IListOfNotes) => {
  const { familyId } = useCurrentFamilyId()
  const ref = useRef<HTMLDivElement | null>(null)

  const { notes, isLoadingNextNotes, isLoadingNotes, fetchNextPage, isSuccess, hasNextPage } = useListOfNotes(
    familyId,
    desc,
    undefined,
    search,
    favorite,
    isOpen
  )
  const isLoading = isLoadingNextNotes || isLoadingNotes

  const onScrollEnd = () => {
    if (hasNextPage && !isLoading) fetchNextPage()
  }

  useOnScrollEnd(ref.current, onScrollEnd)

  return (
    <Container id="scrollBox" ref={ref}>
      {isSuccess &&
        notes?.pages.map((page) =>
          page.results.length === 0 ? (
            <NoResultsMessage>Sem resultados</NoResultsMessage>
          ) : (
            page.results.map((item, key) => (
              <NoteContainer
                key={key}
                onClick={() => onNoteSelection(item)}
                isSelected={item === selectedNote}
              >
                <NoteTitle>
                  {item.title}
                  <StarIconButton isFavorite={item.favorite}>
                    {item.favorite ? <StarIcon /> : <StarOutlineIcon />}
                  </StarIconButton>
                </NoteTitle>
                <NoteName>
                  {item.text.length > 120 ? item.text.substring(0, 120) + ' ...' : item.text}
                </NoteName>
                <DateAndTime item={item} />
              </NoteContainer>
            ))
          )
        )}
      {!isSuccess && <LoadingCircleBackground />}
      {isLoading && <LoadingCircleBackground />}
    </Container>
  )
}
