import AddIcon from '@mui/icons-material/Add'
import React from 'react'

import { useCoreContext } from '../../../../../hooks/contexts/coreContext/coreProvider'
import { useTransactionContext } from '../../../../../hooks/contexts/transactionContext'
import * as S from './styles'
import { IButtonNewEntry } from './types'

export const ButtonNewEntry = ({ onClick }: IButtonNewEntry) => {
  const { modal } = useTransactionContext()
  const { familiaId } = useCoreContext()

  const handleClick = () => {
    onClick?.()
    modal.show()
  }

  if (!familiaId) return <></>

  return (
    <S.Button id="novo-lancamento" type="button" onClick={handleClick}>
      <S.Icon>
        <AddIcon />
      </S.Icon>
      <S.Text className="nav-link-inner--text adaptartamanhoSpan">Lançamento</S.Text>
    </S.Button>
  )
}
