import React, { useCallback, useMemo, useState } from 'react'

import { formatMonetaryValue } from '../../../domain/formatter'
import { getBillingsByPlannerIdLoading } from '../../../domain/planner'
import { useNumericLocalStorage } from '../../../hooks'
import { useRequiredAuth } from '../../../hooks/useAuth'
import { usePlannerBilling } from '../../../queries/planner/queries'
import { noticeError } from '../../../services/Monitoring'
import Button from '../../Buttons'
import { TableFooter } from '../Footer'
import { Text } from '../Footer/Footer.styles'
import { FooterRow } from '../Footer/Footer.styles'
import Table from '../Table'
import { ContainerTable, ErrorContainer } from '../Table.styles'
import { useColumns } from './columns'

const defaultSort: Sort = { desc: false, prop: 'creation' }

interface Sort {
  prop: string
  desc: boolean
}

interface Props {
  plannerId: number
  widthBeforeHorizontalScroll?: number
  filterDate: Date
}

export const BillingsByPlannerIdTable = ({ plannerId, widthBeforeHorizontalScroll, filterDate }: Props) => {
  const token = useRequiredAuth()
  const [page, setPage] = useState(0)
  const [search, setSearch] = useState<string | undefined>('')
  const { value: pageSize, setValue: setPageSize } = useNumericLocalStorage('billing-page-size', 10)
  const [sort, setSort] = useState<Sort>(defaultSort)
  const onSort = useCallback(
    (columnId: string, order: 'desc' | 'asc' | 'none') => {
      if (order === 'none') setSort(defaultSort)
      else if (columnId === 'value') setSort({ desc: order === 'desc', prop: 'investors' })
      else setSort({ desc: order === 'desc', prop: columnId })
    },
    [setSort]
  )
  const [includeDeleted, setIncludeDeleted] = useState<boolean>(false)

  const { billings, billingsError, isBillingsError, isLoadingBillings, refetchGetBillingsByPlannerId } =
    usePlannerBilling({
      desc: sort?.desc,
      includeDeleted,
      orderBy: sort?.prop,
      pageIndex: page,
      pageSize,
      plannerId,
      search,
      filterDate
    })

  const totalPages = billings ? Math.ceil(billings.totalResults / pageSize) : 1

  const columns = useColumns(billings?.valuePerFamily || 0)

  const loadingData = useMemo(() => getBillingsByPlannerIdLoading(), [])

  if (isBillingsError) {
    const plannerId = token.isPlanner ? token.plannerId : undefined
    noticeError(billingsError, { plannerId })

    return (
      <ErrorContainer>
        <br /> Erro ao carregar tabela :c <br /> Tente novamente: <br />
        <Button text="Tentar novamente." onClick={() => refetchGetBillingsByPlannerId()} />
      </ErrorContainer>
    )
  }

  return (
    <ContainerTable>
      <Table
        title="Famílias"
        data={billings?.results || []}
        columns={columns}
        isLoading={isLoadingBillings}
        loadingData={loadingData}
        filterEnabled={true}
        onFilterChange={setSearch}
        downloadFileName={`Fatura-${plannerId}-${new Date().toISOString().split('T')[0]}`}
        pageable={true}
        pageIndex={page}
        pageSize={pageSize}
        totalPages={totalPages}
        onNextPage={() => setPage(page + 1)}
        onPreviousPage={() => setPage(page - 1)}
        onPageSizeChange={setPageSize}
        sortable={true}
        onSort={onSort}
        filterableColumnNames={['Incluir inativos']}
        onColumnFilterChange={(column, value = true) => {
          if (column === 'Incluir inativos' && (value === undefined || typeof value === 'boolean'))
            setIncludeDeleted(value)
          else console.warn('Received unknown filter: ', column, value)
        }}
        renderFooter={() => (
          <TableFooter>
            <FooterRow>
              <Text>
                Total de famílias: {includeDeleted ? billings?.totalFamilies : billings?.totalActiveFamilies}
              </Text>
              <Text>Total da fatura: R$ {formatMonetaryValue(billings?.totalFee || 0)}</Text>
            </FooterRow>
          </TableFooter>
        )}
        widthBeforeHorizontalScroll={widthBeforeHorizontalScroll || 1230}
      />
    </ContainerTable>
  )
}
