import React from 'react'
import { Redirect } from 'react-router-dom'

import { ManagerBillingTable } from '../../../components/Tables'
import { useRequiredAuth } from '../../../hooks/useAuth'
import { loginPath } from '../../../routes'

export default ({ filterDate }) => {
  const { isManager, token } = useRequiredAuth()
  if (!isManager || token?.empresaId === undefined) return <Redirect to={loginPath} />

  return <ManagerBillingTable companyId={token.empresaId} filterDate={filterDate} />
}
