import React from 'react'

import { DuplicatedLancamentos } from '../../domain/extrato/api/sendExtrato'
import { ExtractWithoutCategory } from '../../domain/extrato/api/sendVistaExtract'
import { AlertModal } from '../Modal'
import { AlertContent, NotUploadedList } from './styles'

interface Props {
  isOpen: boolean
  onAccept: () => void
  duplicated: DuplicatedLancamentos[]
  withoutCategory: ExtractWithoutCategory[]
}

const getDuplicatedText = (length: number) => {
  if (length > 1) return `Foram detectados ${length} lançamentos`
  return `Foi detectado ${length} lançamento`
}

const getWithoutCategoryText = (length: number) => {
  if (length > 1) return `${length} itens com categoria divergente, não foram adicionados.`
  return `${length} item com categoria divergente, não foi adicionado.`
}

export const SuccessfulUploadAlert = ({ isOpen, onAccept, duplicated, withoutCategory }: Props) => {
  const title = 'Seus lançamentos foram importados com sucesso!'
  const hasDuplicated = duplicated?.length > 0
  const hasWithoutCategory = withoutCategory?.length > 0
  return (
    <AlertModal open={isOpen} onClose={onAccept} title={title}>
      {hasDuplicated && (
        <AlertContent>
          <h4>Itens duplicados</h4>
          <p>
            {getDuplicatedText(duplicated.length)} em duplicidade. Mas não se preocupe. Fizemos uma otimização
            para você e os lançamentos em duplicidade foram importados somente 01 vez.
          </p>
        </AlertContent>
      )}

      {hasWithoutCategory && (
        <AlertContent>
          <h4>Itens não adicionados</h4>
          <p>{getWithoutCategoryText(withoutCategory.length)}</p>
          <NotUploadedList>
            {withoutCategory.map(({ descricao }, index) => (
              <li key={`${descricao}-${index}`}>{descricao}</li>
            ))}
          </NotUploadedList>
        </AlertContent>
      )}
    </AlertModal>
  )
}
