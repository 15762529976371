import equals from 'fast-deep-equal'
import React from 'react'
import { Column } from 'react-table'

import { isIdentifiable } from '../../domain/identifiable'
import { Checkbox } from '../Inputs'

const checkboxId = 'checkbox-column'

export const isCheckboxColumn = <T extends object>(column: Column<T>) => column.id === checkboxId

interface Props<T extends object> {
  rows: T[]
  selected?: T[]
  onHeaderSelect?: (selected: boolean) => void
  onSelect?: (value: T) => void
  onDeselect?: (value: T) => void
}

// TODO: fix type errors.
export const getCheckboxColumn = <T extends object>({
  rows,
  selected,
  onHeaderSelect,
  onSelect,
  onDeselect
}: Props<T>): Column<T> => {
  const matches = (first: T, second: T) => {
    if (isIdentifiable(first) && isIdentifiable(second)) return first.id === second.id
    else if (typeof first === 'object' && typeof second === 'object') return equals(first, second)
    console.warn('Warning, table rows are not identifiable nor objects. Comparison may fail.')
    return first === second
  }
  const isSelected = (item: T) => selected?.some((current) => matches(current, item)) ?? false
  const allSelected = rows.every((row) => selected?.some((selected) => matches(selected, row))) ?? false

  const column: Column<T> = {
    id: checkboxId,
    Header: () => {
      return (
        <Checkbox
          checked={allSelected}
          onChange={(isHeaderSelected) => {
            onHeaderSelect?.(!isHeaderSelected)
          }}
        />
      )
    },
    accessor: (value) => value,
    Cell: ({ value }: { value: T }) => {
      return (
        <Checkbox
          checked={isSelected(value)}
          onChange={(selected) => (selected ? onSelect?.(value) : onDeselect?.(value))}
        />
      )
    },
    width: 40,
    disableSortBy: true
  }
  return column
}
