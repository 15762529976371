import { noticeError } from '../../../services/Monitoring'
import { bankIntegrationClient } from '../../../webClient'

export interface Params {
  itemId: string
}

export const deleteConnection = async ({ itemId }: Params) => {
  const { status } = await bankIntegrationClient.delete(`item/${itemId}`)
  if (status !== 200) {
    const error = new Error(`Deleting connection ${itemId}`)
    noticeError(error)
    console.error(error.message, error)
    throw error
  }
  return
}
