import React from 'react'
import styled from 'styled-components'

import { NextIcon, PreviousIcon } from '../Icons'
import { NumbersSelect } from '../Selects'

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 2vw;
  height: 8vh;
`

const PageText = styled.b`
  margin-left: 1vw;
  margin-right: 1vw;
`

interface Props {
  pageIndex: number
  pageSize: number
  totalPages: number
  onPageSizeChange?: (newSize: number) => void
  onNextPage?: () => void
  onPreviousPage?: () => void
}

export default ({ pageIndex, pageSize, totalPages, onNextPage, onPreviousPage, onPageSizeChange }: Props) => {
  const canGoBack = pageIndex > 0
  const canGoNext = pageIndex + 1 < totalPages

  return (
    <Container>
      <PreviousIcon onClick={() => onPreviousPage?.()} disabled={!canGoBack} />
      <PageText>
        Página {pageIndex + 1} / {totalPages}
      </PageText>
      <NextIcon onClick={() => onNextPage?.()} disabled={!canGoNext} />
      <PageText>
        Mostrar{' '}
        <NumbersSelect
          numbers={[10, 25, 50, 100, 200]}
          selection={pageSize}
          onChange={(newSize) => onPageSizeChange?.(newSize)}
        />{' '}
        por página
      </PageText>
    </Container>
  )
}
