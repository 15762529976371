import React, { useState } from 'react'

import { EQUITY_TYPES, TFixedEquity, translateEquityType } from '../../../domain/investments/fixedEquity'
import { useCurrentFamilyId } from '../../../hooks'
import { useRequiredAuth } from '../../../hooks/useAuth'
import { useCurrentUserId } from '../../../hooks/useCurrentUserId'
import { useCreateFixedAsset, useUpdateFixedAsset } from '../../../queries/investments/fixedAssetsMutations'
import { useEditEntryInvestmentById, useSaveManualInvestment } from '../../../queries/investments/mutations'
import { DatePicker, MonetaryInput, TextInput, TextInputTitle } from '../../Inputs'
import { Dono } from '../../Navbars/AdminNavbar/components/SaveDataTransaction/types'
import { EquityTypeSelect } from '../../Selects/EquityTypeSelect'
import { InvestmentsTypeSelect } from '../../Selects/InvestmentsTypeSelect'
import { PersonsSelect } from '../../Selects/PersonsSelect'
import SaveButtonModal from '../SaveButtonModal'
import {
  saveFixedAsset,
  saveManualInvestment,
  showError,
  validateFixedAsset,
  validateManualInvestment
} from './actions'
import * as S from './styles'
import { IFixedEquityModal } from './types'

export const FixedEquityModal = ({ open, fixedAsset, onClose, onSave }: IFixedEquityModal) => {
  const { isPlanned } = useRequiredAuth()
  const isEditing = !!fixedAsset?.id
  const fixedAssetType = fixedAsset?.type && translateEquityType(fixedAsset.type)
  const [type, setType] = useState<TFixedEquity | undefined>(fixedAssetType)
  const [investmentType, setInvestmentType] = useState(fixedAsset?.investmentType)
  const [person, setPerson] = useState<Dono>()
  const [name, setName] = useState(fixedAsset?.name)
  const [amountOriginal, setAmountOriginal] = useState(fixedAsset?.amountOriginal)
  const [amount, setAmount] = useState(fixedAsset?.amount)
  const [value, setValue] = useState(fixedAsset?.value)
  const { familyId } = useCurrentFamilyId()
  const { userId } = useCurrentUserId()
  const [date, setDate] = useState<Date | undefined>(
    fixedAsset?.buyDate ? new Date(fixedAsset.buyDate) : new Date()
  )

  const valueField = type === 'Veículo' ? 'Valor de Mercado*' : 'Valor*'
  const isManualInvestment = type === 'Investimentos'
  const currentValues = {
    balance: amountOriginal,
    buyDate: date,
    familyId,
    investmentType,
    isManual: isManualInvestment,
    name,
    type: EQUITY_TYPES[type?.toUpperCase() || ''],
    userId: person?.usuario?.id || fixedAsset?.userId || userId,
    value: amount ?? value
  }

  const {
    createManualInvestmentAsync,
    isLoadingCreateManualInvestment,
    isErrorCreateManualInvestment,
    createManualInvestmentError,
    resetCreateManualInvestment
    // createManualInvestmentSuccess
  } = useSaveManualInvestment()

  const {
    createFixedAssetAsync,
    isCreatingFixedAsset,
    isErrorCreatingFixedAsset,
    fixedAssetCreationError,
    resetFixedAssetCreation
  } = useCreateFixedAsset()

  const {
    updateFixedAssetAsync,
    isUpdatingFixedAsset,
    isErrorUpdatingFixedAsset,
    fixedAssetUpdateError,
    resetFixedAssetUpdate
  } = useUpdateFixedAsset()

  const { editEntryInvestmentByIdAsync } = useEditEntryInvestmentById()

  const { isValid: isValidManualInvestment } = validateManualInvestment({ type, investmentType, name })
  const { isValid: isValidFixedAsset } = validateFixedAsset({ type, familyId, value, buyDate: date, name })
  const hasError = isErrorCreatingFixedAsset || isErrorUpdatingFixedAsset || isErrorCreateManualInvestment
  const isLoading = isCreatingFixedAsset || isUpdatingFixedAsset || isLoadingCreateManualInvestment

  if (hasError) {
    let reset = () => {}

    if (isErrorCreatingFixedAsset) reset = resetFixedAssetCreation
    if (isErrorUpdatingFixedAsset) reset = resetFixedAssetUpdate
    if (isErrorCreateManualInvestment) reset = resetCreateManualInvestment

    showError({
      type: fixedAsset ? 'criar' : 'atualizar',
      error: fixedAssetCreationError || fixedAssetUpdateError || createManualInvestmentError,
      currentValues,
      reset,
      onClose
    })
  }

  const saveMethod = () => {
    if (isManualInvestment) {
      saveManualInvestment({
        fixedAsset,
        currentValues,
        onSave,
        updateMethod: editEntryInvestmentByIdAsync,
        method: createManualInvestmentAsync,
        isEditing
      })
      return
    }

    saveFixedAsset({
      fixedAsset,
      currentValues,
      onSave,
      updateMethod: updateFixedAssetAsync,
      method: createFixedAssetAsync
    })
  }

  const invalidValue = isEditing && (!amount || amount <= 0)
  const disabledSaveButton = !isValidManualInvestment || !isValidFixedAsset || invalidValue

  return (
    <SaveButtonModal
      open={open}
      closeable
      onClose={onClose}
      loadingMessage="Criando patrimônio..."
      onSave={saveMethod}
      isLoading={isLoading}
      disabledSaveButton={disabledSaveButton}
    >
      <S.Row>
        {fixedAsset ? (
          <S.Title>
            Editar Patrimônio: <span>{fixedAsset.name}</span>
          </S.Title>
        ) : (
          <S.Title>Cadastrar Patrimônio</S.Title>
        )}
      </S.Row>

      <S.RowForm>
        <S.SelectContainer>
          <TextInputTitle>Tipo de Patrimônio*</TextInputTitle>
          <EquityTypeSelect disabled={isEditing} type={type} onChange={setType} borderless={true} />
        </S.SelectContainer>

        {isManualInvestment && (
          <>
            <S.SelectContainer>
              <TextInputTitle>Tipo de Investimento*</TextInputTitle>
              <InvestmentsTypeSelect type={investmentType} onChange={setInvestmentType} borderless={true} />
            </S.SelectContainer>

            {!isPlanned && !isEditing && (
              <S.SelectContainer>
                <TextInputTitle>Responsável pela Carteira*</TextInputTitle>
                <PersonsSelect selectedPerson={person} onChange={setPerson} borderless familyId={familyId} />
              </S.SelectContainer>
            )}
          </>
        )}

        <S.SelectContainer>
          <TextInput title="Descrição do Patrimônio*" onChange={setName} text={name} />
        </S.SelectContainer>

        {isEditing && (
          <>
            <S.SelectContainer>
              <MonetaryInput
                disabled={isManualInvestment && fixedAsset?.isManual}
                title="Valor Aplicado*"
                onChange={setAmountOriginal}
                value={amountOriginal}
              />
            </S.SelectContainer>

            <S.SelectContainer>
              <MonetaryInput
                title="Valor Atual"
                onChange={setAmount}
                value={amount}
                disabled={typeof fixedAsset?.amount === 'number' && fixedAsset?.amount <= 0}
              />
              {fixedAsset?.amount && invalidValue ? <S.ErrorMessage>Valor obrigatório</S.ErrorMessage> : null}
            </S.SelectContainer>
          </>
        )}

        {!isManualInvestment && (
          <>
            <S.SelectContainer>
              <TextInputTitle>Data da Compra*</TextInputTitle>
              <DatePicker formStyle={true} currentDate={date} onChange={setDate} />
            </S.SelectContainer>

            <S.SelectContainer>
              <MonetaryInput title={valueField} onChange={setValue} value={value} />
            </S.SelectContainer>
          </>
        )}
      </S.RowForm>
    </SaveButtonModal>
  )
}
