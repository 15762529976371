import useKonami from 'use-konami'

import { loginPath } from '../routes'

export const useClearCacheEasterEgg = () => {
  useKonami({
    onUnlock: () => {
      localStorage.clear()
      window.location.replace(`/auth${loginPath}`)
    },
    sequence: ['ArrowDown', 'ArrowDown', 'ArrowDown', 'c', 'a', 'c', 'h', 'e']
  })
}
