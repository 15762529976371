import { useMutation, useQueryClient } from 'react-query'

import {
  deleteBudget as deleteBudgetAPI,
  Params as DeleteBudgetParams} from '../../domain/orcamento/api/deleteBudget'
import { postOrcamentoPeriodoApi } from '../../domain/orcamento/api/postOrcamentoPeriodo'
import { BudgetDeletionResult } from '../../domain/orcamento/budgetDeletionResult'
import { cacheBaseKey as transactionsCacheKey } from '../lancamento/queries'
import { cacheKey } from './queries'

export const useDeleteBudget = () => {
  const client = useQueryClient()
  const {
    mutate: deleteBudget,
    isLoading: isDeletingBudget,
    isError: isBudgetDeletionError,
    error: budgetDeletionError,
    isSuccess: budgetDeleted,
    reset: resetBudgetDeletion
  } = useMutation<BudgetDeletionResult, Error, DeleteBudgetParams, unknown>(deleteBudgetAPI, {
    retry: false,
    onSuccess: () => {
      client.invalidateQueries(cacheKey)
      client.invalidateQueries(transactionsCacheKey)
    }
  })
  return {
    deleteBudget,
    isDeletingBudget,
    isBudgetDeletionError,
    budgetDeletionError,
    budgetDeleted,
    resetBudgetDeletion
  }
}

export const usePostOrcamentoPeriodo = () => {
  const {
    mutateAsync: postOrcamentoPeriodoAsync,
    isLoading: isPostingOrcamentoPeriodo,
    isError: isPostingOrcamentoPeriodoError,
    error: postingOrcamentoPeriodoError,
    data
  } = useMutation(postOrcamentoPeriodoApi)

  const postingOrcamentoPeriodoData = data?.results

  return {
    postOrcamentoPeriodoAsync,
    isPostingOrcamentoPeriodo,
    isPostingOrcamentoPeriodoError,
    postingOrcamentoPeriodoError,
    postingOrcamentoPeriodoData
  }
}
