import { useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useHistory } from 'react-router-dom'

import { useTheme } from '../../../hooks'
import { useSelectFamily } from '../../../hooks/useCurrentFamily'
import * as S from './styles'
import { IClientSelector } from './types'

export const ClientSelector = ({ onClick, className, size }: IClientSelector) => {
  const history = useHistory()
  const { family, setFamilyImage, familyImage, isLoadingFamilyImage, isPlanned, finalImageUrl } =
    useSelectFamily()

  const {
    theme: { colors }
  } = useTheme()

  const colorScheme = isPlanned
    ? { dark: colors?.primaryDarker, light: colors?.primary }
    : { dark: colors?.secondaryDarker, light: colors?.secondary }

  const getName = () => {
    if (isPlanned) {
      return family?.nome || ''
    }
    return family?.nome || 'Selecione um cliente'
  }

  useEffect(() => {
    if (familyImage) {
      let imageUrl = familyImage
      if (familyImage.includes('base64') || familyImage.includes('http')) {
        imageUrl = familyImage
      } else {
        imageUrl = `https://app.meuvista.com/v1${familyImage}`
      }
      setFamilyImage(imageUrl)
    }
  }, [familyImage, setFamilyImage])

  return (
    <S.FamilyContainer
      className={className}
      colorSqueme={colorScheme}
      onClick={() => (isPlanned ? history.push('/admin/usuarios') : onClick())}
      size={size}
    >
      <S.FamilyFigure image={finalImageUrl} isLoading={isLoadingFamilyImage}>
        {isLoadingFamilyImage && <Skeleton width={65} height={60} circle />}
        <img src={finalImageUrl} alt={family?.nome || 'Familia'} />
      </S.FamilyFigure>

      <S.FamilyNameContainer>
        <S.FamilyName>{getName()}</S.FamilyName>
      </S.FamilyNameContainer>

      <S.FamilyIconContainer hide={isPlanned}>
        <S.FamilyIcon className="fas fa-chevron-right" />
      </S.FamilyIconContainer>
    </S.FamilyContainer>
  )
}
