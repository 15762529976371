import { noticeError } from '../../../services/Monitoring'
import { getWithToken } from '../../../webClient'
import { SimplifiedCategoria } from '../Categoria'

interface Response {
  categorias: SimplifiedCategoria[]
}

export const fetchByFamily = async (familyId: number): Promise<{ categorias: SimplifiedCategoria[] }> => {
  if (!familyId) {
    console.log('Missing family id when fetching category')
    return {
      categorias: []
    }
  }
  const url = `categoria/familia/${familyId}`
  const {
    data: { categorias },
    status
  } = await getWithToken<Response>(url)

  if (status !== 200) {
    const error = new Error(`Error getting family ${familyId} categorias. status ${status}`)
    console.error(error)
    noticeError(error, { familyId })
    throw error
  }

  return {
    categorias
  }
}
