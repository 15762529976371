import { styled } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import * as React from 'react'

const StyledContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column'
})

const StyledLabel = styled('label')({
  fontSize: '0.9rem',
  marginBottom: '0.5rem',
  '&.--disabled': {
    color: 'gray',
    opacity: 0.5
  }
})

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.background.default,
  borderRadius: '2rem',
  color: theme.palette.text.primary,
  display: 'flex',

  '& .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd.MuiAutocomplete-input.MuiInputBase-inputSizeSmall':
    {
      fontSize: '0.8rem'
    },

  '& .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd.MuiAutocomplete-input': {
    padding: '0 0.8rem 0 0.6rem',
    minWidth: ' 90px',
    fontSize: '0.9rem'
  },

  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: ' #d9d9d9',
    borderRadius: ' 2rem'
  },

  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.primary.main
  }
}))

export const SelectAutoComplete = <T extends { label: string; value?: T['value'] | null }>({
  options,
  onChange,
  placeholder = 'Selecione',
  value,
  label,
  disabled,
  isLoading,
  defaultValue,
  hideLabel,
  size = 'medium'
}: {
  options: T[]
  onChange: (option?: T | null) => void
  placeholder?: string
  value?: T['value'] | null
  label?: string
  disabled?: boolean
  isLoading?: boolean
  defaultValue?: T['value'] | null
  hideLabel?: boolean
  size?: 'small' | 'medium'
}) => {
  const selectedOption: T | null = options.find((option) => option.value === value) || null

  return (
    <StyledContainer>
      {label && !hideLabel && <StyledLabel className={disabled ? '--disabled' : ''}>{label}</StyledLabel>}
      <StyledAutocomplete
        options={options}
        autoHighlight
        size={size}
        getOptionLabel={(option) => (option as T).label}
        onChange={(_, option) => onChange(option as T)}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            event.preventDefault()
            event.stopPropagation()
          }
        }}
        disabled={disabled}
        value={selectedOption || null}
        loading={isLoading}
        defaultValue={defaultValue}
        renderOption={(props, option) => {
          const { key, ...optionProps } = props
          return (
            <Box key={key} component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...optionProps}>
              {(option as T).label}
            </Box>
          )
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            slotProps={{
              htmlInput: {
                ...params.inputProps,
                placeholder,
                inputType: 'search',
                autoComplete: 'new-password' // disable autocomplete and autofill
              }
            }}
          />
        )}
      />
    </StyledContainer>
  )
}
