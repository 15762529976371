import React from 'react'
import styled from 'styled-components'

import Colors from '../../../domain/Colors'
import { formatMonetaryValue } from '../../../domain/formatter'
import { FetchSimplifiedResponse } from '../../../domain/lancamento/api/fetchByFamily'
import { TableFooter } from '../Footer'

const ValueRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  background: ${Colors.lightBlue};
  color: ${Colors.white};
  font-size: small;
`

const ValueContainer = styled.div`
  min-height: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`

interface Props {
  lancamentos: FetchSimplifiedResponse
}

export default ({
  lancamentos: { totalDespesas, totalInvestimentos, totalReceitas, totalDividas }
}: Props) => {
  return (
    <TableFooter>
      <ValueRow>
        <ValueContainer>Total Receitas: {formatMonetaryValue(totalReceitas, false)}</ValueContainer>
        <ValueContainer>Total Despesas: {formatMonetaryValue(totalDespesas, false)}</ValueContainer>
      </ValueRow>

      <ValueRow>
        <ValueContainer>Total Investimentos: {formatMonetaryValue(totalInvestimentos, false)}</ValueContainer>
        <ValueContainer>Total Dívidas: {formatMonetaryValue(totalDividas, false)}</ValueContainer>
      </ValueRow>
    </TableFooter>
  )
}
