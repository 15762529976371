import React, { useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'
import styled from 'styled-components'

import { spinnerHide, spinnerShow } from '../../globalValues'
import { useCoreContext } from '../../hooks/contexts/coreContext/coreProvider'
import { useLoadingScreenContext } from '../../hooks/contexts/loadingScreenContext'
import { useGetAllFamiliesByPlannerId, useGetAllUsersByPlannerIdFilter } from '../../queries/pessoa/mutations'
import { getRoute, getRoutePath } from '../../routes'
import { Card, IconCard } from './Card'
import communityButtonIcon from './Card/images/community.png'
import FamilyIcon from './Card/images/family.png'
import nordIcon from './Card/images/nord.svg'
import shoppingBasket from './Card/images/shopping-basket-white.png'
import UserIcon from './Card/images/user.png'
import whitelabelIcon from './Card/images/whitelabel.png'
import { Titulo } from './Titulo'

const Container = styled.div`
  margin-left: 5vw;
  margin-right: 5vw;
  margin-top: 2vw;
  display: flex;
  flex-direction: column;
  padding: 0 0 3rem;
`
const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 2rem;
  gap: 2rem;

  @media screen and (min-width: 1100px) {
    gap: 1rem;
    > div {
      width: 22%;
    }
  }
`

// {this.renderRedirectTermo()}
// {this.renderModalAvisoClientes()}
// {this.renderModalUsuariosEmpty()}
// {this.renderModalClientesEmpty()}
// {this.renderRedirect()}
// {this.renderModalPesquisaPlanejador()}
// {this.renderModalPrivacidade()}

export const HomePlanejadorView = () => {
  const { planejadorId: plannerId } = useCoreContext()
  const { setIsLoading } = useLoadingScreenContext()
  const {
    getAllUsersByPlannerIdFilter,
    isLoadingGetAllUsersByPlannerIdFilter,
    dataGetAllUsersByPlannerIdFilter
  } = useGetAllUsersByPlannerIdFilter()
  const { getAllFamiliesByPlannerId, isLoadingGetAllFamiliesByPlannerId, dataGetAllFamiliesByPlannerId } =
    useGetAllFamiliesByPlannerId()

  const qtdFamilias = dataGetAllFamiliesByPlannerId?.length || 0
  const qtdUsuarios = dataGetAllUsersByPlannerIdFilter?.length || 0

  useEffect(() => {
    getAllUsersByPlannerIdFilter({ plannerId: plannerId || undefined })
    getAllFamiliesByPlannerId({ plannerId: plannerId || undefined })
  }, [plannerId, getAllUsersByPlannerIdFilter, getAllFamiliesByPlannerId])

  useEffect(() => {
    if (isLoadingGetAllFamiliesByPlannerId || isLoadingGetAllUsersByPlannerIdFilter) {
      setIsLoading(true)
      spinnerShow($)
    } else {
      setIsLoading(false)
      spinnerHide($)
    }
  }, [isLoadingGetAllFamiliesByPlannerId, isLoadingGetAllUsersByPlannerIdFilter, setIsLoading])

  return (
    <Container>
      <Titulo />
      <Row>
        {isLoadingGetAllFamiliesByPlannerId ? (
          <Skeleton height={265} width={200} />
        ) : (
          <Card
            title={`${qtdFamilias} Clientes`}
            icon={FamilyIcon}
            description="Veja e edite todos os clientes cadastrados"
            color="cyan"
            goToButton={{ link: getRoutePath(getRoute('Clientes')) }}
            plusButton={{ link: getRoutePath(getRoute('Clientes - Novo')) }}
          />
        )}

        {isLoadingGetAllUsersByPlannerIdFilter ? (
          <Skeleton height={265} width={200} />
        ) : (
          <Card title={`${qtdUsuarios} Usuários`} icon={UserIcon} description="" color="lightGreen" />
        )}

        <Card
          title="Tutoriais"
          description="Assista aos tutoriais do Vista"
          goToButton={{ link: getRoutePath(getRoute('Tutoriais')) }}
        />

        <Card
          title="Whitelabel"
          description="Informações necessárias para ativação do Whitelabel"
          icon={whitelabelIcon}
          // iconSize="6vw"
          iconOrientation="vertical"
          goToButton={{ link: getRoutePath(getRoute('Whitelabel')), newTab: false }}
        />

        <Card
          title="Faturas Vista"
          description="Veja aqui suas faturas do Vista"
          color="lightBlue"
          goToButton={{ link: getRoutePath(getRoute('Faturas')) }}
        />

        <Card
          featured
          title="VITRINE"
          description="Conheça todos os produtos Meu Vista"
          icon={shoppingBasket}
          goToButton={{ link: 'https://meuvista.com/vitrine/', newTab: true }}
        />

        <IconCard
          title="COMUNIDADE"
          iconSource={communityButtonIcon}
          goToButton={{ link: 'https://materiais.meuvista.com/comunidade' }}
        />

        <IconCard
          title="Nord"
          iconSource={nordIcon}
          goToButton={{ link: 'https://lps.nordresearch.com.br/nord-selecao-parceiro-vista' }}
        />
      </Row>
    </Container>
  )
}
