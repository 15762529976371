import React from 'react'
// import CurrencyInput from 'react-currency-input'
import axios from 'axios'
import WrapperForCurrency from '../../../../theme/wrapperForCurrency'
import { withContexts } from '../../../../components'
import * as S from '../styles'
import { StyledCurrencyInput } from '../../../viewStyles'
import { noticeError } from '../../../../services/Monitoring'

class ReduzirGastos extends React.Component {
  moment = require('moment')

  componentDidMount() {
    var $this = this

    this.setState(
      {
        familiaId: this.props.familiaId
      },
      $this.refreshData()
    )
  }

  getData = () => {
    const url = global.server_api_new + '/EquilibrioFinanceiro/familia/' + this.state.familiaId + '/orcamento'
    const filtro = {
      DataInicial: this.state.startDate,
      DataFinal: this.state.endDate
    }

    axios.post(url, filtro).then((res) => {
      const data = []
      let despesasTotal = 0
      let totalObjetivo = 0
      let totalMesAtual = 0

      if (res.data.results != null) {
        res.data.results.forEach((item) => {
          if (item.tipoOrcamento === 1) {
            data.push({
              id: item.id,
              orcamentoId: item.orcamentoId,
              nome: item.nome,
              estimativa: item.estimado,
              meta: item.meta,
              objetivo: item.objetivo,
              mesAtual: item.mesAtual,
              ehTotal: 0
            })
            despesasTotal += item.estimado
            totalObjetivo += item.objetivo
            totalMesAtual += item.mesAtual
          }
        })
        data.push({
          nome: 'Total',
          estimativa: despesasTotal,
          meta: null,
          objetivo: totalObjetivo,
          mesAtual: totalMesAtual,
          ehTotal: 1
        })
      }

      this.setState({ data })
    })
  }

  setStartDate = (date, callback) => {
    if (date != null) {
      date.setHours(0)
      date.setMinutes(0, 0, 0)
      this.props.setDataInicio(this.moment(date).format('YYYY-MM-DDTHH:mm:ss'))
    }

    const startDate = date

    this.setState({ startDate }, function () {
      if (callback != null) {
        callback()
      } else {
        this.getData()
      }
    })
  }

  setEndDate = (date, callback) => {
    if (date != null) {
      date.setHours(23)
      date.setMinutes(59, 59, 59)
      this.props.setDataFim(this.moment(date).format('YYYY-MM-DDTHH:mm:ss'))
    }

    const endDate = date

    this.setState({ endDate }, function () {
      if (callback != null) {
        callback()
      } else {
        this.getData()
      }
    })
  }

  columns = [
    {
      name: 'ehTotal',
      options: {
        display: false,
        viewColumns: false,
        filter: false
      }
    },
    {
      name: 'id',
      options: {
        display: false,
        viewColumns: false,
        filter: false
      }
    },
    {
      name: 'orcamentoId',
      options: {
        display: false,
        viewColumns: false,
        filter: false
      }
    },
    {
      name: 'nome',
      label: 'Nome',
      options: {
        display: true,
        viewColumns: true,
        filter: false
      }
    },
    {
      name: 'estimativa',
      label: 'Estimativa',
      options: {
        display: true,
        viewColumns: true,
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value == null) value = 0

          return (
            <div>
              {value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }).replace('R$', '')}
            </div>
          )
        }
      }
    },
    {
      name: 'meta',
      label: 'Meta',
      options: {
        display: true,
        viewColumns: true,
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          var $this = this

          if (value == null) value = 0

          if (tableMeta.rowData[0] === 1) {
            return <></>
          }

          return (
            <div style={{ cursor: 'pointer' }}>
              <StyledCurrencyInput
                style={{ width: '60px' }}
                value={value}
                thousandSeparator={'.'}
                suffix={' %'}
                precision="2"
                onChangeEvent={(_event, _formattedValue, value) => {
                  updateValue(value)

                  if (this.state.timeout != null) {
                    clearTimeout(this.state.timeout)
                  }

                  var timeout = setTimeout(function () {
                    var orcamentoId = tableMeta.rowData[2]
                    var familia = this.props.familiaId
                    var service = `/EquilibrioFinanceiro/familia/${familia}/orcamento/${orcamentoId}`
                    var url = global.server_api_new + service

                    var dados = {
                      meta: value
                    }

                    axios
                      .post(url, dados)
                      .then((res) => {
                        if (res.data.success) {
                          $this.getData()
                        }
                      })
                      .catch((error) => {
                        console.log(error)
                        noticeError('Erro ao atualizar meta')
                      })
                  }, 1000)

                  this.setState({ timeout })
                }}
              />
            </div>
          )
        }
      }
    },
    {
      name: 'objetivo',
      label: 'Objetivo',
      options: {
        display: true,
        viewColumns: true,
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value == null) value = 0

          return (
            <div>
              {value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }).replace('R$', '')}
            </div>
          )
        }
      }
    },
    {
      name: 'mesAtual',
      label: 'Realizado',
      options: {
        display: true,
        viewColumns: true,
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value == null) value = 0

          return (
            <div>
              {value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }).replace('R$', '')}
            </div>
          )
        }
      }
    }
  ]

  options = {
    filter: true,
    filterType: 'dropdown',
    responsive: 'stacked',
    print: false,
    download: false,
    sort: true,
    pagination: false,
    selectableRows: 'none',
    textLabels: global.textLabels
  }

  state = {
    data: []
  }

  refreshData = () => {
    const $this = this

    let startDate = this.props.dataInicio
    let endDate = this.props.dataFim

    this.setStartDate(startDate, function () {
      $this.setEndDate(endDate, function () {
        $this.getData()
      })
    })
  }

  render() {
    return (
      <>
        <button
          id="reduzirGastos"
          onClick={() => this.refreshData()}
          style={{ position: 'fixed', left: '-1000px' }}
        />
        <S.Table data={this.state.data} columns={this.columns} options={this.options} />
      </>
    )
  }
}
export default withContexts(ReduzirGastos)
