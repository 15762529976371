import { Palette } from '@mui/material'
import { useMemo } from 'react'

export const useChartTipoOrcamentoPrevistoRealizado = ({
  palette,
  nomesTipoOrcamento,
  tiposOrcamentoEstimados,
  tiposOrcamentoRealizados
}: {
  palette: Palette
  nomesTipoOrcamento: string[]
  tiposOrcamentoEstimados: number[]
  tiposOrcamentoRealizados: number[]
}) => {
  const chartData = useMemo(() => {
    return {
      options: {
        colors: [`${palette.secondary.main}`, `${palette.primary.main}`, '#fa0000'],
        legend: {
          show: false
        },
        plotOptions: {
          bar: {
            horizontal: false,
            endingShape: 'rounded',
            dataLabels: {
              position: 'top'
            }
          }
        },
        dataLabels: {
          enabled: true,
          textAnchor: 'middle' as const,
          formatter: function (val) {
            return val != null
              ? val
                  .toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL'
                  })
                  .replace('R$', '')
              : ''
          },
          hideOverflowingLabels: true,
          offsetY: -20,
          style: {
            fontSize: '11px',
            fontFamily: 'Open Sans, sans-serif',
            colors: ['#57657D'],
            textAlign: 'center'
          }
        },
        stroke: {
          curve: 'smooth'
        },
        xaxis: {
          categories: nomesTipoOrcamento,
          labels: {
            style: {
              fontFamily: 'Open Sans, sans-serif',
              fontWeight: 800,
              fontSize: '13px'
            }
          }
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return value != null
                ? value
                    .toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL'
                    })
                    .replace('R$', '')
                : ''
            },
            style: {
              fontWeight: 600
            }
          }
        }
      },
      series: [
        {
          name: 'Estimado',
          data: tiposOrcamentoEstimados
        },
        {
          name: 'Realizado',
          data: tiposOrcamentoRealizados
        }
      ]
    }
  }, [
    palette.primary.main,
    palette.secondary.main,
    nomesTipoOrcamento,
    tiposOrcamentoEstimados,
    tiposOrcamentoRealizados
  ])

  return chartData
}
