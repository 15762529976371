import React from 'react'
import { useHistory } from 'react-router-dom'

import routes from '../../routes'
import PlusIcon from '../Icons/PlusIcon'

export default () => {
  const history = useHistory()
  const redirectToMeioPagametosPage = () => {
    const meioPagamentoRoute = routes.find((route) => route.name === 'Meios de Pagamento')
    if (!meioPagamentoRoute) {
      // TODO: Adicionar error handling para quando não encontramos rota
      const mensagemErr = 'Meio pagamento route not found'
      console.error(mensagemErr)
      throw new Error(mensagemErr)
    }
    history.push(`${meioPagamentoRoute.layout}${meioPagamentoRoute.path}`)
  }

  return <PlusIcon tooltip="Criar novo meio de pagamento" onClick={redirectToMeioPagametosPage} />
}
