import styled, { css } from 'styled-components'

import colors from '../../../domain/Colors'
import Theme from '../../../domain/theme'

interface IconProps {
  iconSize: string
  iconOrientation?: 'horizontal' | 'vertical'
}

export const Container = styled.div<{ color?: keyof typeof colors; featured?: Theme }>`
  background-color: ${({ color }) => (color ? colors[color] : colors.lightGray)};
  border: 1px solid ${colors.lightGray2};
  align-items: center;
  border-radius: 8%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem 1rem;
  /* min-width: 16.5vw; */
  text-align: center;
  /* width: 16.5vw; */
  min-width: 200px;
  transition: transform 0.2s ease-in-out;
  transform: scale(1);
  width: 22%;

  &:hover,
  &:focus,
  &:active {
    transform: scale(1.05);
  }

  ${({ featured }) =>
    featured &&
    css`
      background: ${featured.colors.secondary};
      color: #fff;

      h2 {
        color: #fff;
      }
    `}

  @media screen and (max-width: 1015px) {
    width: 45%;
  }
`

export const Icon = styled.img<IconProps>`
  max-height: 35px;
  margin-bottom: 0.7rem;
`

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  p {
    line-height: 1.5;
  }
`
