import 'react-confirm-alert/src/react-confirm-alert.css'

import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import CurrencyInput from 'react-currency-input'
import { Col, Row } from 'reactstrap'

import { getTransactionType, TRANSACTION_NAME_CODE } from '../../../../../domain/lancamento/lancamento'
import { TipoOrcamento } from '../../../../../domain/orcamento/Orcamento'
import { useSelectComboContext } from '../../../../../hooks/contexts/selectComboContext'
import { useTransactionContext } from '../../../../../hooks/contexts/transactionContext'
import { CurrencyParceledInput } from '../../../../Inputs/InputAmountV2'
import { InputQuantity } from '../../../../Inputs/InputQuantidade'
import { FieldSelectCategoria } from '../../../../Selects/FieldSelectCategoria'
import { FieldSelectDate } from '../../../../Selects/FieldSelectDate'
import { FieldSelectOrcamento } from '../../../../Selects/FieldSelectOrcamento'
import { FieldSelectPaymentMethods } from '../../../../Selects/FieldSelectPaymentMethods'
import { TIPO_ORCAMENTO_NAME_CODE, Transaction } from '../SaveDataTransaction/types'
import { useSaveTransactionsActions } from '../SaveDataTransaction/useSaveTransactionAction'
import { INVESTMENT_NAME_CODE, OPERACAO_NAME_CODE } from './types'

export const FormReceita = ({ parentThis }) => {
  const { SaveButton } = useSaveTransactionsActions()
  const { MEIOS_PAGAMENTOS_OPTIONS } = useSelectComboContext()
  const { transaction, setTransaction } = useTransactionContext()
  const [item, setStateItem] = useState<Transaction | undefined>(transaction)
  const { state } = parentThis || {}
  const [paymentMethod, setPaymentMethod] = useState(item?.meioPagamento)
  const isEditing = !!item?.id
  const { isParceled, isRepeated } = getTransactionType({
    transactionType: item?.tipoDeParcela ?? TRANSACTION_NAME_CODE.unico
  })

  const setItem = (data, callback?: () => void) => {
    setStateItem({
      ...item,
      ...data
    })
    if (callback) {
      setTimeout(() => {
        callback?.()
      }, 100)
    }
  }

  const initialData = useMemo(() => {
    let temp = item
    if (item && !item?.repeatedParcels) {
      temp = {
        ...item,
        repeatedParcels: item?.parcelas
      }
    }

    return temp
  }, [item])

  const findMeioPagamento = useCallback(
    (findMeioPagamentoPadrao = false) => {
      if (item && MEIOS_PAGAMENTOS_OPTIONS) {
        if (item?.meioPagamento?.nome && !findMeioPagamentoPadrao) {
          const meioPagamento = MEIOS_PAGAMENTOS_OPTIONS?.find((op) => op.label === item?.meioPagamento.nome)
          // @ts-expect-error mismatch
          setPaymentMethod(meioPagamento?.value)
        } else if (item?.categoria?.meioPagamentoPadrao?.nome) {
          const meioPagamentoPadrao = MEIOS_PAGAMENTOS_OPTIONS.find(
            (op) => op.label === item?.categoria.meioPagamentoPadrao.nome
          )
          // @ts-expect-error mismatch
          setPaymentMethod(meioPagamentoPadrao?.value)
        }
      }
    },
    [MEIOS_PAGAMENTOS_OPTIONS, item]
  )

  const [validForm, setValidForm] = useState(false)
  const validateForm = useCallback(() => {
    const { categoria, meioPagamentoId, valor } = item || {}

    if (categoria === null || meioPagamentoId === null) {
      setValidForm(false)
      return
    }

    if (!isEditing && !valor) {
      setValidForm(false)
      return
    }

    setValidForm(true)
  }, [isEditing, item])

  useEffect(() => {
    findMeioPagamento()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [findMeioPagamento, item?.meioPagamento])

  useEffect(() => {
    setItem({
      meioPagamento: paymentMethod
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethod])

  useEffect(() => {
    validateForm()
  }, [item, validateForm])

  useEffect(() => {
    const _transaction = transaction || {}
    setItem({
      ..._transaction,
      data: transaction?.data ?? moment(new Date(), 'DD-MM-YYYY').toDate(),
      tipoOrcamento: TIPO_ORCAMENTO_NAME_CODE.Receita
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (validForm) {
      setTransaction(item)
    }
  }, [item, setTransaction, validForm])

  return (
    <Row id="formReceita">
      <FieldSelectOrcamento
        type={TipoOrcamento.Receita}
        selectedId={item?.orcamentoId}
        // @ts-expect-error expected-error
        defaultValue={item?.id && item?.orcamentoId ? item?.orcamentoId : undefined}
        // @ts-expect-error expected-error
        onSelect={({ value }) => {
          setItem({
            orcamento: value,
            orcamentoId: value?.id
          })
        }}
      />

      <FieldSelectCategoria
        lancamentoId={item?.orcamento?.id ?? item?.orcamentoId}
        defaultValue={
          (item?.categoria?.id ?? typeof item?.categoriaId === 'number')
            ? Number(item?.categoriaId)
            : undefined
        }
        onSelect={({ categoria }) => {
          const meioPagamento = categoria.meioPagamentoPadrao ?? item?.meioPagamento ?? null

          let newItem = {
            categoria: categoria,
            categoriaId: categoria?.id
          }

          if (categoria?.meioPagamentoPadrao) {
            newItem = {
              ...newItem,
              // @ts-expect-error
              meioPagamento: meioPagamento,
              meioPagamentoId: meioPagamento?.id
            }
          }

          setItem(newItem, () => findMeioPagamento(true))
        }}
      />

      {item?.patrimonio?.tipoInvestimento === INVESTMENT_NAME_CODE.RendaFixaPos &&
        item?.operacao !== OPERACAO_NAME_CODE.VENDA && (
          <Col lg="6" xl="6">
            <div>
              <label>Taxa *</label>
              <CurrencyInput
                id="lancamentoTaxaCustomizada"
                value={
                  item?.taxaCustomizadaFormatada != null
                    ? item?.taxaCustomizadaFormatada
                    : item?.taxaCustomizada
                }
                thousandSeparator={'.'}
                decimalSeparator={','}
                precision="2"
                suffix=" %"
                onChangeEvent={(_, formattedValue, value) => {
                  setItem({
                    taxaCustomizada: value,
                    taxaCustomizadaFormatada: formattedValue
                  })
                }}
              />
            </div>
          </Col>
        )}

      <FieldSelectPaymentMethods
        defaultValue={
          item?.meioPagamentoId ??
          item?.financialInstitutionCode ??
          item?.meioPagamento?.id ??
          paymentMethod?.id
        }
        onSelect={({ value }) => {
          setItem({
            meioPagamento: value,
            meioPagamentoId: value?.id
          })
        }}
      />

      {item?.patrimonio?.tipoPatrimonio &&
        INVESTMENT_NAME_CODE[item?.patrimonio?.tipoPatrimonio] &&
        item?.operacao !== OPERACAO_NAME_CODE.VENDA && (
          <Col lg="6" xl="6">
            <div>
              <label>Origem do Recurso *</label>
              {parentThis.montarDropDown(
                state.meiosPagamentoCombo,
                'lancamentoMeioPagamento',
                ({ value }) => {
                  setItem({
                    meioPagamentoNome: value
                  })
                },
                item?.meioPagamento
              )}
            </div>
          </Col>
        )}

      <CurrencyParceledInput
        partialData={{
          id: item?.id,
          valor: item?.valor,
          parcelas: item?.parcelas,
          valorParcela: item?.valorParcela,
          tipoDeParcela: item?.tipoDeParcela
        }}
        onChange={(data) => {
          setItem(data)
          return
        }}
      />
      <FieldSelectDate
        id="lancamentoData"
        disabled={isEditing && (isParceled || isRepeated)}
        onChange={(date: Date) => {
          setItem({
            data: date
          })
        }}
        selectedValue={item?.data}
      />

      <Col lg="6" xl="6" className="lancamento receita">
        <div>
          <InputQuantity
            onRadioChange={(value) => {
              setItem({
                tipoDeParcela: value
              })
            }}
            onInputChange={(event) => setItem({ parcelas: event.target.value })}
            parcelas={item?.parcelas}
            tipoDeParcela={item?.tipoDeParcela}
            isEditing={isEditing}
            // @ts-expect-error initialData
            initialData={initialData}
          />
        </div>
      </Col>

      {item?.patrimonio?.tipoInvestimento &&
        [1, 2, 3, 4, 5, 6, 8, 9].includes(item?.patrimonio?.tipoInvestimento) &&
        item?.operacao === OPERACAO_NAME_CODE.VENDA && (
          <Col lg="6" xl="6">
            <div>
              <label>Imposto de Renda *</label>
              <CurrencyInput
                id="lancamentoImpostoRenda"
                style={{ paddingLeft: 25 }}
                value={item?.impostoRendaFormatado != null ? item?.impostoRendaFormatado : item?.impostoRenda}
                thousandSeparator={'.'}
                decimalSeparator={','}
                precision="2"
                onChangeEvent={(_, formattedValue, value) => {
                  setItem({
                    impostoRenda: value,
                    impostoRendaFormatado: formattedValue
                  })
                }}
              />
            </div>
          </Col>
        )}

      {item?.patrimonio?.tipoInvestimento &&
        [1, 2, 3, 4, 5, 6, 8, 9].includes(item?.patrimonio?.tipoInvestimento) && (
          <Col lg="6" xl="6">
            <div>
              <label>IOF *</label>
              <CurrencyInput
                id="lancamentoIOF"
                style={{ paddingLeft: 25 }}
                value={item?.iofFormatado != null ? item?.iofFormatado : item?.iof}
                thousandSeparator={'.'}
                decimalSeparator={','}
                precision="2"
                onChangeEvent={(_, formattedValue, value) => {
                  setItem({
                    iof: value,
                    iofFormatado: formattedValue
                  })
                }}
              />
            </div>
          </Col>
        )}

      <Col lg="6" xl="6" className="lancamento despesa receita investimento divida">
        <div>
          <label>Descrição</label>
          <textarea
            id="lancamentoOQue"
            onChange={(e) => {
              setItem({
                descricao: e.target.value
              })
            }}
            value={item?.descricao}
            style={{ padding: 20 }}
          ></textarea>
        </div>
      </Col>

      <Col lg="12" xl="12" style={{ display: 'flex', justifyContent: 'center' }}>
        <SaveButton
          disabled={!validForm}
          transaction={{
            ...item,
            // @ts-expect-error
            meioPagamento: item?.meioPagamento ?? paymentMethod
          }}
        />
      </Col>
    </Row>
  )
}
