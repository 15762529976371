import React, { useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'
import { Card, Table } from 'reactstrap'

import { useTheme } from '../../hooks'
import { useCoreContext } from '../../hooks/contexts/coreContext/coreProvider'
import { usePostTipoOrcamentoPrevistoRealizado } from '../../queries/dashboard'

const reordenacaoListaPorNome = (array, nomes) => {
  if (!array || !nomes) return []
  nomes?.forEach(function (nome, i) {
    for (let j = 0; j < array?.length; j++) {
      const item = array[j]
      if (item.nome === nome) {
        const aux = array[i]
        array[i] = item
        array[j] = aux
      }
    }
  })
}

export const SumarioPrevistoRealizadoGeral = () => {
  const { familiaId: familyId, startDate, endDate } = useCoreContext()
  const { theme } = useTheme()
  const {
    postTipoOrcamentoPrevistoRealizadoAsync,
    orcamentoPrevistoRealizado,
    isLoadingPostTipoOrcamentoPrevistoRealizado
  } = usePostTipoOrcamentoPrevistoRealizado()

  let estimadoTotal = 0
  let realizadoTotal = 0

  const reordenadoOrcamentoPrevistoRealizado = orcamentoPrevistoRealizado
  reordenacaoListaPorNome(reordenadoOrcamentoPrevistoRealizado, [
    'Receitas',
    'Despesas',
    'Investimentos',
    'Dívidas'
  ])

  if (reordenadoOrcamentoPrevistoRealizado) {
    reordenadoOrcamentoPrevistoRealizado?.forEach((item) => {
      estimadoTotal += item.nome === 'Receitas' ? item.estimado : -item.estimado
      realizadoTotal += item.nome === 'Receitas' ? item.totalMedio : -item.totalMedio
    })
  }

  useEffect(() => {
    if (!startDate || !endDate) return
    postTipoOrcamentoPrevistoRealizadoAsync({
      familyId: familyId || undefined,
      filter: {
        DataFinal: endDate,
        DataInicial: startDate,
        Origem: 'Rightbar:Resumo',
        Cliente: 'Web'
      }
    })
  }, [endDate, familyId, postTipoOrcamentoPrevistoRealizadoAsync, startDate])

  return (
    <Card className="shadow home-summary-estimated-realizaded-card-dark" style={{ borderRadius: 15 }}>
      {isLoadingPostTipoOrcamentoPrevistoRealizado ? (
        <Table>
          <thead style={{ borderBottom: '1px solid #dddddd' }}>
            <tr>
              <th></th>
              <th>
                <h3
                  className="mb-0 chart-title"
                  style={{
                    textTransform: 'none',
                    letterSpacing: 0,
                    fontSize: '12px',
                    color: theme.colors.secondary
                  }}
                >
                  Estimado
                </h3>
              </th>
              <th>
                <h3
                  className="mb-0 chart-title"
                  style={{
                    textTransform: 'none',
                    letterSpacing: 0,
                    fontSize: '12px',
                    color: theme.colors.primary
                  }}
                >
                  Realizado
                </h3>
              </th>
            </tr>
          </thead>
          <tbody>
            {Array.from({ length: 5 })?.map((item, index) => {
              return (
                <tr key={index}>
                  <td
                    style={{
                      whiteSpace: 'normal',
                      paddingLeft: '1rem',
                      fontSize: 11
                    }}
                  >
                    <div>
                      <Skeleton height={10} />
                    </div>
                  </td>
                  <td style={{ color: theme.colors.secondary, fontSize: '12px' }}>
                    <Skeleton height={10} />
                  </td>
                  <td style={{ color: theme.colors.primary, fontSize: '12px' }}>
                    <Skeleton height={10} />
                  </td>
                </tr>
              )
            })}
          </tbody>

          <tfoot>
            <tr style={{ borderTop: '1px solid #dddddd' }}>
              <td>Saldo</td>
              <td style={{ fontSize: '12px', color: theme.colors.secondary }}>
                <Skeleton height={10} />
              </td>
              <td style={{ fontSize: '12px', color: theme.colors.primary }}>
                <Skeleton height={10} />
              </td>
            </tr>
          </tfoot>
        </Table>
      ) : (
        <Table>
          <thead style={{ borderBottom: '1px solid #dddddd' }}>
            <tr>
              <th></th>
              <th>
                <h3
                  className="mb-0 chart-title"
                  style={{
                    textTransform: 'none',
                    letterSpacing: 0,
                    fontSize: '12px',
                    color: theme.colors.secondary
                  }}
                >
                  Estimado
                </h3>
              </th>
              <th>
                <h3
                  className="mb-0 chart-title"
                  style={{
                    textTransform: 'none',
                    letterSpacing: 0,
                    fontSize: '12px',
                    color: theme.colors.primary
                  }}
                >
                  Realizado
                </h3>
              </th>
            </tr>
          </thead>
          <tbody>
            {reordenadoOrcamentoPrevistoRealizado?.map((item, index) => {
              const estimado = item.estimado != null ? item.estimado : 0
              const totalMedio = item.totalMedio != null ? item.totalMedio : 0

              return (
                <tr key={index}>
                  <td
                    style={{
                      whiteSpace: 'normal',
                      paddingLeft: '1rem',
                      fontSize: 11
                    }}
                  >
                    <div>
                      <b>{item.nome}</b>
                    </div>
                  </td>
                  <td style={{ color: theme.colors.secondary, fontSize: '12px' }}>
                    {estimado
                      .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                      .replace('R$', '')}
                  </td>
                  <td style={{ color: theme.colors.primary, fontSize: '12px' }}>
                    {totalMedio
                      .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                      .replace('R$', '')}
                  </td>
                </tr>
              )
            })}
          </tbody>

          {estimadoTotal != null && realizadoTotal != null && (
            <tfoot>
              <tr style={{ borderTop: '1px solid #dddddd' }}>
                <td>Saldo</td>
                <td style={{ fontSize: '12px', color: theme.colors.secondary }}>
                  {estimadoTotal
                    .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                    .replace('R$', '')}
                </td>
                <td style={{ fontSize: '12px', color: theme.colors.primary }}>
                  {realizadoTotal
                    .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                    .replace('R$', '')}
                </td>
              </tr>
            </tfoot>
          )}
        </Table>
      )}
    </Card>
  )
}
