import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import React, { useEffect } from 'react'

import { getTransactionType } from '../../../../../domain/lancamento/lancamento'
import { useTransactionContext } from '../../../../../hooks/contexts/transactionContext'
import { useStore } from '../../../../../hooks/useStore'
import { AlertModal } from '../../../../Modal'
import { formatCurrencyBRL } from '../../../../Utils'
import * as S from './styles'
import { DetailsTransactionProps } from './types'

export const DetailsDespesaTransaction = ({
  open,
  onClose,
  editingTransaction = false
}: DetailsTransactionProps) => {
  const { saveTransaction, isSubmitting, isSuccess, transaction } = useTransactionContext()
  const { findMeioPagamento, findOrcamento, findCategory } = useStore()
  const [radioValue, setRadioValue] = React.useState<number | null | undefined>(null)
  const { isParceled: parceled, isRepeated: repeated } = getTransactionType({
    transactionType: transaction?.tipoDeParcela ?? 99
  })
  const isParceled = parceled || repeated || transaction?.editInstallments
  const orcamentoLabel = findOrcamento(transaction?.orcamentoId)?.nome
  const categoryLabel = findCategory(transaction?.categoriaId)?.nome
  const meioPagamentoLabel = findMeioPagamento(transaction?.meioPagamentoId)?.nome

  let title = 'Criação'
  let subtitle: string | React.ReactNode = 'Você deseja criar este lançamento:'

  if (editingTransaction) {
    title = 'Edição'
    subtitle = 'Você deseja editar este lançamento:'
  }

  if (transaction?.editRepeated) {
    title = 'Edicão repetido'
    subtitle = <>Atenção, este é um lançamento repetido. Você deseja alterar:</>
  }

  if (transaction?.editInstallments) {
    title = 'Edição lançamento parcelado'
    subtitle = (
      <>
        Atenção, este é um lançamento parcelado.
        <br /> Você deseja alterar:
      </>
    )
  }

  const primaryButton = {
    text: 'Salvar',
    onClick: async () =>
      saveTransaction({
        parceled: radioValue ?? 0
      }),
    disabled: isSubmitting
  }

  const secondaryButton = {
    text: 'Cancelar',
    onClick: onClose,
    disabled: isSubmitting
  }

  const handleChange = (event) => {
    setRadioValue(Number(event.currentTarget.value))
  }

  const EditTransactionFields = () => {
    const editParceled = isParceled && editingTransaction

    if (editParceled) {
      return (
        <div>
          <S.Subtitle>Valor da parcela</S.Subtitle>
          <p>{formatCurrencyBRL(transaction?.valorParcela ?? 0)}</p>
        </div>
      )
    }

    return <></>
  }
  const CreateTransactionFields = () => {
    return (
      <>
        <div>&nbsp;</div>
        <div>
          <S.Subtitle>Valor{isParceled && ' total'}</S.Subtitle>
          <p>{formatCurrencyBRL(transaction?.valor ?? 0)}</p>
        </div>
        {isParceled && (
          <div>
            <S.Subtitle>Valor da parcela</S.Subtitle>
            <p>{formatCurrencyBRL((transaction?.valor ?? 0) / (transaction?.parcelas ?? 0))}</p>
          </div>
        )}
      </>
    )
  }

  useEffect(() => {
    if (isSuccess) onClose()
  }, [isSuccess, onClose])

  return (
    <AlertModal
      className="modal-despesa"
      onClose={onClose}
      open={open}
      primaryButton={primaryButton}
      secondaryButton={secondaryButton}
      title={`Resumo: ${title}`}
    >
      {!isParceled && <S.Title>{subtitle}</S.Title>}

      <S.Details>
        <div>
          <S.Subtitle>Orçamento</S.Subtitle>
          <p>{transaction?.orcamento?.nome || orcamentoLabel || '---'}</p>
        </div>

        <div>
          <S.Subtitle>Categoria</S.Subtitle>
          <p>{transaction?.categoria?.nome || categoryLabel || '---'}</p>
        </div>

        <div>
          <S.Subtitle>Forma de pagamento</S.Subtitle>
          <p>{transaction?.meioPagamento?.nome || meioPagamentoLabel || '---'}</p>
        </div>

        {editingTransaction ? <EditTransactionFields /> : <CreateTransactionFields />}
      </S.Details>

      {editingTransaction && isParceled && (
        <S.Form>
          <FormControl component="fieldset">
            <FormLabel component="legend">{subtitle}</FormLabel>
            <RadioGroup
              aria-label="transaction"
              name="transaction"
              value={radioValue}
              onChange={handleChange}
            >
              <FormControlLabel value={0} control={<Radio />} label="Todas as parcelas" />
              <FormControlLabel value={1} control={<Radio />} label="Somente esta parcela" />
              <FormControlLabel value={2} control={<Radio />} label="A partir desta parcela" />
            </RadioGroup>
          </FormControl>
        </S.Form>
      )}
    </AlertModal>
  )
}
