import { useMutation } from 'react-query'

import {
  getAllFamiliesByPlannerIdApi,
  getAllUsersByPlannerIdFilterApi
} from '../../domain/pessoa/api/getAllPessoasByFamily'

export const useGetAllUsersByPlannerIdFilter = () => {
  const {
    mutateAsync: getAllUsersByPlannerIdFilter,
    isLoading: isLoadingGetAllUsersByPlannerIdFilter,
    error: errorGetAllUsersByPlannerIdFilter,
    data: dataGetAllUsersByPlannerIdFilter
  } = useMutation(getAllUsersByPlannerIdFilterApi, {
    retry: 1
  })

  return {
    getAllUsersByPlannerIdFilter,
    isLoadingGetAllUsersByPlannerIdFilter,
    errorGetAllUsersByPlannerIdFilter,
    dataGetAllUsersByPlannerIdFilter
  }
}

export const useGetAllFamiliesByPlannerId = () => {
  const {
    mutateAsync: getAllFamiliesByPlannerId,
    isLoading: isLoadingGetAllFamiliesByPlannerId,
    error: errorGetAllFamiliesByPlannerId,
    data: dataGetAllFamiliesByPlannerId
  } = useMutation(getAllFamiliesByPlannerIdApi, {
    retry: 1
  })

  return {
    getAllFamiliesByPlannerId,
    isLoadingGetAllFamiliesByPlannerId,
    errorGetAllFamiliesByPlannerId,
    dataGetAllFamiliesByPlannerId
  }
}
