import React, { useEffect, useState } from 'react'

import { InnerContent,Title } from './styles'
import * as S from './styles'
import { NewModalProps } from './types'

export const NewModal = ({
  mode = 'confirm',
  children,
  className,
  closeable = true,
  confirmIsDisabled = false,
  onClose,
  open = false,
  secondaryButton,
  title,
  size,
  toastyType = 'success'
}: NewModalProps) => {
  const [startAnimation, setStartAnimation] = useState(false)

  useEffect(() => {
    const keysListener = (event: KeyboardEvent) => {
      if (event.key === 'Escape') onClose?.()
    }

    document.addEventListener('keydown', keysListener)

    return () => {
      document.removeEventListener('keydown', keysListener)
    }
  }, [onClose, secondaryButton, confirmIsDisabled])

  useEffect(() => {
    if (mode === 'toasty' && open) {
      setTimeout(() => {
        setStartAnimation(true)
      }, 500)
    }
  }, [mode, open])

  useEffect(() => {
    if (startAnimation) {
      setTimeout(() => {
        setStartAnimation(false)
      }, 2500)
    }
  }, [startAnimation])

  if (mode === 'toasty') {
    return (
      <S.Toasty open={open} toastyType={toastyType} startAnimation={startAnimation}>
        <S.ToastyType toastyType={toastyType}>
          <span className="ni ni-check-bold" />
        </S.ToastyType>
        <S.ToastyContent>
          {title && <Title>{title}</Title>}
          {children}
        </S.ToastyContent>
      </S.Toasty>
    )
  }

  return (
    <S.Modal
      className={className}
      closeable={closeable}
      hideBackground={true}
      onClose={onClose}
      open={open}
      size={size}
    >
      <InnerContent>
        {title && <Title>{title}</Title>}
        {children}
      </InnerContent>
    </S.Modal>
  )
}
