import React from 'react'
import { Oval } from 'react-loader-spinner'

import { LoadingBackground } from '../Loading'

export default () => {
  return (
    <LoadingBackground>
      <Oval />
      <p>Carregando...</p>
    </LoadingBackground>
  )
}
