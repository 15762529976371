import { useTheme } from '@mui/material'
import React from 'react'

import { LiquidButton } from '../../Button'
import * as S from './styles'
import { ICustomActionBar } from './types'

export const CustomActionBar = ({
  // onClear,
  // onCancel,
  onSetToday,
  onSetOneMonth,
  onSetThreeMonths,
  onSetSixMonths,
  // onSetOneYear,
  onSetCurrentMonth,
  actions,
  className,
  activeFilter
}: ICustomActionBar) => {
  const { palette } = useTheme()
  if (actions == null || actions.length === 0) {
    return null
  }

  const ACTIONS_MAP = {
    // clear: {
    //   label: 'Limpar',
    //   onClick: onClear
    // },
    // cancel: {
    //   label: 'Cancelar',
    //   onClick: onCancel
    // },
    today: {
      label: 'Hoje',
      onClick: onSetToday
    },
    currentMonth: {
      label: 'Mês atual',
      onClick: onSetCurrentMonth
    },
    oneMonth: {
      label: '1 mês',
      onClick: onSetOneMonth
    },
    threeMonths: {
      label: '3 meses',
      onClick: onSetThreeMonths
    },
    sixMonths: {
      label: '6 meses',
      onClick: onSetSixMonths
    }
    // oneYear: {
    //   label: '1 ano',
    //   onClick: onSetOneYear
    // }
  }

  return (
    <S.Container className={className} pallete={palette}>
      {actions?.map((action) => {
        const isActive = action === activeFilter

        return (
          <LiquidButton
            key={action}
            onClick={() => ACTIONS_MAP[action]?.onClick?.()}
            disabled={isActive}
            color="secondary"
            variant={isActive ? 'contained' : 'outlined'}
          >
            {ACTIONS_MAP[action].label}
          </LiquidButton>
        )
      })}
    </S.Container>
  )
}
