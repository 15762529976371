import { IconButton } from '@mui/material'
import { NavLink } from 'react-router-dom'
import { Navbar } from 'reactstrap'
import Collapse from 'reactstrap/lib/Collapse'
import styled, { css } from 'styled-components'

import Fitas from '../../theme/fitas'
import { ClientSelector } from './ClientSelector'
import { NavigationLinks as NavigationsLinksBase } from './components/NavigationLinks'

export const NavigationsLinks = styled(NavigationsLinksBase)`
  padding: 0 1.5rem 1.5rem;
  margin: auto !important;

  @media screen and (min-width: 768px) {
    padding-bottom: 5rem;
  }
`

export const Logo = styled.div<{ $width: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  height: 100%;
  flex: 1;

  img {
    max-width: 160px;
    width: ${({ $width }) => $width};
  }

  @media screen and (min-width: 768px) {
    height: auto;

    img {
      max-width: auto;
    }
  }
`

export const collapsedNavBarWidth = '80px'

export const Nav = styled(Navbar)`
  display: flex;
  flex-direction: column;
  padding: 0 !important;
  transition: width 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);

  &.navbar-expand-sm {
    max-width: ${collapsedNavBarWidth};

    ${NavigationsLinks} {
      display: none !important;
    }

    ${Logo} {
      padding: 5px 15px;
    }
  }
`

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  width: 100%;

  @media screen and (min-width: 768px) {
    display: block;
    height: 100%;
  }
`

export const SidebarMenuLink = styled(NavLink)<{ $tipoUsuario: number }>`
  &.active {
    background: ${({ $tipoUsuario, theme: { colors } }) =>
      $tipoUsuario <= 1 ? colors.secondaryDarker : colors.primaryDarker} !important;
    color: #fff !important;
  }
  &.active > .fa-angle-right {
    color: #fff !important;
    background: ${({ $tipoUsuario, theme: { colors } }) =>
      $tipoUsuario <= 1 ? colors.secondaryDarker : colors.primaryDarker} !important;
  }

  &.active,
  &.active i {
    color: #fff !important;
    background: ${({ $tipoUsuario, theme: { colors } }) =>
      $tipoUsuario <= 1
        ? colors.secondaryDarker
        : colors.backgroundSidebarManagerHighlightDarker || colors.primaryDarker} !important;
    border: none !important;
    border-image: none !important;
  }

  &[data-inactive] {
    opacity: 0.5;
  }
`

export const IconCollapse = styled(IconButton)`
  background-color: ${({ theme }) => theme?.colors?.secondaryDarker};
  svg {
    fill: ${({ theme }) => theme?.colors?.secondaryLighter};
  }
`

export const Version = styled.span<{ expanded: boolean }>`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.secondaryDarker};
  border-top: 1px solid ${({ theme }) => theme.colors.secondaryLighter};
  bottom: 0;
  color: white;
  display: none;
  font-size: 0.7rem;
  justify-content: space-between;
  padding: 0.5rem 1rem 0.5rem 1.1rem;
  position: fixed;
  text-align: center;
  width: ${({ expanded }) => (expanded ? '250px' : collapsedNavBarWidth)};
  z-index: 10;

  ${({ expanded }) =>
    !expanded &&
    css`
      justify-content: center;

      > span {
        display: none;
      }

      ${IconCollapse} {
      }
    `}

  @media screen and (min-width: 768px) {
    display: flex;
  }
`

export const FamilyBackground = styled.div<{ background: string }>`
  background: ${({ background }) => background};
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  height: 70px;
  justify-content: center;
  margin-top: 10px;
  padding-left: 45;
  position: relative;
  width: 90%;

  @media screen and (min-width: 768px) {
    width: 80%;
    margin: auto;
    margin-left: 10px;
    margin-top: 0.7rem;
    margin-bottom: 0.7rem;
    width: 80%;
    margin: auto;
    margin-left: 10px;
    margin-top: 0.7rem;
    margin-bottom: 0.7rem;
  }
`

export const CollapseContainer = styled(Collapse)`
  padding-bottom: 65px;
`

export const ClientSelect = styled(ClientSelector)`
  margin: 0 auto 1.5rem;
  width: 95%;
`

export const UserTypeBanner = styled(Fitas)`
  margin: 8px 40px;
  width: 100%;

  @media screen and (min-width: 768px) {
    margin: 0 auto 1rem;
    width: 175px;
  }
`
