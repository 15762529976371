import React, { useEffect } from 'react'

import { NewModal } from '../NewModal'
import { ModalSizesProps } from '../NewModal/types'
import { Button, ButtonContainer,InnerContent, Title } from './AlertModal.styles'

interface AlertModalProps extends ModalSizesProps {
  children: React.ReactNode
  open: boolean
  mode?: 'confirm' | 'toasty'
  onClose: () => void
  primaryButton?: {
    disabled?: boolean
    text?: string
    onClick?: () => void
  }
  secondaryButton?: {
    disabled?: boolean
    text: string
    onClick?: () => void
  }
  hidePrimaryButton?: boolean
  hideSecondaryButton?: boolean
  title: string
  className?: string
  toastyType?: 'success' | 'error' | 'warning' | 'info'
}

export const AlertModal = ({
  mode = 'confirm',
  children,
  className,
  open,
  onClose,
  primaryButton = {
    disabled: false,
    text: 'Ok',
    onClick: onClose
  },
  secondaryButton,
  title,
  size = 'medium',
  toastyType = 'success',
  hidePrimaryButton = false
}: AlertModalProps) => {
  useEffect(() => {
    const keysListener = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && !primaryButton?.disabled) onClose?.()
    }

    document.addEventListener('keydown', keysListener)

    return () => {
      document.removeEventListener('keydown', keysListener)
    }
  }, [onClose, primaryButton, secondaryButton])

  return (
    <NewModal
      mode={mode}
      open={open}
      onClose={() => onClose?.()}
      closeable={false}
      className={className}
      size={size}
      toastyType={toastyType}
    >
      <InnerContent>
        <Title>{title}</Title>
        {children}

        {mode !== 'toasty' && (
          <ButtonContainer>
            {!hidePrimaryButton && (
              <Button
                onClick={(event) => {
                  event.stopPropagation()
                  event.preventDefault()
                  primaryButton.onClick?.()
                }}
                disabled={primaryButton?.disabled}
                bgColor="red"
              >
                {primaryButton?.text}
              </Button>
            )}

            {secondaryButton && (
              <Button
                onClick={(event) => {
                  event.stopPropagation()
                  event.preventDefault()
                  secondaryButton?.onClick?.()
                }}
                disabled={secondaryButton.disabled}
                bgColor="darkGray"
              >
                {secondaryButton.text}
              </Button>
            )}
          </ButtonContainer>
        )}
      </InnerContent>
    </NewModal>
  )
}
