import React from 'react'
import { confirmAlert } from 'react-confirm-alert'
import Switch from 'react-switch'
import styled from 'styled-components'

import { Account,Connection } from '../../../domain/bankingIntegration/Connection'
import Colors from '../../../domain/Colors'
import { useLinkAccountToMeioPagamentoMutation } from '../../../queries/bankingIntegration/mutations'
import { useActiveOrInactiveAccountById } from '../../../queries/investments/mutations'
import { noticeError } from '../../../services/Monitoring'
import { NewMeioPagamentoButton } from '../../Buttons'
import { MeioPagamentoSelect } from '../../Selects'
import { InvestmentsByItemIdTable } from '..'

const StyledMeioPagamentoSelect = styled(MeioPagamentoSelect)`
  width: 85%;
  padding: 0px !important;
  height: 2.5rem;
  border: 1px solid ${Colors.purple};
  line-height: 21px;
  font-size: 14px;
`

interface Props {
  connection: Connection
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1vw;
`

const Row = styled.div<{ index?: number }>`
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  width: 100%;
  align-items: center;
  align-content: start;
  height: 3rem;
  background-color: ${({ index }) =>
    index !== undefined && index % 2 === 0 ? Colors.tableRowGray : Colors.white};
`

const HeaderValue = styled.div<{ size: number }>`
  height: 3rem;
  justify-content: center;
  align-items: center;
  display: flex;
  font-weight: bold;
  flex: ${({ size }) => size};
`

const Value = styled.div<{ size: number }>`
  height: 2.8rem;
  justify-content: center;
  align-items: center;
  display: flex;
  flex: ${({ size }) => size};
`

interface ConnectionRowProps {
  account: Account
  index: number
}

const AccountRow = ({
  account: { id, descricao, meioPagamentoId, isActive, itemId },
  index
}: ConnectionRowProps) => {
  const { linkAccountToMeioPagamento, isLinkError, linkError } = useLinkAccountToMeioPagamentoMutation()
  const { activeOrInactiveAccountByIdAsync } = useActiveOrInactiveAccountById()
  const changeMeio = async (meio: number) => {
    await linkAccountToMeioPagamento({ accountId: id, meioPagamentoId: meio })
    let message = 'Alteração realizada com sucesso. '
    message += 'A partir de agora, os novos lançamentos serão associados a esse meio de pagamento.'

    confirmAlert({
      title: 'Informação',
      message,
      buttons: [
        {
          label: 'Ok',
          onClick: () => {}
        }
      ]
    })
  }
  if (isLinkError && linkError) noticeError(linkError)

  return (
    <Row key={`account_${id}`} index={index}>
      <Value size={2}>{descricao}</Value>
      <Value size={2}>
        <StyledMeioPagamentoSelect onChange={changeMeio} selectedId={meioPagamentoId} />
      </Value>
      <Value size={1}>
        <NewMeioPagamentoButton />
      </Value>
      <Value size={1}>
        <Switch
          onChange={() => activeOrInactiveAccountByIdAsync({ id, isActive: !isActive, itemId })}
          checked={isActive}
        />
      </Value>
    </Row>
  )
}

export default ({ connection }: Props) => {
  const { id } = connection

  return (
    <Container key={`connection_${id}`}>
      {connection.contas.length > 0 &&
        connection.contas.map((account, index) => (
          <>
            {index === 0 && (
              <Row>
                <HeaderValue size={2}>Descrição</HeaderValue>
                <HeaderValue size={2}>Meio de Pagamento</HeaderValue>
                <HeaderValue size={1}></HeaderValue>
                <HeaderValue size={1}>Ativo / Suspenso</HeaderValue>
              </Row>
            )}
            <AccountRow key={`account_row_${index}`} account={account} index={index} />
          </>
        ))}

      {connection.contas.length > 0 ? (
        connection.contas.map(
          (account, index) =>
            account.tipo === 'BANK' && (
              <InvestmentsByItemIdTable key={`transaction_row_${index}`} itemId={id} />
            )
        )
      ) : (
        <InvestmentsByItemIdTable key={`transaction_row_${id}`} itemId={id} />
      )}
    </Container>
  )
}
