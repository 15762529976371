import React from 'react'

import { TrashCanIcon } from '../../../Icons'
import * as S from './styles'

export const ButtonDelete = ({ id, name, handleDelete, isManual = false }) => {
  return (
    <S.CellContainer>
      <TrashCanIcon
        size="small"
        tooltip={`Deletar '${name}'`}
        onClick={() => handleDelete({ id, name, isManual })}
      />
    </S.CellContainer>
  )
}
