global.getPublicUrl = () => {
  const publicUrl = process.env.PUBLIC_URL
  const pathParts = publicUrl.split('/')
  const contextPath = pathParts[pathParts.length - 1]
  return window.location.origin + '/' + contextPath
}

global.bodyScroll = (open) => {
  const body = document.getElementsByTagName('html')[0]
  const className = '--modal-open'
  const hasClassName = body.classList.contains(className)

  if (open && !hasClassName) {
    body.classList.add(className)
  }

  if (!open && hasClassName) {
    body.classList.remove(className)
  }
}

const baseApiUrl = process.env.REACT_APP_API_BASE_URL
global.server_api_new = baseApiUrl

global.termoId = '8a6b8fce-7340-4170-9f60-dd674fe6b4d4'
global.email = ''
global.senha = ''

global.idSonhoAposentadoria = 119

global.spinnerActive = true

if (['/v1/admin/home'].includes(window.location.pathname)) {
  global.spinnerActive = false
}

global.spinnerMessage = 'Processando...'
global.estados = [
  'AC',
  'AL',
  'AP',
  'AM',
  'BA',
  'CE',
  'DF',
  'ES',
  'GO',
  'MA',
  'MT',
  'MS',
  'MG',
  'PA',
  'PB',
  'PR',
  'PE',
  'PI',
  'RJ',
  'RN',
  'RS',
  'RO',
  'RR',
  'SC',
  'SP',
  'SE',
  'TO'
]
global.estadoCivil = ['Solteiro', 'Casado', 'Divorciado', 'Viúvo']
global.tipoEmpresa = ['PessoaFisica', 'PessoaJuridica']
global.tipoUsuario = ['Admin', 'Planejador', 'Pessoa']
//global.tipoRecorrencia = [ 'Mensal', 'Bimestral', 'Trimestral', 'Semestral', 'Anual' ];
global.tipoRecorrencia = ['Mensal', 'Avulso']
global.tipoOrcamento = ['Receita', 'Despesa', 'Investimento', 'Divida']
global.tipoMeioPagamento = [
  'Dinheiro',
  'Pré-pago / Vale / Ticket',
  'Cartão Crédito',
  'Instituição Financeira'
]
global.statusDivida = ['Aberto', 'Atrasado', 'Pago']
global.statusSonho = ['Não Iniciado', 'Em Progresso', 'Pausado', 'Concluído']
global.tipoPatrimonio = ['Imóvel', 'Veículo', 'Empresa', 'Investimento', 'Outros']
global.bandeira = ['Visa', 'Master', 'Elo', 'Amex', 'Diners', 'Hiper']
global.frequenciaCategoria = ['Mensal', 'Anual']
global.grupoDeCategoria = [
  'Alimentação',
  'Educação',
  'Lazer',
  'Moradia',
  'Transporte',
  'Receitas',
  'Investimento',
  'Dívida',
  'Saúde',
  'Outros',
  'Pessoal',
  'Viagens',
  'Bem Estar',
  'Trabalho',
  'Impostos',
  'Integração Bancária'
]
global.statusDaCategoria = ['Ativa', 'Historica']
global.indexador = ['TR', 'PRE', 'CDI', 'OUTRO']
global.amortizacao = ['SAC', 'PRICE', 'OUTRO']
global.statusFamilia = ['Aberto', 'Pago', 'Atrasado']

global.proRata7Dias = true

global.grupoCategoriaSonho = {
  '-1': 'Outros',
  1: 'Viagens',
  2: 'Aventuras',
  3: 'Pessoal',
  4: 'Esportes',
  5: 'Carreira / Educação',
  6: 'Família',
  7: 'Saúde / Bem Estar',
  8: 'Generosidade',
  9: 'Compras',
  10: 'Aposentadoria'
}

global.tipoInvestimento = {
  1: 'Fundos',
  2: 'Previdência',
  6: 'Renda Fixa Pré-fixado',
  3: 'Renda Fixa Pós-fixado',
  4: 'Tesouro Direto',
  5: 'Poupança',
  8: 'Ações, FII, ETFs',
  9: 'Debêntures',
  10: 'Moedas',
  99: 'Produto Customizado'
  //7: 'Bitcoin',
}

global.naturezaFundo = { 1: 'Fundo', 2: 'Previdência' }
global.metodologiaDePreco = { 1: 'Por Valor', 2: 'Por Variação' }
global.tipoRetirada = [
  { value: 1, label: 'Resgate' },
  { value: 3, label: 'Come Cotas' }
]
global.operacao = [
  { value: 0, label: 'Aplicação' },
  { value: 1, label: 'Resgate' },
  { value: 2, label: 'Saldo Inicial' }
]

global.operacao2 = ['Aplicação', 'Resgate', 'Saldo Inicial']

global.taxa = 0.4
global.inflacao = 4
global.planosCarteiras = [1, 2]
global.planosCarteiras2 = { 1: 'Plano Individual', 2: 'Plano Família' }
global.carenciaCobranca = 7

global.tipoConsolidacao = {
  1: 'Desde o Início',
  2: 'Ano Corrente',
  3: 'Últimos 12 Meses'
}
global.tipoGrafico = { 1: 'Geral', 2: 'Rentabilidade' }

global.spinnerShow = function ($) {
  var html = $('html') //IE7 won't have that
  html.css('overflow', 'hidden')
  // window.scrollTo(0, 0)
  $('._loading_overlay_wrapper').show()
}

global.spinnerHide = function ($, scrollPosition) {
  // const currentScroll = [
  //   document.documentElement.scrollLeft || document.body.scrollLeft,
  //   document.documentElement.scrollTop || document.body.scrollTop
  // ]
  // const scroll = scrollPosition ?? currentScroll

  $('._loading_overlay_wrapper').hide()
  $('html').css('overflow', 'auto')
  // window.scrollTo(scroll[0], scroll[1])
}

global.showModal = (skyLightModal) => {
  // if (typeof window.scrollTo === 'function') {
  //   window.scrollTo(0, 0)
  // }
  skyLightModal.show()
}

global.logarErroDeRequisicao = function (error) {
  // Error 😨
  if (error.response) {
    /*
     * The request was made and the server responded with a
     * status code that falls out of the range of 2xx
     */
    console.log(error.response.data)
    console.log(error.response.status)
    console.log(error.response.headers)
  } else if (error.request) {
    /*
     * The request was made but no response was received, `error.request`
     * is an instance of XMLHttpRequest in the browser and an instance
     * of http.ClientRequest in Node.js
     */
    console.log(error.request)
  } else {
    // Something happened in setting up the request and triggered an Error
    console.log('Error', error.message)
  }

  console.log(error.config)
}

global.maskCurrency = function (e) {
  var value = e.target.value
  var formattedValue = value

  if (value !== '') {
    value = value.replace(/\D/g, '')
    value = parseFloat(parseInt(value) / 100)

    formattedValue = value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }).replace('R$', '')
  }

  e.target.value = formattedValue
}

global.textLabels = {
  body: {
    noMatch: 'Nenhum registro encontrado',
    toolTip: 'Ordenar',
    columnHeaderTooltip: (column) => `Ordenar por ${column.label}`
  },
  pagination: {
    next: 'Próxima',
    previous: 'Anterior',
    rowsPerPage: 'Registros por página:',
    displayRows: 'de'
  },
  toolbar: {
    search: 'Procurar',
    downloadCsv: 'Baixar CSV',
    print: 'Imprimir',
    viewColumns: 'Ver Colunas',
    filterTable: 'Filtrar Tabela'
  },
  filter: {
    all: 'Todos',
    title: 'FILTROS',
    reset: 'LIMPAR'
  },
  viewColumns: {
    title: 'Mostrar Colunas',
    titleAria: 'Mostrar/Esconder colunas'
  },
  selectedRows: {
    text: 'registro(s) selecionado(s)',
    delete: 'Remover',
    deleteAria: 'Remover registros selecionados'
  }
}

global.diferencaEntreDatasEmDias = function (dataInicio, dataFim) {
  var diffMilissegundos = dataFim - dataInicio
  var diffSegundos = diffMilissegundos / 1000
  var diffMinutos = diffSegundos / 60
  var diffHoras = diffMinutos / 60
  var diffDias = diffHoras / 24
  return diffDias
}

global.retornoMes = (mesExt) => {
  if (mesExt === 'Jan') {
    return '01'
  } else if (mesExt === 'Fev') {
    return '02'
  } else if (mesExt === 'Mar') {
    return '03'
  } else if (mesExt === 'Abr') {
    return '04'
  } else if (mesExt === 'Mai') {
    return '05'
  } else if (mesExt === 'Jun') {
    return '06'
  } else if (mesExt === 'Jul') {
    return '07'
  } else if (mesExt === 'Ago') {
    return '08'
  } else if (mesExt === 'Set') {
    return '09'
  } else if (mesExt === 'Out') {
    return '10'
  } else if (mesExt === 'Nov') {
    return '11'
  } else if (mesExt === 'Dez') {
    return '12'
  }
}

global.convertToFloat = (dado) => {
  if (dado === null || dado === undefined) {
    return 0
  }

  let temp = dado + ''
  temp = temp.trim()

  temp = temp.replace('R$', '').replace(/\s/, '')

  if (temp.includes(',')) {
    return parseFloat(temp.replaceAll('.', '').replaceAll(',', '.'))
  } else {
    return parseFloat(temp.trim())
  }
}

global.version = require('../package.json').version
