import React from 'react'
import styled from 'styled-components'

import trashCan from '../../assets/img/theme/trash-can.png'
import { Size, withSize } from '../withSize'
import Icon from './Icon'

interface Props {
  size?: Size
  onClick?: () => void
  tooltip?: string
  noMargin?: boolean
}

const StyledIcon = styled(Icon)<{ size?: Size }>`
  max-width: ${({ size }) => withSize(size)};
  max-height: ${({ size }) => withSize(size)};
  cursor: pointer;
`

export default ({ onClick, tooltip, size, noMargin = false }: Props) => (
  <StyledIcon
    noMargin={noMargin}
    alt="Deletar"
    size={size}
    src={trashCan}
    onClick={onClick}
    tooltip={tooltip}
  />
)
