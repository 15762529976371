import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import React from 'react'

import { ButtonDefault } from '../Mui/Button'

export const DownloadExcelDefault = () => {
  return (
    <ButtonDefault
      startIcon={<CloudDownloadIcon />}
      href="/modeloExcelPadraoVista.xlsx"
      // @ts-expect-error change component type to anchor
      download
      component="a"
    >
      Excel Padrão Vista
    </ButtonDefault>
  )
}
