import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'reactstrap'

import { NewBankIntegrationButton } from '../../../components/Buttons'
import ErrorMessageModal, { MessageComponentProps } from '../../../components/ErrorHandling/ErrorMessageModal'
import { PageFirstRow } from '../../../components/Headers'
import { ConnectionsTable } from '../../../components/Tables'
import { asyncDelay } from '../../../domain/timing'
import { useCoreContext } from '../../../hooks/contexts/coreContext/coreProvider'
import { useLoadingScreen } from '../../../hooks/useLoadingScreen'
import { useLinkAccountToMeioPagamentoMutation } from '../../../queries/bankingIntegration/mutations'
import { useBankIntegrationConnections } from '../../../queries/bankingIntegration/queries'
import { useFamilyMeiosPagamentos } from '../../../queries/meioPagamento/queries'

export const Integracoes = () => {
  const { familiaId: familyId } = useCoreContext()
  const { setIsLoading } = useLoadingScreen()
  const { isLoadingConnections, isErrorConnections, connectionsError, refetchConnections } =
    useBankIntegrationConnections(familyId ?? undefined)
  const { meiosPagamentos, isLoadingMeios, isErrorMeios, meiosPagamentoError } = useFamilyMeiosPagamentos(
    familyId ?? undefined
  )
  const [isDeletingConnection, setIsDeletingConnection] = useState(false)
  const [connectionDeletionError, setConnectionDeletionError] = useState<Error | null>()
  const isConnectionDeletionError = !!connectionDeletionError
  const { isLinkError, isLinking, linkError } = useLinkAccountToMeioPagamentoMutation()

  const isError = isErrorMeios || isErrorConnections || isConnectionDeletionError
  const isLoading = isLoadingMeios || isLoadingConnections || isDeletingConnection || isLinking

  // useEffect(() => global.mostrarAjudaDaPaginaAutomaticamente, [])
  useEffect(() => {
    const getIsLoadingMessage = () => {
      if (isLoadingMeios) return 'Carregando meios de pagamento...'
      if (isLoadingConnections) return 'Carregando conexões bancárias...'
      if (isDeletingConnection) return 'Deletando conexão bancária...'
      return 'Carregando...'
    }
    setIsLoading(isLoading, getIsLoadingMessage())
  }, [isLoadingMeios, isLoadingConnections, isDeletingConnection, isLoading, setIsLoading])

  const getErrorMessages = () => {
    const tags = {
      familyId: familyId?.toString(),
      meiosPagamento: JSON.stringify(meiosPagamentos)
    }
    const errorMessages: MessageComponentProps[] = []
    const title = 'Erro de integração'
    if (isConnectionDeletionError)
      errorMessages.push({
        title,
        text: 'Erro ao deletar conexão',
        error: connectionDeletionError,
        tags
      })
    if (isErrorConnections)
      errorMessages.push({
        title,
        text: `Erro ao recuperar conexões da família ${familyId}`,
        error: connectionsError,
        tags
      })
    if (isErrorMeios)
      errorMessages.push({
        title,
        text: `Erro ao recuperar meios de pagamento da família ${familyId}`,
        error: meiosPagamentoError,
        tags
      })
    if (isLinkError)
      errorMessages.push({
        title,
        text: 'Erro ao associar meio de pagamento',
        error: linkError,
        tags
      })
    return errorMessages
  }

  return (
    <Container fluid>
      <ErrorMessageModal messages={getErrorMessages()} on={isError} />
      <PageFirstRow title="Integrações">
        <NewBankIntegrationButton onSuccess={() => asyncDelay(800).then(refetchConnections)} />
      </PageFirstRow>
      <Row className="entry-list">
        <Col lg="12" xl="12" style={{ marginBottom: '15px' }}>
          <ConnectionsTable
            onConnectionDeletion={(isDeleting, isError, error) => {
              if (isDeletingConnection !== isDeleting) setIsDeletingConnection(isDeleting)
              if (isConnectionDeletionError !== isError) setConnectionDeletionError(error)
            }}
          />
        </Col>
      </Row>
    </Container>
  )
}
