import { Theme } from '@mui/material'
import styled from 'styled-components'

export const Container = styled.menu<{ pallete: Theme['palette'] }>`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  padding: 0 0 0 0.8rem;

  && {
    > label {
      font-size: 0.7rem;

      &.Mui-disabled {
        color: ${({ pallete }) => pallete.getContrastText(pallete.secondary.main)};
      }
    }
  }
`
