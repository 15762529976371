import styled from 'styled-components'

import colors from '../../../../../domain/Colors'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

export const TextArea = styled.textarea`
  border: 1px solid ${colors.grayBlue};
  border-radius: 0.5rem;
  padding: 1rem;
  height: 140px;
  resize: none;
`

export const Error = styled.span`
  color: red;
  font-size: 0.8rem;
`

export const LengthContainer = styled.span`
  display: flex;
`

export const Counter = styled.div`
  align-items: center;
  display: flex;
  font-size: 0.8rem;
  justify-content: space-between;
`

export const HiddenButton = styled.button`
  display: none;
`
