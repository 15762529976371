import { createTheme } from '@material-ui/core'
import { endOfDay, endOfMonth,startOfDay, startOfMonth } from 'date-fns'
import moment from 'moment'

export const dateRangeLimit = ({ startDate, endDate, maxYears = 8 }) => {
  if (!startDate || !endDate) {
    return {
      startDate: startOfDay(startOfMonth(new Date())),
      endDate: endOfDay(endOfMonth(new Date()))
    }
  }

  let dataInicial = startOfDay(startOfMonth(startDate))
  let dataFinal = endOfDay(endOfMonth(endDate))

  if (typeof dataInicial !== 'object') {
    dataInicial = moment(moment().format(dataInicial)).toDate()
  }

  if (typeof dataFinal !== 'object') {
    dataFinal = moment(moment().format(dataFinal)).toDate()
  }

  const diffDate = moment(dataFinal).diff(dataInicial, 'years')
  if (diffDate > maxYears) {
    dataInicial = moment(moment(dataFinal).subtract(maxYears, 'years')).toDate()
  }

  return {
    startDate: dataInicial,
    endDate: dataFinal,
    diffDate,
    validMaxYears: diffDate <= maxYears
  }
}

export const theme = createTheme({
  overrides: {
    // @ts-ignore
    MUIDataTableBodyCell: {
      root: { padding: 0 }
    },
    MuiTableRow: {
      root: {
        '&:nth-of-type(odd)': {
          backgroundColor: '#f8f9fe'
        },
        '& input': {
          background: 'transparent'
        }
      }
    },
    MUIDataTableSelectCell: {
      fixedHeaderCommon: {
        backgroundColor: 'unset'
      }
    },
    MuiTableCell: {
      root: {
        fontFamily: 'unset',
        borderTop: 'none',
        padding: '16px 10px'
      },
      footer: {
        color: '#FFF',
        fontWeight: 700
      }
    },
    MUIDataTableHeadCell: {
      fixedHeaderYAxis: {
        fontWeight: 700
      },
      fixedHeaderCommon: {
        zIndex: 0
      }
    },
    MuiPaper: {
      root: {
        overflow: 'hidden',
        margin: 0
      }
    },
    MuiToolbar: {
      regular: {
        backgroundColor: '#e4eaf4'
      }
    }
  }
})

export const TYPE_COLOR_MAP = {
  0: '#e7f4e3',
  1: '#f8dfe2',
  2: '#e3f3ff',
  3: '#f8dfe2'
}

export const obterCor = (tipo) => {
  return TYPE_COLOR_MAP[tipo]
}
