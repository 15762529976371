import React from 'react'
import styled from 'styled-components'

import Colors from '../../../domain/Colors'
import { formatMonetaryValue } from '../../../domain/formatter'
import { SimplifiedPlannerList } from '../../../domain/planner'
import { TableFooter } from '../Footer'
import { FooterRow } from '../Footer/Footer.styles'

const ValueContainer = styled.div`
  min-height: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  color: ${Colors.white};
`

interface Props {
  planners?: SimplifiedPlannerList
}

export default ({ planners }: Props) => (
  <TableFooter>
    <FooterRow>
      <ValueContainer>Valor total: {formatMonetaryValue(planners?.totalFee || 0)}</ValueContainer>
      <ValueContainer>Famílias ativas: {planners?.activeFamilies || 0}</ValueContainer>
      <ValueContainer>Famílias inativas: {planners?.inactiveFamilies || 0}</ValueContainer>
    </FooterRow>
  </TableFooter>
)
