import { Categoria } from '../../../components/Modal/DeleteTransaction/types'
import { postWithToken } from '../../../webClient'
import { ManyResults } from '../../Results'

// Todo: type filters when necessary
interface Filter {
  IncluirOrcamentos?: boolean
}

export const fetchCategoryByFamilyIdFiltroApi = async (familyId: number, filter: Filter) => {
  if (!familyId) {
    console.log('Missing family id when fetching category')
    return {
      categorias: [],
      totalResults: 0
    }
  }

  const url = `categoria/familia/${familyId}/filtro`
  const {
    data: { results: categorias, success, errorMsg, totalResults },
    status
  } = await postWithToken<ManyResults<Categoria>, Filter>(url, filter, {
    timeout: 15 * 1000
  })
  if (!success || status !== 200) {
    const msg = `Error getting lancamentos for family ${familyId} with filters ${JSON.stringify(filter)}`
    console.error(msg, errorMsg)
    throw new Error(msg)
  }
  return { categorias, totalResults }
}
