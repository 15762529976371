import { Typography } from '@mui/material'
import MUIDataTable from 'mui-datatables'
import styled, { css } from 'styled-components'

import colors from '../../../domain/Colors'

export const Table = styled(MUIDataTable)`
  && {
    border: 1px solid #e4eaf4;
    box-shadow: none;
    margin-bottom: 2rem;

    .inputCurrency {
      border: 1px solid #bfbfbf;
      padding: 0.15rem 0.3rem;
    }

    @media screen and (max-width: 960px) {
      td.MuiTableCell-root {
        height: auto;
        min-height: 40px;
        vertical-align: middle;
        padding: 0 0.5rem;
        display: inline-flex;
        align-items: center;
      }
    }
  }

  [class^='MuiToolbar-root-'] {
    margin-top: 15px;
    z-index: 2;
  }
`

export const TabContainer = styled.div`
  display: flex;
`

export const TabTitle = styled(Typography)<{ isActive: boolean }>`
  background: #e4eaf4;
  border-radius: 16px 16px 0 0;
  border-width: 1px 1px 0px 1px;
  border: 1px solid #e4eaf4;
  cursor: pointer;
  display: inline-block;
  line-height: 2rem;
  padding: 10px 5px;
  text-align: center;
  width: 33.33%;

  && {
    font-size: 0.9rem;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      border-bottom: none;
      background-color: ${colors.white};
      font-weight: bold;
    `}
`
