import MUIDataTable from 'mui-datatables'
import React from 'react'
import styled from 'styled-components'

import { TYPE_COLOR_MAP } from '../../../views/internas/GestaoOrcamento/utils'

const SMuiDataTable = styled(MUIDataTable)`
  && {
    &.MuiPaper-root .MuiToolbar-root.MuiToolbar-regular,
    [class*='MuiToolbar-root-'] {
      background-color: #e4eaf4;
      z-index: 2;
    }

    tbody {
      tr.MuiTableRow-root:nth-of-type(odd) {
        background-color: #f8f9fe;

        > td {
          background-color: #f8f9fe;
        }
      }

      tr.MuiTableRow-root:nth-of-type(even) {
        background-color: #fff;

        > td {
          background-color: #fff;
        }
      }

      tr {
        td {
          padding: 0.4rem 0.6rem;
        }
      }
    }

    &.--thin {
      tbody {
        tr {
          td {
            padding: 0;

            .expandable-row {
              padding: 1rem;
            }
          }
        }
      }

      table {
        tbody {
          .expandable-row {
            padding: 1rem;

            .MuiToolbar-root.MuiToolbar-regular.MuiToolbar-gutters {
              > [class*='MUIDataTableToolbar-actions-'] {
                display: flex;
                flex: 0;
                padding: 0 1rem;
                gap: 1rem;

                > button {
                  width: fit-content;
                }
                > span {
                  display: flex;
                }
              }
            }
          }
        }
      }
    }

    &.--colorable {
      tbody {
        tr {
          &.color-type-0 td {
            background-color: ${TYPE_COLOR_MAP[0]};
          }

          &.color-type-1 td {
            background-color: ${TYPE_COLOR_MAP[1]};
          }

          &.color-type-2 td {
            background-color: ${TYPE_COLOR_MAP[2]};
          }

          &.color-type-3 td {
            background-color: ${TYPE_COLOR_MAP[3]};
          }
        }
      }
    }

    &.--expandable {
      tbody {
        tr {
          td.MuiTableCell-paddingCheckbox {
            .MuiButtonBase-root {
              &.MuiIconButton-root {
                padding: 9px 0px;
                border-radius: 0;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
`

export const StyledMuiDataTable = (props) => {
  return <SMuiDataTable {...props} />
}
