import React, { useState } from 'react'

import { useUpdateInvestmentById } from '../../../../queries/investments/mutations'
import { InvestmentNameConfirmModal } from '../../../Modal'
import { EditableTextInput } from '../index'

interface Props {
  currentName: string
  investmentId: string
}

export const InvestmentNameInput = ({ currentName, investmentId }: Props) => {
  const { isLoadingUpdateInvestment, updateInvestment } = useUpdateInvestmentById()
  const [newName, setNewName] = useState(currentName)
  const isConfirmModalOpen = currentName !== newName
  const [inputIdentifier, setInputIdentifier] = useState(0)

  const onCancel = () => {
    setNewName(currentName)
    setInputIdentifier(inputIdentifier + 1)
  }

  return (
    <>
      {isConfirmModalOpen && (
        <InvestmentNameConfirmModal
          originalName={currentName}
          newName={newName}
          confirmDisabled={isLoadingUpdateInvestment}
          onCancel={onCancel}
          onConfirm={() => {
            updateInvestment({ pluggyInvestmentId: investmentId, name: newName })
            setNewName(currentName)
          }}
        />
      )}
      <EditableTextInput
        key={inputIdentifier}
        loading={isLoadingUpdateInvestment}
        initialInputValue={currentName}
        placeholder="Nome do investimento"
        onEdit={setNewName}
      />
    </>
  )
}
