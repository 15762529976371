import axios, { AxiosError, AxiosRequestConfig } from 'axios'
import { differenceInMinutes } from 'date-fns'

import { noticeError } from './services/Monitoring'
import { getCookie, resetAuthCookies } from './store/auth'

const getJwtTokenFromLocalStorage = async () => {
  try {
    const { jwt } = getCookie('TOKEN')
    return jwt ? 'Bearer ' + jwt : undefined
  } catch (err) {
    return undefined
  }
}

const JEST_WORKER_ID = process.env.JEST_WORKER_ID

const ErrorInterceptor = (error: AxiosError) => {
  if (JEST_WORKER_ID) return Promise.reject(error)
  const isWrongCredentialsError = error?.response?.data?.type === 'captcha_invalid_login'
  const COUNT = localStorage?.getItem('COUNT') || '0'
  const COUNT_DATE = localStorage?.getItem('COUNT_DATE')
    ? JSON.parse(localStorage?.getItem('COUNT_DATE') || '')
    : new Date()

  console.log('ERROR TYPE', error?.response?.data?.type)

  noticeError(error, {
    url: error.config.url,
    status: error?.response?.status,
    message: error.message
  })

  if ((error?.response?.status === 403 && !isWrongCredentialsError) || error?.isAxiosError) {
    let addCount
    const difference = differenceInMinutes(new Date(), COUNT_DATE)

    if (
      error?.response?.data?.message ===
      'User is not authorized to access this resource with an explicit deny'
    ) {
      window.location.href = '/auth/login'
      return
    }

    if (difference <= 1) {
      addCount = String(Number(COUNT) + 1)
      localStorage?.setItem('COUNT', addCount)
    } else {
      localStorage?.removeItem('COUNT_DATE')
      localStorage?.removeItem('COUNT')
    }

    if (Number(addCount) >= 30) {
      localStorage?.removeItem('jwt')
      localStorage?.removeItem('COUNT')
      localStorage?.removeItem('COUNT_DATE')
      resetAuthCookies()
      console.error('User is not authorized to access this resource with an explicit deny')
      window.location.href = '/auth/login'
      return
    }
  }
  return Promise.reject(error)
}

const bearerInterceptor = async (config: AxiosRequestConfig) => {
  const jwt = await getJwtTokenFromLocalStorage()
  const loggedIn = window.location.pathname.includes('/v1/admin')
  if (config.headers && jwt && loggedIn) config.headers.Authorization = jwt
  return config
}
axios.interceptors.request.use(bearerInterceptor)
axios.interceptors.response.use((r) => r, ErrorInterceptor)

export const s3Client = axios.create({
  baseURL: process.env['REACT_APP_THEMES_API'],
  timeout: 10 * 1000
})

export const bankIntegrationClient = axios.create({
  baseURL: process.env['REACT_APP_BANK_INTEGRATION_API'],
  timeout: 10 * 1000
})
bankIntegrationClient.interceptors.request.use(bearerInterceptor)

export const apiWebClient = axios.create({
  baseURL: process.env['REACT_APP_API_BASE_URL'],
  timeout: 10 * 1000
})
apiWebClient.interceptors.request.use(bearerInterceptor)
apiWebClient.interceptors.response.use((r) => r, ErrorInterceptor)

export const extratosApi = axios.create({
  baseURL: process.env['REACT_APP_API_EXTRATOS']
})
extratosApi.interceptors.request.use(bearerInterceptor)

export const investmentsClient = axios.create({
  baseURL: process.env['REACT_APP_INVESTMENTS_URL'],
  timeout: 10 * 1000,
  validateStatus: () => true
})
investmentsClient.interceptors.request.use(bearerInterceptor)

export const annotationsApi = axios.create({
  baseURL: process.env['REACT_APP_API_ANNOTATIONS']
})
annotationsApi.interceptors.request.use(bearerInterceptor)

export const authClient = axios.create({
  baseURL: process.env['REACT_APP_AUTH_BASE_URL'],
  timeout: 10 * 1000,
  validateStatus: () => true
})

const apiWebClientV2 = axios.create({
  baseURL: process.env['REACT_APP_AUTH_BASE_URL'],
  timeout: 30 * 1000
})
apiWebClientV2.interceptors.request.use(bearerInterceptor)
apiWebClientV2.interceptors.response.use((r) => r, ErrorInterceptor)

export const postWithToken = <Response, Body>(url: string, body?: Body, config?: AxiosRequestConfig) => {
  return apiWebClient.post<Response>(`${url}`, body, config)
}

export const putWithToken = <Response, Body>(url: string, body?: Body) => {
  return apiWebClient.put<Response>(`${url}`, body)
}

export const deleteWithToken = <Response, Body>(url: string, body?: Body, config?: AxiosRequestConfig) => {
  return apiWebClient.delete<Response>(`${url}`, { ...config, data: body })
}

export const getWithToken = <Response>(url: string, config?: AxiosRequestConfig) => {
  return apiWebClient.get<Response>(`${url}`, config)
}

export const getWithTokenApiV2 = <Response>(url: string, config?: AxiosRequestConfig) => {
  return apiWebClientV2.get<Response>(`${url}`, config)
}

export const patchWithTokenApiV2 = <Response, Body>(
  url: string,
  body?: Body,
  config?: AxiosRequestConfig
) => {
  return apiWebClientV2.patch<Response>(`${url}`, body, config)
}

export const postWithTokenApiV2 = <Response, Body>(url: string, body?: Body, config?: AxiosRequestConfig) => {
  return apiWebClientV2
    .post<Response>(`${url}`, body, config)
    .then((response) => response)
    .catch((error) => {
      return {
        data: error.response?.data,
        status: error.response?.status
      }
    })
}

export const deleteWithTokenApiV2 = <Response, Body>(
  url: string,
  body?: Body,
  config?: AxiosRequestConfig
) => {
  return apiWebClientV2.delete<Response>(`${url}`, { ...config, data: body })
}
