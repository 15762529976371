import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export interface IExtractTableInitialState {
  searchFilter?: string
  methodId?: number
  budgetId?: number
  type?: string
  categoryId?: number
  sort?: string
  pagination: { pageIndex: number; pageSize: number }
}

export interface IExtractTableStore extends IExtractTableInitialState {
  setSearchFilter: (searchFilter: string) => void
  setMethodId: (methodId: number) => void
  setBudgetId: (budgetId: number) => void
  setType: (type: string) => void
  setCategoryId: (categoryId: number) => void
  setPagination: (pagination: { pageIndex: number; pageSize: number }) => void
}

const initialState: IExtractTableInitialState = {
  searchFilter: '',
  methodId: undefined,
  budgetId: undefined,
  type: undefined,
  categoryId: undefined,
  sort: undefined,
  pagination: { pageIndex: 0, pageSize: 15 }
}

export const useExtractTableStore = create<IExtractTableStore>()(
  persist(
    (set) => ({
      ...initialState,
      setSearchFilter: (searchFilter: string) => set({ searchFilter }),
      setMethodId: (methodId: number) => set({ methodId }),
      setBudgetId: (budgetId: number) => set({ budgetId }),
      setType: (type: string) => set({ type }),
      setCategoryId: (categoryId: number) => set({ categoryId }),
      setPagination: (pagination: { pageIndex: number; pageSize: number }) => {
        console.log('setPagination', pagination)
        set({ pagination })
      }
    }),
    {
      name: 'extractTableStore'
    }
  )
)
