import { format } from 'date-fns'
import React, { useMemo } from 'react'
import { Column } from 'react-table'
import ReactTooltip from 'react-tooltip'

// import { InvestmentValueInput } from '../../Inputs/editableInput/investmentValueInput'
import { formatMonetaryValue } from '../../../domain/formatter'
// import { InvestmentNameInput } from '../../Inputs/editableInput/investmentNameInput'
import { IGetAllTransactionsResultItem } from '../../../domain/investments/api/ManualInvestments/types'
import { formatCurrencyBRL } from '../../Utils'
import { ButtonDelete } from '../EquityInvestmentsByUserIdTable/Columns/ButtonDelete'
import { IUseColumns } from '../EquityInvestmentsByUserIdTable/Columns/types'

export const useColumns = ({ handleDelete }: IUseColumns) => {
  const columns = useMemo((): Column<IGetAllTransactionsResultItem>[] => {
    return [
      {
        Header: 'Data do lançamento',
        id: 'buyDate',
        accessor: 'buyDate',
        Cell: ({ value }) => format(new Date(value), 'dd/MM/yyyy')
      },
      {
        Header: 'Valor',
        id: 'amount',
        accessor: 'amount',
        Cell: (row) => {
          const {
            value
            // row: {
            //   values: { id, name }
            // }
          } = row

          return `R$ ${formatMonetaryValue(value)}`
          // return <InvestmentValueInput key={value} investmentId={id} currentValue={value || 0} name={name} />
        }
      },
      {
        Header: 'Instituição financeira',
        id: 'financialInstitution',
        accessor: 'financialInstitution',
        Cell: (row) => {
          const {
            value: financialInstitutionCode
            // row: {
            //   values: { id }
            // }
          } = row

          // return (
          //   <InvestmentNameInput
          //     currentName={financialInstitutionCode || ' - - - '}
          //     investmentId={id}
          //     key={financialInstitutionCode || 'financialInstitutionCode'}
          //   />
          // )
          return financialInstitutionCode
        }
      },
      {
        Header: 'Quantidade da cota',
        id: 'quantity',
        accessor: 'quantity',
        Cell: ({ value }) => {
          return value ? (
            value
          ) : (
            <>
              <span data-tip="Quantidade da cota">- - -</span>
              <ReactTooltip place="top" type="dark" effect="float" />
            </>
          )
        }
      },
      {
        Header: 'Valor da cota',
        id: 'valueQuota',
        accessor: 'valueQuota',
        Cell: ({ value }) =>
          value ? (
            `R$ ${formatMonetaryValue(value)}`
          ) : (
            <>
              <span data-tip="Valor da cota">- - -</span>
              <ReactTooltip place="top" type="dark" effect="float" />
            </>
          )
      },
      // {
      //   Header: 'Data da cota',
      //   id: 'dateQuota',
      //   accessor: 'dateQuota',
      //   Cell: (row) => {
      //     const {
      //       value: dateQuota,
      //       row: {
      //         values: { id },
      //       },
      //     } = row

      //     return (
      //       <InvestmentNameInput currentName={dateQuota || ''} investmentId={id} key={dateQuota} />
      //     )
      //   },
      // },
      // {
      //   Header: 'Taxa anual',
      //   id: 'taxes',
      //   accessor: 'taxes',
      //   Cell: ({ value }) =>
      //     value ? (
      //       `R$ ${formatMonetaryValue(value)}`
      //     ) : (
      //       <>
      //         <span data-tip="Taxa anual">- - -</span>
      //         <ReactTooltip place="top" type="dark" effect="float" />
      //       </>
      //     )
      // },
      {
        Header: 'Tipo de movimentação',
        id: 'type',
        accessor: 'type'
      },
      {
        Header: 'Descrição',
        id: 'description',
        accessor: 'description'
      },
      // {
      //   Header: 'Considerar na Gestão de Orçamento',
      //   id: 'enableBudgetManager',
      //   accessor: 'enableBudgetManager',
      //   Cell: ({ value }) => (value ? <i className="ni ni-check-bold" /> : '')
      // },
      {
        Header: 'Controle',
        id: 'id',
        accessor: 'id',
        disableSortBy: true,
        Cell: (row) => {
          const {
            row: {
              original: { amount, transactionId, financialInstitution, type }
            }
          } = row

          if (handleDelete) {
            return (
              <ButtonDelete
                id={transactionId}
                name={`${type} (${financialInstitution}): ${formatCurrencyBRL(amount)}`}
                handleDelete={handleDelete}
                // isManual={original.isManual}
              />
            )
          }
          return null
        }
      }
    ]
  }, [handleDelete])
  return columns
}
