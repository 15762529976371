import React, { createContext, useContext } from 'react'

import { usePostDashboardOrcamentoPeriodoByFamilyIdMutation } from '../../../queries/dashboard/queries'
import { parseDataOrcamentoPeriodo } from './services'

export const DashboardContext = createContext({})

export const DashboardProvider = ({ children }) => {
  const {
    postDashboardOrcamentoPeriodoByFamilyIdAsync,
    data,
    isLoadingPostDashboardOrcamentoPeriodoByFamilyId,
    postDashboardOrcamentoPeriodoByFamilyIdSuccess,
    postDashboardOrcamentoPeriodoByFamilyIdError,
    resetPostDashboardOrcamentoPeriodoByFamilyId
  } = usePostDashboardOrcamentoPeriodoByFamilyIdMutation()

  return (
    <DashboardContext.Provider
      value={{
        postDashboardOrcamentoPeriodoByFamilyIdAsync: (props) =>
          postDashboardOrcamentoPeriodoByFamilyIdAsync(props),
        dashboardOrcamentoPeriodo: parseDataOrcamentoPeriodo(data),
        isLoadingPostDashboardOrcamentoPeriodoByFamilyId,
        postDashboardOrcamentoPeriodoByFamilyIdSuccess,
        resetPostDashboardOrcamentoPeriodoByFamilyId,
        postDashboardOrcamentoPeriodoByFamilyIdError
      }}
    >
      {children}
    </DashboardContext.Provider>
  )
}

export const useDashboardContext = () => {
  const context = useContext(DashboardContext)

  if (!context) {
    throw new Error('DashboardContext ===> Need Wrap User container')
  }

  return context
}
