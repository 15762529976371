import styled from 'styled-components'

import { ButtonDefault } from './ButtonDefault'

export const LiquidButton = styled(ButtonDefault)`
  && {
    @media screen and (min-width: 1024px) {
      min-width: auto;
    }
  }
`
