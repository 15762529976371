import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import React, { useState } from 'react'

import { useBankPdfTypes } from '../../queries/extrato/queries'
import { DownloadExcelDefault } from '../Buttons'
import { ModalV2 } from '../Modal/ModalV2'
import { ButtonGradient } from '../Mui/Button'
import { FormExtractUpload } from './FormExtractUpload'

export const UploadExtratoV2 = () => {
  const [banco] = useState<string>()
  const [typePdf, setTypesPdf] = useState<string>()
  const [year, setYear] = useState<number>()
  const typesPDF = useBankPdfTypes(banco)
  const [extractMenuIsOpen, setExtractMenuIsOpen] = useState(false)

  const displayPdfTypes = typesPDF && typesPDF.length > 0
  if (!displayPdfTypes && typePdf) setTypesPdf(undefined)

  const displayYearDropdown = banco?.toLowerCase()?.startsWith('btg')
  if (!displayYearDropdown && year) setYear(undefined)

  return (
    <>
      <DownloadExcelDefault />

      <ButtonGradient
        tabIndex={-1}
        startIcon={<CloudUploadIcon />}
        onClick={() => setExtractMenuIsOpen(!extractMenuIsOpen)}
      >
        Upload de Extrato
      </ButtonGradient>

      <ModalV2 open={extractMenuIsOpen} close={() => setExtractMenuIsOpen(false)} title="Upload de Extrato">
        <FormExtractUpload extractMenuIsOpen={extractMenuIsOpen} />
      </ModalV2>
    </>
  )
}
