import React from 'react'
import styled from 'styled-components'

import Colors from '../../domain/Colors'
import TextInputTitle from './TextInputTitle'

const Container = styled.div<{ noPadding?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: ${({ noPadding }) => (noPadding ? 0 : '0 15px 0 15px')};
  width: 100%;
`

const Input = styled.input`
  height: calc(2.75rem + 2px);
  padding: 5px 8px;
  color: ${Colors.disabledTextGray};
  background-color: ${Colors.disabledTextBackground};
  border-radius: 1.3rem;
  border: 0px solid white;
  font-size: 0.875rem;
  cursor: text;

  :disabled {
    opacity: 0.5;
  }
`

interface Props {
  disabled?: boolean
  noPadding?: boolean
  maxLength?: number
  onChange?: (newText: string) => void
  text?: string
  title: string
}

export default ({ text, title, onChange, disabled = false, maxLength, noPadding }: Props) => (
  <Container noPadding={noPadding}>
    <TextInputTitle>{title}</TextInputTitle>
    <Input
      type="text"
      value={text || ''}
      disabled={!onChange || disabled}
      onChange={({ target: { value } }) => onChange?.(value)}
      maxLength={maxLength || 1024}
    />
  </Container>
)
