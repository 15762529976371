import React, { ReactNode } from 'react'
import styled from 'styled-components'

const Container = styled.div<{ rounded?: boolean; lowIndex?: boolean }>`
  /* justify-content: flex-start; */
  align-items: center;
  background-color: black;
  border-radius: ${({ rounded }) => (rounded ? '16px' : '0')};
  color: white;
  display: flex;
  flex-direction: column;
  font-weight: bold;
  height: 100vh;
  justify-content: center;
  opacity: 0.65;
  padding-top: 5vh;
  left: 0;
  top: 0;
  position: fixed;
  width: 100%;
  z-index: ${({ lowIndex }) => (lowIndex ? 98 : 9999)};
`

interface Props {
  children?: ReactNode
  rounded?: boolean
  lowIndex?: boolean
}

export default ({ children, rounded, lowIndex }: Props) => {
  return (
    <Container rounded={rounded} lowIndex={lowIndex}>
      {children}
    </Container>
  )
}
