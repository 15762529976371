import React from 'react'

import { fixedEquityType, TFixedEquity } from '../../../domain/investments/fixedEquity'
import Select from '../Select'
import { IEquityTypeSelect } from './types'

export const EquityTypeSelect = ({ type, onChange, borderless = false, disabled }: IEquityTypeSelect) => {
  const selected = type || 'Selecione...'

  const options = fixedEquityType.map((type: TFixedEquity) => ({
    text: type,
    value: type
  }))

  return (
    <Select
      borderless={borderless}
      disabled={disabled}
      grayscale={true}
      onSelection={onChange}
      options={options}
      placeholder={selected}
    />
  )
}
