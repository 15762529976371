import { useHistory } from 'react-router-dom'
import { Row } from 'reactstrap'

import { useCoreContext } from '../../../hooks/contexts/coreContext/coreProvider'
import { ModalV2 } from '../../Modal/ModalV2'
import { FamilySelect } from './FamilySelect'

export const ModalClientSelect = ({
  isOpen,
  onClose
}: {
  isOpen: boolean
  onClose: () => void
  onChange?: (familyId: string) => void
}) => {
  const { tipoUsuario: userType } = useCoreContext()
  const tipoUsuario = Number(userType)
  const history = useHistory()

  const onchange = () => {
    history.push('/admin/home')
    onClose()
  }

  return (
    <ModalV2
      usePrimary={Number(tipoUsuario) > 1}
      open={isOpen}
      close={onClose}
      title={
        <div>
          <div className="pop-up-title">
            <i className="ni ni-check-bold"></i>
            <h2>Seleção de Cliente</h2>
          </div>
        </div>
      }
    >
      <Row>
        {/* @ts-ignore */}
        <FamilySelect onClose={onClose} onChange={onchange} />
      </Row>
    </ModalV2>
  )
}
