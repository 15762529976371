import { ItemStatus } from 'pluggy-js'

import { ITEM_STATUSES } from '../../../domain/bankingIntegration/Connection'

const translateLabelsToPortuguese = (value: ItemStatus | string): string => {
  switch (value) {
    // case ITEM_STATUSES.CREATING:
    //   return 'CRIANDO'
    case ITEM_STATUSES.LOGIN_ERROR:
      return 'CREDENCIAIS ERRADAS'
    // case ITEM_STATUSES.MERGING:
    //   return 'CONSOLIDANDO DADOS'
    case ITEM_STATUSES.OUTDATED:
      return 'DESATUALIZADO'
    case ITEM_STATUSES.UPDATED:
      return 'ATUALIZADO'
    case ITEM_STATUSES.UPDATING:
      return 'ATUALIZANDO...'
    case ITEM_STATUSES.WAITING_USER_INPUT:
      return 'AGUARDANDO USUÁRIO'
    case 'TOKEN_NEEDED':
      return 'ATUALIZAR AQUI'
    default:
      return value
  }
}

export default translateLabelsToPortuguese
