import styled from 'styled-components'

import Colors from '../../../domain/Colors'
import { TipoOrcamento } from '../../../domain/orcamento/Orcamento'

interface Props {
  tipo: TipoOrcamento
}

export default styled.span<Props>`
  color: ${({ tipo }) => {
    if (
      tipo === TipoOrcamento.Despesa ||
      tipo === TipoOrcamento.Divida ||
      tipo === TipoOrcamento.InvestimentoResgate
    )
      return Colors.red
    else if (tipo === TipoOrcamento.Investimento || tipo === TipoOrcamento.Receita) return Colors.green
    else return Colors.black
  }};
`
