import React from 'react'

import Button from '../Buttons'
import * as S from './styles'

export const TimeoutModal = ({ isOpen, onRequestClose, onLogOff }) => {
  return (
    <S.Modal open={isOpen} onClose={onRequestClose} title="Sessão expirada">
      <S.Content>
        <p>O seu tempo está expirando devido a inatividade.</p>
        <p>
          Opte por manter a sessão iniciada ou fazer logoff. <br />
          Caso contrário, a sessão será terminada automaticamente.
        </p>
      </S.Content>

      <S.Footer>
        <Button onClick={onRequestClose} text="Manter sessão iniciada" />
        <Button onClick={onLogOff} text="Fazer logoff" />
      </S.Footer>
    </S.Modal>
  )
}
