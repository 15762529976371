import { Typography } from '@mui/material'
import React from 'react'

import * as S from './styles'

const ICONS_MAP = {
  calendar: <S.IconCalendar />,
  tips: <S.IconTipsIdea />,
  dashboard: <S.IconDashboard />
}

export const PageHeader = ({
  title,
  children,
  icon = 'calendar',
  className
}: {
  title: string
  children?: React.ReactNode
  icon?: 'calendar' | 'tips' | 'dashboard'
  className?: string
}) => {
  return (
    <S.PageHeader className={className}>
      <S.TitleContainer>
        {ICONS_MAP[icon]}
        <Typography variant="h6">{title}</Typography>
      </S.TitleContainer>

      {children}
    </S.PageHeader>
  )
}
