import React from 'react'

import { ConcatChildrenPagination, Pagination } from './components/Pagination'
import { IframeVimeo } from './components/VideoIframe/Iframe'

interface IContent {
  children: React.ReactNode
  concatBefore?: boolean
}

export const COMMOM_CONTENT = {
  Home: {
    Cliente: () => (
      <div>
        <IframeVimeo title="Home" videoId="1006569242?h=5596fd4776" />
      </div>
    )
  },
  GestaoOrcamento: () => (
    <Pagination>
      <div>
        <IframeVimeo title="Novo lançamento de receita e despesa" videoId="1004612863?h=9dfd72ecf3" />
      </div>

      <div>
        <IframeVimeo title="Criação de um orçamento despesas e receitas" videoId="1008221871?h=c4114ae3e6" />
      </div>

      <div>
        <IframeVimeo
          title="Edição, Exclusão, Suspensão de Orçamentos e Categorias"
          videoId="1004607888?h=34ffe448d9"
        />
      </div>

      <div>
        <IframeVimeo title="Criação de uma nova categoria de orçamento" videoId="1004607365?h=018dbeb5dd" />
      </div>

      <div>
        <IframeVimeo title="Resumos das estimativas e realizados" videoId="1005516686?h=2d14cdb40a" />
      </div>

      <div>
        <IframeVimeo title="Visão dos orçamentos/grupos/categorias" videoId="1004613456?h=8eb7395026" />
      </div>

      <div>
        <IframeVimeo
          title="Descrição, ordenação de colunas e filtros do orçamento"
          videoId="1006569955?h=854239a3c9"
        />
      </div>

      <div>
        <IframeVimeo title="Notificação de atingimento de meta" videoId="1006570592?h=e37b167786" />
      </div>
    </Pagination>
  ),
  Extrato: () => (
    <Pagination>
      <div>
        <IframeVimeo
          title="Extrato download e utilização do excel “Padrão Vista”"
          videoId="1004606583?h=5d7ff773a9"
        />
      </div>

      <div>
        <IframeVimeo title="Extrato como fazer o Upload" videoId="1006569658?h=15056e53c6" />
      </div>

      <div>
        <IframeVimeo title="Extrato descrição geral" videoId="1006571710?h=8028669e10" />
      </div>

      <div>
        <IframeVimeo
          title="Extrato categorização das receitas e despesas"
          videoId="1006573118?h=4fb136d02c"
        />
      </div>
    </Pagination>
  ),
  Dividas: () => (
    <Pagination>
      <div>
        <IframeVimeo title="Cadastro de dívidas" videoId="1006570274?h=5356dd067e" />
      </div>

      <div>
        <IframeVimeo title="Lançamento e atualização de dívidas" videoId="1005517217?h=a17e57a99f" />
      </div>
    </Pagination>
  ),
  PlanosSonhos: () => (
    <div>
      <IframeVimeo title="Cadastro de Planos e Sonhos" videoId="1005955264?h=e38ba4387c" />
    </div>
  ),
  GestaoPlanos: ({ children, concatBefore }: IContent) => {
    if (concatBefore) {
      return (
        <ConcatChildrenPagination>
          {children}
          <IframeVimeo title="Gestão de Planos" videoId="1003782632?h=e3f97a7808" />
        </ConcatChildrenPagination>
      )
    } else {
      return (
        <ConcatChildrenPagination>
          <IframeVimeo title="Gestão de Planos" videoId="1003782632?h=e3f97a7808" />
          {children}
        </ConcatChildrenPagination>
      )
    }
  },
  IndependenciaFinanceira: () => (
    <Pagination>
      <div>
        <IframeVimeo title="Tutorial Independência Financeira" videoId="1005523055?h=49a07ad9a0" />
      </div>

      <div>
        <IframeVimeo title="Como atualizar o plano" videoId="1008221692?h=f279ae11a9" />
      </div>
    </Pagination>
  ),
  Patrimonio: {
    Integracoes: () => (
      <Pagination>
        <div>
          <IframeVimeo title="Patrimônio" videoId="1004624437?h=cedde2a11d" />
        </div>

        <div>
          <IframeVimeo title="Novo lançamento de Investimentos" videoId="1004612002?h=b61661ed68" />
        </div>

        <div>
          <IframeVimeo title="Cadastro Manual de Patrimônio" videoId="1004617550?h=74a185757b" />
        </div>
      </Pagination>
    )
  },
  DestinacaoPatrimonio: () => (
    <div>
      <IframeVimeo title="Destinação de Patrimônio" videoId="1005518141?h=e7e2e00b6d" />
    </div>
  ),
  Integracoes: () => (
    <Pagination>
      <div>
        <IframeVimeo title="Integrações bancárias e de investimento" videoId="1005956062?h=0fcd3d74df" />
      </div>

      <div>
        <IframeVimeo title="Integrações bancárias - Descrição da tela" videoId="1006571427?h=1b8f4f3109" />
      </div>
    </Pagination>
  ),
  Configuracoes: {
    Usuarios: ({ children }: { children?: React.ReactNode }) => (
      <ConcatChildrenPagination>
        <div>
          <IframeVimeo
            videoId="1003803339?h=ac2aaf05dd"
            title="Cadastrar novos usuários dentro de uma família"
          />
        </div>

        <div>
          <IframeVimeo videoId="1005515552?h=327d76e120" title="Registrar meios de Pagamento do usuário" />
        </div>

        {children}
      </ConcatChildrenPagination>
    ),
    ConfiguracaoInicial: () => (
      <div>
        <IframeVimeo videoId="1005516190?h=96dbb27147" title="Configuração inicial" />
      </div>
    )
  }
}
