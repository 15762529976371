import React, { FunctionComponent, useCallback, useState } from 'react'

import { getDarker, getLighter } from '../../components/Utils/Colors'
import Theme, { defaultTheme } from '../../domain/theme'
import { getTheme } from '../../domain/theme/api/getTheme'
import { useObjectLocalStorage } from '../useLocalStorage'
import { CurrentMuiThemeProvider, LegacyMuiProvider } from './coreContext/useMuiTheme'

interface ContextValue {
  theme: Theme
  changeTheme: (themeName: string) => Promise<void>
}

interface Props {
  children: React.ReactNode
}

const Context = React.createContext<ContextValue>({
  theme: defaultTheme,
  changeTheme: async () => {}
})

const isDefaultTheme = (themeName: string) =>
  !themeName || themeName === 'default' || themeName === 'undefined' || themeName === 'null'

const Provider: FunctionComponent<Props> = ({ children }) => {
  const [usingDefaultTheme, setIsUsingDefaultTheme] = useState(true)
  const { value: theme, setValue: setTheme } = useObjectLocalStorage('theme-content', defaultTheme)

  const changeTheme = useCallback(
    async (themeName: string) => {
      if (isDefaultTheme(themeName)) {
        if (!usingDefaultTheme) {
          setIsUsingDefaultTheme(true)
          setTheme({
            ...defaultTheme,
            colors: {
              ...defaultTheme.colors,
              primaryLighter: getLighter(theme?.colors?.primaryLighter, theme?.colors?.primary),
              secondaryLighter: getLighter(theme?.colors?.secondaryLighter, theme?.colors?.secondary),
              primaryDarker: getDarker(theme?.colors?.primaryDarker, theme?.colors?.primary),
              secondaryDarker: getDarker(theme?.colors?.secondaryDarker, theme?.colors?.secondary)
            }
          })
        } else {
          console.debug('Already using default theme, skipping theme change')
        }
        return
      }
      try {
        const newTheme = await getTheme(themeName)
        setIsUsingDefaultTheme(false)
        setTheme({
          ...newTheme,
          name: themeName.split('-')[1],
          colors: {
            ...newTheme.colors,
            primaryLighter: getLighter(newTheme?.colors?.primaryLighter, newTheme?.colors?.primary),
            secondaryLighter: getLighter(newTheme?.colors?.secondaryLighter, newTheme?.colors?.secondary),
            primaryDarker: getDarker(newTheme?.colors?.primaryDarker, newTheme?.colors?.primary),
            secondaryDarker: getDarker(newTheme?.colors?.secondaryDarker, newTheme?.colors?.secondary)
          }
        })
      } catch (err) {
        console.error('Error setting new theme as', themeName, err)
      }
    },
    [usingDefaultTheme, setTheme, theme]
  )

  const currentTheme = useCallback(() => theme || defaultTheme, [theme])

  return (
    <Context.Provider value={{ theme: currentTheme(), changeTheme }}>
      <LegacyMuiProvider theme={currentTheme()}>
        <CurrentMuiThemeProvider theme={currentTheme()}>{children}</CurrentMuiThemeProvider>
      </LegacyMuiProvider>
    </Context.Provider>
  )
}

export { Context as ThemeContext, Provider as ThemeProvider }
export type { ContextValue as ThemeContextValue, Props as ThemeProviderProps }
