import { Typography } from '@mui/material'
import React from 'react'

import { TAB_CODE_TO_NAME, TABS } from '../SaveDataTransaction/types'
import * as S from './styles'

export const TitleModal = ({ item, state, setActiveTab, activeTab }) => {
  const title = state?.editingTransaction ? 'Edição de Lançamento' : 'Cadastro de Lançamento'
  let subtitle: undefined | string

  if (item?.patrimonio !== null && item?.patrimonio?.vistaType) {
    subtitle = ` - ${item.patrimonio.vistaType}`
  }

  return (
    <S.ModalHeader>
      <div className="pop-up-title" style={{ marginBottom: '10px' }}>
        <Typography variant="h6" component="h2">
          {title}
          {subtitle && <span style={{ fontSize: '14px' }}>{subtitle}</span>}
        </Typography>
      </div>

      <S.Navigation>
        {TABS.map((tab) => (
          <S.NavItem
            id={`lancamento-${tab.toLowerCase()}`}
            key={`lancamento-${tab.toLowerCase()}`}
            className={`lancamento-opcoes${activeTab === tab ? ' active' : ''}`}
            variant={activeTab === tab ? 'contained' : 'outlined'}
            onClick={(event) => {
              event.preventDefault()

              if (activeTab !== tab) {
                setActiveTab(tab)
              }
            }}
          >
            <span className="nav-link-inner--text">{TAB_CODE_TO_NAME[tab]}</span>
          </S.NavItem>
        ))}
      </S.Navigation>
    </S.ModalHeader>
  )
}
